<template>
	<v-container>
		<v-layout row wrap>
			<v-flex xs12>
				<h1>Existing Quotes</h1>
				<v-data-table></v-data-table>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
const sclExistingQuotesView = {
	name: 'SclExistingQuotesView',
	props: {
		api: {
			type: Object,
			default: () => {}
		}
	}
};

export default sclExistingQuotesView;
</script>
<style lang="scss"></style>
