import axios from 'axios';

// component
const component = {
	name: 'SclInvoiceAmountModal',
	data() {
		return {
			dialog: false,
			account: null,
			credits: [],
			results: [],
			invoice: {}
		};
	},
	mounted() {
		const self = this;
		this.$root.$on(this.propApi.event, async event => {
			this.dialog = true;
			this.invoice = event;
			await self.submit();
		});
		this.results = this.propApi.tableData.tableItems;
		this.invoice = this.propApi.invoice;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.customerData ? typeof obj.customerData === 'object' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		items() {
			if (!this.results || this.results.length == 0) {
				return this.propApi.customerData ? this.propApi.customerData.customers : [];
			}
			return this.results;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'invoice-amount-event',
					width: '645',
					modalLabel: '',
					persistent: false,
					postbackURL: 'http://portal.sc92.sc/api/v1/mch/invoices/amount',
					invoice: {
						ref: '1233',
						date: '11/11/20',
						site: 'sydney',
						purchaseOrder: '12334'
					},
					tableData: {
						headers: [
							{
								text: 'Payment IDs',
								align: 'left',
								sortable: false,
								value: 'payID',
								class: 'pt-0 pl-0',
								width: '20%'
							},
							{
								text: 'Payment Method',
								value: 'payMethod',
								align: 'left',
								sortable: false,
								width: '30%'
							},
							{ text: 'Date', value: 'date', align: 'left', sortable: false, width: '25%' },
							{
								text: 'Amount (incl GST)',
								value: 'amount',
								align: 'left',
								sortable: false,
								width: '25%'
							}
						],
						tableItems: []
					}
				},
				this.api
			);
		}
	},
	methods: {
		async submit() {
			try {
				this.loading = true;
				const payload = {
					invoiceNumber: this.invoice.invoiceNumber
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				this.loading = false;
				if (response.status == 200 && response.data.success) {
					this.results = response.data.result.invoiceAppliedAmount;
					this.credits = response.data.result.invoiceCredit;
				} else {
					this.errorMessage = 'Send email failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Send email failed, please try again later.';
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
