/**
 * SclButton api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * outline prop
 * @param {boolean} true
 *
 * copy prop
 * @param {string} 'lorem ipsum'
 *
 * icon prop
 * @param {string} 'fas fa-lock'
 *
 * type prop
 * @param {string} 'submit'
 *
 * target prop
 * @param {string} '_blank'
 */

// template
const template = `
	<v-btn depressed class="subheading scl-component scl-button" :color="propApi.color" v-on:click="clickEvent(propApi.modal)" :outline="propApi.outline" :block="propApi.block" :target="propApi.target" :class="{'scl-button--outline' : propApi.outline }">
		<v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon> {{ propApi.copy }}
		<div class="scl-button__tally" v-if="propApi.tally">
			<p class='mb-0'>{{propApi.tally}}</p>
		</div>
	</v-btn>
`;

// export template
export default template;
