// css import
require('./_scl-snackbar.scss');

// component
const component = {
	name: 'SclSnackbar',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.buttonColour ? typeof obj.buttonColour === 'string' : true));
				set.add(!!(obj.vertical ? typeof obj.vertical === 'string' : true));
				set.add(!!(obj.horizontal ? typeof obj.horizontal === 'string' : true));
				set.add(!!(obj.autoHeight ? typeof obj.autoHeight === 'boolean' : true));
				set.add(!!(obj.timeout ? typeof obj.timeout === 'number' : true));
				set.add(!!(obj.snackColour ? typeof obj.snackColour === 'string' : true));
				set.add(!!(obj.snackButtonColour ? typeof obj.snackButtonColour === 'string' : true));
				set.add(!!(obj.copy ? typeof obj.copy === 'string' : true));
				set.add(!!(obj.subcopy ? typeof obj.subcopy === 'string' : true));
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		this.options = Object.assign({}, this.options, this.api);
		this.$root.$on(this.propApi.event, event => {
			if (event) {
				this.options.closeButtonHidden = !!event.closeButtonHidden; // this option is not used since 16th Sep 2022
				this.options.copy = event.copy || '';
				this.options.subcopy = event.subcopy || '';
				this.options.type = event.type || '';
				this.options.timeout = event.timeout || 0;
				this.options.showCloseButton = event.showCloseButton || false;
			}
			this.snackbar = true;
		});
	},
	computed: {
		propApi() {
			return Object.assign({}, this.options);
		}
	},
	data() {
		return {
			snackbar: false,
			options: {
				autoHeight: true,
				buttonColour: '#ff0000',
				closeButtonHidden: false, // this option is not used since 16th Sep 2022
				showCloseButton: false,
				copy: '',
				event: 'snackbar-event',
				horizontal: '',
				icon: '',
				snackButtonColour: '#ffffff',
				snackColour: '#3333333',
				subcopy: '',
				timeout: 0,
				type: 'success', //error | success
				vertical: ''
			}
		};
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
