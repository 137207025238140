<template>
	<v-container fluid class="scl-bar-chart-table__container">
		<v-layout row wrap fill-height>
			<v-flex xs12>
				<v-data-table :headers="tableHeaders" :items="itemsGroupedByMA" class="subheading" hide-actions>
					<template v-slot:headers="props">
						<tr>
							<th v-for="header in props.headers" :key="'h-' + header.key" class="scl-bar-chart-table__header-chart">
								<div class="scl-bar-chart-table__header-chart-container">
									<div class="scl-bar-chart-table__header-chart--bar" :style="{ height: chartScale(header.value) + '%' }"></div>
								</div>
								<p class="body-1 mb-0 py-2">{{ header.text }}</p>
							</th>
						</tr>
					</template>
					<template v-slot:items="props">
						<td colspan="12" class="pa-0 pt-4">
							<div class="scl-bar-chart-table__item-row">
								<div class="column-master-account">{{ props.item[0] }}</div>
								<template v-for="(item, key) in props.item">
									<tr v-if="key !== 0" class="column-site-values" :key="key">
										<div class="scl-bar-chart-table__item-row">
											<div class="column-sitename">{{ item.siteName }}</div>
											<template v-for="(value, ikey) in item">
												<div
													v-if="ikey !== 'siteName' && ikey !== 'masterAccountCode' && ikey !== 'masterAccountName'"
													:key="ikey"
													class="column-amount"
													:class="{ 'text-xs-center': value === 0 }"
												>
													{{ formatValue(value, true) }}
												</div>
											</template>
										</div>
									</tr>
								</template>
							</div>
						</td>
					</template>
					<template v-slot:footer>
						<td colspan="12" class="pa-0">
							<div class="scl-bar-chart-table__item-row">
								<div class="column-sitename font-weight-bold">SUBTOTAL:</div>
								<template v-for="(item, key) in subTotalRow">
									<div
										v-if="key !== 'siteName' && key !== 'masterAccountCode' && key !== 'masterAccountName'"
										:key="key"
										class="column-amount font-weight-bold"
									>
										{{ formatValue(item) }}
									</div>
								</template>
							</div>
						</td>
					</template>
					<template v-slot:no-data="props">
						<td :colspan="props.headers.length" class="py-4 text-xs-center">
							<p class="mb-0 caption text-capitalize font-weight-bold">no available site data</p>
						</td>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex xs12 d-flex class="scl-bar-chart-table__footer--highlight px-3 py-4">
				<v-layout row wrap>
					<v-flex xs12 md6 d-flex align-center :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"> </v-flex>
					<v-flex xs12 md6>
						<div class="text-xs-left text-md-right font-weight-bold headline"><span class="pr-4">TOTAL:</span>${{ formatValue(tableTotal) }}</div>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { formatAmount } from '@/_scl-portals/utils/formatter';

export default {
	name: 'SclBarChartTable',
	props: {
		tableData: {
			type: Object,
			default: () => ({
				headers: [],
				tableItems: []
			})
		}
	},
	computed: {
		chartMax() {
			return this.tableData.headers.reduce((a, b) => (b.value > a ? b.value : a), 0);
		},
		tableHeaders() {
			return this.tableData.headers.slice(1, -1);
		},
		tableItems() {
			return this.tableData.tableItems.slice(0, -1);
		},
		itemsGroupedByMA() {
			let groupByMA = [];
			groupByMA = this.tableItems.reduce((group, item) => {
				const { masterAccountName } = item;
				group[masterAccountName] = group[masterAccountName] ?? [];
				group[masterAccountName].push(item);
				return group;
			}, {});
			const groupedByMAarr = Object.keys(groupByMA).map(key => [key, ...groupByMA[key]]);
			return groupedByMAarr;
		},
		subTotalRow() {
			const row = this.tableData.tableItems.slice(-1)[0] || {};
			delete row.siteName;
			delete row.masterAccountCode;
			delete row.masterAccountName;
			return row;
		},
		tableTotal() {
			return Object.values(this.subTotalRow).reduce((a, b) => a + b, 0);
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		formatValue(val, useDash = false) {
			const value = val === 0 ? (useDash ? '—' : 0) : `${val || ''}`;
			return parseInt(value) >= 0 ? formatAmount(value) : value;
		},
		chartScale(val) {
			const scale = (val / this.chartMax) * 100;
			return scale;
		}
	}
};
</script>

<style lang="scss" scoped>
$min-fixed-layout: 1180px;
$max-fixed-layout: 1400px;

.scl-bar-chart-table {
	&__container {
		::v-deep table.v-table {
			tbody td,
			tfoot td {
				padding: 0 12px;
				font-size: 16px !important;
			}
			@media (min-width: $min-fixed-layout) {
				table-layout: fixed;
			}
		}
		thead {
			tr {
				height: 90px;
			}
			th {
				min-width: 70px;
				height: 90px;
				padding: 0 !important;

				@media (max-width: $md) {
					min-width: 55px;
				}

				@media (max-width: $sm) {
					min-width: 100px;
				}
			}
		}
	}
	&__footer {
		&--highlight {
			background-color: $color-grey7;
		}
	}

	&__header-chart {
		padding: 0;
		&--bar {
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 50%;
			background-color: $color-orange;
			transform: translateX(-50%);

			@media (max-width: $md) {
				width: 50%;
			}

			@media (max-width: $sm) {
				width: 60%;
			}
		}
		@media (max-width: $sm) {
			.body-1 {
				font-size: 12px !important;
			}
		}
	}
	&__header-chart-container {
		position: relative;
		height: 430px;
		border-bottom: 1px solid $color-grey4;

		@media (max-width: $md) {
			height: 320px;
		}

		@media (max-width: $sm) {
			height: 200px;
		}
	}
	&__item-row {
		display: flex;
		flex-flow: row wrap;

		.column-sitename {
			flex-basis: 100%;
			padding: 8px 8px 0;
			color: $color-grey3;
		}
		.column-master-account {
			flex-basis: 100%;
			padding: 24px 8px 0;
			color: $accent;
			font-weight: $font-weight-bold;
			border-bottom: 2px solid $color-grey4;
			font-size: 18px;
		}
		.column-site-values {
			flex-basis: 100%;
			padding: 8px 8px 0;
		}
		.column-amount {
			flex: 1 1 0;
			padding: 0 8px 8px;
			text-align: right;

			@media (min-width: $min-fixed-layout) and (max-width: $max-fixed-layout) {
				font-family: 'Arial Narrow';
			}
		}
	}
}
</style>
