/**
 * SclSocialShare api
 *
 **/

const template = `
	<div class="scl-threshold-setting scl-form">
		<v-container grid-list-xl fluid>
			<v-layout row wrap>
				<v-flex xs12>
					<h2 class="scl-threshold-setting__title pb-1 font-weight-black">Global 'due within threshold' SETTINGS </h2>
				</v-flex>
				<v-flex xs12 class="pb-0">
					<h3 class="pb-2">Current "Due within X Days" Status</h3>
				</v-flex>
				<v-flex xs12 class="pt-0">
					<v-chip label outline text-color="#4E4A46" class="font-weight-bold scl-threshold-setting__chip subheading">
						<v-icon size="16" left color="#63BF00">fas fa-circle</v-icon>Due within {{threshold}} days
					</v-chip>
				</v-flex>
				<v-flex xs12 class="pb-0">
					<h3 class="pb-2">Change Threshold (1-30 Days)</h3>
				</v-flex>
				<v-flex xs12 class="scl-threshold-setting__step-input pt-0">
					<v-text-field v-model="threshold" class="scl-form__step-number font-weight-bold" hide-details solo flat type="number" label="Number" prepend-icon="fas fa-minus" append-outer-icon="fas fa-plus" @click:append-outer="increment" @click:prepend="decrement"></v-text-field>
				</v-flex>
				<v-flex xs12>
					<v-btn class="scl-component scl-button" flat @click="submit">
						update
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
