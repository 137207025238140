import { Pie } from 'vue-chartjs';

// component
const component = {
	name: 'SclPieChart',
	mixins: [Pie],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			htmlLegend: null
		};
	},
	computed: {
		chartdata() {
			const chartData = this.propApi.data[0] || {};
			const { data = {} } = chartData?.datasets?.[0];
			if (chartData.display === 'percentage') {
				chartData.dataTotal = data?.reduce((accumulator, item) => accumulator + item);
				data.forEach((element, index) => (data[index] = Math.round((element / chartData.dataTotal) * 100)));
			}
			return chartData;
		},
		propApi() {
			return Object.assign(
				{
					data: []
				},
				this.api
			);
		},
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false
				},
				tooltips: {
					enabled: false,
					custom: function (tooltipModel) {
						// Tooltip Element
						var tooltipEl = document.getElementById('chartjs-tooltip');

						// Create element on first render
						if (!tooltipEl) {
							tooltipEl = document.createElement('div');
							tooltipEl.id = 'chartjs-tooltip';
							tooltipEl.className = 'chartjs-tooltip-pie';
							tooltipEl.innerHTML = '<table></table>';
							document.body.appendChild(tooltipEl);
						}

						// Hide if no tooltip
						if (tooltipModel.opacity === 0) {
							tooltipEl.style.opacity = 0;
							return;
						}

						// Set caret Position
						tooltipEl.classList.remove('above', 'below', 'no-transform');
						if (tooltipModel.yAlign) {
							tooltipEl.classList.add(tooltipModel.yAlign);
						} else {
							tooltipEl.classList.add('no-transform');
						}

						function getBody(bodyItem) {
							const item = bodyItem.lines[0].split(':')[1];
							return item;
						}

						// Set Text
						if (tooltipModel.body) {
							const titleLines = tooltipModel.title || [];
							const bodyLines = tooltipModel.body.map(getBody);
							const dataAppend = this._data.display === 'percentage' ? '%' : '';
							let innerHtml = '<thead>';

							titleLines.forEach(function (title) {
								innerHtml += '<tr><th>' + title + '</th></tr>';
							});
							innerHtml += '</thead><tbody>';

							bodyLines.forEach(function (body, i) {
								const colors = tooltipModel.labelColors[i];
								let style = 'background:' + colors.backgroundColor;
								style += '; border-color:' + colors.borderColor;
								style += '; border-width: 2px';
								const span = '<span style="' + style + '"></span>';
								innerHtml += '<tr><td>' + span + parseInt(body).toLocaleString() + dataAppend + '</td></tr>';
							});
							innerHtml += '</tbody>';

							const tableRoot = tooltipEl.querySelector('table');
							tableRoot.innerHTML = innerHtml;
						}

						// `this` will be the overall tooltip
						const position = this._chart.canvas.getBoundingClientRect();

						// Display, position, and set styles for font
						tooltipEl.style.opacity = 1;
						tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
						tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 35 + 'px';
						tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
						tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
						tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
						tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
					}
				},
				legendCallback: chart => {
					const legendHtml = [];
					const dataAppend = this.chartdata.display === 'percentage' ? '%' : '';

					var item = chart.data.datasets[0];
					for (var i = 0; i < item.data.length; i++) {
						legendHtml.push('<div class="flex xs12">');
						legendHtml.push(
							'<i aria-hidden="true" class="v-icon fas fa-circle theme--light" style="font-size: 16px; color:' +
								item.backgroundColor[i] +
								'; caret-color:' +
								item.backgroundColor[i] +
								';"></i>'
						);
						legendHtml.push('<span class="chart-legend-label-text pl-2 subheading">' + chart.data.labels[i] + '</span>');

						legendHtml.push(
							'<span class="chart-legend-label-text font-weight-bold subheading">&nbsp;- ' +
								item.data[i].toLocaleString() +
								dataAppend +
								'</span>'
						);
						legendHtml.push('</div>');
					}

					return legendHtml.join('');
				}
			};
		}
	},
	watch: {
		htmlLegend(newVal) {
			this.$emit('legend-markup', newVal);
		}
	},
	mounted() {
		this.initChart();
	},
	methods: {
		initChart() {
			this.renderChart(this.chartdata, this.options);
			this.htmlLegend = this.generateLegend();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
