/**
 * SclCardToggle
 */

// template
const template = `
	<v-card class="scl-card-toggle subheading mb-2" flat>
		<v-container pa-0>
			<v-layout column ma-0>
				<v-flex xs12 pa-3 class="scl-card-toggle__content">
					<v-layout column>
						<v-flex xs-12 d-flex>
							<div>Status<br>
								<span v-if="toggle">On</span>
								<span v-if="!toggle">Off</span>
							</div>
							<v-switch v-model="toggle" class="pt-0 mt-0 scl-form__switch justify-end"></v-switch>
						</v-flex>
						<v-flex xs12 mb-3>
							<div class="font-italic">{{propApi.message}}</div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
`;

// export template
export default template;
