/**
 * SclAddAdminModal
 */

// template
const template = `
	<div class="scl-add-admin-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-add-admin-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									add admin user
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form ref="form">
						<v-container grid-list-xl class="scl-add-admin-modal__container pt-0">
							<v-layout row wrap px-2 class="scl-add-admin-modal__container-layout">
								<v-flex xs12>
									<p>Create a new admin by entering their user details below. You will need to inform the new user of their login credentials needed to access the portal.</p>
								</v-flex>
								<v-flex xs12 class="pb-0">
									<p class="mb-0 font-weight-bold">Personal Details</p>
								</v-flex>
								<v-flex xs12>
									<v-text-field v-model="user.firstname" :rules="[rules.required]" class="scl-form__input" outline solo label="first name"></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field v-model="user.lastname" :rules="[rules.required]" class="scl-form__input" outline solo label="last name"></v-text-field>
								</v-flex>

								<v-divider></v-divider>

								<v-flex xs12 class="pb-0">
									<p class="mb-0 font-weight-bold">Login credentials</p>
								</v-flex>
								<v-flex xs12>
									<v-text-field v-model="user.email" class="scl-form__input"
										:rules="[rules.required, rules.email]" outline solo label="email address"></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field v-model="user.password" class="scl-form__input scl-add-admin-modal__password" type="password"
										:rules="[rules.required, rules.min, rules.passwordSecurity]"  outline solo label="password"></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field v-model="confirmPassword" class="scl-form__input scl-add-admin-modal__password" type="password"
										:rules="[rules.required, rules.passwordMatch]"  outline solo label="confirm password"></v-text-field>
								</v-flex>

								<v-divider></v-divider>

								<v-flex xs12 class="pb-0">
									<p class="mb-0 font-weight-bold">Role</p>
								</v-flex>
								<v-flex xs12>
									<v-select
										v-model="user.role"
										:items="roles"
										class="scl-form__input scl-form__select"
										outline solo
										label="select" append-icon="fas fa-chevron-down"
										:rules="[rules.required]"
									></v-select>
								</v-flex>
							</v-layout>
						</v-container>
						<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
							<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
							<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">create user</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
