/**
 * SclBottomSheetFeatured api
 *
 * @param {boolean} inset - Reduces the maximum width of the sheet
 * @param {boolean} persistent - Clicking outside will not dismiss the dialog
 * @param {boolean} value - Controls visibility
 */

// template
const template = `
	<v-bottom-sheet class="scl-bottom-sheet-featured" v-model="sheet" :inset="propApi.inset" :persistent="propApi.persistent" :value="propApi.value" :hide-overlay="propApi.hideOverlay" :disabled="disabledSheet">
		<template v-slot:activator>
			<v-btn color="primary" block depressed class="d-none">
				Bottom Sheet
			</v-btn>
		</template>
		<v-card flat class="scl-bottom-sheet-featured__card">
			<v-container pa-0 class="scl-bottom-sheet-featured__container">
				<v-btn class="scl-bottom-sheet-featured__close-button mt-0 mr-3" icon @click="sheet = !sheet">
					<v-icon color="#ff6105" size="32">fas fa-times</v-icon>
				</v-btn>
				<v-layout row ma-0 justify-center>
					<v-flex xs10 md7>
						<v-layout row wrap>
							<v-flex xs12 md4>
								<video id="featuredVideo" ref="featuredVideo" width="200" controls>
									<source :src="propApi.videoURLMp4" type="video/mp4">
									<source :src="propApi.videoURLOgg" type="video/ogg">
									Your browser does not support HTML5 video.
								</video>
							</v-flex>
							<v-flex xs12 md8 text-xs-center text-md-left>
								<p class="scl-bottom-sheet-featured__title font-weight-bold mb-0">{{propApi.title}}</p>
								<p class="scl-bottom-sheet-featured__subtitle font-weight-bold mb-2">{{propApi.subtitle}}</p>
								<div class="scl-bottom-sheet-featured__sheet-controls d-flex">
									<v-btn class="scl-component scl-button scl-button--small px-5 title" @click="playPause()">{{buttonLabel}}</v-btn>
									<v-checkbox dark v-model="disabledSheet" label="Do not show again" class="scl-bottom-sheet-featured__input-cb mt-1"></v-checkbox>
								</div>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
	</v-bottom-sheet>
`;

// export template
export default template;
