import axios from 'axios';

// component
const component = {
	name: 'SclPastThresholdSetting',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			stepNumber: 0,
			dueStatus: 5,
			rateGroup: 'rates',
			thresholdSetting: {
				threshold: 0,
				site: 0,
				capabilities: {
					hires: false,
					withrates: false,
					invoices: false
				}
			}
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					thresholdSetting: {
						threshold: 0,
						site: 0,
						capabilities: {
							hires: true,
							withrates: true,
							invoices: false
						}
					},
					postbackPipe: null,
					postbackURL: null
				},
				this.api
			);
		}
	},
	mounted() {
		this.thresholdSetting = this.propApi.thresholdSetting;
	},
	methods: {
		incrementThreshold() {
			this.thresholdSetting.threshold = parseInt(this.thresholdSetting.threshold, 10) + 1;
		},
		decrementThreshold() {
			if (this.thresholdSetting.threshold > 0) {
				this.thresholdSetting.threshold = parseInt(this.thresholdSetting.threshold, 10) - 1;
			}
		},
		incrementSite() {
			this.thresholdSetting.site = parseInt(this.thresholdSetting.site, 10) + 1;
		},
		decrementSite() {
			if (this.thresholdSetting.site > 0) {
				this.thresholdSetting.site = parseInt(this.thresholdSetting.site, 10) - 1;
			}
		},
		hireStatus(hireDays) {
			if (hireDays > 30) {
				return '#FF6105';
			}
			if (hireDays <= 5 && hireDays >= 1) {
				return '#63BF00';
			}
			if (hireDays <= 30 && hireDays >= 1) {
				return '#ECAF1E';
			}
			if (hireDays === 0) {
				return '#006600';
			}
			if (!hireDays) {
				return '#B6B5B3';
			}
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					settings: this.thresholdSetting
				};

				const result = await this.getResults(payload, this.propApi.postbackURL);

				if (result.status == 200 && result.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Settings updated',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Settings updated failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
