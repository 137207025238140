import axios from 'axios';

// component
const component = {
	name: 'SclAddAdminModal',
	data() {
		return {
			dialog: false,
			valid: true,
			roles: ['a', 'b'],
			confirmEmail: null,
			confirmPassword: null,
			user: {
				firstname: '',
				lastname: '',
				email: null,
				password: null,
				role: null
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				min: value => (value !== null && value.length >= 8) || 'Minimum 8 characters',
				passwordMatch: () => this.user.password === this.confirmPassword || 'The password you entered does not match',
				passwordSecurity: value => {
					const pattern = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;
					return pattern.test(value) || 'Invalid password, you must have 1 uppercase, number and a special character.';
				},
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				},
				emailMatch: () => this.user.email === this.confirmEmail || 'The email you entered does not match'
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
		this.roles = this.api.roles;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.roles ? typeof obj.roles === 'object' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '650',
					persistent: true,
					modalLabel: '',
					event: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			if (!this.$refs.form.validate()) {
				return false;
			}
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					user: this.user
				};
				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Admin user added',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Create user failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
