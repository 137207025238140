/**
 * SclChangeAccountModal
 */

// template
const template = `
	<div class="scl-change-account-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-change-account-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card>
					<v-toolbar class="scl-dialog__toolbar" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form v-model="account" class="scl-form ">
						<v-container grid-list-xl class="scl-change-account-modal__container pt-0">
							<v-layout row wrap fill-height class="ma-0">
								<v-flex xs12 md2 pt-0 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
									<v-select
										:items="tiers"
										v-model="tier"
										class="scl-form__input scl-form__select"
										outline
										label="Tier"
										@change="submit"
										hide-details
									></v-select>
								</v-flex>
								<v-flex xs12 md10 pt-0 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
									<v-layout row wrap class="ma-0">
										<v-flex xs4 md5 pt-0 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
											<v-text-field
												v-model="queryName"
												class="scl-form__input"
												name="customer-search"
												outline
												label="Customer Name"
												prepend-inner-icon="fas fa-search"
												hide-details
												@keydown.enter="submit"
												@change="resetPagination"
											></v-text-field>
										</v-flex>
										<v-flex xs4 md5 pt-0 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
											<v-text-field
												v-model="queryCode"
												class="scl-form__input"
												outline
												label="MA Code"
												prepend-inner-icon="fas fa-search"
												hide-details
												@keydown.enter="submit"
												@change="resetPagination"
											></v-text-field>
										</v-flex>
										<v-flex xs2 md2 class="pt-0 pl-0">

											<v-btn :disabled="!searchValid" class="form-customer__submit scl-component scl-button" large block depressed color="accent" @click="submit">Search</v-btn>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs12 py-0>
									<a class="font-weight-bold icon-link" @click="clearSelection">
										<v-icon color="#ff6105" size="15" class="pr-1">fas fa-trash</v-icon> Clear customer selection
									</a>
								</v-flex>
							</v-layout>
							<v-layout row wrap fill-height d-flex class="ma-0">
								<v-data-table
									:headers="propApi.customerData.headers"
									:items="items"
									:rows-per-page-items="[10,25,30]"
									:pagination.sync="pagination"
									:total-items="total"
									@update:pagination="updatePagination"
								>
									<template v-slot:items="props">
										<tr @click="selectedRow(props.item)">
											<td><a href="#" @click="selectAccount(props.item)">{{ props.item.name }}</a></td>
											<td class="text-xs-left">{{ props.item.tier }}</td>
											<td class="text-xs-left">{{ props.item.ma }}</td>
										</tr>
									</template>
								</v-data-table>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>

			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
