/**
 * SclChangePasswordModal
 */

// template
const template = `
	<div class="scl-change-password-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					{{propApi.modalLabel}}
					</v-btn>
				</template>
				<v-card>
					<v-toolbar class="scl-dialog__toolbar mb-3" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form v-model="valid" ref="changePasswordForm" class="scl-form">
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row wrap>
								<v-flex xs12 class="py-0">
									<v-text-field
										class="scl-form__input"
										type="password"
										outline
										:rules="[rules.required, rules.passwordSecurity]"
										v-model="enquiry.currentPassword"
										label="Current password"
									></v-text-field>
								</v-flex>
								<v-flex xs12 class="py-0">
									<v-text-field
									class="scl-form__input"
									type="password"
									outline
									:rules="[rules.required, rules.min]"
									v-model="enquiry.newPassword"
									label="new password"

								></v-text-field>
								</v-flex>
								<v-flex xs12 class="py-0">
									<v-text-field
									class="scl-form__input"
									type="password"
									outline
									:rules="[rules.required, rules.passwordMatch]"
									v-model="enquiry.confirmPassword"
									label="confirm password"
								></v-text-field>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>

					<v-card-actions class="pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" block outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button" block :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">submit</v-btn>
				  </v-card-actions>
				</v-card>

			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
