/**
 * SclTopNav api
 *
 * minWidth prop : empty value results in auto width
 * @param {number} minWidth
 *
 * maxWidth prop : empty value results in auto width
 * @param {number} maxWidth
 */

const template = `
	<div>
		<!-- small nav -->
		<v-toolbar v-if="$vuetify.breakpoint.smAndDown" dark scroll-off-screen height="60" class="scl-top-nav__toolbar px-0">
			<v-container class="scl-top-nav__container d-flex px-0">
				<v-layout row align-center>
					<v-flex class="scl-top-nav__menu-container xs2">
						<div class="scl-top-nav__drawer-btn--hidden ml-0 pa-3 mr-4 d-flex align-center">
							<v-icon>fas fa-bars</v-icon>
						</div>
					</v-flex>
					<v-flex class="scl-top-nav__drawer xs8 justify-center">
						<a href="#">
							<v-img
								:src="propApi.image"
								height="25"
								width="142"
								class="scl-top-nav__logo"
							></v-img>
						</a>
					</v-flex>
					<v-flex class="scl-top-nav__menu-container justify-end pr-3 xs2">
						<a href="#" class="scl-top-nav__menu-logout d-flex align-center text-uppercase">
							<v-icon size="21" color="#ffffff">fas fa-sign-out-alt</v-icon>
						</a>
					</v-flex>
				</v-layout>
			</v-container>
		</v-toolbar>

		<!-- large nav -->
		<v-toolbar v-else dark scroll-off-screen class="scl-top-nav__toolbar px-0">
			<v-container class="scl-top-nav__container d-flex px-0">
				<v-layout align-center>
					<!-- logo -->
					<v-flex class="scl-top-nav__drawer">
						<div class="scl-top-nav__drawer-btn--hidden ml-0 pa-3 mr-4 d-flex align-center">
							<v-icon>fas fa-bars</v-icon>
						</div>
						<a href="#">
							<v-img
								:src="propApi.image"
								height="25"
								width="142"
								class="scl-top-nav__logo"
							></v-img>
						</a>
					</v-flex>
					<!-- main nav -->
					<v-flex class="scl-top-nav__menu-container justify-end">
						<p href="#" class="scl-top-nav__menu-email pr-3 mr-3 mb-0 align-center d-flex">{{propApi.accountEmail}}</p>
						<a href="#" class="scl-top-nav__menu-logout d-flex align-center text-uppercase pr-3">
							<v-icon class="mr-2" size="18">fas fa-sign-out-alt</v-icon>
							<v-list-tile-title class="font-weight-bold">Log out</v-list-tile-title>
						</a>
					</v-flex>
				</v-layout>
			</v-container>
		</v-toolbar>
	</div>
`;

export default template;
