/**
 * SclSideNav api
 *
 */

const template = `
	<div class="scl-side-nav" :class="{'mini-menu' : mini}">
		<a class="scl-side-nav__drawer-btn ml-0 pa-3 mr-4 d-flex align-center" 
			flat 
			@click.stop="mini = !mini" 
			@mouseover="mini = false" 
			@mouseleave="mini = true"
			:class="{'mini-menu' : mini}">
			<v-icon>fas fa-bars</v-icon>
		</a>
		<v-navigation-drawer permanent dark width="280px" :mini-variant.sync="mini" mini-variant-width="73" class="scl-side-nav__drawer">
			<div class="scl-side-nav__menulist-top">
				<v-list dense class="pt-0 pt-3 scl-side-nav__list">
					<v-list-tile v-for="(overviewLink, index) in propApi.overviewLinks" 
							:key="index" 
							:href="overviewLink.url" 
							@mouseover="mini = false"
							@mouseleave="mini = true"
							:class="{'active': retrievePageName(overviewLink.url) === currentPage}">
						<v-icon class="mr-3" size="24">{{overviewLink.icon}}</v-icon>
						<v-list-tile-title class="text-capitalize">{{overviewLink.text}}</v-list-tile-title>
					</v-list-tile>
				</v-list>
			</div>
			<div class="scl-side-nav__menulist-bottom">
				<v-list dense class="pt-0 scl-side-nav__list">
					<div class="scl-side-nav__menulist-manage pb-2">
						<p class="scl-side-nav__menulist-manage--label caption pt-2 mb-2 pl-3"><span :class="{'mini-menu' : mini}">{{propApi.accountLinks.name}}</span></p>
						<v-list-tile v-for="(accountLink, index) in propApi.accountLinks.links" 
									:key="index" 
									class="scl-side-nav__menulist-manage--item" 
									:href="accountLink.url" 
									@mouseover="mini = false"
									@mouseleave="mini = true"
									:class="{'active': retrievePageName(accountLink.url) === currentPage}">
							<v-icon class="mr-3" size="24">{{accountLink.icon}}</v-icon>
							<v-list-tile-title class="text-capitalize">{{accountLink.text}}</v-list-tile-title>
						</v-list-tile>
					</div>
				</v-list>

				<v-list dense class="pt-0 scl-side-nav__list py-2">
					<v-list-tile v-for="(footerLink, index) in propApi.footerLinks" 
						:key="index" 
						:href="footerLink.url" 
						@mouseover="mini = false"
						@mouseleave="mini = true"
						:class="{'active': retrievePageName(footerLink.url) === currentPage}">
						<v-icon class="mr-3" size="24">fas fa-user-circle</v-icon>
						<v-list-tile-title class="text-capitalize">{{footerLink.text}}</v-list-tile-title>
					</v-list-tile>
				</v-list>
			</div>
		</v-navigation-drawer>
	</div>
`;

export default template;
