import axios from 'axios';

// component
const component = {
	name: 'SclSelectContactModal',
	data() {
		return {
			dialog: false,
			errorMessage: null,
			selected: [],
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 25,
				sortBy: 'name',
				total: 0
			},
			loading: null,
			results: [],
			query: null
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
			this.searchSubmit();
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.customerData ? typeof obj.customerData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackSelectURL ? typeof obj.postbackSelectURL === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		items() {
			if (!this.results || this.results.length == 0) {
				return this.propApi.customerData ? this.propApi.customerData.customers : [];
			}
			return this.results;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '915',
					modalLabel: '',
					persistent: false,
					event: 'select-contact-event',
					postbackURL: 'http://portal.sc92.sc/api/v1/mch/accounts/search',
					postbackSubmitURL: 'http://portal.sc92.sc/api/v1/mch/admin/exceptions/update',
					customerData: {
						headers: [
							{
								text: 'first name',
								align: 'left',
								sortable: true,
								value: 'firstname',
								class: 'text-capitalize font-weight-bold pt-0 pl-0'
							},
							{
								text: 'surname',
								value: 'surname',
								align: 'left',
								sortable: true,
								class: 'text-capitalize font-weight-bold pl-0'
							},
							{ text: 'mobile', value: 'mobile', align: 'left', sortable: true, class: 'text-capitalize font-weight-bold pl-0' },
							{ text: 'managed by', value: 'managed', align: 'left', sortable: true, class: 'text-capitalize font-weight-bold pl-0' },
							{ text: 'status', value: 'status', align: 'left', sortable: true, class: 'text-capitalize font-weight-bold pl-0' }
						],
						customers: [
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							},
							{
								firstName: 'Jordan',
								lastName: 'ward',
								mobileNumber: '0414 002 984',
								managed: 'Bill',
								status: 'Onine'
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		resetPagination() {
			this.pagination.page = 0;
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			await this.searchSubmit();
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		closeModal() {
			this.dialog = false;
		},
		async searchSubmit() {
			this.errorMessage = null;
			// If not open, don't search
			if (!this.dialog) {
				return;
			}
			try {
				this.loading = true;
				const payload = {
					page: this.pagination.page - 1,
					pageSize: this.pagination.rowsPerPage,
					segments: '',
					text: this.query || ''
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);
				if (response.status == 200 || response.data.success) {
					const result = response.data.result;
					this.showRequestSuccess = true;
					this.pagination.total = result?.total;
					this.results = result?.results;
				} else {
					this.errorMessage = 'Table update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.loading = false;
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Table update failed, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage,
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		},
		searched(val) {
			if (!val) {
				return '';
			}
			let plantText = val;
			const textRegExp = new RegExp(this.query, 'gi');
			if (this.query != '') {
				plantText = val.replace(textRegExp, function (r) {
					return '<span class="query--searchmatch">' + r + '</span>';
				});
			}
			return plantText;
		},
		createContact() {
			this.dialog = false;
		},
		selectRow(event, item) {
			this.dialog = false;
			this.$root.$emit(event, item);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
