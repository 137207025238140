// Mixins
import mxAccount from '@portals/mixins/global/mxAccount.js';

// component
const component = {
	name: 'SclCardProfile',
	mixins: [mxAccount],
	props: {
		api: {
			type: Object,
			required: false,
			validator: () => {
				const set = new Set();
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					data: [],
					event: 'card-profile-event'
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.accountData = this._mxAccount_generateFormDetailsData(event);
		});
	},
	data() {
		return {
			accountData: []
		};
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
