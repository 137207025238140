// Mixins
import mxAccount from '@portals/mixins/global/mxAccount.js';

// component
const component = {
	name: 'SclUserDetailsForm',
	mixins: [mxAccount],
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: {},
					titleItems: ['mr', 'mrs', 'dr'],
					roleItems: ['manager'],
					stateItems: ['nsw', 'qld']
				},
				this.api
			);
		}
	},
	watch: {
		accountData: {
			handler(newVal) {
				this.$root.$emit('stepper-data-details', newVal);
				const isValid = this.$refs.detailsForm.validate();
				this.$root.$emit('user-details-valid', isValid);
			},
			deep: true
		},
		'propApi.accountData': {
			handler(newVal) {
				this.accountData = newVal;
				this.$root.$emit('stepper-data-details', newVal);
				const isValid = this.$refs.detailsForm.validate();
				this.$root.$emit('user-details-valid', isValid);
			},
			deep: true
		}
	},
	data() {
		return {
			accountData: {},
			stepNumber: 0,
			select: {
				title: ['mr', 'mrs', 'dr'],
				role: ['manager'],
				state: ['nsw', 'qld']
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				},
				mobile: value => {
					const pattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
					return pattern.test(value) || 'Invalid mobile.';
				}
			}
		};
	},
	methods: {
		previousStep() {},
		nextStep() {}
	},
	created() {
		this.accountData = this._mxAccount_generateFormDetailsData();
	},
	mounted() {
		const self = this;
		this.$root.$on('navigate-form', () => {
			if (!self.$refs.form.validate()) {
				return false;
			}
			self.$root.$emit('stepper-event', ['user-details', self.accountData]);
		});
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
