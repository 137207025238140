/**
 * SclUserAccessForm api
 *
 **/

const template = `
<div class="scl-user-access-form scl-form">
	<v-form ref="accessForm">
		<v-container grid-list-xl fluid class="px-0">
			<v-layout row wrap>
				<!-- Role -->
				<v-flex pb-0 xs12 v-if="propApi.addOwnerAccess">
					<div class="d-inline-flex">
						<v-checkbox v-model="isManager" label="Administrative users" hide-details class="mt-0 pt-0"></v-checkbox>
						<scl-tooltip
							v-if="managerType === 'Admin' || managerType === ''"
							:api="{ icon: 'far fa-question-circle', message: tooltips.coatesHireOwner }"
						></scl-tooltip>
						<scl-tooltip v-else :api="{ icon: 'far fa-question-circle', message: tooltips.coatesHireManager }"></scl-tooltip>
					</div>
				</v-flex>
				<v-flex pt-4 pb-0 xs12 v-if="propApi.addOwnerAccess">
					<v-radio-group
						:disabled="!isManager"
						v-model="managerType"
						class="mt-0 pb-0 pl-4 d-flex"
						:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
					>
						<v-layout row wrap>
							<v-flex xs4>
								<v-radio label="Coates Connect Administrator" value="Admin"></v-radio>
							</v-flex>
							<v-flex>
								<v-radio label="Coates Connect Manager" value="Manager"></v-radio>
							</v-flex>
						</v-layout>
					</v-radio-group>
				</v-flex>
				<!-- Access -->
				<v-flex pb-0 xs12>
					<div class="d-inline-flex">
						<v-checkbox v-model="accountData.myHires" label="My Hires" hide-details class="mt-0 pt-0"></v-checkbox>
						<scl-tooltip :api="{ icon: 'far fa-question-circle', message: tooltips.myHires }"></scl-tooltip>
					</div>
				</v-flex>
				<v-flex pt-4 pb-0 xs12>
					<v-radio-group
						:disabled="!accountData.myHires"
						v-model="accountData.myHiresWithRates"
						class="mt-0 pb-0 pl-4 d-flex"
						:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
					>
						<v-layout row wrap>
							<v-flex xs4>
								<v-radio label="With rates" value="With Rates"></v-radio>
							</v-flex>
							<v-flex>
								<v-radio label="Without rates" value="Without Rates"></v-radio>
							</v-flex>
						</v-layout>
					</v-radio-group>
				</v-flex>
				<v-flex pt-0 pb-0 xs12>
					<v-radio-group
						:disabled="!accountData.myHires"
						v-model="accountData.myHiresWithEOHD"
						class="mt-0 pt-0 pb-0 pl-4 d-flex"
						:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
					>
						<v-layout row wrap>
							<v-flex xs4>
								<v-radio label="Edit EOHD" value="Edit EOHD"></v-radio>
							</v-flex>
							<v-flex>
								<v-radio label="Disable Edit EOHD" value="Disable Edit EOHD"></v-radio>
							</v-flex>
						</v-layout>
					</v-radio-group>
				</v-flex>
				<v-flex pb-0 xs12>
					<div class="d-inline-flex">
						<v-checkbox v-model="accountData.myInvoices" label="My Invoices" hide-details class="mt-0 pt-0"></v-checkbox>
						<scl-tooltip :api="{ icon: 'far fa-question-circle', message: tooltips.myInvoices }"></scl-tooltip>
					</div>
				</v-flex>
				<v-flex pb-0 xs12>
					<div class="d-inline-flex">
						<v-checkbox v-model="accountData.myPricebook" label="My Pricebook" hide-details class="mt-0 pt-0"></v-checkbox>
						<scl-tooltip :api="{ icon: 'far fa-question-circle', message: tooltips.myPricebooks }"></scl-tooltip>
					</div>
				</v-flex>

				<v-flex pt-4 xs12>
					<v-radio-group
						:disabled="!accountData.myPricebook"
						v-model="accountData.myPricebookWithExport"
						class="mt-0 pl-4 d-flex"
						:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
					>
						<v-layout row wrap>
							<v-flex xs4>
								<v-radio label="With export" value="With export"></v-radio>
							</v-flex>
							<v-flex>
								<v-radio label="Without export" value="Without export"></v-radio>
							</v-flex>
						</v-layout>
					</v-radio-group>
				</v-flex>
				<v-flex pb-0 xs12>
					<div class="d-inline-flex">
						<v-checkbox v-model="accountData.myQuoteRequests" label="My Quote Requests" hide-details class="mt-0 pt-0"></v-checkbox>
						<scl-tooltip :api="{ icon: 'far fa-question-circle', message: tooltips.myQuoteRequests }"></scl-tooltip>
					</div>
				</v-flex>
				<v-flex xs12>
					<v-checkbox v-model="accountData.myTelemetry" label="My Telemetry" hide-details class="mt-0 pt-0 mb-3"></v-checkbox>
				</v-flex>
				<v-flex xs12>
					<h3 class="pb-0 font-weight-regular">Send invitation Email?</h3>
					<v-radio-group v-model="accountData.sendInvite" class="mt-0 ml-0 pl-0 pt-4 d-flex">
						<v-layout row wrap>
							<v-flex>
								<v-radio label="No, send invitation later" value="no"></v-radio>
							</v-flex>
							<v-flex>
								<v-radio label="Yes, send invitation upon user creation" value="yes"></v-radio>
							</v-flex>
						</v-layout>
					</v-radio-group>
				</v-flex>
				<v-flex xs6 class="scl-site-access-accordion__note pa-3 my-3" v-if="accountData.sendInvite === 'no'">
					<p class="mb-0 px-3">
						<b>Note:</b> You have chosen to "Send an Invitation Email later". Users will not be able to access the site until an "Invitation
						Email" has been sent. You will have an option to send an invitation email within the "Manage Users" page.
					</p>
				</v-flex>
			</v-layout>
		</v-container>
	</v-form>
</div>
`;

export default template;
