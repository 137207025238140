// component
const component = {
	name: 'SclInvoiceAmountSlot',
	data() {
		return {
			account: null,
			results: [],
			t: 'abc'
		};
	},
	mounted() {
		this.results = this.propApi.tableData;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.customerData ? typeof obj.customerData === 'object' : true));
				return !set.has(false);
			}
		},
		slotData: {
			type: String
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					invoice: {
						ref: '1233',
						date: '11/11/20',
						site: 'sydney',
						purchaseOrder: '12334'
					},
					tableData: {
						headers: [
							{
								text: 'Payment IDs',
								align: 'left',
								sortable: false,
								value: 'payID',
								class: 'pt-0 pl-0',
								width: '20%'
							},
							{
								text: 'Payment Method',
								value: 'payMethod',
								align: 'left',
								sortable: false,
								width: '30%'
							},
							{ text: 'Date', value: 'date', align: 'left', sortable: false, width: '25%' },
							{
								text: 'Amount (incl GST)',
								value: 'amount',
								align: 'left',
								sortable: false,
								width: '25%'
							}
						],
						tableItems: [
							{
								payID: '7433909',
								payMethod: 'Journal',
								date: '21/01/2020',
								amount: '$8,643.24'
							},
							{
								payID: '7433909',
								payMethod: 'Journal',
								date: '21/01/2020',
								amount: '$8,643.24'
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
