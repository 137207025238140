// component
const component = {
	name: 'SclSetupDomainNameHeader',
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: []
				},
				this.api
			);
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
