/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-simple-list scl-form">
		<v-container fluid class="scl-simple-list__container px-0">
			<v-layout row wrap>
				<v-flex xs12>
					<v-data-table
						:headers="tableData.headers"
						:items="tableData.tableItems"
						:pagination.sync="pagination"
						:hide-actions="propApi.hideFooter"
						class="scl-simple-list__data-table"
					>
						<template v-slot:items="props">
							<td v-for="item in props.item" :class="item.class">
								<a href="javscript:void(0)" class="text--primary" @click.prevent="clickEvent(item.linkEvent, item)" v-if="item.isLink">{{ item.val }}</a>
								<span v-else >{{ item.val }}</span>
							</td>
						</template>
						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
				<v-flex xs12 v-if="propApi.showTotal">
					<v-sheet color="#E8E7E5" class="text-xs-right py-2 px-3">
						<p class="mb-0 font-weight-bold title-2">TOTAL: <span>{{tableTotal}}</span></p>
					</v-sheet>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
