/**
 * SclReportModal
 */

// template
const template = `
	<div class="scl-report-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					modal
					</v-btn>
				</template>

				<v-card>
					<div class="scl-report-modal-form scl-form">
						<v-toolbar class="scl-dialog__toolbar" flat>
							<v-layout row>
								<v-card-title class="pa-0" primary-title>
									<legend class="scl-dialog__toolbar-title">
										{{ propApi.modalLabel }}
									</legend>
								</v-card-title>
								<v-btn icon dark @click="dialog = false">
									<v-icon>fas fa-times</v-icon>
								</v-btn>
							</v-layout>
						</v-toolbar>
						<v-container grid-list-xl fluid pa-4>
							<v-layout row wrap>
								<v-flex xs12>
									<h3 class="pb-3">About this report</h3>
									<p>{{modalData.message}}</p>
									<p>{{modalData.schedule}}</p>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs12>
									<v-divider class="mb-4"></v-divider>
									<h3 class="pb-3">Report status</h3>
									<v-select
										v-model="modalData.selectModel"
										:items="propApi.selectItem"
										class="scl-form__input scl-form__select"
										outline
										label="Status" append-icon="fas fa-chevron-down"
									></v-select>
								</v-flex>
								<v-flex xs12>
									<v-card-actions class="justify-end pa-0" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
										<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">Cancel</v-btn>
										<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">confirm</v-btn>
									</v-card-actions>
								</v-flex>
							</v-layout>
						</v-container>
					</div>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
