// component
const component = {
	name: 'SclGroupList',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					data: []
				},
				this.api
			);
		}
	},
	mounted() {
		this.data = this.propApi.data;
		this.$root.$on(this.propApi.event, event => {
			this.data = [
				{
					label: 'Personal details',
					value: [event.firstName, event.lastName],
					type: 'text'
				},
				{
					label: 'User role',
					value: [event.role],
					type: 'text'
				},
				{
					label: 'Login details',
					value: [event.email],
					type: 'text'
				},
				{
					label: 'Login password',
					value: [{ password: '******' }],
					type: 'password'
				}
			];
			// this.data = event;
		});
	},
	data() {
		return {
			data: []
		};
	},
	// /**Remove watch because it will reset props when switching tabs**/
	// watch: {
	// 	'propApi.data': {
	// 		handler(newVal) {
	// 			this.data = newVal;
	// 		}
	// 	}
	// },
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
