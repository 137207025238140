/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-simple-list scl-form scl-exception-table">
		<v-container class="scl-simple-list__container">
			<v-layout row wrap fill-height d-flex>
				<v-data-table
					:headers="propApi.tableData.headers"
					:items="propApi.tableData.tableItems"
					:rows-per-page-items="[5,10,15]"
				>
					<template v-slot:items="props">
						<td class="text-xs-left">{{ props.item.name }}</td>
						<td class="text-xs-left">{{ props.item.threshold }}</td>
						<td class="text-xs-center"><a :href="getEditUrl(props.item)" color="#ff6105" class="grey-link"><v-icon>fas fa-pencil</v-icon></a></td>
						<td class="text-xs-center"><v-btn flat icon @click.stop="addCustomer()" color="#ff6105"><v-icon>far fa-trash-alt</v-icon></v-btn></td>
					</template>
					<template v-slot:no-data>
						<td :colspan="propApi.tableData.headers.length" class="py-4 text-xs-center">
							<p class="mb-0 caption text-capitalize font-weight-bold">There are no customers added to Current Exceptions</p>
						</td>
					</template>
					<template v-slot:actions-prepend>
						<p class="mb-0 caption text-capitalize scl-datatable__action-date">
							<span v-if="propApi.tableData.tableUpdated !== undefined">
								Data last updated on {{propApi.tableData.tableUpdated}}
							</span>
						</p>
					</template>
				</v-data-table>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
