/**
 * SclAccountAccessModal
 */

// template
const template = `
	<div class="scl-account-access-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-account-access-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-3" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form class="scl-form ">
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row wrap fill-height class="">
								<v-flex xs3 :class="{ 'd-none': $vuetify.breakpoint.smAndDown }" class="font-weight-bold text--secondary caption">parent group</v-flex>
								<v-flex xs3 :class="{ 'd-none': $vuetify.breakpoint.smAndDown }" class="font-weight-bold text--secondary caption">master account</v-flex>
								<v-flex xs6 :class="{ 'd-none': $vuetify.breakpoint.smAndDown }" class="font-weight-bold text--secondary caption">site account</v-flex>
							</v-layout>
							<v-layout row wrap fill-height v-for="(parent, index) in accountAccess" :key="index" class="topline">
							<v-flex xs12 :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }" class="font-weight-black">parent group</v-flex>
								<v-flex xs12 md3 class="font-weight-black subheading">{{parent.parentGroup}}</v-flex>
								<v-flex xs12 md9 row>
									<v-layout row wrap v-for="(master, index) in parent.masterAccount" :key="index" :class="{topline : index !== 0}">
										<v-flex xs12 :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }">master account</v-flex>
										<v-flex xs12 md4 class="font-weight-bold subheading">{{master.masterAccountName}}</v-flex>
										<v-flex xs12 md8 row>
											<v-layout row wrap>
												<v-flex xs12 :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }" class="text-capitalize">site account</v-flex>
												<v-flex xs12 md4 v-for="(site, index) in master.siteAccounts" :key="index" class="subheading">{{site.siteAccountName}}</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>

			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
