// component
const component = {
	name: 'SclSiteAccessAccordion',
	data() {
		return {
			expand: [],
			siteAccess: [],
			headerLabel: 'Site access',
			btnEvent: 'select-access-event'
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.expand ? typeof obj.expand === 'boolean' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	watch: {
		accounts: {
			handler(newVal) {
				this.siteAccess = newVal;
			},
			deep: true
		},
		propApi: {
			handler(newVal) {
				this.headerLabel = newVal.headerLabel;
				this.btnEvent = newVal.btnEvent;
				if (!newVal || !newVal.accounts || !newVal.accounts[0]) {
					return;
				}
				this.siteAccess = newVal.accounts;
			},
			deep: true
		}
	},
	mounted() {
		const self = this;
		this.$root.$on(this.propApi.event, data => {
			self.siteAccess = data;
		});
		if (this.propApi && this.propApi.siteAccounts) {
			this.siteAccess = this.propApi.siteAccounts;
		}
		this.headerLabel = this.propApi.headerLabel;
		this.btnEvent = this.propApi.btnEvent;
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					expand: false,
					icon: 'fas fa-chevron-down',
					siteAccess: [],
					event: ''
				},
				this.api
			);
		},
		siteAccessAssigned() {
			return this.siteAccess.length > 0;
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		updateParentData(data) {
			this.$emit('site-access-event', data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
