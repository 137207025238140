/**
 * SclPageNotification api
 */

// template
const template = `
	<div class="scl-page-notification">
		<v-container>
			<v-layout d-flex column align-center>
				<v-flex class="xs12">
					<v-icon size="45">{{propApi.icon}}</v-icon>
				</v-flex>
				<v-flex class="xs12 pt-4 font-weight-bold">
					{{propApi.message}}
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
