// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclChangeAccountModal',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			account: null,
			errorMessage: null,
			selected: null,
			segments: [],
			tier: null,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 25,
				sortBy: 'name',
				totalItems: 0
			},
			filters: {
				name: [],
				tiers: [],
				search: ''
			},
			queryName: null,
			queryCode: null,
			loading: null,
			results: [],
			total: 0,
			tiers: []
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, async () => {
			this.dialog = true;
			await this.submit();
		});
		this.tier = 'ALL';
		this.results = this.api.results;
		this.tiers = this.api.tiers;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.customerData ? typeof obj.customerData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackSelectURL ? typeof obj.postbackSelectURL === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		items() {
			if (!this.results || this.results.length == 0) {
				return this.propApi.customerData ? this.propApi.customerData.customers : [];
			}
			return this.results;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: '',
					width: '915',
					modalLabel: '',
					persistent: false,
					postbackURL: 'http://portal.sc92.sc/api/v1/mch/admin/search',
					postbackSelectURL: 'http://portal.sc92.sc/api/v1/mch/admin/change',
					customerData: {
						headers: [
							{
								text: 'customer name',
								align: 'left',
								sortable: false,
								value: 'name',
								class: 'text-uppercase font-weight-black pt-0',
								width: '60%'
							},
							{ text: 'tiers', value: 'tiers', align: 'left', sortable: false, class: 'text-uppercase font-weight-black', width: '10%' },
							{ text: 'ma code', value: 'ma', align: 'left', sortable: false, class: 'text-uppercase font-weight-black', width: '30%' }
						],
						customers: []
					}
				},
				this.api
			);
		},
		searchValid() {
			return true; //this.query;
		}
	},
	watch: {
		query() {
			this.resetPagination();
		}
	},
	methods: {
		chartScale(val) {
			const chartScale = (val / this.chartMax) * 100;
			return chartScale;
		},
		selectedRow(item) {
			// this.dialog = false;
			this.clickEvent('account-filter-event', { selectedItem: item });
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		resetPagination() {
			this.pagination.page = 1;
		},
		columnValueList(val) {
			return this.propApi.customerData.customers.map(d => d[val]);
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.sortBy = data.sortBy;
			this.pagination.descending = !!data.descending;
			this.pagination.rowsPerPage = data.rowsPerPage;
			await this.submit();
		},
		parseDate(date) {
			if (!date) {
				return null;
			}
			const [month, day, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		clearSelection() {
			this.tier = 'ALL';
			this.queryName = '';
			this.queryCode = '';
			this.submit();
		},
		async submit() {
			this.errorMessage = null;
			// ensure data is only fetched on dialog display
			if (!this.dialog) {
				return;
			}
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					page: this.pagination.page,
					pageSize: this.pagination.rowsPerPage,
					sortBy: this.pagination.sortBy,
					descending: !!this.pagination.descending,
					segments: this.segments.length > 0 ? this.segments.join(',') : '',
					tier: this.tier || 'ALL',
					text: this.queryName || '',
					code: this.queryCode || ''
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					this.total = response.data.result.total;
					this.pagination.total = response.data.result.total;
					this.results = response.data.result.results;
				} else {
					this.$root.$emit('snackbar-event', {
						copy: 'Update failed, please try again later.',
						type: 'error'
					});
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Account update failed, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage,
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async selectAccount(account) {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				this.loading = true;
				const payload = {
					code: account.ma,
					name: account.name,
					tier: account.tier,
					id: account.id
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackSelectURL);

				this.loading = false;
				if (response.status === 200 || response.success) {
					this.$root.$emit('snackbar-event', {
						closeButtonHidden: true,
						copy: response.message || 'Switching accounts updated successfully',
						type: 'success',
						timeout: 2000
					});
					location.reload();
				} else {
					this.errorMessage = 'Switching accounts failed, please try again later.';
					const snackBarMessage = {
						copy: response.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Switching accounts failed, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage,
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
