<template>
	<div>
		<v-text-field
			v-if="filterConfig.type === 'text'"
			v-model="filterValue"
			solo
			outline
			@keydown.enter="filterUpdated"
			append-icon="fas fa-search"
			@click:append="filterUpdated"
			class="scl-table-filter__search-filter scl-form__input"
		></v-text-field>
		<scl-date-selector v-if="filterConfig.type === 'date'" @update-date="dateSelected" />
		<v-flex v-if="filterConfig.type === 'daterange'" layout row pl-0>
			<v-flex xs6 pl-2>
				<scl-date-selector @update-date="(_, date) => dateRangeSelected(date, 'from')" :label="'From'" :max-date="maxDate" />
			</v-flex>
			<v-flex xs6 pr-2>
				<scl-date-selector @update-date="(_, date) => dateRangeSelected(date, 'to')" :label="'To'" :min-date="minDate" />
			</v-flex>
		</v-flex>
		<v-select
			v-if="filterConfig.type === 'enum'"
			v-model="filterValue"
			:items="filterConfig.enums"
			item-text="text"
			item-value="value"
			return-object
			label=""
			outline
			hide-details
			solo
			flat
			class="scl-form__input scl-form__select"
			append-icon="fas fa-caret-down"
			@change="filterUpdated"
		></v-select>
	</div>
</template>

<script>
import SclDateSelector from '../date-picker/SclDateSelector.vue';
// component
const SclQuotesSearchFilter = {
	name: 'SclQuotesSearchFilter',
	components: { SclDateSelector },
	data() {
		return {
			filterValue: '',
			minDate: null,
			maxDate: null,
			selectedDateRange: [null, null]
		};
	},
	props: {
		filterConfig: Object
	},
	mounted() {
		switch (this.filterConfig.type) {
			case 'text':
				// do nothing
				break;
			case 'date':
			case 'daterange':
				this.minDate = this.filterConfig.minDate || null;
				this.maxDate = this.filterConfig.maxDate || null;
				break;
			case 'enum':
				this.filterValueEnums =
					Array.isArray(this.filterConfig.filterValueEnums) && this.filterConfig.filterValueEnums.length > 0
						? this.filterConfig.filterValueEnums
						: [{ text: 'Please provide valid enums', value: -1 }];
				break;
		}
	},
	methods: {
		dateSelected(p1, date) {
			this.filterValue = date;
			this.filterUpdated();
		},
		filterUpdated() {
			if (typeof this.filterValue === 'string' && this.filterValue.length > 0) {
				var payload = Object.assign({}, this.filterConfig, { filterValue: this.filterValue });
				this.$emit('filter-updated', payload);
			}
			this.filterValue = '';
		},
		dateRangeSelected(date, type) {
			if (type === 'from' && typeof date === 'string') {
				this.selectedDateRange[0] = date;
				this.minDate = new Date(date);
			} else if (type === 'to' && typeof date === 'string') {
				this.selectedDateRange[1] = date;
				this.maxDate = new Date(date);
			}
			// only fire event when both are selected
			if (this.selectedDateRange.length > 0 && this.selectedDateRange.join('').length > 12) {
				this.filterValue = this.selectedDateRange.join('~');
				this.filterUpdated();
			}
		}
	},
	template: null
};

export default SclQuotesSearchFilter;
</script>
