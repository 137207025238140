import axios from 'axios';

// component
const component = {
	name: 'SclQuoteContactModal',
	data() {
		return {
			dialog: false,
			valid: true,
			isLoading: false,
			formData: {
				subject: '',
				message: '',
				sender: ''
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				message: value => !!value || 'Message can not be left blank'
			}
		};
	},
	mounted() {
		this.$root.$on('open-contact-modal', () => {
			this.dialog = true;
			this.setValues();
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.subHeader ? typeof obj.subHeader === 'string' : true));
				set.add(!!(obj.state ? typeof obj.state === 'string' : true));
				set.add(!!(obj.quoteId ? typeof obj.quoteId === 'string' : true));
				set.add(!!(obj.firstName ? typeof obj.firstName === 'string' : true));
				set.add(!!(obj.lastName ? typeof obj.lastName === 'string' : true));
				return !set.has(false);
			}
		}
	},
	watch: {
		'formData.message': function () {
			if (this.$refs.form.validate()) {
				this.valid = true;
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '640',
					modalLabel: '',
					title: '',
					subHeader: '',
					state: '',
					quoteId: '',
					firstName: '',
					lastName: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			if (!this.$refs.form.validate()) {
				this.valid = false;
				return false;
			} else {
				this.valid = true;
			}
			this.isLoading = true;
			try {
				const payload = {
					QuoteId: this.propApi.quoteId,
					Subject: this.formData.subject,
					Message: this.formData.message,
					State: this.propApi.state
				};

				const response = await this.getResults(payload, '/api/v1/mch/quotes/SendQuoteContactUsEmail');

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: 'Your message was sent successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.isLoading = false;
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		},
		setValues() {
			if (this.propApi.quoteId) {
				this.formData.subject = `Quote Request follow up QR${this.propApi.quoteId}`;
			}
			if (this.propApi.firstName) {
				this.formData.sender = `${this.propApi.firstName} ${this.propApi.lastName}`;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
