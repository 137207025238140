/**
 * SclUpdateHire
 */

// template
const template = `
	<div class="scl-date-picker-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-date-picker-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-3" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-date-picker-modal__container pt-0 px-2">
						<v-layout column wrap fill-height d-flex px-2 class="scl-date-picker-modal__container-layout">
							<v-flex xs12 pa-0>
							<v-date-picker v-model="hireItems.date" full-width color="#ff6105" class="scl-date-picker-modal__date-picker"></v-date-picker>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="scl-date-picker-modal__actions d-flex">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="clickEvent('update-hire-event')">update</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
