<template>
	<div class="scl-session-timeout-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog v-model="dialog" :width="width" persistent content-class="scl-session-timeout-modal__component">
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<v-icon color="white">far fa-exclamation-triangle</v-icon>
								<legend class="scl-dialog__toolbar-title">Session {{ hasExpired ? 'Timed Out' : 'Information' }}</legend>
							</v-card-title>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-session-timeout-modal__container pt-0">
						<v-layout row wrap px-2 class="scl-session-timeout-modal__container-layout">
							<v-flex xs12 class="px-0">
								<p v-if="!hasExpired">
									Your session will expire in
									<span v-if="minutesLeft > 0">{{ minutesLeft }} minutes.</span>
									<span v-else>{{ secondsLeft }} seconds.</span>
								</p>
								<template v-else>
									<p>Your session has expired.</p>
									<p>
										If you have any unsaved data, close this dialog box and copy the data before logging in again. If not, log in again now.
									</p>
								</template>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{ 'd-flex': $vuetify.breakpoint.smAndDown }">
						<template v-if="!hasExpired">
							<v-btn color="accent" class="scl-component scl-button" outline :href="api.logoutUrl">LOGOUT</v-btn>
							<v-btn color="accent" class="scl-component scl-button" :class="{ 'ml-2': $vuetify.breakpoint.mdAndUp }" @click="keepSessionAlive">
								KEEP WORKING
							</v-btn>
						</template>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

let timer, authenticationTimer;

const OPEN_MODAL_EVENT = 'session-timeout-event';

export default {
	name: 'SclSessionTimeoutModal',
	data() {
		return {
			dialog: false,
			expiryTime: 0,
			minutesLeft: null,
			secondsLeft: null
		};
	},
	mounted() {
		this.$root.$on(OPEN_MODAL_EVENT, () => {
			this.dialog = true;
		});
	},
	beforeDestroy() {
		clearInterval(timer);
		clearInterval(authenticationTimer);
	},
	props: {
		api: {
			type: Object,
			default: () => ({
				logoutUrl: null,
				keepAliveUrl: null,
				isAuthenticatedUrl: null
			})
		},
		width: {
			type: String,
			default: '500px'
		},
		threshold: {
			type: Number,
			default: 10
		},
		expiry: {
			type: String,
			required: true
		},
		logoutUrl: {
			type: Object,
			default: null
		},
		keepAliveUrl: {
			type: Object,
			default: null
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async isAuthenticated() {
			try {
				if (this.api.isAuthenticatedUrl) {
					return await axios.get(this.api.isAuthenticatedUrl);
				}
			} catch (e) {
				console.error(e);
			}
			return false;
		},
		async keepSessionAlive() {
			try {
				if (this.api.keepAliveUrl) {
					const response = await axios.get(this.api.keepAliveUrl);
					this.setExpiryTime(response.data.expiry);
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.dialog = false;
			}
		},
		setExpiryTime(expiry) {
			var dt = new Date(parseInt(expiry));
			if (isNaN(dt)) {
				return;
			}
			clearInterval(timer);
			this.hasExpired = false;
			const expiryTime = dt - 5000; // Shorten by 5s to give some leeway.
			const timespanSeconds = Math.round((expiryTime - new Date()) / 1000);
			const minutes = Math.floor(timespanSeconds / 60);
			const seconds = timespanSeconds % 60;

			const vm = this;
			vm.minutesLeft = minutes;
			if (timespanSeconds <= 0) {
				vm.expireSession();
			} else if (timespanSeconds <= 60) {
				vm.setSecondsTimer(timespanSeconds);
			} else {
				setTimeout(() => {
					if (minutes < 2) {
						vm.setSecondsTimer(59);
					} else {
						vm.setMinutesTimer(minutes);
					}
				}, seconds * 1000);
			}
		},
		// Extend the authentication expiry by calling this method every X period (threshold) time to extend the authentication time
		setCheckAuthenticationTime() {
			const thresholdMs = this.threshold * 1000 * 60;
			const self = this;
			authenticationTimer = setInterval(() => {
				self.isAuthenticated();
			}, thresholdMs);
		},
		setMinutesTimer(minutes) {
			const vm = this;
			vm.minutesLeft = minutes;
			clearInterval(timer);
			timer = setInterval(() => {
				vm.minutesLeft--;
				if (!vm.dialog && vm.minutesLeft <= vm.threshold) {
					vm.dialog = true;
				}
				if (vm.minutesLeft === 1) {
					vm.setSecondsTimer(59);
				}
			}, 60000);
		},
		setSecondsTimer(seconds) {
			clearInterval(timer);
			this.minutesLeft = 0;
			this.secondsLeft = seconds;
			this.dialog = true;
			const vm = this;
			timer = setInterval(() => {
				vm.secondsLeft--;
				if (vm.secondsLeft <= 0) {
					vm.expireSession();
				}
			}, 1000);
		},
		expireSession() {
			this.hasExpired = true;
			clearInterval(timer);
			// auto-logout after timeout expires
			window.location = this.api.logoutUrl;
		}
	},
	watch: {
		expiry: {
			immediate: true,
			handler(newVal) {
				this.setExpiryTime(newVal);
				this.setCheckAuthenticationTime();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.scl-session-timeout-modal {
	::v-deep &__component {
		.v-card__title {
			align-items: baseline;
		}
		.scl-dialog__toolbar {
			background-color: $color-orange !important;
		}
		.v-toolbar__content {
			max-width: 100% !important;
		}
		.scl-dialog__toolbar-title {
			padding-left: 1rem;
			color: #fff !important;
		}
	}
}
</style>
