import axios from 'axios';

// component
const component = {
	name: 'SclBottomNav',
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.listingData ? typeof obj.listingData === 'object' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					listingData: []
				},
				this.api
			);
		}
	},
	methods: {
		cancelChanges() {
			this.clickEvent('confirmation-event');
		},
		confirmChanges() {
			this.submit(this.listingData);
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit(data) {
			this.errorMessage = null;
			try {
				this.loading = true;
				const payload = {
					item: data
				};

				const result = await this.getResults(payload, this.propApi.postbackSelectURL);

				this.loading = false;
				if (result.status == 200 || result.data.statusCode == 200) {
					this.showRequestSuccess = true;
					location.reload();
				} else {
					this.errorMessage = 'Submit failed please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Submit failed please try again later.';
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	}
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

export default component;
