/**
 * SclRoleAccessModal
 */

// template
const template = `
	<div class="scl-role-access-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-role-access-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{propApi.modalLabel}}
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-role-access-modal__container pt-0">
						<v-layout row wrap px-2 class="scl-role-access-modal__container-layout">
							<v-flex xs12 px-0 pt-0>
								<p class="mt-4"><span class="font-weight-bold">{{modalData.firstName}} {{modalData.surname}}</span> Is requesting the following capability change:</p>
							</v-flex>

							<v-divider></v-divider>

							<v-flex xs12 pt-3 px-0 class="scl-role-access-modal__selection-item">
								<v-layout row wrap v-for="(accessItem, index) in modalData.capability" :key="index">
									<v-flex xs3>
										<p class="my-0 mr-4">{{accessItem.name}}</p>
									</v-flex>
									<v-flex xs1>
										<v-icon size="18" class="pr-3" color="#ff6105">fas fa-arrow-right</v-icon>
									</v-flex>
									<v-flex xs8>
										<span v-for="(item, index) in accessItem.capabilities" :key="index">{{item}}<span v-if="index !== accessItem.capabilities.length - 1">, </span></span>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-divider></v-divider>
							<v-flex xs12 px-0 class="scl-dialog__alert">{{errorMessage}}</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">reject</v-btn>
						<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">approve</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
