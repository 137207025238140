/**
 * All components for global registration
 */
import { SclButton, SclRouteButton } from './button/index';
import * as HeaderComponents from './header/index';
import { SclTopNav, SclSideNav, SclBottomNav, SclManageUserSideNav } from './menu/index';

//Portal specific import/exports
import { SclAccountDetail, SclTableFilter, SclUserDetails, SclUserAccess, SclUserStatus, SclUpdateStatus } from './account/index';
import { SclAccountFilter } from './page/index';
import {
	SclFeedbackForm,
	SclPersonalDetailForm,
	SclLoginForm,
	SclResetPasswordForm,
	SclFileUpload,
	SclThresholdSetting,
	SclPastThresholdSetting,
	SclUserDetailsForm,
	SclUserAccessForm,
	SclEmailDomainForm
} from './form/index';
import {
	SclChangePasswordModal,
	SclAccountAccessModal,
	SclResetPasswordModal,
	SclChangeAccountModal,
	SclConfirmationModal,
	SclHistoryModal,
	SclEditAccessModal,
	SclSelectAccessModal,
	SclUpdateHireModal,
	SclExpectedOffhireModal,
	SclDatePickerModal,
	SclManageModal,
	SclResendEmailModal,
	SclUnlockUserModal,
	SclUnsavedChangesModal,
	SclAddCustomerModal,
	SclThresholdModal,
	SclAddAdminModal,
	SclLoginMessageModal,
	SclSendInvitationModal,
	SclRemoveConfirmationModal,
	SclInviteModal,
	SclRoleAccessModal,
	SclEmailDomainModal,
	SclUpdateAdminAccessModal,
	SclUpdateUserAccessModal,
	SclUpdateUserDetailsModal,
	SclUpdateUserStatusModal,
	SclInvoiceAmountModal,
	SclDomainModal,
	SclModal,
	SclSelectContactModal,
	SclExceptionAccordionModal,
	SclReportModal,
	SclAlertModal,
	SclSessionTimeoutModal,
	SclQuoteContactModal
} from './modal/index';
import {
	SclCardContact,
	SclCardContactSimple,
	SclCardSpotlight,
	SclCardCta,
	SclCardImage,
	SclCardSolid,
	SclCardIcon,
	SclCard,
	SclConfirmationDetail,
	SclRadialProgressCard,
	SclAccountAgeingCard,
	SclCardToggle,
	SclCardProfile
} from './card/index';
import {
	SclPreferencesList,
	SclContactList,
	SclSimpleList,
	SclExpandableTable,
	SclExceptionTable,
	SclAdminTable,
	SclAccessRequestTable,
	SclBarChartTable,
	SclExpandableInvoiceTable,
	SclPricingTable,
	SclGroupList,
	SclServerPaginatedTable
} from './list/index';
import * as QuoteComponents from './quotes/index';
import { SclBottomSheet, SclBottomSheetFeatured, SclBottomSheetHireMobileMenu } from './bottom-sheet/index';
import { SclTooltip } from './tooltip/index';
import { SclSnackbar } from './snackbar/index';
import { SclFooter } from './footer/index';
import { SclPageNotification } from './notification/index';
import { Scl1Col, Scl2Col, Scl2Col4, Scl2Col8, Scl3Col, Scl4Col, SclGridList } from './grid/index';
import { SclDummyData } from './dummy/index';
import { SclLoadingSpinner } from './loading/index';
import { SclPageTabs } from './tabs/index';
import { SclExceptionAccordion, SclSiteAccessAccordion } from './accordion/index';
import { SclStepper } from './stepper/index';
import { SclPieChart, SclPieChartPanel, SclBarChart, SclBarChartPanel } from './chart/index';
import { SclInvoiceAmountSlot } from './slot/index';
import { SclWysiwyg } from './wysiwyg/index';

export default [
	// NOTE: this might be a cleaner way to register components instead
	// of double import/exporting them
	...Object.values(QuoteComponents),
	...Object.values(HeaderComponents),
	SclButton,
	SclTopNav,
	SclAccountDetail,
	SclSideNav,
	SclFeedbackForm,
	SclChangePasswordModal,
	SclAccountAccessModal,
	SclCardContact,
	SclPreferencesList,
	SclContactList,
	SclCardContactSimple,
	SclPersonalDetailForm,
	SclBottomSheet,
	SclTooltip,
	SclSnackbar,
	SclFooter,
	SclLoginForm,
	SclResetPasswordForm,
	SclResetPasswordModal,
	SclPageNotification,
	SclCardSpotlight,
	Scl1Col,
	Scl2Col,
	Scl2Col4,
	Scl2Col8,
	Scl3Col,
	Scl4Col,
	SclGridList,
	SclDummyData,
	SclCardCta,
	SclCardImage,
	SclCardSolid,
	SclCardIcon,
	SclCard,
	SclFileUpload,
	SclBottomSheetFeatured,
	SclLoadingSpinner,
	SclChangeAccountModal,
	SclAccountFilter,
	SclConfirmationModal,
	SclQuoteContactModal,
	SclHistoryModal,
	SclEditAccessModal,
	SclSelectAccessModal,
	SclSimpleList,
	SclPageTabs,
	SclUpdateHireModal,
	SclExpectedOffhireModal,
	SclExpandableTable,
	SclTableFilter,
	SclDatePickerModal,
	SclExceptionTable,
	SclRouteButton,
	SclExceptionAccordion,
	SclBottomNav,
	SclAdminTable,
	SclManageModal,
	SclResendEmailModal,
	SclUnlockUserModal,
	SclUnsavedChangesModal,
	SclAddCustomerModal,
	SclBottomSheetHireMobileMenu,
	SclThresholdSetting,
	SclThresholdModal,
	SclAddAdminModal,
	SclPastThresholdSetting,
	SclLoginMessageModal,
	SclManageUserSideNav,
	SclStepper,
	SclUserDetails,
	SclUserAccess,
	SclUserStatus,
	SclUpdateAdminAccessModal,
	SclUpdateUserAccessModal,
	SclUpdateUserDetailsModal,
	SclUpdateUserStatusModal,
	SclSiteAccessAccordion,
	SclSendInvitationModal,
	SclRemoveConfirmationModal,
	SclConfirmationDetail,
	SclInviteModal,
	SclRoleAccessModal,
	SclAccessRequestTable,
	SclEmailDomainModal,
	SclEmailDomainForm,
	SclInvoiceAmountModal,
	SclPieChart,
	SclPieChartPanel,
	SclBarChart,
	SclBarChartTable,
	SclServerPaginatedTable,
	SclBarChartPanel,
	SclExpandableInvoiceTable,
	SclRadialProgressCard,
	SclAccountAgeingCard,
	SclPricingTable,
	SclModal,
	SclInvoiceAmountSlot,
	SclDomainModal,
	SclCardToggle,
	SclCardProfile,
	SclUpdateStatus,
	SclUserDetailsForm,
	SclUserAccessForm,
	SclSelectContactModal,
	SclGroupList,
	SclExceptionAccordionModal,
	SclReportModal,
	SclAlertModal,
	SclSessionTimeoutModal,
	SclWysiwyg
];
