/**
 * SclResendEmailModal
 */

// template
const template = `
	<div class="scl-resend-email-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-resend-email-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{ propApi.modalLabel }}
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-resend-email-modal__container pt-0">
						<v-layout row wrap px-2 class="scl-resend-email-modal__container-layout">
							<v-flex xs12 px-0 pt-0 pb-4>
								<p class="mb-4">{{ propApi.subtitleLabel || 'Resend an email to:' }}</p>
								<div class="ml-4">
									<v-icon size="18" class="pr-3">fas fa-user-alt</v-icon>{{accountItem.firstname}} {{accountItem.surname}}
								</div>
							</v-flex>

							<v-flex xs12 class="px-0">
								<p class="body-1"><span v-html="propApi.modalText"></span><span v-if="accountItem.lastEmail"> The last registration email was sent on <strong>{{accountItem.lastEmail}}</strong></span></p>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">{{ propApi.submitLabel || 'resend' }}</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
