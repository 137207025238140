/**
 * SclUserDetailsForm api
 *
 **/

const template = `
	<div class="scl-user-details-form scl-form">
		<v-container grid-list-xl fluid px-0>
			<v-layout row wrap>
				<v-form ref="detailsForm">
					<v-container grid-list-xl class="scl-user-details__container pt-0">
						<v-layout row wrap class="scl-user-details__container-layout">
							<v-flex xs12 class="pb-0">
								<p class="mb-0">Personal Details</p>
							</v-flex>
							<v-flex xs12 md4>
								<v-select
								v-model="accountData.title"
								:items="propApi.titleItems"
								class="scl-form__input scl-form__select"
								outline
								label="Title" append-icon="fas fa-chevron-down"
								></v-select>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.firstName" :rules="[rules.required]" class="scl-form__input" outline label="first name"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.lastName" :rules="[rules.required]" class="scl-form__input" outline label="last name"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.jobTitle" class="scl-form__input scl-form__input--optional" outline label="Job Title"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-select
								v-model="accountData.role"
								:items="propApi.roleItems"
								class="scl-form__input scl-form__select scl-form__input--optional"
								outline
								label="Role" append-icon="fas fa-chevron-down"
								></v-select>
							</v-flex>
							<v-flex xs12>
								<v-divider class="mb-3"></v-divider>
								<v-text-field v-model="accountData.email" class="scl-form__input" :disabled="!!accountData.contact && accountData.contact != ''"
									:rules="[rules.required, rules.email]" outline label="login email"></v-text-field>
								<v-divider></v-divider>
							</v-flex>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.mobileNumber" :rules="[rules.required]" class="scl-form__input" outline label="Mobile number"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.phoneNumber" class="scl-form__input scl-form__input--optional" outline label="Business contact number"></v-text-field>
							</v-flex>
							<v-flex xs12>
							<v-divider></v-divider>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12>
								<v-sheet class="scl-form__input--optional">
								</v-sheet>
							</v-flex>
							<v-flex xs12 md4>
								<v-text-field v-model="accountData.unit" class="scl-form__input" outline label="number"></v-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12>
								<v-text-field v-model="accountData.street" class="scl-form__input" outline label="Street name"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-text-field v-model="accountData.suburb" class="scl-form__input" outline label="Suburb"></v-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<v-select
								v-model="accountData.state"
								:items="propApi.stateItems"
								class="scl-form__input scl-form__select"
								outline
								label="State" append-icon="fas fa-chevron-down"
								></v-select>
							</v-flex>
							<v-flex xs12 md4>
								<v-text-field type="number" v-model="accountData.postcode" class="scl-form__input" outline label="Postcode"></v-text-field>
							</v-flex>
						</v-layout>
					</v-container>
				</v-form>
			</v-layout>
		</v-container>
	</div>
`;
export default template;
