/**
 * SclEnquireModal
 */

// template
const template = `
	<div class="scl-preferences-list scl-form">
		<v-container grid-list-xl fluid>
			<v-layout row wrap class="underline mb-3 pb-2">
				<v-flex xs5 pl-0>{{propApi.header}}</v-flex>
				<v-flex xs7 text-xs-right pr-0><a href="javascript:void(9);" @click.prevent="clearAll">Clear all</a></v-flex>
			</v-layout>
			<v-layout row wrap class="underline mb-3 pt-3 pb-2" v-for="(preference, index) in preferences" :key="index">
				<!--
					<v-checkbox class="mt-1 ml-4" :label="option.label" v-model="option.value" v-for="(option, index) in preference.options" :key="index"></v-checkbox>
				-->
				<v-flex xs-2 text-xs-right class="scl-preferences-list__toggles pa-0">
					<v-checkbox class="mt-1 ml-4" v-model="option.value" v-for="(option, index) in preference.options" :key="index"></v-checkbox>
				</v-flex>
				<v-flex xs7 class="scl-account-detail__layout-inner pa-0">
					<a href="#" @click.stop="clickEvent(propApi.event, props.item)">{{preference.name}}</a>
				</v-flex>
			</v-layout>
			<v-flex xs2>
				<v-btn
				color="accent" block
				class="scl-component scl-button"
				depressed
				@click="submit"
			>
				save
				</v-btn>
			</v-flex>
		</v-container>
	</div>
`;

// export template
export default template;
