// Mixins
import mxAccount from '../../mixins/global/mxAccount.js';

// component
const component = {
	name: 'SclUserDetails',
	mixins: [mxAccount],
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: [],
					event: 'user-details-event'
				},
				this.api
			);
		}
	},
	mounted() {},
	watch: {
		'propApi.accountData': {
			handler(newVal) {
				this.accountData = this._mxAccount_generateFormDetailsData(newVal);
			}
		}
	},
	data() {
		return {
			accountData: []
		};
	},
	methods: {
		nextStep() {
			this.$root.$emit('stepper-event', ['user-details', this.accountData]);
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
