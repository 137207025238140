/**
 * SclAccordion api
 *
 */

// template
const template = `
	<div class="scl-exception-accordion pa-3">
		<v-expansion-panel :expand="propApi.expand">
			<v-expansion-panel-content lazy :expand-icon="propApi.icon" v-for="(option, index) in collection.options" :key="index">
				<template v-slot:header>
					<div>{{option.name}}</div>
				</template>
				<v-card>
					<v-container class="fluid pa-0" v-if="option.type == 'group'">
						<v-layout row wrap align-center class="py-3 px-3">
							<v-flex xs12 md1 offset-md3>
								<v-select v-model="option.value" @change="updateAccess" :items="option.items" label="Select" outline hide-details solo flat class="text-uppercase"></v-select>
							</v-flex>
						</v-layout>
						<v-layout row align-center v-for="(item, index) in option.lineItem" :key="index" class="py-3 px-3">
							<v-flex xs11 md3>
								{{item.name}}
							</v-flex>
							<v-flex xs1 md1 class=text-xs-center>
								<v-icon v-if="item.value === 'ON'" size="14" color="#ff6105">fas fa-check</v-icon>
								<v-icon v-else size="14" color="#ff6105">fas fa-times</v-icon>
							</v-flex>
						</v-layout>
					</v-container>
					<v-container class="fluid pa-0" v-if="option.type == 'single'">
						<v-layout row wrap align-center v-for="(item, index) in option.lineItem" :key="index" class="py-3 px-3">
							<v-flex xs12 md3 :class="{'mb-2': $vuetify.breakpoint.smAndDown}">
								{{item.name}}
							</v-flex>
							<v-flex xs12 md9>
								<v-select v-model="item.value" :items="item.items" label="Select" outline hide-details solo flat class="text-uppercase"></v-select>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</div>
`;

// export template
export default template;
