/**
 * This mixin contains the functionality required for fetching data
 */
import axios from 'axios';

const mxAccount = {
	created: function () {},
	methods: {
		async _mxAccount_fetchAccountData(payload, postbackURL, pipe) {
			try {
				let response;
				if (pipe === 'get') {
					response = await axios.get(postbackURL, payload);
				}
				if (pipe === 'post') {
					response = await axios.post(postbackURL, payload);
				}

				return response;
			} catch (e) {
				return;
			}
		}
	}
};

export default mxAccount;
