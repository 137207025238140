import axios from 'axios';
// component

const component = {
	name: 'SclHistoryModal',
	data() {
		return {
			dialog: false,
			valid: true,
			plantInfo: null,
			plantNo: null,
			items: []
		};
	},
	mounted() {
		const self = this;
		this.$root.$on(this.propApi.event, async event => {
			const orderLineId = event && event.orderLineId !== undefined ? event.orderLineId : 0;
			this.dialog = true;
			self.submit(orderLineId);
		});
		this.items = this.propApi.tableData.items;
		this.plantInfo = this.propApi.plantInfo;
		this.plantNo = this.propApi.plantNo;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.messaging ? typeof obj.messaging === 'string' : true));
				set.add(!!(obj.historyFooterMsg ? typeof obj.historyFooterMsg === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.plantNo ? typeof obj.plantNo === 'string' : true));
				set.add(!!(obj.plantInfo ? typeof obj.plantInfo === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'history-event',
					width: '650',
					persistent: false,
					modalLabel: '',
					plantNo: '',
					plantInfo: '',
					historyFooterMsg: '',
					postbackURL: '',
					tableData: {
						headers: [
							{
								text: 'Updated By',
								align: 'left',
								sortable: false,
								value: 'updatedBy',
								class: 'font-weight-bold pt-0'
							},
							{ text: 'Date & Time Updated', value: 'dateTimeUpdated', align: 'left', sortable: false, class: 'font-weight-bold' },
							{ text: 'Previous Exp. Off Hire Date', value: 'preOffHire', align: 'left', sortable: false, class: 'font-weight-bold' },
							{ text: 'New Exp. Off Hire Date', value: 'newOffHire', align: 'left', sortable: false, class: 'font-weight-bold pr-0' }
						],
						history: [
							{
								updatedBy: 'Carter',
								dateTimeUpdated: '11/05/2000',
								preOffHire: '01/01/1950',
								newOffHire: '21/01/2050'
							},
							{
								updatedBy: 'Jack',
								dateTimeUpdated: '21/05/2100',
								preOffHire: '01/01/1990',
								newOffHire: '21/01/2150'
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		async submit(orderLineId) {
			this.errorMessage = null;
			try {
				this.loading = true;
				const payload = {
					orderLineId: orderLineId
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);
				this.loading = false;
				if (response.status == 200) {
					const result = response.data.result;
					this.showRequestSuccess = true;
					this.items = result.results;
					this.plantNo = result.plantNo;
					this.plantInfo = result.plantInfo;
				} else {
					this.errorMessage = 'Hire detail update failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Hire detail update failed, please try again later.';
			}
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
