// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';

// component
const component = {
	name: 'SclUpdateUserStatusModal',
	mixins: [mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.label ? typeof obj.label === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'user-status-event',
					width: '512px',
					label: '',
					postbackURL: '',
					postbackPipe: '',
					accountData: {}
				},
				this.api
			);
		}
	},
	data() {
		return {
			dialog: false,
			accountData: []
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
	},
	watch: {
		'propApi.accountData': function (newVal) {
			this.accountData = newVal;
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					userId: this.accountData.userId,
					status: this.accountData.status
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					this.$root.$emit('snackbar-event', {
						copy: response.data.message || 'User status updated successfully',
						type: 'success'
					});
					location.reload();
				} else {
					this.errorMessage = 'User status update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
