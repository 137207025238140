import axios from 'axios';

// component
const component = {
	name: 'SclExceptionTable',
	data() {
		return {
			items: [],
			pagination: {
				sortBy: null,
				page: 0,
				rowsPerPage: 25,
				total: 0
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.navigationEditURL ? typeof obj.navigationEditURL === 'string' : true));
				set.add(!!(obj.postbackSelectURL ? typeof obj.postbackSelectURL === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	async mounted() {},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					addEvent: 'add-customer-event',
					editEvent: 'threshold-event',
					navigationEditURL: 'http://portal.sc92.sc/api/v1/mch/admin/customer-exceptions/',
					postbackURL: 'http://portal.sc92.sc/api/v1/mch/admin/search',
					tableData: {
						headers: [
							{
								text: 'Customer',
								align: 'left',
								sortable: true,
								value: 'customer',
								width: '80%'
							},
							{ text: 'Edit', value: 'edit', align: 'center', sortable: false },
							{ text: 'Remove', value: 'remove', align: 'center', sortable: false }
						],
						tableItems: [
							{
								name: 'hindmarsh',
								id: 'h'
							},
							{
								name: 'west',
								id: 'w'
							},
							{
								name: 'team b',
								id: 't'
							},
							{
								name: 'outpost',
								id: 'o'
							},
							{
								name: 'southern',
								id: 's'
							},
							{
								name: 'bridge',
								id: 'b'
							}
						],
						tableUpdated: '21/06/2020 14:50'
					}
				},
				this.api
			);
		}
	},
	methods: {
		addCustomer() {
			this.clickEvent(this.propApi.addEvent);
		},
		updateThreshold(customer) {
			this.clickEvent(this.propApi.editEvent, customer);
		},
		getEditUrl(item) {
			return `${this.propApi.navigationEditURL}${item.code}`;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async searchAccounts() {
			const page = this.pagination.page > 1 ? this.pagination.page - 1 : 0;
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					page: page,
					pageSize: this.pagination.rowsPerPage,
					text: '',
					segments: '',
					tier: 'ALL'
				};
				const response = await this.getResults(payload, this.propApi.postbackURL);
				if (response.status == 200 || response.data.statusCode == 200) {
					const result = response.data.result;
					this.showRequestSuccess = true;
					//this.items = result.Results;
					this.pagination.total = result.total;
					this.pagination.page = result.page;
					this.items = result.results;
				} else {
					this.errorMessage = 'Loading accounts failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Loading accounts failed, please try again later.';
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.rowsPerPage;
			await this.searchAccounts();
		},
		async editCustomer(customer) {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					name: customer.name,
					id: customer.id,
					tier: customer.tier
				};

				const result = await this.getResults(payload, this.propApi.postbackSelectURL);

				if (result.status == 200 || result.data.statusCode == 200) {
					this.showRequestSuccess = true;
					location.reload();
				} else {
					this.errorMessage = 'Edit customer failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Edit customer failed, please try again later.';
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
