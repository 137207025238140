/**
 * SclAccountAccessModal
 */

// template
const template = `
	<div class="scl-update-user-access-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					modal
					</v-btn>
				</template>

				if (user.RoleCode.GetValueOrDefault() == coatesSystemAdmin.Role_Id)
				{
					model.AccountData.AssignedCapabilities.CoatesSystemAdministrator.Value = coatesSystemAdmin.Description;
				}
				if (user.RoleCode.GetValueOrDefault() == coatesSalesSupport.Role_Id)
				{
					model.AccountData.AssignedCapabilities.CoatesSystemAdministrator.Value = coatesSalesSupport.Description;
				}
				if (user.RoleCode.GetValueOrDefault() == coatesViewOnlyAdmin.Role_Id)
				{
					model.AccountData.AssignedCapabilities.ViewOnlyAdmin.Value = coatesViewOnlyAdmin.Description;
				}
				if (user.RoleCode.GetValueOrDefault() == coatesSales.Role_Id)
				{
					model.AccountData.AssignedCapabilities.CoatesSales.Value = coatesSales.Description;
				}

				<v-card class="fill-height">
					<div class="scl-user-access-form scl-form">
						<v-container grid-list-xl fluid class="px-0">
							<v-layout row wrap>
								<v-flex xs12>
									<h3 class="pb-2 font-weight-regular">Assign capabilities (Please select at least one)</h3>
									<div class="d-inline-flex">
										<v-checkbox v-model="propApi.accountData.coatesSales"
													label="Coates Sales"
													hide-details
													class="mt-0 pt-0 font-weight-regular"></v-checkbox>
										<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
									</div>
								</v-flex>
								<v-flex xs12>
									<div class="d-inline-flex">
										<v-checkbox v-model="propApi.accountData.coatesSalesSupport"
													label="Coates Sales Support"
													hide-details
													class="mt-0 pt-0"></v-checkbox>
										<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
									</div>
								</v-flex>
								<v-flex xs12>
									<div class="d-inline-flex">
										<v-checkbox v-model="propApi.accountData.coatesSystemAdministrator"
													label="Coates System Administrator"
													hide-details
													class="mt-0 pt-0"></v-checkbox>
										<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
									</div>
								</v-flex>
								<v-flex xs12>
									<div class="d-inline-flex">
										<v-checkbox v-model="propApi.accountData.viewOnlyAdmin"
													label="View Only Admin"
													hide-details
													class="mt-0 pt-0"></v-checkbox>
										<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
									</div>
								</v-flex>
								<v-flex xs12>
									<v-card-actions class="scl-user-details__actions px-0" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
										<v-spacer />
										<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">submit</v-btn>
									</v-card-actions>
								</v-flex>
							</v-layout>
						</v-container>
					</div>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
