/**
 * SclHeader api
 */

// template
const template = `
	<div class="scl-hire-header">
		<v-container fluid>
			<v-layout row wrap class="mb-2">
				<v-flex xs12 order-xs0 mb-3>
					<scl-route-button :api="{href: 'http://#', label: 'Back to my hires'}"></scl-route-button>
				</v-flex>
				<v-flex xs12 md10 order-xs1 mb-2>
					<h1 class="font-weight-bold scl-hire-header__title">{{propApi.title}}</h1>
				</v-flex>
				<v-flex xs2 order-xs3 order-md2 text-xs-right>
					<a href="javascript:void(0);"><v-icon class="scl-hire-header__icon" color="primary">fas fa-print</v-icon></a>
				</v-flex>
				<v-flex xs10 order-xs2 order-md3 class="scl-hire-header__subtitle">
					<div>Plant No. <span class="font-weight-bold">{{propApi.plantNo}}</span></div>
					<div class="link-divider"></div>
					<div>On-Hire Date <span class="font-weight-bold">{{propApi.plantHireDate}}</span></div>
					<div v-if="subtitleCopy !== ''"><p>{{subtitleCopy}}</p></div>
				</v-flex>
			</v-layout>
			<v-layout row wrap class="mb-2" v-if="propApi.site !== null">
				<v-flex xs12>
					<p class="caption mb-0">Viewing pricing for the site:</p>
					<p class="font-weight-bold title-2 mb-0">{{propApi.site}}</p>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex shrink class="mr-3">
					<p class="caption mb-0">Master Account</p>
					<v-select v-model="accountType.master" :items="propApi.accountItems.masterAccountItems" return-object @change="filterSearch" :class="{'d-none': $vuetify.breakpoint.smAndDown}" label="Select Master Account" outline hide-details solo flat class="scl-hire-table-header__title-select text-capitalize"></v-select>
				</v-flex>
				<v-flex shrink class="mr-3">
					<p class="caption mb-0">Site account</p>
					<v-select v-model="accountType.site" :disabled="!accountType.master" :items="propApi.accountItems.siteAccountItems" return-object @change="filterSearch" :class="{'d-none': $vuetify.breakpoint.smAndDown}" label="Select Site Account" outline hide-details solo flat class="scl-hire-table-header__title-select text-capitalize"></v-select>
				</v-flex>
				<v-flex grow class="mr-3" v-if="accountType.siteId">
					<p class="caption mb-0">Pricebook no</p>
					<v-chip color="#CCCBC9" text-color="#4E4A46" class="font-weight-bold title-2 ml-0 mt-2 pa-1">{{accountType.siteId}}</v-chip>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
