<template>
	<v-card class="scl-account-ageing-card">
		<v-container fluid class="pa-0">
			<v-layout row wrap>
				<v-flex xs12 class="scl-account-ageing-card__indicator">
					<scl-radial-progress-card :value="value" :color="color" />
				</v-flex>
				<v-flex xs12 class="scl-account-ageing-card__duration">
					<v-sheet :color="color" class="py-1 px-2">
						<p class="mb-0 font-weight-bold white--text text-capitalize">{{ label }}</p>
					</v-sheet>
				</v-flex>
				<v-flex xs12 class="d-flex py-3 px-2 subheading scl-account-ageing-card__details">
					<p class="mb-0 text-capitalize">{{ month }}</p>
					<v-spacer></v-spacer>
					<p class="mb-0 text-xs-right font-weight-bold">{{ formatCurrency(amount) }}</p>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
</template>

<script>
import { formatAmount } from '@/_scl-portals/utils/formatter';

export default {
	name: 'SclAccountAgeingCard',
	props: {
		value: {
			type: Number,
			default: 0
		},
		color: {
			type: String,
			default: '#000'
		},
		month: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		amount: {
			type: Number,
			default: 0
		}
	},
	methods: {
		formatCurrency(amt) {
			return `$${formatAmount(amt)}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.scl-account-ageing-card {
	width: auto;
	overflow: hidden;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	&__indicator {
		background-color: $color-grey7;
	}
	&__details {
		color: $color-3D3935;
	}
}
</style>
