/**
 * SclHeader api
 *
 */

// template
const template = `
	<div class="scl-page-header">
		<v-container fluid>
			<v-layout row wrap>
				<v-flex xs12>
					<h1 class="font-weight-black scl-page-header__title">{{propApi.title}}</h1>
					<p class="mb-0">{{propApi.copy}}</p>
				</v-flex>
				<!-- <v-flex xs12>
					<v-btn @click="addUser" class="scl-component scl-button scl-button--small">
						<v-icon class="scl-hire-table-header__icon" size="18">fas fa-user-plus</v-icon>
						Add admin user
					</v-btn>
					<v-btn @click="updateMessage" class="scl-component scl-button scl-button--small">
						<v-icon class="scl-hire-table-header__icon" size="18">fas fa-comment</v-icon>
						Login message
					</v-btn>
				</v-flex> -->
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
