<template>
	<v-card class="scl-radial-progress-card" flat color="rgba(0, 0, 0, 0)">
		<v-card-text ma-0 class="d-flex py-4">
			<v-progress-circular :rotate="-90" :size="150" :width="23" :value="value" :color="color">{{ value }}%</v-progress-circular>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'SclRadialProgressCard',
	props: {
		value: {
			type: Number,
			default: 0
		},
		color: {
			type: String,
			default: '#000'
		}
	}
};
</script>

<style lang="scss" scoped></style>
