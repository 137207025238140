export const capitalise = str => str.charAt(0).toUpperCase() + str.slice(1);

export const lowerCaseFirstChar = str => str.charAt(0).toLowerCase() + str.slice(1);

export const toKebabCase = str => {
	return (str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g) || []).join('-').toLowerCase();
};

export const toPascalCase = str => str.match(/\w+/g)?.reduce((acc, word) => acc + capitalise(word), '') || '';

export const toCamelCase = str => lowerCaseFirstChar(toPascalCase(str));
export const toHuman = (value = '') =>
	value
		.split(/(?=[A-Z])|(\d+)/g)
		.filter(value => value)
		.join(' ')
		.toLowerCase();

const Formats = {
	camel: toCamelCase,
	kebab: toKebabCase,
	pascal: toPascalCase,
	human: toHuman
};

export const formatText = (str, format) => {
	if (format && Formats[format]) {
		return Formats[format](str);
	}
	return str;
};
