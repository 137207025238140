import axios from 'axios';

// component
const component = {
	name: 'SclStepper',
	components: {},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.arrayItem ? Array.isArray(obj.arrayItem) : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			el: 1,
			account: {},
			selectedAccounts: [],
			resultMessage: null,
			successMessage: null,
			userDetailsValid: false,
			userAccessValid: false,
			accountDetails: {},
			accountCapabilities: {}
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					value: false,
					disabled: true,
					accounts: {},
					contact: '',
					title: '',
					firstName: '',
					surname: '',
					jobTitle: '',
					role: '',
					email: '',
					mobileNumber: '',
					phoneNumber: '',
					addressNumber: '',
					addressStreet: '',
					addressSuburb: '',
					addressState: '',
					addressPostcode: '',
					managed: '',
					coatesHireOwner: false,
					coatesHireManager: false,
					myHires: true,
					myHiresWithRates: '',
					myHiresWithEOHD: '',
					myInvoices: true,
					myPricebook: false,
					myPricebookWithExport: '',
					myTelemetry: false,
					sendInvite: 'yes',
					status: null,
					lastLogin: null,
					lastEmail: null,
					myQuoteRequests: true
				},
				this.api
			);
		},
		isValid() {
			if (!this.$refs.userDetailsForm) {
				return false;
			}
			if (!this.$refs.userDetailsForm.$refs.detailsForm) {
				return false;
			}
			return this.$refs.userDetailsForm.$refs.detailsForm.validate();
		}
	},
	watch: {},
	mounted() {
		this.account = this.propApi;
		const self = this;
		//need to use events as in MVC the components don't dynamically rebuild and pass the props down

		//on account details settings change
		this.$root.$on('stepper-data-details', data => {
			if (!self.userDetailsValid) {
				return;
			}
			self.accountDetails = data;
			self.account = Object.assign(self.account, data);
		});
		//on account access settings change
		this.$root.$on('stepper-data-access', data => {
			if (!self.userAccessValid) {
				return;
			}
			//Assign Selected Sites
			self.siteAccess = self.selectedAccounts;
			//Assign Selected roles
			self.accountAccess = data;

			self.accountCapabilities = {
				coatesHireManager: data.coatesHireManager,
				coatesHireOwner: data.coatesHireOwner,
				myHires: data.myHires,
				myHiresWithEOHD: data.myHiresWithEOHD,
				myHiresWithRates: data.myHiresWithRates,
				myPricebook: data.myPricebook,
				myPricebookWithExport: data.myPricebookWithExport,
				myTelemetry: data.myTelemetry,
				myInvoices: data.myInvoices,
				sendInvite: data.sendInvite
			};
			self.account = Object.assign(self.account, data);
			//self.account.capabilities = data;
		});
		//on account access modal change
		this.$root.$on('select-account-access-event', data => {
			self.selectedAccounts = data;
		});
		this.$root.$on('user-details-valid', valid => {
			self.userDetailsValid = valid;
		});
		this.$root.$on('user-access-valid', valid => {
			self.userAccessValid = valid;
		});
		this.$root.$on('stepper-event', data => {
			this.account = { ...data[1] };
			switch (data[0]) {
				case 'user-details':
					this.el = 2;
					break;
				case 'user-access':
					this.el = 3;
					break;
				case 'add-user':
					this.submit();
					break;
				case 'edit-step':
					this.el = 1;
					break;
			}
		});
		if (this.propApi.stepCopy) {
			this.$root.$emit('update-subtitle-copy', this.propApi.stepCopy[this.el - 1]);
		}
	},
	methods: {
		previousStep() {
			if (this.el === 0) {
				return;
			}
			this.el--;
			if (this.propApi.stepCopy) {
				this.$root.$emit('update-subtitle-copy', this.propApi.stepCopy[this.el - 1]);
			}
			document.getElementById('page-top-position').scrollIntoView();
		},
		nextStep() {
			this.el++;
			if (this.propApi.stepCopy) {
				this.$root.$emit('update-subtitle-copy', this.propApi.stepCopy[this.el - 1]);
			}
			document.getElementById('page-top-position').scrollIntoView();
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				this.successMessage = null;
				this.resultMessage = null;
				const payload = {
					contact: this.accountDetails.contact,
					user: this.accountDetails,
					accounts: this.selectedAccounts
				};
				const result = await this.getResults(payload, this.propApi.postbackURL);

				if (result.status === 200 && result.data.success) {
					const snackBarMessage = {
						closeButtonHidden: true,
						copy: result.data.message || 'User successfully added',
						type: 'success',
						timeout: 2000
					};
					this.successMessage = snackBarMessage.copy;
					this.$root.$emit('snackbar-event', snackBarMessage);
					if (this.propApi.redirectUrl) {
						window.location.replace(this.propApi.redirectUrl);
					}
				} else {
					this.errorMessage = 'Detail update failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.resultsMessage = snackBarMessage.copy;
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

export default component;
