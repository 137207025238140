/**
 * SclCardIcon api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * copy prop
 * @param {string} 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor'
 *
 * icon prop
 * @param {string} 'fas fa-truck-pickup'
 */

// template
const template = `
	<div>
		<v-card flat :href="propApi.href" class="scl-card-icon__card">
			<v-icon color="accent" class="scl-card-icon__icon">{{ propApi.icon }}</v-icon>
			<v-card-title class="scl-card-icon__container">
				<div>
					<h4 class="scl-card-icon__title">{{ propApi.title }}</h4>
					<p class="scl-card-icon__copy">{{ propApi.copy }}</p>
				</div>
			</v-card-title>
		</v-card>
	</div>
`;

// export template
export default template;
