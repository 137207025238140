<template>
	<v-dialog v-model="showSpinner" content-class="scl-loading-spinner" transition="fade-transition" fullscreen persistent>
		<v-progress-circular indeterminate size="64" width="8" color="#ff6105" />
	</v-dialog>
</template>

<script>
export default {
	name: 'SclLoadingSpinner',
	data() {
		return {
			showSpinner: false
		};
	},
	created() {
		this.$root.$on('loadingSpinner', value => {
			this.showSpinner = value;
		});
	}
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
	::v-deep .scl-loading-spinner {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(255, 255, 255, 0.3);
	}
}
</style>
