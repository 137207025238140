/**
 * SclDueWithinThresholdModal
 */

// template
const template = `
	<div class="scl-add-customer-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-add-customer-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">Add customers</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form v-model="account" class="scl-form ">
						<v-container grid-list-xl class="scl-add-customer-modal__container pt-0">
							<v-layout row wrap fill-height>
								<v-flex xs12 md3 pt-0>
									<v-select
										:items="propApi.items"
										class="scl-form__input scl-form__select scl-add-customer-modal__select"
										outline
										label="Customer Level"
									></v-select>
								</v-flex>
								<v-flex xs12 md9 pt-0 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}" class="scl-add-customer-modal__search">
									<v-layout row wrap>
										<v-flex xs8>
											<v-text-field
												v-model="tableQuery.query"
												class="scl-form__input"
												outline
												label="Search"
												prepend-inner-icon="fas fa-search"
												hide-details
											></v-text-field>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout row wrap fill-height px-3>
								<v-flex xs12>
									<v-data-table
										:headers="propApi.customerData.headers"
										:items="items"
										item-key="name"
										:rows-per-page-items="[10,20,30]"
										:loading="loading"
										:search="tableQuery.query"
										v-model="selected"
									>
										<template v-slot:items="props">
											<tr>
												<td class="px-0 align-center scl-add-customer-modal___zindex hidden-sm-and-down">
													<v-checkbox :disabled="props.item.disabled" class="scl-add-customer-modal___checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
												</td>
												<td class="body-1 pl-0" v-html="searched(props.item.name)"></td>
												<td class="text-xs-left body-1" v-html="searched(props.item.level)"></td>
												<td class="text-xs-left body-1" v-html="searched(props.item.code)"></td>
											</tr>
										</template>
									</v-data-table>
								</v-flex>
								<v-flex xs12 class="scl-add-customer-modal__action">
									<p class="mr-4 mb-0">{{selected.length}} selected</p>
									<v-btn class="scl-component scl-button" :disabled="selected.length === 0" flat @click="submit()">
										add customer(s)
									</v-btn>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>

			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
