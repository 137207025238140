/**
 * SclResetPasswordModal
 */

// template
const template = `
	<div class="scl-reset-password-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					{{propApi.modalLabel}}
					</v-btn>
				</template>
				<v-card>
					<v-toolbar class="scl-dialog__toolbar mb-3" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form v-if="!showRequestSuccess" ref="resetPasswordForm" class="scl-form">
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row wrap>
								<v-flex xs12 class="pb-0">
									<p>{{propApi.copy}}</p>
									<v-text-field
										v-model="email"
										type="email"
										label="Email Address"
										:rules="[rules.required, rules.email]"
										required
										outline
										class="scl-form__input"
										autocapitalise="off"
										autocorrect="off"
										autocomplete="email"
										validate-on-blur
									></v-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs12>
									<v-btn
									color="accent" block
									class="scl-component scl-button"
									depressed
									@click="submit"
								>
									reset
									</v-btn>
								</v-flex>
								<v-alert type="error" :value="errorMessage" outline>{{ errorMessage }}</v-alert>
							</v-layout>
						</v-container>
					</v-form>
					<v-form v-if="showRequestSuccess" class="scl-form">
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row wrap>
								<v-flex xs12 class="pb-0">
									<p>
										An email has been sent to you with a password reset link.
										<br />Please allow a few minutes for the email to arrive.
									</p>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
