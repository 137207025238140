/**
 * SclUnsavedChangesModal
 */

// template
const template = `
	<div class="scl-unsaved-changes-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-unsaved-changes-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									Unsaved changes
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-unsaved-changes-modal__container pt-0">
						<v-layout row wrap px-2 class="scl-unsaved-changes-modal__container-layout">
							<v-flex xs12 class="px-0">
								<p>Would you like to save changes before navigation away from the section?</p>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">LEAVE WITHOUT SAVING</v-btn>
						<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">SAVE CHANGES</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
