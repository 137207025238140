import axios from 'axios';
import mxList from '@portals/mixins/components/mxList.js';
import mxDate from '@portals/mixins/common/mxDate.js';

// component
const component = {
	name: 'SclExpandableTable',
	mixins: [mxList, mxDate],
	data() {
		return {
			selected: [],
			paginationUpdating: null,
			pagination: {
				sortBy: 'OnHireDate',
				page: 1,
				rowsPerPage: 25,
				total: 0,
				descending: true
			},
			indeterminate: false,
			tableQuery: [],
			loading: false,
			tableData: {},
			results: [],
			headers: [] // this is no longer used, have not been tested
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					event: 'confirmation-event',
					postbackURL: '',
					postbackPipe: '',
					tableData: {}
				},
				this.api
			);
		},
		pageSizeQueryParam() {
			const url = new URL(window.location.href);
			const param = url.searchParams.get('rowsPerPage');
			const pageSize = param ? parseInt(param) : 25;
			if (pageSize > 0 && pageSize <= 25) {
				return pageSize;
			}
			return 25;
		},
		filterParameter() {
			const url = new URL(window.location.href);
			return url.searchParams.get('filter');
		},
		indexedResults() {
			return this.results.map((item, index) => ({
				id: index,
				...item
			}));
		}
	},
	watch: {
		selected() {
			if (this.selected.length > 0 && this.selected.length != this.propApi.tableData.tableItems) {
				this.indeterminate = true;
			}
			if (this.selected.length === 0) {
				this.indeterminate = false;
			}
		},
		'propApi.tableData': {
			handler(newVal) {
				if (!newVal) {
					return;
				}
				this.tableData = newVal;
				this.tableQuery = newVal.tableQuery ? newVal.tableQuery : [];
			},
			deep: true,
			immediate: true
		}
	},
	async mounted() {
		const self = this;
		this.paginationUpdating = false;
		this.$root.$on('loadingSpinner', ev => {
			this.loading = ev;
		});
		this.$root.$on('table-filter-query', filters => {
			self.paginationUpdating = false;
			self.tableQuery = filters;
		});
		this.$root.$on('table-filter-results', event => {
			self.paginationUpdating = false;
			self.results = event.result.results;
			self.pagination.total = event.result.total;
		});
		this.pagination.rowsPerPage = this.pageSizeQueryParam;
		this.results = !this.api ? [] : this.api.tableData.tableItems;
		this.headers = !this.api ? [] : this.api.tableData.headers; // remove this line if headers in data is removed
	},
	methods: {
		hireStatus(status) {
			const statusColour = this._mxList_statusColour(status);
			return statusColour;
		},
		selectRow(item) {
			if (!this.selected || this.selected.length > 0) {
				this.selected = [];
			}
			if (this.selected.length === 0) {
				this.selected.push(item);
				this.clickEvent(this.propApi.event, this.selected);
				return;
			}
		},
		toggleRowSelect() {
			if (this.selected.length > 0) {
				this.selected = [];
				this.indeterminate = false;
				return;
			}
			if (this.selected.length === 0) {
				this.results.forEach(item => this.selected.push(item));
				return;
			}
		},
		searched(val, key) {
			if (!val) {
				return '';
			}
			let tableCellString = val;
			if (!key) {
				return tableCellString;
			}
			const itemKey = key.toLowerCase();

			this.tableQuery.forEach(queryItem => {
				if ((queryItem && itemKey === queryItem.category.toLowerCase()) || queryItem.category.toLowerCase() === 'all') {
					const textRegExp = new RegExp(queryItem.query, 'gi');
					if (this.results.length > 0 && queryItem.query != '') {
						tableCellString = tableCellString.replace(textRegExp, function (r) {
							return '<span class="scl-expandable-table__table-row--searchmatch">' + r + '</span>';
						});
					}
				}
			});
			return tableCellString;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},
		async updatePagination(data) {
			this.paginationUpdating = true;
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.pageSize || data.rowsPerPage;
			this.pagination.sortBy = data.sortBy;
			this.pagination.descending = data.descending;
			this.$root.$emit('table-update-pagination', this.pagination);
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		},
		getKeyByValue(object, value) {
			if (!object || !value) {
				return;
			}
			return Object.keys(object).find(key => object[key] === value);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
