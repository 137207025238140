import axios from 'axios';
// Mixins
import mxDate from '../../mixins/common/mxDate.js';
import mxList from '../../mixins/components/mxList.js';

// component
const component = {
	name: 'SclExpandableInvoiceTable',
	mixins: [mxDate, mxList],
	data() {
		const url = new URL(window.location.href);
		return {
			selected: [],
			pagination: {
				sortBy: 'InvoiceDate',
				page: 1,
				rowsPerPage: 25,
				total: 0,
				descending: url.searchParams.get('filter') === 'Closed' ? true : false
			},
			indeterminate: false,
			tableQuery: [],
			tableData: {},
			results: [],
			headers: []
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					postbackURL: '',
					postbackPipe: '',
					tableData: {}
				},
				this.api
			);
		},
		pageSizeQueryParam() {
			const url = new URL(window.location.href);
			const param = url.searchParams.get('rowsPerPage');
			const pageSize = param ? parseInt(param) : 25;
			if (pageSize > 0 && pageSize <= 25) {
				return pageSize;
			}
			return 25;
		},
		filterParameter() {
			const url = new URL(window.location.href);
			return url.searchParams.get('filter');
		}
	},
	watch: {
		selected() {
			if (this.selected.length > 0 && this.selected.length != this.propApi.tableData.tableItems) {
				this.indeterminate = true;
			}
			if (this.selected.length === 0) {
				this.indeterminate = false;
			}
		},
		'propApi.tableData': {
			handler(newVal) {
				if (!newVal) {
					return;
				}
				this.tableData = newVal;
				this.tableQuery = newVal.tableQuery ? newVal.tableQuery : [];
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		const self = this;
		this.$root.$on('loadingSpinner', ev => {
			this.loading = ev;
		});
		this.$root.$on('table-filter-query', filters => {
			self.tableQuery = filters;
		});
		this.$root.$on('table-filter-results', event => {
			self.results = event.result.results;
			self.pagination.total = event.result.total;
		});
		this.pagination.rowsPerPage = this.pageSizeQueryParam;
		this.pagination.descending = this.filterParameter === 'Closed' ? true : false;
		this.results = !this.api ? [] : this.api.tableData.tableItems;
		this.headers = !this.api ? [] : this.api.tableData.headers;
	},
	methods: {
		invoiceStatus(status) {
			const statusColour = this._mxList_invoiceStatusColour(status);
			return statusColour;
		},
		toggleRowSelect() {
			if (this.selected.length > 0) {
				this.selected = [];
				this.indeterminate = false;
				return;
			}
			if (this.selected.length === 0) {
				this.results.forEach(item => this.selected.push(item));
				return;
			}
		},
		searched(val, key) {
			if (val === undefined) {
				console.error('value parameter is undefined in SclExpandableInvoiceTable');
				return '';
			}
			let tableCellString = val;
			if (!key) {
				return tableCellString;
			}
			const itemKey = key.toLowerCase();

			// check key type, for appied, balance and invoice amount, need to convert to currency format
			if (['invoiceAmount', 'applied', 'balance'].includes(key)) {
				tableCellString = new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(val);
			}

			this.tableQuery.forEach(queryItem => {
				if (itemKey === queryItem.category.toLowerCase() || queryItem.category.toLowerCase() === 'all') {
					const textRegExp = new RegExp(queryItem.query, 'gi');
					if (this.results.length > 0 && queryItem.query != '') {
						tableCellString = tableCellString.replace(textRegExp, function (r) {
							return '<span class="scl-expandable-table__table-row--searchmatch">' + r + '</span>';
						});
					}
				}
			});
			return tableCellString;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.rowsPerPage;
			this.pagination.sortBy = data.sortBy;
			this.pagination.descending = data.descending;
			this.$root.$emit('table-update-pagination', this.pagination);
			//await this.submit();
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					page: this.pagination.page,
					pageSize: this.pagination.rowsPerPage,
					text: this.query || '',
					filter: this.filterParameter
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const result = response.data.result;
					this.results = result.results;
					this.pagination.total = result.total;
				} else {
					this.errorMessage = 'Table update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		},
		getKeyByValue(object, value) {
			if (!object || !value) {
				return;
			}
			return Object.keys(object).find(key => object[key] === value);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
