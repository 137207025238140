// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';

// component
const component = {
	name: 'SclPreferencesList',
	mixins: [mxApi],
	data() {
		return {
			preferences: [],
			toggle: false
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.preferenceList ? Array.isArray(obj.preferenceList) : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					event: 'event-alert-modal',
					preferenceList: [],
					postbackPipe: null,
					postbackURL: null
				},
				this.api
			);
		}
	},
	mounted() {
		this.preferences = this.propApi.preferenceList;
	},
	watch: {
		'propApi.preferenceList': function (newVal) {
			this.preferences = newVal;
		}
	},
	methods: {
		toggleOptions(preference) {
			this.toggle = !this.toggle;
			for (var i = 0; i < preference.options.length; i++) {
				preference.options[i].value = this.toggle;
			}
		},
		clearAll() {
			for (var i = 0; i < this.preferences.length; i++) {
				var preference = this.preferences[i];
				for (var j = 0; j < preference.options.length; j++) {
					preference.options[j].value = false;
				}
			}
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					preferences: this.preferences
				};

				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result.status === 200 || result.data.success) {
					this.showRequestSuccess = true;
					const snackBarMessage = {
						copy: result.data.message || 'Preferences successfully updated'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					this.errorMessage = 'Change request failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Change request failed with system error, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
