// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclDomainModal',
	mixins: [mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'add-email-domain',
					width: '512px',
					persistent: false,
					modalLabel: '',
					postbackURL: '',
					postbackPipe: ''
				},
				this.api
			);
		}
	},
	data() {
		return {
			dialog: false,
			errorMessage: '',
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
					return pattern.test(value) || 'Invalid domain.';
				}
			},
			accountData: {
				emailDomain: ''
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			if (event) {
				this.accountData.emailDomain = event.name;
			}
		});
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			if (!this.$refs.form.validate()) {
				return false;
			}

			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					domain: this.accountData.emailDomain
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'User new email domain successfully added',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
