export const AlertTypes = {
	Alert: 'alert',
	Report: 'report'
};

export const Frequencies = {
	Daily: 'Daily',
	ThreeTimesAWeek: 'ThreeTimesAWeek',
	Weekly: 'Weekly',
	Fortnightly: 'Fortnightly',
	Monthly: 'Monthly'
};

export const Methods = {
	Email: 'Email',
	SMS: 'SMS'
};

export const Hours = Array(24)
	.fill('')
	.map((_, i) => {
		const time = i % 12 || 12;
		return `${time}:00 ${i < 12 ? 'am' : 'pm'}`.padStart(5, '0');
	});
