// css import

// component
const component = {
	name: 'SclCardContact',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.phone ? typeof obj.phone === 'string' : true));
				set.add(!!(obj.smsDetail ? typeof obj.smsDetail === 'string' : true));
				set.add(!!(obj.emailAddress ? typeof obj.emailAddress === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					phone: '0444 444 444',
					smsDetail: 'SMS sent at approximately 9:00 am AEST',
					emailAddress: 'email@email.com'
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
