/**
 * SclInvoiceAmountSlot
 */

// template
const template = `
	<v-container grid-list-xl class="scl-invoice-amount-modal__container pt-0">
		<v-layout row wrap fill-height px-1 class="scl-form">
			<v-flex xs6 md5>
				<v-layout row wrap>
					<v-flex xs12 md6 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
						<span class="subheading">Invoice no:</span>
						{{slotData}}
					</v-flex>
					<v-flex xs12 md6 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
						<span class="font-weight-black title-2">{{propApi.invoice.ref}}</span>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs6 md7>
				<v-layout row wrap>
					<v-flex xs12 md3>
						<span class="subheading">Site:</span>
					</v-flex>
					<v-flex xs12 md9 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
						<span class="font-weight-black title-2">{{propApi.invoice.site}}</span>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs6 md5>
				<v-layout row wrap>
					<v-flex xs12 md6 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
						<span class="subheading">Invoice Date:</span>
					</v-flex>
					<v-flex xs12 md6 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
						<span class="font-weight-black title-2">{{propApi.invoice.date}}</span>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs6 md7>
				<v-layout row wrap>
					<v-flex xs12 md3>
						<span class="subheading">PO No:</span>
					</v-flex>
					<v-flex xs12 md9 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
						<span class="font-weight-black title-2">{{propApi.invoice.purchaseOrder}}</span>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs12 class="underline mb-4" :class="{'px-0': $vuetify.breakpoint.mdAndUp}">
				<v-data-table
				:headers="propApi.tableData.headers"
				:items="propApi.tableData.tableItems"
				hide-actions
				class="scl-history-modal__data-table"
				>
					<template v-slot:items="props">
						<td class="text-xs-left body-1 pl-0">{{ props.item.payID }}</td>
						<td class="text-xs-left body-1">{{ props.item.payMethod }}</td>
						<td class="text-xs-left body-1">{{ props.item.date }}</td>
						<td class="text-xs-left font-weight-bold body-1">{{ props.item.amount }}</td>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex xs12 class="underline" :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
				<p class="mb-0">Credit Notes</p>
			</v-flex>
			<v-flex xs12 class="underline" :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
				<a href="#">111222333</a>
			</v-flex>
			<v-flex xs12 mt-3 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
				<p class="mb-0 caption text-capitalize">If you require additional information please contact your nearest branch.</p>
			</v-flex>
		</v-layout>
	</v-container>
`;

// export template
export default template;
