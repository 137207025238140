// component
const component = {
	name: 'SclManageUserSideNav',
	data() {
		return {
			accountDetails: {}
		};
	},
	template: null,
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	mounted() {
		this.accountDetails = this.propApi.accountDetails;
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountLinks: {
						name: 'my account'
					},
					accountDetails: {
						name: 'Mr AA ron',
						role: 'Contact Manager / Administrator',
						status: 'active'
					}
				},
				this.api
			);
		},
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.name}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	}
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

export default component;
