// component
const component = {
	name: 'SclAccountFilter',
	data() {
		return {
			filterData: [],
			customer: '',
			userSpecifiedAccount: null
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.customerAccount ? typeof obj.customerAccount === 'string' : true));
				set.add(!!(obj.filtered ? typeof obj.filtered === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					customerAccount: '',
					filtered: null,
					event: 'account-filter-event'
				},
				this.api
			);
		},
		accountName() {
			return this.customer || this.userSpecifiedAccount || `No Customer Selected`;
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			if (event.selectedItem !== undefined) {
				this.customer = event.selectedItem.name;
			} else {
				this.filterData = event.filter(item => item.value);
			}
		});
		this.customer = this.propApi.customerAccount;
		this.$root.$on('updateConstumAccount', name => {
			this.userSpecifiedAccount = name;
		});
		this.filterData = this.propApi.filtered !== null ? this.propApi.filtered : [];
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
