import { Bar } from 'vue-chartjs';

// component
const component = {
	name: 'SclBarChart',
	mixins: [Bar],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.chartdata ? Array.isArray(obj.chartdata) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		chartdata() {
			return this.propApi.chartdata[0] || {};
		},
		propApi() {
			return Object.assign(
				{
					chartdata: []
				},
				this.api
			);
		},
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false
				},
				scales: {
					yAxes: [
						{
							ticks: {
								fontSize: 14,
								fontColor: '#4E4A46',
								beginAtZero: true,
								callback: value => {
									if (this.chartdata.display === 'currency') {
										return value.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
											minimumFractionDigits: 0,
											maximumFractionDigits: 0
										});
									} else {
										return value;
									}
								}
							}
						}
					],
					xAxes: [
						{
							gridLines: {
								display: false
							},
							ticks: {
								fontSize: 14,
								fontColor: '#4E4A46',
								callback: function (value) {
									return value.substr(0, 3).toUpperCase();
								}
							}
						}
					]
				},
				tooltips: {
					enabled: false,
					custom: function (tooltipModel) {
						// Tooltip Element
						var tooltipEl = document.getElementById('chartjs-tooltip');

						// Create element on first render
						if (!tooltipEl) {
							tooltipEl = document.createElement('div');
							tooltipEl.id = 'chartjs-tooltip';
							tooltipEl.className = 'chartjs-tooltip-bar';
							tooltipEl.innerHTML = '<table></table>';
							document.body.appendChild(tooltipEl);
						}

						// Hide if no tooltip
						if (tooltipModel.opacity === 0) {
							tooltipEl.style.opacity = 0;
							return;
						}

						// Set caret Position
						tooltipEl.classList.remove('above', 'below', 'no-transform');
						if (tooltipModel.yAlign) {
							tooltipEl.classList.add(tooltipModel.yAlign);
						} else {
							tooltipEl.classList.add('no-transform');
						}

						function getBody(bodyItem) {
							const item = bodyItem.lines[0];
							return item;
						}

						// Set Text
						if (tooltipModel.body) {
							const titleLines = tooltipModel.title || [];
							const bodyLines = tooltipModel.body.map(getBody);
							let innerHtml = '<thead>';

							titleLines.forEach(function (title) {
								innerHtml += '<tr><th>' + title + '</th></tr>';
							});
							innerHtml += '</thead><tbody>';

							bodyLines.forEach((item, i) => {
								const value =
									this._data.display === 'currency'
										? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item)
										: item;
								const colors = tooltipModel.labelColors[i];
								let style = 'background:' + colors.backgroundColor;
								style += '; border-color:' + colors.borderColor;
								style += '; border-width: 2px';
								const span = '<span style="' + style + '"></span>';
								innerHtml += '<tr><td>' + span + value + '</td></tr>';
							});
							innerHtml += '</tbody>';

							const tableRoot = tooltipEl.querySelector('table');
							tableRoot.innerHTML = innerHtml;
						}

						// `this` will be the overall tooltip
						const position = this._chart.canvas.getBoundingClientRect();

						// Display, position, and set styles for font
						tooltipEl.style.opacity = 1;
						tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
						tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 45 + 'px';
						tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
						tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
						tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
						tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
					}
				}
			};
		}
	},
	watch: {
		'propApi.data': {
			handler(newVal) {
				if (!newVal) {
					return;
				}
				this.data = newVal;
				// this.initChart();
			}
		},
		immediate: true
	},
	mounted() {
		this.initChart();
	},
	methods: {
		initChart() {
			this.renderChart(this.chartdata, this.options);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
