/**
 * SclUserSatus api
 *
 **/

const template = `
<div class="scl-user-status scl-form">
	<v-container grid-list-xl fluid>
		<v-layout row wrap>
			<v-flex xs12>
				<h2 class="scl-user-status__title pb-4 font-weight-bold">Update Status</h2>
			</v-flex>
			<v-flex xs12>
				<h3 class="pb-2">Change current status</h3>
				<v-icon size="15" class="">fas fa-clock</v-icon> Current Status: active
			</v-flex>
			<v-flex xs12 md4>
				<v-select v-model="accountData.status"
						:items="accountData.availableStatus"
						class="scl-form__input scl-form__select"
						outline solo
						label="select" append-icon="fas fa-chevron-down"></v-select>
			</v-flex>
			<v-flex xs12>
				<v-layout>
					<v-flex>
						<v-btn class="scl-component scl-button scl-button--outline" outline flat @click="cancelChanges">Cancel</v-btn>
						<v-btn class="scl-component scl-button" flat @click="confirmChanges">Update</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-container>
	</div>
`;

export default template;
