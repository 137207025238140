import axios from 'axios';

// component
const component = {
	name: 'SclExceptionAccordion',
	data() {
		return {
			expand: [],
			collection: {},
			customer: null
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.expand ? typeof obj.expand === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		this.$nextTick(function () {
			this.collection = this.propApi.collection;
		});
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					expand: false,
					icon: 'fas fa-chevron-down',
					customer: 'Example',
					postbackUpdateURL: '/api/v1/mch/admin/exceptions/edit',
					collection: {
						name: 'Hindmarsh',
						options: [
							{
								name: 'Expected Off Hire Date Status',
								type: 'group',
								input: 'select',
								items: ['ON', 'OFF'],
								value: 'ON',
								lineItem: [
									{
										name: 'Past Due Status',
										input: '',
										value: true
									},
									{
										name: 'Plant Nearing EOHD',
										input: '',
										value: false
									}
								]
							},
							{
								name: 'Alert',
								type: 'group',
								input: 'select',
								items: ['ON', 'OFF'],
								value: 'ON',
								lineItem: [
									{
										name: 'area',
										input: '',
										value: true
									},
									{
										name: '34 324 23 4 234',
										input: '',
										value: false
									},
									{
										name: 'geofence',
										input: '',
										value: false
									},
									{
										name: 'hire',
										input: '',
										value: true
									}
								]
							},
							{
								name: 'Document Access (By Document Type)',
								type: 'single',
								input: '',
								items: '',
								value: '',
								lineItem: [
									{
										name: 'Certificate and Licenses',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'Coates',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'pdf',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'gif',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'jpg',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									}
								]
							},
							{
								name: 'Document Access (By Document Type)',
								type: 'single',
								input: '',
								items: '',
								value: '',
								lineItem: [
									{
										name: 'Certificate and Licenses',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'Coates',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'pdf',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'gif',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'jpg',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									}
								]
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		updateAccess(change) {
			this.collection.options = this.collection.options.map(x => {
				if (x.name === change.name) {
					x.value = change.value;
					x.lineItem = x.lineItem.map(y => {
						y.value = change.value;
						return y;
					});
				}
				return x;
			});
		},
		cancelChanges() {},
		async confirmChanges() {
			this.errorMessage = null;
			try {
				this.loading = true;
				const payload = {
					code: this.propApi.customer,
					collection: this.collection
				};
				const result = await this.getResults(payload, this.propApi.postbackUpdateURL);

				this.loading = false;
				if (result.status == 200 || result.data.statusCode == 200) {
					this.showRequestSuccess = true;
					location.reload();
				} else {
					this.errorMessage = 'Edit customer failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Edit customer failed, please try again later.';
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
