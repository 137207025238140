// component
const component = {
	name: 'SclUserStatus',
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: []
				},
				this.api
			);
		}
	},
	watch: {
		propApi(val) {
			this.accountData = { ...val.accountData };
		}
	},
	data() {
		return {
			accountData: {
				status: 'Active',
				availableStatus: ['Active', 'Inactive']
			},
			stepNumber: 0,
			dueStatus: 5,
			enquiry: {},
			select: {
				title: ['mr', 'mrs', 'dr'],
				role: ['manager'],
				state: ['nsw', 'qld']
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				}
			}
		};
	},
	methods: {
		nextStep() {
			this.$root.$emit('stepper-event', ['user-details', this.accountData]);
		},
		clickEvent(event) {
			this.$root.$emit(event);
		},
		confirmChanges() {},
		cancelChanges() {}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
