<template>
	<div class="scl-update-user-details-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog v-model="dialog" :width="propApi.width" :persistent="propApi.persistent" content-class="scl-modal__component">
				<template v-slot:activator="{ on }">
					<v-btn color="accent" class="scl-component scl-button d-none" depressed v-on="on"> modal </v-btn>
				</template>

				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{ propApi.modalLabel }}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<div class="scl-user-details-form scl-form">
						<v-container grid-list-xl fluid pa-4>
							<v-layout row wrap>
								<v-form ref="form">
									<v-container grid-list-xl class="scl-user-details__container pt-0">
										<v-layout row wrap class="scl-user-details__container-layout">
											<v-flex xs12 class="pb-0">
												<p class="mb-0 font-weight-black title">Personal Details</p>
											</v-flex>
										</v-layout>
										<v-layout row wrap>
											<v-flex xs12 md4>
												<v-select
													v-model="accountData.title"
													:items="propApi.titles"
													class="scl-form__input scl-form__select"
													outline
													label="Title"
													append-icon="fas fa-chevron-down"
												></v-select>
											</v-flex>
										</v-layout>
										<v-layout row wrap>
											<v-flex xs12>
												<v-text-field
													v-model="accountData.firstName"
													:rules="[rules.required]"
													class="scl-form__input"
													outline
													label="first name"
												></v-text-field>
											</v-flex>
											<v-flex xs12>
												<v-text-field
													v-model="accountData.lastName"
													:rules="[rules.required]"
													class="scl-form__input"
													outline
													label="last name"
												></v-text-field>
											</v-flex>
											<v-flex xs12>
												<v-text-field v-model="accountData.jobTitle" class="scl-form__input" outline label="Job Title"></v-text-field>
											</v-flex>
											<v-flex xs12>
												<v-select
													v-model="accountData.role"
													:items="propApi.roles"
													class="scl-form__input scl-form__select"
													outline
													label="Role"
													append-icon="fas fa-chevron-down"
													:rules="[rules.required]"
												></v-select>
											</v-flex>
										</v-layout>

										<v-divider></v-divider>

										<v-layout row wrap class="scl-user-details__container-layout">
											<v-flex xs12 class="pb-0 pt-4">
												<p class="mb-0 font-weight-black title">Login Details</p>
											</v-flex>
										</v-layout>
										<v-layout row wrap>
											<v-flex xs12>
												<v-text-field
													v-model="accountData.email"
													class="scl-form__input"
													:rules="[rules.required, rules.email]"
													disabled
													outline
													label="email address"
												></v-text-field>
											</v-flex>
										</v-layout>

										<v-divider></v-divider>

										<v-layout row wrap v-if="accountData.type === 'user'">
											<v-flex xs12 class="pt-4">
												<v-layout row wrap class="scl-user-details__container-layout">
													<v-flex xs12 class="pb-0">
														<p class="mb-0 font-weight-black title">Contact Details</p>
													</v-flex>
												</v-layout>
												<v-layout row wrap>
													<v-flex xs12 md6>
														<v-text-field
															v-model="accountData.mobileNumber"
															:rules="[rules.required]"
															class="scl-form__input"
															outline
															label="Mobile number"
														></v-text-field>
													</v-flex>
													<v-flex xs12 md6>
														<v-text-field
															v-model="accountData.businessNumber"
															class="scl-form__input"
															outline
															label="Business number"
														></v-text-field>
													</v-flex>
												</v-layout>
												<v-layout row wrap>
													<v-flex xs12 md3>
														<v-text-field
															v-model="accountData.unit"
															:rules="[rules.required]"
															class="scl-form__input"
															outline
															label="Street number"
														></v-text-field>
													</v-flex>
													<v-flex xs12>
														<v-text-field
															v-model="accountData.street"
															:rules="[rules.required]"
															class="scl-form__input"
															outline
															label="Street name"
														></v-text-field>
													</v-flex>
													<v-flex xs12 md6>
														<v-text-field
															v-model="accountData.suburb"
															:rules="[rules.required]"
															class="scl-form__input"
															outline
															label="Suburb"
														></v-text-field>
													</v-flex>
													<v-flex xs12 md6>
														<v-select
															v-model="accountData.state"
															:items="propApi.states"
															class="scl-form__input scl-form__select"
															outline
															label="State"
															append-icon="fas fa-chevron-down"
															:rules="[rules.required]"
														></v-select>
													</v-flex>
													<v-flex xs12 md3>
														<v-text-field
															type="number"
															v-model="accountData.postcode"
															:rules="[rules.required]"
															class="scl-form__input"
															outline
															label="Postcode"
														></v-text-field>
													</v-flex>
													<v-flex xs12>
														<v-card-actions class="justify-end pa-0" :class="{ 'd-flex': $vuetify.breakpoint.smAndDown }">
															<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal"
																>Cancel</v-btn
															>
															<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">Save</v-btn>
														</v-card-actions>
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-container>
								</v-form>
							</v-layout>
						</v-container>
					</div>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';
import mxAccount from '@portals/mixins/global/mxAccount.js';

// component
export default {
	name: 'SclUpdateUserDetailsModal',
	mixins: [mxAccount, mxApi],
	data() {
		return {
			dialog: false,
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				}
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			if (!event) {
				return;
			}
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.roles ? Array.isArray(obj.roles) : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		accountData() {
			return this.propApi.accountData;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'user-detail-event',
					width: '1000px',
					modalLabel: '',
					postbackURL: '',
					postbackPipe: '',
					roles: [],
					titles: [],
					states: [],
					method: 'apiData',
					accountData: {}
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					accountData: this.accountData
				};
				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response?.status === 200 && response?.data.success) {
					this.$root.$emit('snackbar-event', {
						closeButtonHidden: true,
						copy: response.data.message || 'User details updated successfully added',
						type: 'success',
						timeout: 2000
					});
					location.reload();
				} else {
					this.errorMessage = 'Update details update failed, please try again later.';
					const snackBarMessage = {
						copy: response?.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};
</script>

<style lang="scss">
@import './_SclUpdateUserDetailsModal.scss';
</style>
