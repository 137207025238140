/**
 * SclCardSolid api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * img prop
 * @param {string} 'https://picsum.photos/700/965'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor'
 *
 * icon prop
 * @param {string} 'fas fa-truck-pickup'
 */

// template
const template = `
	<div>
		<v-card flat :href="propApi.href" class="scl-card-solid__card">
			<v-img :src="propApi.img" class="scl-card-solid__img" height="350"></v-img>
			<div class="scl-card-solid__panel">
				<div class="scl-card-solid__panel-container">
					<v-icon color="white" class="scl-card-solid__icon">{{ propApi.icon }}</v-icon>
					<h4 class="scl-card-solid__title">{{ propApi.title }}</h4>
				</div>
			</div>
		</v-card>
	</div>
`;

// export template
export default template;
