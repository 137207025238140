// css import

// component
const component = {
	name: 'SclCardToggle',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.confirmEvent ? typeof obj.confirmEvent === 'string' : true));
				set.add(!!(obj.cancelEvent ? typeof obj.cancelEvent === 'string' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.message ? typeof obj.message === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					title: '',
					message: '',
					toggle: false,
					confirmEvent: '',
					cancelEvent: ''
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.cancelEvent, () => {
			this.toggle = false;
		});
		this.$root.$on(this.propApi.confirmEvent, () => {
			this.toggle = true;
		});
	},
	watch: {
		'propApi.toggle'(newVal) {
			this.toggle = newVal;
		},
		toggle(newVal) {
			if (newVal) {
				this.clickEvent('email-domain-event');
			}
		}
	},
	data() {
		return {
			toggle: false
		};
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
