/**
 * SclCardContact
 */

// template
const template = `
	<v-card class="scl-contact-card mb-2" flat>
		<v-container pa-0>
			<v-layout column ma-0>
				<v-flex xs12 class="scl-contact-card__title font-weight-bold text-capitalize pa-2">
					contact information
				</v-flex>
				<v-flex xs12 pa-3 class="scl-contact-card__content">
					<v-layout column>
						<v-flex xs12 mb-3>
							<div>Mobile Phone Number</div>
							<div class="font-weight-bold mb-2"><a :href="'tel:'+propApi.phone">{{propApi.phone}}</a></div>
							<div class="caption">{{propApi.smsDetail}}</div>
						</v-flex>
						<v-flex xs-12>
							<div>Login Email</div>
							<div class="font-weight-bold"><a :href="'mailto:'+propApi.emailAddress">{{propApi.emailAddress}}</a></div>
						</v-flex>
						<v-flex xs-12 class="scl-contact-card__footer mt-3">
							<div class="caption pt-3">If you want to change your information, go to <a href="#">Profile Details</a></div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
`;

// export template
export default template;
