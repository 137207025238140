<template>
	<div class="scl-quote-request__container">
		<v-layout row wrap class="my-0">
			<v-flex xs12>
				<h2 class="scl-quote-request__title">Delivery or Pickup</h2>
				<p class="mb-4">{{ delivery.subTitle }}</p>
			</v-flex>
			<v-flex xs12 sm12 md8 lg6>
				<v-tabs v-model="activeTab" :show-arrows="false" class="scl-quote-tabs">
					<v-tab :disabled="isReadOnly" class="scl-quote-tabs__tab" href="#tab-1" :ripple="false" data-cy="delivery tab">
						<span>Delivery</span>
					</v-tab>
					<v-tab :disabled="isReadOnly" class="scl-quote-tabs__tab" href="#tab-2" :ripple="false" data-cy="pickup tab">
						<span>Pickup</span>
					</v-tab>
				</v-tabs>
				<!-- tabs content -->
				<v-tabs-items v-model="activeTab" class="scl-quote-tabs__tab-content">
					<!-- Delivery content -->
					<v-tab-item v-if="activeTab === 'tab-1'" eager value="tab-1">
						<v-layout row wrap class="my-0">
							<v-flex xs12>
								<label class="scl-form__label">
									{{ deliveryDetails.locationName.label }}
									<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
								</label>
								<v-text-field
									v-model="delivery.locationName"
									:placeholder="deliveryDetails.locationName.placeholder"
									class="scl-form__input"
									data-cy="delivery location name"
									outline
									:disabled="isReadOnly"
									:readonly="isReadOnly"
									@change="updateDeliveryDetails"
								></v-text-field>
							</v-flex>
							<v-flex xs12 sm4>
								<label class="scl-form__label">
									{{ deliveryDetails.unitNumber.label }}
									<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
								</label>
								<v-text-field
									v-model="delivery.unitNumber"
									:placeholder="deliveryDetails.unitNumber.placeholder"
									class="scl-form__input"
									data-cy="delivery unit number"
									outline
									:disabled="isReadOnly"
									:readonly="isReadOnly"
									@change="updateDeliveryDetails"
								></v-text-field>
							</v-flex>
							<v-flex xs12 sm8>
								<label class="scl-form__label">
									{{ deliveryDetails.street.label }}
									<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
								</label>
								<v-text-field
									v-model="delivery.street"
									:placeholder="deliveryDetails.street.placeholder"
									class="scl-form__input"
									data-cy="delivery street"
									outline
									:disabled="isReadOnly"
									:readonly="isReadOnly"
									@change="updateDeliveryDetails"
								></v-text-field
							></v-flex>
							<v-flex xs12>
								<label class="scl-form__label">
									{{ deliveryDetails.suburb.label }}
									<span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
								</label>
								<v-text-field
									v-if="isReadOnly"
									v-model="readOnlyDelivery"
									class="scl-form__input"
									aria-label="Selected Branch"
									data-cy="delivery suburb postcode"
									outline
									:disabled="isReadOnly"
									:readonly="isReadOnly"
								></v-text-field>
								<v-autocomplete
									v-else
									v-model="delivery.locationId"
									:items="formValues.suburbDetails"
									item-value="id"
									:item-text="getItemText"
									return-object
									:filter="customLocationSearch"
									dense
									filled
									solo
									flat
									outline
									:placeholder="deliveryDetails.suburb.placeholder"
									:rules="!isReadOnly ? [rules.required] : []"
									class="scl-form__input"
									id="deliveryLocation"
									data-cy="delivery suburb postcode"
									:menu-props="{ contentClass: 'scl-quote-delivery-pickup__input--suburb' }"
									attach=".scl-quote-delivery-pickup__input--suburb"
									:clearable="!isReadOnly"
									:append-icon="isReadOnly ? '' : 'far fa-chevron-down select-margin-top'"
									:disabled="isReadOnly"
									:readonly="isReadOnly"
									@change="addressAutocompleteEvent"
								>
									<template slot="item" slot-scope="data">{{ data.item.suburb }}, {{ data.item.state }}, {{ data.item.postcode }} </template>
								</v-autocomplete>
							</v-flex>
							<v-flex xs12>
								<label for="comments" class="scl-form__label">
									{{ deliveryDetails.comments.label }}
									<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
								</label>
								<v-textarea
									v-model="delivery.comments"
									:placeholder="deliveryDetails.comments.placeholder"
									:disabled="isReadOnly"
									:readonly="isReadOnly"
									class="scl-form__input scl-form__textarea"
									data-cy="delivery comments"
									:rules="!isReadOnly ? [rules.counter500] : []"
									outline
									name="comments"
									type="text"
									height="165"
									@change="updateDeliveryDetails"
								></v-textarea>
							</v-flex>
						</v-layout>
					</v-tab-item>

					<!-- Pickup content -->
					<v-tab-item v-if="activeTab === 'tab-2'" value="tab-2" eager class="scl-quote-delivery-pickup__pickup">
						<v-layout class="my-0">
							<v-flex>
								<span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
								<p class="mb-2">Find the nearest branch to you</p>

								<v-text-field
									v-if="selectedBranch.Branch"
									:value="storeName"
									label="Selected Branch"
									aria-label="Selected Branch"
									data-cy="pickup Selected Branch"
									outline
									readonly
									:disabled="isReadOnly"
									:append-icon="!isReadOnly ? 'far fa-times' : ''"
									class="scl-quote-delivery-pickup__input"
									@click:append="handleClearItem"
								></v-text-field>
								<p v-if="selectedBranch.Branch" class="mb-1">
									If your pickup is not available at this branch we will contact you to discuss alternatives
								</p>

								<!-- search: empty -->
								<v-text-field
									v-else
									v-model="query"
									:loading="loading"
									:rules="[rules.required, rules.postcode]"
									label="Enter Postcode"
									data-cy="pickup postcode"
									outline
									append-icon="far fa-search"
									maxlength="4"
									class="scl-quote-delivery-pickup__input"
									aria-label="pickup post code"
								></v-text-field>
							</v-flex>
						</v-layout>

						<template v-if="filteredBranches.length > 0 && !noBranchesFound && query.length > 0">
							<!-- branch results message -->
							<p v-if="isApiResult" class="ma-0">
								{{ filteredBranches.length }} closest branches to <span class="color--primary">{{ query }}</span>
							</p>

							<!-- branch results list -->
							<v-list class="scl-quote-delivery-pickup__branch-results" data-cy="pickup branch result list">
								<v-radio-group :mandatory="true" hide-details>
									<v-list-tile v-for="(item, index) in filteredBranches" :key="index" @click="selectedItem = index">
										<v-list-tile-action>
											<v-icon>fal fa-map-marker-alt</v-icon>
										</v-list-tile-action>
										<v-list-tile-content>
											<v-list-tile-title>{{ item.Branch.StoreName }}</v-list-tile-title>
											<v-list-tile-sub-title>
												{{ item.Branch.StoreAddress }}.
												{{ item.Branch.StoreSuburb }}
												{{ item.Branch.StoreState }}
												{{ item.Branch.StorePostCode }}
											</v-list-tile-sub-title>
										</v-list-tile-content>
										<div v-if="isApiResult" class="v-list__tile__distance">
											{{ item.Distance }}
											km
										</div>
									</v-list-tile>
								</v-radio-group>
							</v-list>
						</template>
						<!-- message: no results -->
						<div v-if="noBranchesFound && query" class="text-xs-center text--grey-mid" data-cy="pickup no branches found">
							<p>
								No results found for
								<span class="primary--text">&lsquo;{{ query }}&rsquo;</span>
							</p>
							<v-card flat class="my-2 bg--grey-lighter">
								<v-card-text>
									<p>
										Sorry it looks like there are no Coates Hire branches in that area. Please try a different location or call us on
										<a href="tel:131552">13 15 52</a> for assistance.
									</p>
								</v-card-text>
							</v-card>
						</div>
					</v-tab-item>

					<!-- Haszardous content -->
					<v-layout row wrap class="mt-4">
						<v-flex xs12>
							<h3 :class="[!isReadOnly ? 'text--orange text--normal' : 'text--normal']">
								{{ isHazardous.label }}
								<scl-tooltip
									class="hazardous-tooltip"
									:api="{
										icon: 'far fa-info-circle',
										message:
											'Will the equipment be exposed to anything that may cause damage while in use or that that will pose a risk to our people upon the return of the equipment? Examples include: asbestos, corrosive or other hazardous materials.'
									}"
								></scl-tooltip>
								<span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
							</h3>
							<v-radio-group v-model="isHazardousOptions" row mandatory :rules="[rules.required]" data-cy="iHazardous Options">
								<v-radio label="No" value="no" color="#ff6105" :disabled="isReadOnly"></v-radio>
								<v-radio label="Yes" value="yes" color="#ff6105" :disabled="isReadOnly"></v-radio>
							</v-radio-group>
						</v-flex>
						<v-flex xs12 v-if="isHazardous.value">
							<label class="scl-form__label">
								{{ hazardousDetails.label }}
								<span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
							</label>
							<v-textarea
								v-model="hazardousDetails.value"
								class="scl-form__input scl-form__textarea"
								aria-label="Hazardous details text area"
								data-cy="Hazardous details text area"
								:rules="[rules.required, rules.counter500]"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								outline
								type="text"
								height="165"
							></v-textarea>
						</v-flex>
					</v-layout>
				</v-tabs-items>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import axios from 'axios';
// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';
import mxRetrieveLocationId from '../../mixins/components/mxRetrieveLocationId';

import SclTooltip from '../tooltip/SclTooltip';

let timeout = null;

const deliveryInit = {
	locationName: '',
	unitNumber: '',
	street: '',
	suburb: '',
	state: '',
	postcode: '',
	comments: '',
	locationId: null,
	selectedBranchCode: ''
};

export default {
	name: 'SclQuoteDeliveryPickup',
	components: {
		SclTooltip
	},
	mixins: [mxRetrieveLocationId, mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.deliveryPickupText ? typeof obj.deliveryPickupText === 'string' : true));
				set.add(!!(obj.hazardousComments ? typeof obj.hazardousComments === 'string' : true));
				set.add(!!(obj.deliveryOrPickup ? typeof obj.deliveryOrPickup === 'string' : true));
				set.add(!!(obj.pickup ? typeof obj.pickup === 'object' : true));
				set.add(!!(obj.delivery ? typeof obj.delivery === 'object' : true));
				set.add(!!(obj.hazardous ? typeof obj.hazardous === 'boolean' : true));
				return !set.has(false);
			}
		},
		isReadOnly: {
			type: Boolean
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					hazardousComments: {},
					hazardous: {},
					deliveryOrPickup: '',
					pickup: {},
					delivery: {
						locationName: '',
						unitNumber: '',
						street: '',
						suburb: '',
						postcode: '',
						state: '',
						comments: '',
						locationId: null,
						subTitle: 'Let us know how you would like to receive the equipment',
						selectedBranchCode: ''
					}
				},
				this.api
			);
		},
		storeName() {
			return this.selectedBranch?.Branch?.StoreName;
		},
		readOnlyDelivery() {
			return `${this.delivery.suburb}, ${this.delivery.state}, ${this.delivery.postcode}`;
		}
	},
	data() {
		return {
			activeTab: 'tab-1',
			isHazardousOptions: '',
			rules: {
				required: value => !!value || 'Required',
				counter50: value => value?.length <= 50 || 'Max 50 characters',
				lettersOnly: value => {
					const lettersPattern = /^[a-zA-Z\s'-]+$/;
					return lettersPattern.test(value) || 'Suburb is invalid';
				},
				postcode: value => {
					return this.isNumeric(value) || 'Postcode is invalid';
				},
				counter500: value => value?.length <= 500 || 'Max 500 characters'
			},
			delivery: JSON.parse(JSON.stringify(deliveryInit)),
			filteredBranches: [],
			isApiResult: false,
			isGeolocationResult: false,
			noBranchesFound: null,
			query: null,
			selectedBranch: {},
			selectedItem: -1,
			loading: false,
			isHazardous: {
				label: 'Will our equipment be exposed to any hazardous substances?',
				value: ''
			},
			hazardousDetails: {
				label: 'Please detail the substances our equipment will be exposed to',
				value: ''
			},
			deliveryDetails: {
				locationName: {
					label: 'Building Name',
					placeholder: 'For example, St Vincent’s Hospital'
				},
				unitNumber: {
					label: 'Unit Number',
					placeholder: 'Unit Number'
				},
				street: {
					label: 'Street',
					placeholder: 'Example Road'
				},
				suburb: {
					label: 'Suburb & Postcode',
					placeholder: 'Search'
				},
				postcode: {
					label: 'Postcode',
					placeholder: 'Postcode'
				},
				comments: {
					label: 'Delivery comments',
					placeholder: 'Please deliver to rear lane'
				}
			},
			formValues: {
				suburbDetails: []
			}
		};
	},
	async mounted() {
		this.$nextTick(async function () {
			await this.getSuburbsData();
		});

		if (this.propApi.deliveryOrPickup === 'Delivery') {
			if (this.isReadOnly) {
				const deleteTabsShow = document.getElementsByClassName('scl-quote-tabs__tab');
				deleteTabsShow[1]?.remove();
			}
			this.activeTab = 'tab-1';
		} else if (this.propApi.deliveryOrPickup === 'Pickup') {
			if (this.isReadOnly) {
				const deleteTabsShow = document.getElementsByClassName('scl-quote-tabs__tab');
				deleteTabsShow[0]?.remove();
			}
			this.activeTab = 'tab-2';
		}

		if (this.propApi.pickup) {
			if (this.propApi.pickup.branch && this.propApi.deliveryOrPickup === 'Pickup') {
				this.selectedBranch = {
					Branch: {
						StoreName: this.propApi.pickup.branch?.storeName,
						StoreState: this.propApi.pickup.branch?.storeState
					}
				};
			}
		}
		if (this.propApi.delivery) {
			this.delivery = this.propApi.delivery;
			if (this.delivery.suburb !== '') {
				this.lookupAndSetBranch(this.readOnlyDelivery).then(() => {
					this.$emit('update-delivery', this.delivery);
				});
			}

			if (!this.isReadOnly) {
				this.$set(this.delivery, 'locationId', this._mxRetrieveLocationId(this.delivery.suburb, this.delivery.postcode, this.formValues.suburbDetails));
			}
		}

		if (this.propApi.hazardous.value === 'true') {
			this.isHazardousOptions = 'yes';
		} else if (this.propApi.hazardous.value === 'false') {
			this.isHazardousOptions = 'no';
		}

		if (this.propApi.hazardousComments) {
			this.hazardousDetails.value = this.propApi.hazardousComments.value;
		}
	},
	watch: {
		isHazardousOptions: {
			handler(newVal) {
				if (newVal === 'yes') {
					this.isHazardous.value = true;
				} else {
					this.isHazardous.value = false;
					this.hazardousDetails.value = '';
				}

				const hazardous = {
					isHazardous: this.isHazardous.value,
					hazardousDetails: this.hazardousDetails.value
				};
				this.$emit('update-hazardous', hazardous);
			},
			deep: true
		},
		'hazardousDetails.value': {
			handler(newVal) {
				const hazardous = {
					isHazardous: this.isHazardous.value,
					hazardousDetails: newVal
				};
				this.$emit('update-hazardous', hazardous);
			},
			deep: true
		},
		activeTab(val) {
			if (val === 'tab-1') {
				this.$emit('delivery-or-pickup', 'Delivery');
			} else if (val === 'tab-2') {
				this.$emit('delivery-or-pickup', 'Pickup');
			}
		},
		branch(val) {
			if (!val) {
				return;
			}
			if (this.selectedBranch !== val) {
				this.selectedBranch = val;
			}
		},

		async query(val) {
			const that = this;
			clearTimeout(timeout);
			if (!this.isNumeric(val)) {
				return;
			}
			// Make a new timeout set to go off in 800ms
			timeout = setTimeout(async function () {
				await that.lookupBranch(val);
			}, 250);
		},

		selectedItem(val) {
			var branch = this.filteredBranches[val];
			if (!branch) {
				return;
			}
			this.selectedBranch = branch;
			this.filteredBranches = [];
		},

		selectedBranch(val) {
			if (!val) {
				return;
			}
			this.$emit('branch-selected', val);
		},

		delivery(val) {
			if (!val) {
				return;
			}

			this.$emit('update-delivery', val);
		}
	},
	methods: {
		async getSuburbsData() {
			const url = `${process.env.VUE_APP_SITE_ROOT}/api/v1/mch/location/GetSuburbsData`;

			try {
				const resp = await this._mxApi_getData({}, url);

				if (resp && resp.status === 200) {
					this.formValues.suburbDetails = resp.data.results.suburbs;
					this.$set(
						this.delivery,
						'locationId',
						this._mxRetrieveLocationId(this.delivery.suburb, this.delivery.postcode, this.formValues.suburbDetails)
					);
				}
			} catch (e) {
				console.error(e);
			}
		},
		updateDeliveryDetails() {
			this.$emit('update-delivery', this.delivery);
		},
		async lookupBranch(query) {
			if (!query) {
				return [];
			}
			this.loading = true;
			const that = this;
			// simulated ajax query
			const url = `${process.env.VUE_APP_SITE_ROOT}/api/location/postcode/${query}/`;
			try {
				await axios.post(url).then(response => {
					that.loading = false;
					that.noBranchesFound = response.data.length == 0;
					that.filteredBranches = response.data;
					that.isGeolocationResult = false;
					that.isApiResult = true;
				});
			} catch (e) {
				that.isApiResult = false;
				that.noBranchesFound = true;
			}
		},
		async lookupAndSetBranch(address) {
			const that = this;
			const url = `${process.env.VUE_APP_SITE_ROOT}/api/location/address/${encodeURIComponent(address)}/?q=${address}`;
			try {
				await axios.post(url).then(response => {
					if (response && response.data && response.data.length > 0) {
						this.delivery.selectedBranchCode = response.data[0].Branch.StoreCode;
					}
				});
			} catch (e) {
				that.isApiResult = false;
			}
		},
		isNumeric(value) {
			return /^\d+$/.test(value);
		},
		handleClearItem() {
			// this.delivery = JSON.parse(JSON.stringify(deliveryInit));
			this.filteredBranches = [];
			this.isApiResult = false;
			this.isGeolocationResult = false;
			this.noBranchesFound = '';
			this.query = '';
			this.selectedBranch = {};
			this.selectedItem = -1;
		},
		addressAutocompleteEvent(e) {
			this.delivery.suburb = e?.suburb || '';
			this.delivery.postcode = e?.postcode || '';
			this.delivery.state = e?.state || '';
			this.delivery.locationId = e?.id || null;
			this.delivery.country = 'Australia';

			if (this.delivery.suburb !== '') {
				this.lookupAndSetBranch(this.readOnlyDelivery).then(() => {
					this.$emit('update-delivery', this.delivery);
				});
			} else {
				this.$emit('update-delivery', this.delivery);
			}
		},
		getItemText(item) {
			return `${item.suburb} ${item.state}, ${item.postcode}`;
		},
		customLocationSearch(item, queryText) {
			const postcode = item.postcode;
			const suburb = item.suburb.toLowerCase();
			const searchText = queryText.toLowerCase();

			return postcode.indexOf(searchText) > -1 || suburb.indexOf(searchText) > -1;
		}
	}
};
</script>
