/**
 * SclBottomSheetFeatured api
 *
 * @param {boolean} inset - Reduces the maximum width of the sheet
 * @param {boolean} persistent - Clicking outside will not dismiss the dialog
 * @param {boolean} value - Controls visibility
 */

// template
const template = `
	<v-bottom-sheet class="scl-bottom-sheet-hire-mobile-menu" v-model="sheet">
		<template v-slot:activator>
			<v-btn color="primary" block depressed class="d-none">
				Bottom mobile menu Sheet
			</v-btn>
		</template>
		<v-card flat class="scl-bottom-sheet-hire-mobile-menu__card pa-3">
			<v-container pa-0 grid-list-xl class="scl-bottom-sheet-hire-mobile-menu__container">
				<v-layout row wrap ma-0 justify-center>
					<v-flex xs12 class="pb-3 px-0 pt-0">
						<v-select v-model="filter.duration" :items="filterItems" label="Hires" outline hide-details solo flat class="scl-bottom-sheet-hire-mobile-menu__title-select text-capitalize font-weight-bold"></v-select>
					</v-flex>
					<v-flex xs12 pa-0>
						<p class="mb-0">Filters</p>
					</v-flex>
					<v-flex xs6 pl-0>
						<v-menu
							ref="dateFromMenu"
							v-model="dateFromMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							lazy
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px">
							<template v-slot:activator="{ on }">
							<v-text-field
							class="scl-bottom-sheet-hire-mobile-menu__date-select font-weight-bold"
								v-model="dateFromFormatted"
								label="From"
								persistent-hint
								hide-details
								@blur="dateFrom = parseDate(dateFromFormatted)"
								v-on="on" append-icon="fas fas fa-calendar-alt"
							></v-text-field>
							</template>
							<v-date-picker v-model="dateFrom" no-title @input="dateFromMenu = false"></v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex xs6 pr-0>
						<v-menu
						ref="dateToMenu"
						v-model="dateToMenu"
						:close-on-content-click="false"
						:nudge-right="40"
						hide-details
						lazy
						transition="scale-transition"
						offset-y
						full-width
						max-width="290px"
						min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field
							class="scl-bottom-sheet-hire-mobile-menu__date-select font-weight-bold"
								v-model="dateToFormatted"
								label="To"
								persistent-hint
								hide-details
								@blur="dateTo = parseDate(dateToFormatted)"
								v-on="on" append-icon="fas fas fa-calendar-alt"
							></v-text-field>
							</template>
							<v-date-picker v-model="dateTo" :min="dateFrom" no-title @input="dateToMenu = false"></v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex xs12>
						<v-checkbox
						v-model="filter.package"
						label="Show Package Details"
						hide-details
						class="mt-0 pt-0"
						></v-checkbox>
					</v-flex>
					<v-flex xs12 text-xs-right px-0>
						<v-btn color="accent" class="scl-component scl-button" @click="clickEvent('hire-table-update-event', filter)">
							apply
						</v-btn>
					</v-flex>
				</v-layout>
			</v-container>
			<v-divider></v-divider>
			<v-container class="px-0">
				<v-layout row wrap>
					<v-flex xs12 pb-1>
						<p class="mb-0">More Actions</p>
					</v-flex>
					<v-flex xs12>
						<v-layout class="scl-bottom-sheet-hire-mobile-menu__footer-link">
							<v-flex class="scl-bottom-sheet-hire-mobile-menu__footer-links">
								<v-btn flat icon class="border mx-0"><v-icon class="scl-hire-table-header__icon" color="primary" size="18">fas fa-print</v-icon><p class="caption text-capitalize">Print</p></v-btn>
							</v-flex>
							<v-flex class="scl-bottom-sheet-hire-mobile-menu__footer-links">
								<v-btn flat icon class="border mx-0"><v-icon class="scl-hire-table-header__icon" color="primary" size="18">fa-file-csv</v-icon><p class="caption text-capitalize">Export to CSV</p></v-btn>
							</v-flex>
							<v-flex class="scl-bottom-sheet-hire-mobile-menu__footer-links">
								<v-btn flat icon class="border mx-0"><v-icon class="scl-hire-table-header__icon" color="primary" size="18">fas fa-file-excel</v-icon><p class="caption text-capitalize">Export to Excel</p></v-btn>
							</v-flex>
							<v-flex class="scl-bottom-sheet-hire-mobile-menu__footer-links">
								<v-btn flat icon class="border mx-0"><v-icon class="scl-hire-table-header__icon" color="primary" size="18">far fa-question-circle</v-icon><p class="caption text-capitalize">Help</p></v-btn>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
	</v-bottom-sheet>
`;

// export template
export default template;
