/**
 * application entry
 */

// imports
import 'babel-polyfill';
import 'whatwg-fetch';
import Vue from 'vue';
import './registerServiceWorker';
import './plugins/vuetify';
import './plugins/googlemaps';
import i18n from './plugins/i18n';
import _kebabCase from 'lodash/kebabCase';
import SitecoreExpEditorPlugin from './plugins/experienceEditor';

// css
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';
import '@fortawesome/fontawesome-pro/css/brands.css';
import '@fortawesome/fontawesome-pro/css/light.css';
import '@/_scl-portals/assets/css/app.scss';

// components
import GlobalComponents from './components/GlobalComponents';

// views
import Views from './views';

// mixins
import DummyData from './mixins/global/DummyData';

// add all components globally

[...GlobalComponents, ...Views].forEach(component => {
	Vue.component(_kebabCase(component.name), component);
});

Vue.config.devtools = true;

Vue.use(SitecoreExpEditorPlugin);

// instantiate Vue
window.vueApp = new Vue({
	i18n,
	el: '#app',
	mixins: [DummyData],
	data() {
		return {
			tabBase: 0,
			tabHire: 0,
			tabAdmin: 0,
			tabAdminPages: 0,
			tabAdminUi: 0,
			tabAccount: 0,
			tabAccountPages: 0,
			tabInvoices: 0,
			tabInvoicesPages: 0,
			tabDashboards: 0,
			tabDashboardsPages: 0,
			tabPricebooks: 0,
			tabReports: 0,
			tabReportsPages: 0,
			tabWysiwyg: 0,
			spinnerDialog: false
		};
	},
	methods: {
		testSpinner() {
			this.$root.$emit('loadingSpinner', true);
			setTimeout(() => {
				this.$root.$emit('loadingSpinner', false);
			}, 5000);
		}
	}
});
