import axios from 'axios';

// component
const component = {
	name: 'SclThresholdModal',
	data() {
		return {
			dialog: false,
			valid: true,
			accountItem: {
				name: null,
				threshold: null,
				numberOfSites: null
			},
			title: 'Set global threshold exeption',
			currentThreshold: {
				thresholdType: 'global-threshold',
				customer: null,
				globalThreshold: null,
				customerException: null,
				selfRegistration: null
			},
			accountData: {
				myHiresWithRates: '',
				myInvoices: false,
				myHires: false
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, item => {
			this.dialog = true;
			// reset threshold data
			this.title = 'Set global threshold exeption';
			this.currentThreshold = {
				thresholdType: 'global-threshold',
				customer: null,
				globalThreshold: null,
				customerException: null,
				selfRegistration: null
			};
			//unsure of what the above object is for
			this.accountItem = {
				threshold: item.threshold,
				name: item.name,
				id: item.id,
				tier: item.tier,
				code: item.code,
				label: item.thresholdLabel
			};
			if (item !== undefined) {
				if (item.customer) {
					this.currentThreshold.thresholdType = 'global-threshold';
					this.currentThreshold.customer = item.customer;
				} else if (item.thresholdType === 'past-due-threshold') {
					this.currentThreshold.thresholdType = 'past-due-threshold';
					this.title = 'Edit ‘Past due threshold’';
				}
			}
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.customer ? typeof obj.customer === 'string' : true));
				set.add(!!(obj.thresholdType ? typeof obj.thresholdType === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'threshold-event',
					width: '512',
					persistent: false,
					modalLabel: '',
					accountItem: {},
					accountData: {}
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.accountData': {
			handler(newVal) {
				this.accountItem = newVal;
				this.accountData = { ...newVal.accountData };
			}
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
			this.accountItem.threshold = 0;
			this.accountItem.numberOfSites = 0;
		},
		increment(name) {
			switch (name) {
				case 'threshold':
					this.accountItem.threshold = parseInt(this.accountItem.threshold, 10) + 1;
					break;
				case 'site':
					this.accountItem.numberOfSites = parseInt(this.accountItem.numberOfSites, 10) + 1;
					break;
			}
		},
		decrement(name) {
			switch (name) {
				case 'threshold':
					this.accountItem.threshold = parseInt(this.accountItem.threshold, 10) - 1;
					break;
				case 'site':
					this.accountItem.numberOfSites = parseInt(this.accountItem.numberOfSites, 10) - 1;
					break;
			}
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					account: this.accountItem
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Details updated successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Detail update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
