import axios from 'axios';
// component
const component = {
	name: 'SclHireHeader',
	data() {
		return {
			drawer: false,
			selectedMasterAccount: null,
			selectedSiteAccount: null,
			isServiceHistoryTab: null,
			accountType: {
				master: null,
				site: null,
				siteId: null
			},
			titleCopy: '',
			subtitleCopy: ''
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.plantName ? typeof obj.plantName === 'string' : true));
				set.add(!!(obj.plantHireDate ? typeof obj.plantHireDate === 'string' : true));
				set.add(!!(obj.site ? typeof obj.site === 'string' : true));
				set.add(!!(obj.orderLineId ? typeof obj.orderLineId === 'string' : true));
				set.add(!!(obj.pricebook ? typeof obj.pricebook === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.account ? typeof obj.account === 'string' : true));
				set.add(!!(obj.siteAccount ? typeof obj.account === 'object' : true));
				set.add(!!(obj.masterAccount ? typeof obj.account === 'object' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		if (this.propApi.masterAccount) {
			this.selectedMasterAccount = this.propApi.masterAccount;
		}
		if (this.propApi.siteAccount) {
			this.selectedSiteAccount = this.propApi.siteAccount;
		}
		const self = this;
		//Need to hide the export on all tabs but service history
		this.$root.$on('tab-change', tab => {
			self.isServiceHistoryTab = String(tab) === '1'; // the tab value is a number at 2023/04/20
		});
		this.$root.$on('update-subtitle-copy', data => {
			this.titleCopy = data.title;
			this.subtitleCopy = data.subtitle;
		});
	},
	watch: {
		'accountType.master': {
			handler() {
				this.accountType.site = null;
				this.accountType.siteId = null;
			}
		},
		selectedMasterAccount: {
			handler() {
				this.siteAccounts = [];
				this.accountType.site = null;
				this.accountType.siteId = null;
				this.selectedSiteAccount = this.siteAccounts && this.siteAccounts.length === 1 ? this.siteAccounts[0] : null;
				const query = {
					siteAccountId: this.siteAccounts && this.siteAccounts.length === 1 ? this.siteAccounts[0].siteAccountId : null,
					masterAccountId: this.selectedMasterAccount.masterAccountId
				};
				if (this.selectedSiteAccount) {
					this.$root.$emit('selectedSA', 3); // By setting this value, we are forcing the default text show "No Data Found."
				}
				this.$root.$emit('table-update-search', query);
			},
			deep: true
		},
		route: {
			handler() {
				const urlParams = new URLSearchParams(window.location.search);
				const myParam = urlParams.get('tab');

				this.isServiceHistoryTab = myParam == '1';
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					title: null,
					plantName: null,
					orderLineId: '12345',
					exportPostbackURL: '/',
					plantHireDate: null,
					site: null,
					pricebook: null,
					account: null,
					siteAccount: null,
					masterAccount: null,
					accountItems: {
						masterAccountItems: null,
						siteAccountItems: null
					}
				},
				this.api
			);
		},
		siteAccounts() {
			if (this.selectedMasterAccount == null) {
				return [];
			}
			return this.selectedMasterAccount.siteAccounts;
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit(fileType) {
			this.clickEvent('table-export-results', fileType);
		},
		selectMasterAccount(masterAccount) {
			this.selectedMasterAccount = masterAccount;
			const accountSelectStatus = 2; // By setting this value, we are forcing the default text show "Please select Site Account."
			this.$root.$emit('selectedMA', accountSelectStatus);
		},
		selectSiteAccount(siteAccount) {
			this.selectedSiteAccount = siteAccount;
			const query = {
				siteAccountId: siteAccount.siteAccountId,
				masterAccountId: this.selectedMasterAccount.masterAccountId
			};
			this.$root.$emit('selectedSA', 3); // By setting this value, we are forcing the default text show "No Data Found."
			this.$root.$emit('table-update-search', query);
		},
		async exportPDF() {
			const payload = {
				page: 0,
				pageSize: 100,
				sortBy: null,
				descending: false,
				orderLineId: this.propApi.orderLineId
			};
			await this.submitExport(payload);
		},
		//TODO: Migrate to a mixin that is comptaible with TableFilter
		async submitExport(payload) {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const result = await this.getAJAXResults(payload, this.propApi.exportPostbackURL);

				//const result2 = await this.getResults(payload, this.propApi.exportPostbackURL);
				const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));

				const link = document.createElement('a');
				link.href = downloadUrl;

				var contentDisposition = result.headers['content-type'];
				link.setAttribute('download', contentDisposition); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();

				if (result.statusCode === 200) {
					this.showRequestSuccess = true;
					//this.results = result.results;
				} else {
					this.errorMessage = 'Hire detail update failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Hire detail update failed, please try again later.';
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},

		async getAJAXResults(data, url) {
			const formData = new FormData();
			const keys = Object.keys(data);
			var form = document.createElement('form');
			form.action = url;
			form.method = 'post';
			form.target = '_blank';

			for (var i = 0; i < keys.length; i++) {
				const key = keys[i];
				if (data[key]) {
					//TODO: change to value type of == array
					if (key == 'filters') {
						for (var j = 0; j < data[key].length; j++) {
							const queryFilterKey = `${key}[${j}][query]`;
							const queryFilterValue = data[key][j].query;
							formData.append(queryFilterKey, queryFilterValue);
							const categoryFilterKey = `${key}[${j}][category]`;
							const categoryFilterValue = data[key][j].category;
							formData.append(categoryFilterKey, categoryFilterValue);
						}
					} else {
						formData.append(key, data[key]);
					}
				}
			}
			document.body.appendChild(form);
			return await axios({
				url: url,
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				method: 'POST',
				responseType: 'blob', // important
				data: formData
			});
		},

		async filterSearch(e) {
			this.errorMessage = null;
			if (e.siteAccountId) {
				this.accountType.siteId = e.siteAccountId;
			}

			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = this.accountType;
				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response && response.status) {
					const result = response.data;
					this.siteAccountItems = this.propApi.accountItems.siteAccountItems;

					this.$root.$emit('table-filter-results', result);
				} else {
					this.errorMessage = 'Account filter failed, please try again later.';
				}
			} catch (e) {
				this.errorMessage = 'Account filter failed, please try again later.';
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
