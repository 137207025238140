import axios from 'axios';

// component
const component = {
	name: 'SclResetPasswordForm',
	data() {
		return {
			dialog: false,
			valid: true,
			successMessage: null,
			resultMessage: '',
			enquiry: {
				username: null,
				currentPassword: '',
				newPassword: null,
				confirmPassword: null
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				min: value => (value !== null && value !== undefined && value.length >= 8) || 'Minimum 8 characters',
				passwordMatch: () => this.enquiry.newPassword === this.enquiry.confirmPassword || 'The password you entered does not match',
				passwordLowercaseValidation: value => {
					const pattern = /^(?=.*[a-z]).*$/;
					return pattern.test(value) || 'The password must contain at least 1 lowercase character';
				},
				passwordOtherValidation: value => {
					const pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).*$/;
					return pattern.test(value) || 'The password must contain at least 1 uppercase, number and special character';
				}
			}
		};
	},
	mounted() {
		if (this.propApi.event !== '') {
			this.$root.$on(this.propApi.event, () => {
				this.$refs.resetPasswordForm.reset();
			});
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.user ? typeof obj.user === 'string' : true));
				set.add(!!(obj.token ? typeof obj.token === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'reset-password-event',
					width: '330',
					persistent: false,
					accountData: {},
					modalLabel: 'Reset password',
					postbackPipe: null,
					postbackURL: null
				},
				this.api
			);
		}
	},
	methods: {
		async submit() {
			if (!this.$refs.resetPasswordForm.validate()) {
				return false;
			}

			this.resultMessage = null;
			this.successMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = this.populateResetPasswordPayload();
				// update change password details is passed in via propApi
				if (this.propApi.user || this.propApi.token) {
					payload.user = this.propApi.user;
					payload.token = this.propApi.token;
				}
				const response = await axios.post(this.propApi.postbackURL, payload);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Password successfully changed',
						type: 'success'
					};
					this.showSuccessMessage = true;
					this.successMessage = snackBarMessage.copy;
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					const snackBarMessage = {
						copy: response.data.message || 'Password change request failed, please try again later.',
						type: 'error'
					};
					this.resultMessage = snackBarMessage.copy;
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		populateResetPasswordPayload() {
			var url = new URL(window.location.href);
			const payload = {
				Token: url.searchParams.get('rptoken'),
				Password: this.enquiry.newPassword
			};
			return payload;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
