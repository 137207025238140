/**
 * This mixin contains the functionality required for Delivery address
 */
const mxRetrieveLocationId = {
	created: function () {},
	methods: {
		_mxRetrieveLocationId(suburb, postcode, locationData) {
			const filters = [item => item.suburb.toLowerCase() === suburb.toLowerCase(), item => item.postcode === postcode];
			const filteredData = filters.reduce((d, f) => d?.filter(f), locationData);
			const locationId = filteredData?.length > 0 ? filteredData[0].id : '';
			return locationId;
		}
	}
};

export default mxRetrieveLocationId;
