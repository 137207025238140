/**
 * SclFileUpload
 */

// template
const template = `
	<div class="scl-file-upload">
		<ul class="pl-0">
			<li v-for="file in files"><v-chip close @input="remove(file)">{{file.name}}</v-chip></li>
		</ul>
		<file-upload
			v-model="files"
			:multiple="true"
			:drop="true"
			ref="upload"
			@input-file="inputFile"
			@input-filter="inputFilter"
		>
			Attach a file
		</file-upload>
		<button class="d-none" v-show="files.length > 0" @click.prevent="$refs.files.active = true" type="button">Start upload</button>
	</div>
`;

// export template
export default template;
