/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-pricing-table scl-form">
		<v-container class="scl-pricing-table__container fluid">
			<v-layout row wrap fill-height>
				<v-flex shrink>
					<v-checkbox class="scl-pricing-table__checkbox mt-0 pt-0" :class="{'scl-pricing-table__checkbox--indeterminate': indeterminate}" hide-details value="all" @click.prevent="toggleRowSelect" :ripple="false" :indeterminate="indeterminate"></v-checkbox>
				</v-flex>
				<v-flex class="scl-pricing-table__selection-cta">
					<div class="scl-pricing-table__selection-cta-divider"></div>
					<v-btn flat @click="toggleRowSelect" class="px-0 text-capitalize">{{ selectAllLabel }}</v-btn>
				</v-flex>
				<v-flex class="scl-pricing-table__pricebook-statement">
					<span v-if="sourceName" class="mb-0">{{sourceInfo}}</span>
				</v-flex>
				<v-flex class="xs12">
					<v-data-table
						v-model="selected"
						:headers="headers"
						:items="results"
						:pagination.sync="pagination"
						@update:pagination="updatePagination"
						:total-items="pagination.total"
						item-key="productDescription">

						<template v-slot:headers="props">
							<tr>
								<th v-for="header in props.headers" :key="header.text" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.align === 'left' ? 'scl-pricing-table__header-left' : '', header.align === 'right' ? 'scl-pricing-table__header-right' : '']" @click="header.sortable ? changeSort(header.value) : ''">
									<v-layout column class="scl-pricing-table__header-cell">
										<div class="scl-pricing-table__header-cell-content">
											<v-flex class="font-weight-bold">
												{{ header.text }}
											</v-flex>
											<v-flex v-if="header.subtext !== '' && header.subtext">
												{{header.subtext}}
											</v-flex>
											<v-icon v-if="header.text !== '' && header.text && header.sortable">fas fa-sort-up</v-icon>
										</div>
									</v-layout>
								</th>
							</tr>
						</template>
						<template v-slot:no-data>
          					<p class="scl-pricing-table__default-text">{{defaultText}}</p>
      					</template>
						<template v-slot:items="props">
						<tr>
							<td class="text-xs-left px-2 align-center scl-pricing-table__zindex">
								<v-checkbox class="scl-pricing-table__checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
							</td>
							<td class="text-xs-left px-2" v-html="searched(props.item.category, 'category')"></td>
							<td class="text-xs-left px-2" v-html="searched(props.item.productDescription, 'productDescription')"></td>
							<td class="text-xs-center px-2" v-html="searched(props.item.productCode, 'productCode')"></td>
							<td class="text-xs-center px-2" v-html="searched(props.item.minHireDays, 'minHireDays')"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-html="searched(props.item.price1)"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-if="props.item.price2" v-html="searched(props.item.price2)"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-if="props.item.price3" v-html="searched(props.item.price3)"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-if="props.item.price4" v-html="searched(props.item.price4)"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-if="props.item.price5" v-html="searched(props.item.price5)"></td>
							<td class="text-xs-right px-2 scl-pricing-table__price-column" v-html="searched(props.item.monthlyRate)"></td>
						</tr>
						</template>

						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
