// component
const component = {
	name: 'SclEnquiryModal',
	data() {
		return {
			dialog: false,
			valid: true,
			enquiry: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				company: '',
				location: '',
				reserve: false,
				hireFrom: '',
				hireTo: '',
				yourEnquiry: ''
			},
			dateFromIso: new Date().toISOString().substr(0, 10),
			dateToIso: new Date().toISOString().substr(0, 10),
			dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateFrom: false,
			dateTo: false
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: 'auto',
					persistent: true
				},
				this.api
			);
		}
	},
	watch: {
		dateFromIso() {
			this.dateFromFormatted = this.formatDate(this.dateFromIso);
		},
		dateToIso() {
			this.dateToFormatted = this.formatDate(this.dateToIso);
		}
	},
	methods: {
		formatDate(date) {
			if (!date) {
				return null;
			}
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) {
				return null;
			}
			const [month, day, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
