import axios from 'axios';

// component
const component = {
	name: 'SclAccessRequestTable',
	data() {
		return {
			selected: [],
			pagination: {
				sortBy: name,
				page: 0,
				rowsPerPage: 25,
				total: 0
			},
			indeterminate: false,
			tableQuery: {
				query: null
			},
			headers: [
				{ text: 'Surname', value: 'surname', align: 'left', sortable: true, width: '10%' },
				{ text: 'First Name.', value: 'firstName', align: 'left', sortable: true, width: '10%' },
				{ text: 'Login Email', value: 'email', align: 'left', sortable: true, width: '20%' },
				{ text: 'Capabilities', value: 'capability', align: 'left', sortable: true, width: '10%' },
				{ text: 'Request', value: 'request', align: 'left', sortable: true }
			],
			results: []
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					postbackURL: '',
					postbackPipe: '',
					tableData: {}
				},
				this.api
			);
		}
	},
	watch: {
		selected() {
			if (this.selected.length > 0 && this.selected.length != this.propApi.tableData.tableItems) {
				this.indeterminate = true;
			}
			if (this.selected.length === 0) {
				this.indeterminate = false;
			}
		}
	},
	mounted() {
		this.results = this.propApi.tableData.tableItems;
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.rowsPerPage;
			await this.submit();
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				var page = this.pagination.page > 1 ? this.pagination.page - 1 : 0;
				const payload = {
					tableData: this.selected,
					page: page,
					pageSize: this.pagination.rowsPerPage
				};
				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					const result = response.data.result;
					this.pagination.total = result.total;
					this.pagination.page = result.page;
					this.results = result.results;
				} else {
					this.errorMessage = 'Access detail update failed, please try again later.';
					const snackBarMessage = {
						copy: this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
