/**
 * This mixin contains the functionality required for the List components
 */

const mxList = {
	created: function () {},
	methods: {
		_mxList_invoiceStatusColour(status) {
			let colourCode = '';
			switch (status) {
				case 'G':
					colourCode = '#63BF00';
					break;
				case 'R':
					colourCode = '#ff6105';
					break;
				case 'Y':
					colourCode = '#ECAF1E';
					break;
				default:
					break;
			}
			return colourCode;
		},
		_mxList_statusColour(status) {
			let colourCode = '';
			switch (status) {
				case 'A':
					colourCode = '#63BF00';
					break;
				case 'R':
					colourCode = '#ff6105';
					break;
				case 'N':
					colourCode = '#ECAF1E';
					break;
				case 'Y':
					colourCode = '#63BF00';
					break;
				case 'G':
					colourCode = '#006600';
					break;
				case 'E':
					colourCode = '#B6B5B3';
					break;
				default:
					break;
			}
			return colourCode;
		}
	}
};

export default mxList;
