// Mixins
import mxApi from '../../mixins/common/mxApi.js';
import mxAccount from '../../mixins/common/mxAccount.js';

// component
const component = {
	name: 'SclManageModal',
	mixins: [mxApi, mxAccount],
	data() {
		return {
			dialog: false,
			valid: true,
			accountItem: [],
			manager: null,
			currentManager: null,
			currentManagerId: null,
			managerList: []
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, async event => {
			this.dialog = true;
			this.accountItem = typeof event === 'undefined' ? [] : event;
			this.currentManager = this.accountItem.managedBy;

			const id = this.accountItem.userId;
			await this.submitSearch(id);

			const manager = this.managerList.filter(x => x.name === this.accountItem.managedBy)[0];
			if (!manager) {
				return;
			}
			this.manager = manager;
			this.currentManagerId = manager.id;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'manage-event',
					width: '650',
					persistent: false,
					modalLabel: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submitSearch(userId) {
			this.errorMessage = null;
			try {
				this.loading = true;
				const payload = {
					userId: userId
				};

				const response = await this._mxAccount_fetchAccountData(payload, this.propApi.postbackSearchURL, 'post');

				this.loading = false;
				if (response.status == 200 || response.data.success) {
					this.showRequestSuccess = true;
					this.managerList = response.data.result.results;
				} else {
					this.errorMessage = 'Update listing failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.loading = false;
				this.errorMessage = 'Update listing failed, please try again later.';
			}
		},
		selectManager(manager) {
			this.currentManagerId = manager;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				if (!this.accountItem) {
					return;
				}
				const currentManager = this.managerList.filter(x => x.name === this.accountItem.managedBy)[0];

				const payload = {
					user: this.accountItem,
					oldManagerId: currentManager ? currentManager.id : 0,
					newManagerId: this.currentManagerId
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Update successful',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
