/**
 * SclUserAccess
 *
 **/

const template = `
<div class="scl-user-access scl-form">
	<v-container grid-list-xl fluid class="px-0">

		<v-layout row wrap>
			<v-flex xs12>
				<h3 class="pb-2 font-weight-regular">Assigned capabilities</h3>
				<div class="d-inline-flex">
					<v-checkbox v-model="accountData.coatesHireAdmin"
								label="My CoatesHire Administrator"
								disabled
								hide-details
								class="mt-0 pt-0 font-weight-regular"></v-checkbox>
					<!--
				<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
				-->
				</div>
			</v-flex>
			<v-flex xs12>
				<div class="d-inline-flex">
					<v-checkbox v-model="accountData.coatesHireManager"
								label="My CoatesHire Manager"
								disabled
								hide-details
								class="mt-0 pt-0"></v-checkbox>
					<!--
				<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
				-->
				</div>
			</v-flex>
			<v-flex xs12>
				<div class="d-inline-flex">
					<v-checkbox v-model="accountData.myHires"
								label="My hires"
								disabled
								hide-details
								class="mt-0 pt-0"></v-checkbox>
					<!--
				<scl-tooltip :api="{icon: 'far fa-question-circle', message: 'Contact me to add new users or to change your access'}"></scl-tooltip>
				-->
				</div>
			</v-flex>
			<v-flex xs12>
				<v-radio-group v-model="accountData.myHiresWithRates" class="mt-0 d-flex" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
					<v-radio label="With rates" disabled value="With Rates"></v-radio>
					<v-radio label="Without rates" disabled value="Without Rates"></v-radio>
				</v-radio-group>
			</v-flex>
			<v-flex xs12>
				<v-radio-group v-model="accountData.myHiresWithEOHD" disabled class="mt-0 d-flex" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
					<v-radio label="Edit EOHD" value="Edit EOHD"></v-radio>
					<v-radio label="Disable Edit EOHD" value="Disable Edit EOHD"></v-radio>
				</v-radio-group>
			</v-flex>
			<v-flex xs12>
				<v-checkbox v-model="accountData.myInvoices"
							label="My Invoices"
							disabled
							hide-details
							class="mt-0 pt-0 mb-3"></v-checkbox>
				<v-checkbox v-model="accountData.myPricebook"
							label="My Pricebook"
							disabled
							hide-details
							class="mt-0 pt-0"></v-checkbox>
			</v-flex>
			<v-flex xs12>
				<v-radio-group v-model="accountData.myPricebookWithExport" disabled class="mt-0 d-flex" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
					<v-radio label="With export" value="With export"></v-radio>
					<v-radio label="Without export" value="Without export"></v-radio>
				</v-radio-group>
			</v-flex>
			<v-flex xs12>
				<v-checkbox v-model="accountData.myTelemetry"
							label="My Telemetry"
							disabled
							hide-details
							class="mt-0 pt-0 mb-3"></v-checkbox>
			</v-flex>
			<v-flex xs12>
				<h3 class="pb-2 font-weight-regular">Send invitation Email?</h3>
				<v-radio-group v-model="accountData.sendInvite" disabled class="mt-0 d-flex" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
					<v-radio label="No, send invitation later" value="no"></v-radio>
					<v-radio label="Yes, send invitation upon user creation" value="yes"></v-radio>
				</v-radio-group>
			</v-flex>
			<v-flex xs12>
				<!--<scl-site-access-accordion @site-access-event="onSiteAccessEvent"></scl-site-access-accordion>-->
			</v-flex>
			<v-flex xs12>
				<v-card-actions class="scl-user-details__actions px-0" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
					<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
					<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="nextStep">next</v-btn>
				</v-card-actions>
			</v-flex>
		</v-layout>
	</v-container>
	</div>
`;

export default template;
