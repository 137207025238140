/**
 * SclContactForm api
 */

// template
const template = `
	<div>
		<v-form class="scl-form">
			<v-container grid-list-xl class="scl-contact-form__container pa-0">

				<v-layout row>
					<v-flex lg12>
						<legend class="scl-form__title">ENQUIRY ABOUT OUR TRAINING COURSES</legend>
						<!-- Mobile call -->
						<a v-if="$vuetify.breakpoint.mdAndDown" class="scl-contact-form__call" href="tel:13 15 52">
							<v-icon color="accent">fas fa-phone-alt</v-icon>
							<span>13 15 52</span>
						</a>
					</v-flex>
				</v-layout>

				<v-layout row wrap>

					<!-- form left col -->
					<v-flex :class="{ 'pb-0' : $vuetify.breakpoint.xsOnly }" xs12 sm6 lg4>
						<v-text-field
							class="scl-form__input"
							outline
							label="First Name"
						></v-text-field>
						<v-text-field
							class="scl-form__input"
							outline
							label="Last Name"
						></v-text-field>
						<v-text-field
							class="scl-form__input"
							outline
							label="Email Address"
						></v-text-field>
						<v-text-field
							class="scl-form__input"
							outline
							label="Phone"
						></v-text-field>
						<v-text-field
							class="scl-form__input"
							outline
							label="Company (Optional)"
						></v-text-field>
					</v-flex>

					<!-- form right col -->
					<v-flex :class="{ 'pt-0' : $vuetify.breakpoint.xsOnly }" xs12 sm6 lg4>
						<v-text-field
							class="scl-form__input"
							outline
							label="Location"
						></v-text-field>
						<v-select
							:items="items"
							class="scl-form__input scl-form__select"
							outline
							label="Subject (Optional)"
						></v-select>
						<v-textarea
							class="scl-form__input scl-form__textarea"
							outline
							name="input-7-1"
							label="Your enquiry"
							type="text"
							height="138"
						></v-textarea>
						<v-btn block class="scl-form__btn" color="accent" depressed type="submit">Submit Enquiry</v-btn>
						<p class="scl-form__terms">By submitting this enquiry you agree to Coates Hire's Communication <a href="#">Terms &amp; Conditions</a>.</p>
					</v-flex>

					<!-- right panel -->
					<v-flex v-if="$vuetify.breakpoint.lgAndUp" lg4>
						<h6 class="scl-form__label">GIVE US A CALL</h6>
						<a class="scl-contact-form__call" href="tel:1300 657 867">
							<v-icon color="accent">fas fa-phone-alt</v-icon>
							<span>1300 657 867</span>
						</a>
						<v-list dense class="scl-contact-form__list">
							<v-list-tile>
								<v-list-tile-action>
									<v-icon>fas fa-check</v-icon>
								</v-list-tile-action>
								<v-list-tile-content>
									<v-list-tile-title>Safety Standard Gurantee</v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
							<v-list-tile>
								<v-list-tile-action>
									<v-icon>fas fa-check</v-icon>
								</v-list-tile-action>
								<v-list-tile-content>
									<v-list-tile-title>24/7 Online Services</v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
							<v-list-tile>
								<v-list-tile-action>
									<v-icon>fas fa-check</v-icon>
								</v-list-tile-action>
								<v-list-tile-content>
									<v-list-tile-title>Same day delivery available</v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
						</v-list>
					</v-flex>

				</v-layout>
			</v-container>
		</v-form>
	</div>
`;

// export template
export default template;
