/**
 * SclSubscribeForm
 */

// template
const template = `
	<div>
		<v-form v-model="valid">
			<v-container>
				<v-layout row wrap>
					<v-flex xs12 md3>
						<div class="scl-subscribe-form__container">
							<v-icon class="scl-subscribe-form__icon">fas fa-envelope</v-icon>
							<p>Sign up to receive our latest news and special offers</p>
						</div>
					</v-flex>
					<v-flex xs12 md9>
						<div class="scl-subscribe-form__container">
							<v-text-field
								v-model="emailAddress"
								:rules="emailRules"
								:counter="10"
								placeholder="Email Address"
								class="scl-component scl-input"
								required
							></v-text-field>
							<scl-button :api="{ outline: false, copy: 'subscribe', type: 'submit' }" class="scl-component scl-button"></scl-button>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</v-form>
	</div>
`;

// export template
export default template;
