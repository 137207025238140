<template>
	<div class="scl-quote-request__container">
		<v-layout row wrap class="my-0">
			<v-flex xs12>
				<h2 class="scl-quote-request__title">Hire Period</h2>
				<p class="mb-4">{{ propApi.hirePeriod.subTitle }}</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<!-- Start date & time -->
			<v-flex xs12 sm3 class="py-0">
				<label class="scl-form__label">Start Date <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label>
				<scl-date-selector
					:key="uiKey"
					date-input-title="Start Date"
					:starting-date="startingDate"
					:rules="[minDateValidation]"
					:min-date="minDate"
					:max-date="maxDate"
					:is-secondary="false"
					:valid-form="isStartDateValid"
					@update-date="updateStartDate"
					:disableUi="isReadOnly"
					:readonly="isReadOnly"
				/>
			</v-flex>
			<v-flex xs12 sm3 class="py-0">
				<label class="scl-form__label">Start time</label>
				<scl-time-select
					:assign-time="userSelectedPeriod.startTime"
					@time-selected="updatedSelectedTime($event, 'startTime')"
					:disabled="isReadOnly"
					:readonly="isReadOnly"
				/>
			</v-flex>
			<!-- <v-flex xs12 sm1 class="py-0"></v-flex> -->
		</v-layout>
		<v-layout row wrap>
			<!-- End date & time -->
			<v-flex xs12 sm3 class="py-0">
				<label class="scl-form__label">End Date <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label>
				<scl-date-selector
					:key="uiKey"
					date-input-title="End Date"
					:starting-date="endingDate"
					:rules="[maxDateValidation]"
					:min-date="minDate"
					:max-date="maxDate"
					:is-secondary="false"
					:valid-form="isEndDateValid"
					@update-date="updateEndDate"
					:disableUi="isReadOnly"
					:readonly="isReadOnly"
				/>
			</v-flex>
			<v-flex xs12 sm3 class="py-0">
				<label class="scl-form__label">End time</label>
				<scl-time-select
					:assign-time="userSelectedPeriod.endTime"
					@time-selected="updatedSelectedTime($event, 'endTime')"
					:disabled="isReadOnly"
					:readonly="isReadOnly"
				/>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// Components
import SclTimeSelect from '../form/SclTimeSelect.vue';
import SclDateSelector from '../date-picker/SclDateSelector.vue';

export default {
	name: 'SclQuoteHirePeriod',
	components: {
		SclDateSelector,
		SclTimeSelect
	},
	props: {
		validForm: {
			type: Boolean
		},
		api: {
			type: Object,
			required: false
		},
		isReadOnly: {
			type: Boolean
		},
		hirePeriod: {
			type: Object
		}
	},
	data() {
		return {
			userSelectedPeriod: {
				startDate: '',
				endDate: '',
				startTime: '0700',
				endTime: '1700'
			},
			rules: {
				required: value => !!value || 'Required'
			},
			isStartDateValid: true,
			isEndDateValid: true
		};
	},
	created() {
		if (this.hirePeriod) {
			this.userSelectedPeriod.startTime = this.hirePeriod.startTime;
			this.userSelectedPeriod.endTime = this.hirePeriod.endTime;
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					hirePeriod: {}
				},
				this.api
			);
		},
		startingDate() {
			if (this.hirePeriod && this.hirePeriod.startDate) {
				return this.hirePeriod.startDate;
			} else {
				return this.setInitialDate();
			}
		},
		endingDate() {
			if (this.hirePeriod && this.hirePeriod.endDate) {
				return this.hirePeriod.endDate;
			} else {
				return this.setInitialDate();
			}
		},
		minDate() {
			const date = new Date();
			date.setDate(date.getDate() + 1);
			return date;
		},

		maxDate() {
			const date = new Date();
			date.setMonth(date.getMonth() + 6);
			return date;
		},
		uiKey() {
			const randomizer = 13 * Math.random();
			const uniqueKey = `picker${this.startingDate}-${randomizer}`;
			return uniqueKey;
		}
	},
	mounted() {
		this.emitSelectedPeriod();
	},
	methods: {
		setInitialDate() {
			let isoDate = new Date();
			isoDate.setDate(isoDate.getDate() + 1);
			let formattedDate = isoDate.getFullYear() + '-' + ('0' + (isoDate.getMonth() + 1)).slice(-2) + '-' + ('0' + isoDate.getDate()).slice(-2);

			return formattedDate;
		},
		clear() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit('input', this.userSelectedPeriod);
		},
		updateStartDate(_, isoDate) {
			this.$set(this.userSelectedPeriod, 'startDate', isoDate);
			this.dateInputValidation();
			this.$emit('update-date', this.userSelectedPeriod);
		},
		updateEndDate(_, isoDate) {
			this.$set(this.userSelectedPeriod, 'endDate', isoDate);
			this.dateInputValidation();
			this.$emit('update-date', this.userSelectedPeriod);
		},
		updatedSelectedTime(number, type) {
			switch (type) {
				case 'startTime':
					this.userSelectedPeriod.startTime = number;
					break;
				case 'endTime':
					this.userSelectedPeriod.endTime = number;
					break;
			}
			this.$emit('update-date', this.userSelectedPeriod);
		},
		maxDateValidation() {
			return this.userSelectedPeriod.endDate < this.userSelectedPeriod.startDate ? 'End date can not be before the start date' : true;
		},
		minDateValidation() {
			if (this.userSelectedPeriod.startDate > this.userSelectedPeriod.endDate) {
				return 'Start date can not be later than end date';
			}

			return true;
		},
		emitSelectedPeriod() {
			this.$emit('update-date', this.isSecondary, this.userSelectedPeriod);
		},
		dateInputValidation() {
			if (this.userSelectedPeriod.endDate < this.userSelectedPeriod.startDate || this.userSelectedPeriod.startDate > this.userSelectedPeriod.endDate) {
				this.isStartDateValid = false;
				this.isEndDateValid = false;
			} else {
				this.isStartDateValid = true;
				this.isEndDateValid = true;
			}
		}
	}
};
</script>
