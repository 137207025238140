/**
 * SclUpdateStatus api
 *
 */

const template = `
	<div class="scl-update-status">
		<v-container fluid pl-0>
			<v-layout class="scl-update-status__status-item pb-4" row wrap v-for="(statusItem, i) in statusData" :key="i">
				<v-flex xs12 class="pa-0">
					<h3>{{statusItem.label}}</h3>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0">{{statusItem.value}}</p>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
