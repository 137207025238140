/**
 * SclBranchAutocomplete api
 */

// template
const template = `
	<scl-branch-autocomplete inline-template class="scl-component scl-branch-autocomplete">
		<div>
			<v-container class="scl-branch-autocomplete__container">
				<v-layout>
					<v-flex sm12 md10 offset-md1 lg8 offset-lg2>
						<v-autocomplete
							v-model="model"
							:items="items"
							:search-input.sync="search"
							background-color="white"
							color="primary"
							class="scl-branch-autocomplete__input"
							hide-no-data
							item-text="Description"
							item-value="API"
							placeholder="Enter postcode, suburb, city or state"
							prepend-icon="fa-map-marker-alt"
							return-object
						>
							<template v-slot:prepend>
								<div class="scl-branch-autocomplete__label-container">
									<label class="scl-branch-autocomplete__label"><v-icon class="scl-branch-autocomplete__label-icon">fas fa-map-marker-alt</v-icon><span>Find a Branch</span></label>
								</div>
							</template>
							<template v-slot:append>
								<v-btn v-if="!model" class="scl-branch-autocomplete__search-btn" color="accent" depressed href="#">SEARCH</v-btn>
								<v-btn v-else @click="clear()" class="scl-branch-autocomplete__clear-btn" flat icon>
									<v-icon>far fa-times-circle</v-icon>
								</v-btn>
							</template>
						</v-autocomplete>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
	</scl-branch-autocomplete>
`;

// export template
export default template;
