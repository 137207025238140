/**
 * SclFooterListLarge
 */

// template
const template = `
	<div>
		<v-container class="scl-footer-list-large__container">
			<v-layout row wrap>
				<v-flex xs12 sm4 md3 lg2>
					<div class="scl-footer-list-large__title">Lorem ipsum</div>
					<v-list class="scl-footer-list-large__list">
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
					</v-list>
				</v-flex>
				<v-flex xs12 sm4 md3 lg2>
					<div class="scl-footer-list-large__title">Lorem ipsum</div>
					<v-list class="scl-footer-list-large__list">
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
					</v-list>
				</v-flex>
				<v-flex xs12 sm4 md3 lg2>
					<div class="scl-footer-list-large__title">Lorem ipsum</div>
					<v-list class="scl-footer-list-large__list">
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
					</v-list>
				</v-flex>
				<v-flex v-if="$vuetify.breakpoint.mdAndUp" md3 lg2 offset-lg4>
					<div class="scl-footer-list-large__title">give us a call</div>
					<div class="scl-footer-list-large__call">
						<v-icon class="scl-footer-list-large__call-icon">fas fa-phone-alt</v-icon>
						<span class="scl-footer-list-large__call-title">13 15 52</span>
					</div>
					<scl-button :api="{ href: 'http://www.example.com/', outline: true, copy: 'contact us' }" class="scl-component scl-button scl-button--small"></scl-button>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex>
					<div class="scl-footer-list-large__title">Lorem ipsum</div>
					<v-list class="scl-footer-list-large__list scl-footer-list-large__list--full">
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
						<v-list-tile href="#">
							Lorem ipsum dolor
						</v-list-tile>
					</v-list>
				</v-flex>
			</v-layout>
			<v-layout v-if="$vuetify.breakpoint.smAndDown" class="pt-4" row wrap>
				<v-flex>
					<scl-button :api="{ href: 'tel:13 15 52', outline: false, copy: 'call us 13 15 52', icon: 'fas fa-phone-alt' }" class="scl-component scl-button"></scl-button>
					<scl-button :api="{ href: 'http://www.example.com/', outline: true, copy: 'contact us' }" class="scl-component scl-button scl-button--white"></scl-button>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
