// component
const component = {
	name: 'SclPageHeader',
	data() {
		return {
			data: {}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.subtitle ? typeof obj.subtitle === 'string' : true));
				set.add(!!(obj.links ? Array.isArray(obj.links) : true));
				set.add(!!(obj.layout ? typeof obj.layout === 'string' : true));
				set.add(!!(obj.bgColor ? typeof obj.bgColor === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'page-header-event',
					title: '',
					subtitle: '',
					links: [],
					layout: 'row',
					bgColor: '#ffffff'
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.data = { ...event };
		});
		this.data = this.propApi;
	},
	methods: {
		clickEvent(event) {
			this.$root.$emit(event);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
