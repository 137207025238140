// component
const component = {
	name: 'Scl2Col',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: () => {
				const set = new Set();
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign({}, this.api);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
