// css import
require('./_scl-tabs.scss');

// component
const component = {
	name: 'SclTabs',
	data() {
		return {
			activeTab: 'tab-0'
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.centered ? typeof obj.centered === 'boolean' : true));
				set.add(!!(obj.grow ? typeof obj.grow === 'boolean' : true));
				set.add(!!(obj.hideSlider ? typeof obj.hideSlider === 'boolean' : true));
				set.add(!!(obj.icons ? typeof obj.icons === 'boolean' : true));
				set.add(!!(obj.right ? typeof obj.right === 'boolean' : true));
				set.add(!!(obj.disableTouch ? typeof obj.disableTouch === 'boolean' : true));
				set.add(!!(obj.overflowArrows ? typeof obj.overflowArrows === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					centered: false,
					grow: false,
					hideSlider: false,
					icons: false,
					right: false,
					disableTouch: true,
					overflowArrows: false
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// export component
export default component;
