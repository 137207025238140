import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclExceptionAccordionModal',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			valid: true,
			collection: {},
			modalSublabel: null
		};
	},
	mounted() {
		this.modalSublabel = this.propApi.modalSublabel;
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			this.accountItem = typeof event === 'undefined' ? [] : event;
			if (event.customer) {
				this.modalSublabel = event.customer;
			}
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.modalSublabel ? typeof obj.modalSublabel === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'exception-accordion-event',
					width: '960',
					persistent: false,
					modalLabel: '',
					collection: {
						name: 'Hindmarsh',
						options: [
							{
								name: 'Expected Off Hire Date Status',
								type: 'group',
								input: 'select',
								items: ['ON', 'OFF'],
								value: 'ON',
								lineItem: [
									{
										name: 'Past Due Status',
										input: '',
										value: true
									},
									{
										name: 'Plant Nearing EOHD',
										input: '',
										value: false
									}
								]
							},
							{
								name: 'Alert',
								type: 'group',
								input: 'select',
								items: ['ON', 'OFF'],
								value: 'ON',
								lineItem: [
									{
										name: 'area',
										input: '',
										value: true
									},
									{
										name: '34 324 23 4 234',
										input: '',
										value: false
									},
									{
										name: 'geofence',
										input: '',
										value: false
									},
									{
										name: 'hire',
										input: '',
										value: true
									}
								]
							},
							{
								name: 'Document Access (By Document Type)',
								type: 'single',
								input: '',
								items: '',
								value: '',
								lineItem: [
									{
										name: 'Certificate and Licenses',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'Coates',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'pdf',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'gif',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'jpg',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									}
								]
							},
							{
								name: 'Document Access (By Document Type)',
								type: 'single',
								input: '',
								items: '',
								value: '',
								lineItem: [
									{
										name: 'Certificate and Licenses',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'Coates',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'pdf',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									},
									{
										name: 'gif',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'ON'
									},
									{
										name: 'jpg',
										input: 'select',
										items: ['ON', 'OFF'],
										value: 'OFF'
									}
								]
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.collection.name = this.accountItem;
			this.collection.options = this.propApi.collection.options;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					collection: this.collection
				};
				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Changes saved successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Save changes failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
