/**
 * This mixin contains the functionality required for handling dates
 */

const mxDate = {
	created: function () {},
	methods: {
		_mxDate_formatDate(date) {
			if (!date) {
				return null;
			}

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		_mxDate_parseDate(date) {
			if (!date) {
				return null;
			}
			const [day, month, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		_mxDate_parseDateString(dateStr) {
			if (!dateStr) {
				return null;
			}
			const dateParts = dateStr.split('/');
			// month is 0-based, that's why we need dataParts[1] - 1
			return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
		}
	}
};

export default mxDate;
