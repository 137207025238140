/**
 * SclConfirmationModal
 */

// template
const template = `
	<div class="scl-confirmation-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-confirmation-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-confirmation-modal__container pt-0">
						<v-layout row wrap fill-height d-flex class="scl-confirmation-modal__container-layout">
							<v-flex>
								<pre v-html="propApi.messaging"></pre>
								<pre class="scl-confirmation-modal__footnote body-2 mt-5" v-if="propApi.footnote">{{propApi.footnote}}</pre>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="scl-confirmation-modal__actions">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="nextStep">continue</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
