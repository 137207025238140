/**
 * SclEnquireModal
 */

// template
const template = `
	<div>
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-component scl-enquiry-modal"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button"
						:class="{ 'scl-button--small' : btnSmall }"
						depressed
						:outline="!btnSmall"
						v-on="on"
					>
						Enquire Now
					</v-btn>
				</template>
				<v-card>
					<v-form v-model="valid" class="scl-form">
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row>
								<v-flex lg12>
									<legend class="scl-form__title">MAKE AN ENQUIRY</legend>
								</v-flex>
							</v-layout>

							<v-layout row wrap>
								<v-flex :class="{ 'order-xs1' : $vuetify.breakpoint.smAndDown }" xs12 md7>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.firstName"
										label="First Name"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.lastName"
										label="Last Name"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.email"
										label="Email Address"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.phone"
										label="Phone"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.company"
										label="Company (Optional)"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.location"
										label="Your Location"
									></v-text-field>
									<v-checkbox
										v-model="enquiry.reserve"
										class="scl-form__input scl-form__checkbox"
										label="I WOULD LIKE TO RESERVE THIS EQUIPMENT"
									></v-checkbox>

									<template v-if="enquiry.reserve">
										<v-layout row wrap>
											<v-flex xs12 sm6 class="py-0">
												<v-menu
													ref="dateFrom"
													v-model="dateFrom"
													:close-on-content-click="false"
													:nudge-right="40"
													lazy
													transition="scale-transition"
													offset-y
													full-width
													max-width="290px"
													min-width="290px"
												>
													<template v-slot:activator="{ on }">
														<v-text-field
															v-model="dateFromFormatted"
															class="scl-form__input"
															outline
															label="From"
															hint="dd/mm/yyyy"
															v-on="on"
														></v-text-field>
													</template>
													<v-date-picker v-model="dateFromIso" no-title @input="dateFrom = false"></v-date-picker>
												</v-menu>
											</v-flex>
											<v-flex xs12 sm6 class="py-0">
												<v-menu
													ref="dateTo"
													v-model="dateTo"
													:close-on-content-click="false"
													:nudge-right="40"
													lazy
													transition="scale-transition"
													offset-y
													full-width
													max-width="290px"
													min-width="290px"
												>
													<template v-slot:activator="{ on }">
														<v-text-field
															v-model="dateToFormatted"
															class="scl-form__input"
															outline
															label="To"
															hint="dd/mm/yyyy"
															v-on="on"
														></v-text-field>
													</template>
													<v-date-picker v-model="dateToIso" no-title @input="dateTo = false"></v-date-picker>
												</v-menu>
											</v-flex>
										</v-layout>
									</template>

									<v-textarea
										class="scl-form__input scl-form__textarea"
										outline
										name="input-7-1"
										label="Your enquiry"
										v-model="enquiry.yourEnquiry"
										height="168"
									></v-textarea>
									<v-btn block class="scl-form__btn" color="accent" depressed type="submit">Submit Enquiry</v-btn>
									<p class="scl-form__terms">By submitting this enquiry you agree to Coates Hire's Communication <a href="#">Terms &amp; Conditions</a>.</p>
								</v-flex>

								<!-- right column image -->
								<v-flex xs12 md5>
									<div class="scl-enquiry-modal__product">
										<h6 class="scl-form__label">Enquiring for</h6>
										<div class="scl-enquiry-modal__product-container">
											<v-img class="scl-enquiry-modal__product-img" src="https://via.placeholder.com/85/85" width="85" height="85" aspect-ratio="1" />
											<span>Product Name - Type - Measurement</span>
										</div>
									</div>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>

			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
