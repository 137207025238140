/**
 * SclDomainModal
 */

// template
const template = `
	<div class="scl-domain-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					modal
					</v-btn>
				</template>

				<v-card class="fill-height">
					<div class="scl-domain-form scl-form">
						<v-toolbar class="scl-dialog__toolbar" flat>
							<v-layout row>
								<v-card-title class="pa-0" primary-title>
									<legend class="scl-dialog__toolbar-title">
										{{ propApi.modalLabel }}
									</legend>
								</v-card-title>
								<v-btn icon dark @click="dialog = false">
									<v-icon>fas fa-times</v-icon>
								</v-btn>
							</v-layout>
						</v-toolbar>
						<v-form ref="form">
							<v-container grid-list-xl fluid>
								<v-layout row wrap>
									<v-flex xs12>
										<v-text-field
										v-model="accountData.emailDomain"
										class="scl-form__input"
										:rules="[rules.required]"
										outline
										label="Email domain"
										></v-text-field>
									</v-flex>
									<v-flex xs12 pt-0>
										<v-card-actions class="justify-end pa-0" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
										<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">Cancel</v-btn>
										<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit" >Save</v-btn>
										</v-card-actions>
									</v-flex>
								</v-layout>
							</v-container>
						</v-form>
					</div>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
