/**
 * SclAccountFilter api
 *
 */

const template = `
	<div class="scl-table-filter scl-form">
		<v-container grid-list-xl class="scl-table-filter__container" fluid>
			<v-layout row wrap>
				<v-flex grow>
					<v-layout row wrap>
						<v-flex pa-0 shrink :class="{'xs4': $vuetify.breakpoint.smAndDown}">
							<v-select
								v-model="searchCategory"
								:items="searchHeaders"
								item-text="text"
								item-value="value"
								return-object
								class="scl-form__input scl-form__select"
								outline
								solo
								label="Filter"
								append-icon="fas fa-caret-down"
							>
							</v-select>
						</v-flex>
						<v-flex pa-0 grow :class="{'xs8 mb-3': $vuetify.breakpoint.smAndDown, 'mr-2': $vuetify.breakpoint.mdAndUp}">
							<v-text-field v-model="searchQuery" solo outline @keydown.enter="submitSearch"
							@focusout="eventFieldFocusOut" :label="searchLabel" append-icon="fas fa-search" @click:append="submitSearch" class="scl-table-filter__search-filter scl-form__input"></v-text-field>
						</v-flex>
						<v-flex v-if="selectFilters.length" pa-0 shrink :class="{'xs6 mb-3': $vuetify.breakpoint.smAndDown, 'mr-2': $vuetify.breakpoint.mdAndUp}">
							<v-select
								v-model="selectFilter1"
								:items="selectFilters[0].filterOptions"
								item-text="text"
								item-value="value"
								return-object
								class="scl-form__input scl-form__select"
								:label="selectFilters[0].categoryName"
								outline
								solo
							>
							</v-select>
						</v-flex>
						<v-flex v-if="selectFilters.length > 1" pa-0 shrink :class="{'xs6 mb-3': $vuetify.breakpoint.smAndDown, 'mr-2': $vuetify.breakpoint.mdAndUp}">
							<v-select
								v-model="selectFilter2"
								:items="selectFilters[1].filterOptions"
								item-text="text"
								item-value="value"
								return-object
								class="scl-form__input scl-form__select"
								:label="selectFilters[1].categoryName"
								outline
								solo
							>
							</v-select>
						</v-flex>
						<v-flex pa-0 mr-2 hidden-sm-and-down v-if="showDateFilters">
							<v-menu
								ref="dateFromMenu"
								v-model="dateFromMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								lazy
								transition="scale-transition"
								offset-y
								full-width
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
								<v-text-field
								class="scl-form__date-select font-weight-black"
									v-model="dateFromFormatted"
									label="From"
									persistent-hint
									hide-details
									@blur="dateFrom = parseDate(dateFromFormatted)"
									v-on="on" append-icon="fas fas fa-calendar-alt"
								></v-text-field>
								</template>
								<v-date-picker v-model="dateFrom" no-title :max="dateTo" @input="dateFromMenu = false"></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex pa-0 mr-2 hidden-sm-and-down v-if="showDateFilters">
							<v-menu
								ref="dateToMenu"
								v-model="dateToMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								hide-details
								lazy
								transition="scale-transition"
								offset-y
								full-width
								max-width="290px"
								min-width="290px"

							>
								<template v-slot:activator="{ on }">
								<v-text-field
								class="scl-form__date-select font-weight-black"
									v-model="dateToFormatted"
									label="To"
									persistent-hint
									hide-details
									@blur="dateTo = parseDate(dateToFormatted)"
									v-on="on" append-icon="fas fas fa-calendar-alt"
								></v-text-field>
								</template>
								<v-date-picker v-model="dateTo" :min="dateFrom" no-title @input="dateToMenu = false"></v-date-picker>
							</v-menu>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex shrink hidden-sm-and-down v-if="propApi.showPackageOption">
					<v-layout row>
						<v-flex x12 pa-0>
							<v-checkbox
							v-model="packageDetails"
							label="Show Package Details"
							hide-details
							class="mt-0 pt-0"
						></v-checkbox>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex shrink class="scl-table-filter__icon-group" v-if="propApi.showCSV">
					<v-layout row>
						<v-flex x12 pa-0 class="d-flex align-center">
							<json-csv :fetch="fetchDownloadData" name="table-results.csv" type="csv">
								<v-btn flat icon outline class="border">
									<v-icon class="scl-table-filter__icon" color="primary">fas fa-file-csv</v-icon>
								</v-btn>
							</json-csv>
							<div class="divider-vertical"></div>
							<v-btn flat icon><v-icon class="scl-table-filter__icon" color="primary" size="35">far fa-question-circle</v-icon></v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs10 class="scl-table-filter__search-chips pl-0">
					<v-chip close v-for="(searchChip, index) in searchChips" color="#ff6105" :key="index" class="scl-table-filter__search-chips" @input="removeSearchChip(searchChip)">
						<strong class="mx-2">'{{ searchChip.query }}</strong>({{searchChip.category}})'
					</v-chip>
					<v-chip close v-if="selectFilter1 !== null" color="#ff6105" class="scl-table-filter__search-chips" @input="clearSelectFilter1()">
						<strong class="mx-2">'{{ selectFilter1.text }}</strong>({{ selectFilters[0].categoryName}})'
					</v-chip>
					<v-chip close v-if="selectFilter2 !== null" color="#ff6105" class="scl-table-filter__search-chips" @input="clearSelectFilter2()">
						<strong class="mx-2">'{{ selectFilter2.text }}</strong>({{ selectFilters[1].categoryName}})'
					</v-chip>
				</v-flex>
				<v-flex xs2 text-xs-right>
					<v-btn
						data-cy="clear-all-chips"
						flat
						small
						class="scl-table-filter__clear-btn text-capitalize font-weight-bold"
						color="#ff6105"
						v-if="searchChips.length > 0 || selectFilter1 !== null || selectFilter2 !== null"
						@click="clearChips"
					>
						Clear Tags <v-icon size="18" class="pl-2">fas fa-times</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
