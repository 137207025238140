/**
 * SclChangePasswordModal
 */

// template
const template = `
	<div class="scl-reset-password-form scl-form">
		<v-card>
			<v-toolbar class="scl-dialog__toolbar mb-3" flat>
				<v-layout row>
					<v-card-title class="pa-0" primary-title>
						<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
					</v-card-title>
				</v-layout>
			</v-toolbar>
			<v-form v-if="!successMessage" ref="resetPasswordForm" class="scl-form">
				<v-container>
					<v-layout row wrap>
						<v-flex xs12 class="scl-contact-card__title font-weight-bold text-capitalize pa-2">
							Password criteria
						</v-flex>
						<v-flex xs12 pa-3 class="scl-contact-card__content">
							<v-layout column>
								<v-flex xs12 mb-3 class="text-left text-xs-left">
									<v-flex xs-12>
										<v-icon color="#ff6105" class="mt-2">fa-caret-right</v-icon>
										&nbsp;Must include at least 8 characters
									</v-flex>
									<v-flex xs-12>
										<v-icon color="#ff6105" class="mt-2">fa-caret-right</v-icon>
										<span>&nbsp;Must contain at least one lowercase letter</span>
									</v-flex>
									<v-flex xs-12>
										<v-icon color="#ff6105" class="mt-2">fa-caret-right</v-icon>
										<span>&nbsp;Must include at least one number, upper case and special character</span>
									</v-flex>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="xs12 text-xs-left">
							<p class="font-weight-bold mb-2">New password</p>
							<v-text-field v-model="enquiry.newPassword"
										class="scl-form__input"
										outline
										:rules="[rules.required, rules.min, rules.passwordLowercaseValidation, rules.passwordOtherValidation]"
										id="password"
										type="password"
										name="NewPassword"
										data-vv-name="NewPassword"></v-text-field>
						</v-flex>
						<v-flex class="xs12 text-xs-left">
							<p class="font-weight-bold mb-2">Confirm Password</p>
							<v-text-field v-model="enquiry.confirmPassword"
										class="scl-form__input"
										outline
										:rules="[rules.required, rules.passwordMatch]"
										id="newPassword"
										type="password"
										name="ConfirmPassword"
										data-vv-name="ConfirmPassword"></v-text-field>
						</v-flex>
						<v-flex xs12 mt-4>
							<v-btn
								color="accent"
								class="scl-component scl-button"
								depressed
								block
								@click="submit"
							>
							save
							</v-btn>
						</v-flex>
						<v-alert type="error" :value="resultMessage" outline>{{ resultMessage }}</v-alert>
					</v-layout>
				</v-container>
			</v-form>
			<v-form v-if="successMessage" class="scl-form">
				<v-container>
					<v-layout row wrap>
						<v-flex xs12 class="pb-0">
							<p>
								{{successMessage}}
							</p>
							<p><a href="/login">Return to Login</a></p>
						</v-flex>
					</v-layout>
				</v-container>
			</v-form>
		</v-card>
	</div>
`;

// export template
export default template;
