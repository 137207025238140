/**
 * SclSelect
 */

// template
const template = `
	<div>
		<v-select
			:items="['test 1', 'test 2', 'test 3', 'test 4', 'test 5', 'test 6']"
			class="scl-form__input scl-form__select"
			outline
			label="Filter your search"
		></v-select>
	</div>
`;

// export template
export default template;
