import axios from 'axios';

// component
const component = {
	name: 'SclSendInvitationModal',
	data() {
		return {
			dialog: false,
			valid: true,
			errorMessage: '',
			listData: {}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, selected => {
			this.listData.listItems = selected.filter(item => item.hasInvitableStatus);
			this.dialog = true;
		});
		this.listData = this.propApi.listData;
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'send-invitation-event',
					width: '950',
					persistent: false,
					modalLabel: '',
					listData: {
						listHeaders: [
							{
								text: 'First Name',
								align: 'left',
								sortable: false,
								value: 'firstname',
								width: '10%'
							},
							{ text: 'Surname', value: 'surname', align: 'left', sortable: false, width: '10%' },
							{ text: 'Login email', value: 'email', align: 'left', sortable: false, width: '25%' },
							{ text: 'Capabilities', value: 'capabilities', align: 'left', sortable: false, width: '30%' },
							{ text: 'Status', value: 'status', align: 'left', sortable: false, width: '20%' },
							{ text: 'Last Email Sent', value: 'lastEmail', align: 'left', sortable: false, width: '15%' },
							{ text: '', value: 'remove', align: 'left', sortable: false, width: '10%' }
						],
						listItems: [
							{
								firstname: 'Jack',
								surname: 'Summer',
								email: 'j.summer@email.com',
								customer: 'Wick',
								capabilities: 'My Invoices, My Hires (Rates), EOHD Without Edit',
								status: 'Pending Registration Expired',
								lastEmail: '21/01/2050'
							},
							{
								firstname: 'Jill',
								surname: 'west',
								email: 'j.summer@email.com',
								customer: '',
								capabilities: 'My Invoices, My Hires (Rates), EOHD Without Edit',
								status: 'Pending Registration Expired',
								lastEmail: '21/01/2050'
							},
							{
								firstname: 'Bob',
								surname: 'east',
								email: 'j.summer@email.com',
								customer: 'bw',
								capabilities: 'My Invoices, My Hires (Rates), EOHD Without Edit',
								status: 'Pending Registration Expired',
								lastEmail: '21/01/2050'
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		clickEvent(event) {
			this.dialog = false;
			this.$root.$emit(event, this.hireItems);
		},
		removeItem(event) {
			this.listData.listItems.splice(event, 1);
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					users: this.listData.listItems
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Email sent successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					this.$root.$emit('table-update-search', '');
				} else {
					this.errorMessage = 'Send email failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.closeModal();
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
