/**
 * SclAccordion api
 *
 * expand prop
 * @param {boolean} true || false
 *
 * icon prop
 * @param {string} 'fas fa-chevron-down'
 */

// template
const template = `
	<scl-accordion inline-template :api="{ expand: true, icon: 'fas fa-chevron-down' }" class="scl-component scl-accordion">
		<v-expansion-panel :expand="propApi.expand">
			<v-expansion-panel-content lazy :expand-icon="propApi.icon">
				<template v-slot:header>
					<div><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></div>
				</template>
				<v-card>
					<v-card-text><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></v-card-text>
				</v-card>
			</v-expansion-panel-content>
			<v-expansion-panel-content lazy :expand-icon="propApi.icon">
				<template v-slot:header>
					<div><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></div>
				</template>
				<v-card>
					<v-card-text><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</scl-accordion>
`;

// export template
export default template;
