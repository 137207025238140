// component
import mxApi from '@portals/mixins/common/mxApi.js';
const component = {
	name: 'SclPersonalDetailForm',
	mixins: [mxApi],
	data() {
		return {
			formData: {},
			titles: [],
			roles: [],
			states: [],
			isValid: false,
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				},
				mobile: value => {
					const pattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
					return pattern.test(value) || 'Invalid mobile.';
				}
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.titles ? Array.isArray(obj.titles) : true));
				set.add(!!(obj.roles ? Array.isArray(obj.roles) : true));
				set.add(!!(obj.states ? Array.isArray(obj.states) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		propApi() {
			return Object.assign(
				{
					formData: {},
					titles: [],
					roles: [],
					states: []
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.formData': function (newVal) {
			this.formData = newVal;
		},
		formData: {
			handler() {
				this.isValid = this.$refs.form?.validate();
			},
			deep: true
		}
	},
	methods: {
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					account: this.formData
				};
				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result.success || result.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Profile updated successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
