// component
const component = {
	name: 'SclUpdateStatus',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					data: []
				},
				this.api
			);
		}
	},
	data() {
		return {
			statusData: []
		};
	},
	watch: {
		'propApi.data': {
			handler(newVal) {
				this.statusData = newVal;
			}
		}
	},
	methods: {},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
