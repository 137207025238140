/**
 * SclExceptionAccordionModal
 */

// template
const template = `
	<div class="scl-exception-accordion-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-exception-accordion-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<div class="scl-exception-accordion__labels" :class="{'px-3': $vuetify.breakpoint.mdAndUp}">
									<h3 class="mb-0">{{propApi.modalLabel}}</h3>
									<p class="mb-0 subheading">{{modalSublabel}}</p>
								</div>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<scl-exception-accordion></scl-exception-accordion>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">save</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
