/**
 * SclQuoteContactModal
 */

// template
const template = `
	<div class="scl-quote-contact-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				content-class="scl-quote-contact-modal__component"
			>
				<v-card class="fill-height">
					<v-toolbar class="mb-4" flat>
						<v-layout row class="justify-end">
							<v-icon color="#FF6105" @click="dialog = false">far fa-times</v-icon>
						</v-layout>
					</v-toolbar>
					<v-form ref="form" class="scl-quote-contact-modal__form">
						<v-container grid-list-xl class="scl-quote-contact-modal__container py-0">
							<v-layout row wrap px-2 class="scl-quote-contact-modal__container-layout">
								<v-flex xs12 pa-0>
									<v-card-title class="pa-0 scl-quote-contact-modal__title" primary-title>
										{{ propApi.title}}
									</v-card-title>
									<p class="mb-4">
										{{ propApi.subHeader}}
									</p>
									<label class="scl-form__label">Subject <span class="scl-form__label--additional">Required</span></label>									
									<v-text-field
										v-model="formData.subject"
										:rules="[rules.required]"
										placeholder="Quote Request follow up QR########"
										class="scl-form__input"
										outline solo
										required
									></v-text-field>
									<label class="scl-form__label">Message <span class="scl-form__label--additional">Required</span></label>
									<v-textarea v-model="formData.message" outline required :rules="[rules.message]" class="scl-quote-contact-modal__textarea"></v-textarea>
									<label class="scl-form__label">Sender <span class="scl-form__label--additional">Required</span></label>									
									<v-text-field
										v-model="formData.sender"
										:rules="[rules.required]"
										class="scl-form__input"
										outline solo
										required
									></v-text-field>
								</v-flex>
							</v-layout>
						</v-container>
						<v-card-actions class="scl-quote-contact-modal__actions justify-start pt-0">
							<v-flex xs12 md6>						
								<v-btn  color="#FF6105" class="scl-component scl-button ml-2" @click.prevent="submit" > 
									<v-progress-circular 
										v-if="isLoading" 
										color="white"
										indeterminate
										size="24"
										class="mr-3">
									</v-progress-circular> 
									<v-icon v-else left> fal fa-chevron-right</v-icon> 
									{{ isLoading ? 'Submitting' : 'Submit' }}
								</v-btn>
								
								</v-flex>
								<p v-if="!valid">
									The form isn’t quite complete. Please review the highlighted fields and try again.
								</p
							</v-flex>
						</v-card-actions>
							
					</v-form>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
