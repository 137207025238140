import axios from 'axios';

// component
const component = {
	name: 'SclPricingTable',
	data() {
		return {
			selected: [],
			pagination: {
				page: 1,
				sortBy: 'ProductCode',
				rowsPerPage: 25
			},
			indeterminate: false,
			tableQuery: [],
			results: [],
			tableData: {},
			priceBookIds: '',
			sourceName: '',
			masterAccountName: '',
			siteGroupName: '',
			siteAccountName: '',
			parentGroupName: ''
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		defaultText() {
			if (this.api.tableData.accountSelectStatus === 0) {
				return 'Please select Customer.';
			} else if (this.api.tableData.accountSelectStatus === 1) {
				return 'Please select Master Account.';
			} else if (this.api.tableData.accountSelectStatus === 2) {
				return 'Please select Site Account.';
			} else {
				return 'No pricing data available.';
			}
		},
		sourceInfo() {
			switch (this.sourceName) {
				case 'rate card':
					return `Pricing related to the selected site comes from Rate Card ${this.priceBookIds}`;
				case 'parent group':
					return `Pricing related to the selected site comes from ${this.parentGroupName} ${this.priceBookIds}`;
				case 'master account':
					return `Pricing related to the selected site comes from ${this.masterAccountName} ${this.priceBookIds}`;
				case 'site group':
					return `Pricing related to the selected site comes from ${this.masterAccountName} - ${this.siteGroupName} ${this.priceBookIds}`;
				case 'site':
					return `Pricing related to the selected site comes from ${this.masterAccountName} - ${this.siteAccountName} ${this.priceBookIds}`;
				default:
					return `Unknown source type '${this.sourceName}' have found, please notify dev to update.`;
			}
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					postbackURL: '',
					postbackPipe: '',
					tableData: {}
				},
				this.api
			);
		},
		pageSizeQueryParam() {
			const url = new URL(window.location.href);
			const param = url.searchParams.get('rowsPerPage');
			const pageSize = param ? parseInt(param) : 25;
			if (pageSize > 0 && pageSize <= 25) {
				return pageSize;
			}
			return 25;
		},
		headers() {
			if (this.tableData == null || this.tableData.headers == null || this.tableData.headers.length == 0) {
				return [];
			}
			if (this.results == null || this.results.length == 0) {
				return this.tableData.headers;
			}
			//removing any header where the related result price cell is empty
			const result = this.results[0];
			const filtered = this.tableData.headers.filter(x => {
				if (x.value == 'Price2' && !result.price2) {
					return false;
				}
				if (x.value == 'Price3' && !result.price3) {
					return false;
				}
				if (x.value == 'Price4' && !result.price4) {
					return false;
				}
				if (x.value == 'Price5' && !result.price5) {
					return false;
				}
				return true;
			});
			// Add default alignment for headers
			filtered.forEach((header, i) => {
				if (header.value == 'Category' && header.align == null) {
					filtered[i].align = 'left';
				}
				if (header.value == 'ProductDescription' && header.align == null) {
					filtered[i].align = 'left';
				}
				if (header.value == 'Price1' && header.align == null) {
					filtered[i].align = 'right';
				}
				if (header.value == 'Price2' && header.align == null) {
					filtered[i].align = 'right';
				}
				if (header.value == 'Price3' && header.align == null) {
					filtered[i].align = 'right';
				}
				if (header.value == 'Price4' && header.align == null) {
					filtered[i].align = 'right';
				}
				if (header.value == 'Price5' && header.align == null) {
					filtered[i].align = 'right';
				}
			});
			return filtered;
		},
		selectAllLabel() {
			const label = this.selected.length === 0 ? 'Select All' : 'Deselect All';
			return label;
		}
	},
	watch: {
		selected() {
			if (this.selected.length > 0 && this.selected.length != this.tableData.tableItems) {
				this.indeterminate = true;
			}
			if (this.selected.length === 0) {
				this.indeterminate = false;
			}
		},
		'propApi.tableData': {
			handler(newVal) {
				this.tableData = newVal;
				this.pagination.total = this.propApi.total;
				this.tableQuery = newVal.tableQuery ? newVal.tableQuery : [];
				this.initTable();
			},
			immediate: true,
			deep: true
		}
	},
	async mounted() {
		this.$root.$on('selectedMA', accountSelectStatus => {
			this.api.tableData.accountSelectStatus = accountSelectStatus;
		});
		this.$root.$on('selectedSA', accountSelectStatus => {
			this.api.tableData.accountSelectStatus = accountSelectStatus;
		});
		this.$root.$on('loadingSpinner', ev => {
			this.loading = ev;
		});
		this.$root.$on('table-filter-query', filters => {
			this.tableQuery = filters;
		});
		this.$root.$on('table-filter-results', event => {
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!event.result) {
				this.tableData.headers = event.result.headers;
				this.results = event.result.results;
				this.pagination.total = event.result.total;
				this.priceBookIds = event.result.priceBookIds;
				this.sourceName = event.result.sourceName;
				this.masterAccountName = event.result.masterAccountName;
				this.siteGroupName = event.result.siteGroupName;
				this.siteAccountName = event.result.siteAccountName;
				this.parentGroupName = event.result.parentGroupName;
			}else{
				this.errorMessage = 'Table update failed, please try again later.';
				this.results = [];
				this.pagination.total = 0;
				this.priceBookIds = 0;
				this.sourceName = null;
				this.masterAccountName = 'N/A';
				this.siteGroupName = 'N/A';
				this.siteAccountName = 'N/A';
				this.parentGroupName = 'N/A';
			}
		});

		this.results = !this.api ? [] : this.api.tableData.tableItems;
		this.headers = !this.api ? [] : this.api.tableData.headers;
		this.pagination.page = 0;
		this.pagination.rowsPerPage = this.pageSizeQueryParam;
		if (this.results.length == 0 && (this.masterAccountName + '').length > 0 && (this.siteAccountName + '').length > 0) {
			await this.submit();
		}
	},
	methods: {
		initTable() {
			this.tableData = this.propApi.tableData;
		},
		toggleRowSelect() {
			if (this.selected.length > 0) {
				this.selected = [];
				this.indeterminate = false;
				return;
			}
			if (this.selected.length === 0) {
				this.results.forEach(item => this.selected.push(item));
				return;
			}
		},
		searched(val, key) {
			if (!val) {
				return '';
			}
			let tableCellString = String(val);
			if (!key) {
				return tableCellString;
			}
			const itemKey = key.toLowerCase();

			this.tableQuery.forEach(queryItem => {
				if (itemKey === queryItem.category.toLowerCase() || queryItem.category.toLowerCase() === 'all') {
					const textRegExp = new RegExp(queryItem.query, 'gi');

					if (queryItem.query != '') {
						tableCellString = tableCellString.replace(textRegExp, function (r) {
							return '<span class="scl-expandable-table__table-row--searchmatch">' + r + '</span>';
						});
					}
				}
			});
			return tableCellString;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},

		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.rowsPerPage;
			this.pagination.sortBy = data.sortBy;
			this.pagination.descending = data.descending;
			this.$root.$emit('table-update-pagination', this.pagination);
			//this.submit();
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					page: this.pagination.page,
					pageSize: this.pagination.rowsPerPage,
					text: this.query || '',
					filter: this.filterParameter,
					tableData: this.selected
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const result = response.data.result;
					this.results = result.results;
					this.pagination.total = result.total;
				} else {
					this.errorMessage = 'Table update failed, please try again later.';
					const snackBarMessage = {
						copy: this.errorMessage,
						type: 'error',
						showCloseButton: true
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error',
					showCloseButton: true
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		},
		getKeyByValue(object, value) {
			if (!object || !value) {
				return;
			}
			return Object.keys(object).find(key => object[key] === value);
		},
		changeSort(column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending;
			} else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}

			this.updatePagination(this.pagination);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
