// css import

// component
const component = {
	name: 'SclCardContactSimple',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.name ? typeof obj.name === 'string' : true));
				set.add(!!(obj.phone ? typeof obj.phone === 'string' : true));
				set.add(!!(obj.emailAddress ? typeof obj.emailAddress === 'string' : true));
				set.add(!!(obj.tooltipMessage ? typeof obj.tooltipMessage === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			show: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					icon: null,
					name: null,
					phone: null,
					email: null,
					tooltipMessage: null
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
