// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclLoginForm',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			showRequestSuccess: false,
			errorMessage: '',
			email: '',
			rememberMe: false,
			password: '',
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				}
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					postbackURL: '',
					postbackPipe: ''
				},
				this.api
			);
		}
	},
	methods: {
		clickEvent(event) {
			this.$root.$emit(event);
		},
		async submit() {
			//submit handled in sitecore razor template, using sitecore mvc form postback.
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
