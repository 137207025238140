/**
 * SclProductSummary api
 */

// template
const template = `
	<div class="scl-manage-users-page-header" inline-template style="width:100%;min-width:100%;">
		<v-container fluid>
			<v-layout row wrap>
				<v-flex xs12 md10 order-xs1 mb-2>
					<h1 class="font-weight-black scl-hire-header__title">{{propApi.title}}</h1>
				</v-flex>
				<v-flex xs2 order-xs3 order-md2 text-xs-right>
					<a @@click="print"><v-icon class="scl-hire-header__icon" color="primary">fas fa-print</v-icon></a>
				</v-flex>
				<v-flex xs10 order-xs2 order-md3 class="scl-hire-header__subtitle">
					<span>{{propApi.text}}</span>
				</v-flex>
				<v-flex xs12  order-xs3>
					<v-btn depressed class="scl-component scl-button ml-2" @@click.prevent="emit('add-user')">
						<v-icon class="scl-button__icon">fas fa-plus</v-icon>Add user
					</v-btn>
					<v-btn depressed class="scl-component scl-button ml-2" @@click.prevent="emit('add-user')">
						<v-icon class="scl-button__icon">fas fa-plus</v-icon>Email domain names
					</v-btn>
					<v-btn depressed class="scl-component scl-button ml-2" @@click.prevent="emit('add-user')" >
						<v-icon class="scl-button__icon">fas fa-plus</v-icon>Role access requests
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
