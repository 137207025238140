// component
const component = {
	name: 'SclConfirmationDetail',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: {},
					selectedAccounts: []
				},
				this.api
			);
		}
	},
	mounted() {
		this.accountData = this.propApi.accountData;
		this.selectedAccounts = this.propApi.selectedAccounts;
	},
	watch: {
		propApi(val) {
			this.accountData = { ...val.accountData };
			this.selectedAccounts = { ...val.selectedAccounts };
		}
	},
	data() {
		return {
			accountData: {},
			selectedAccounts: []
		};
	},
	methods: {
		addUser() {
			this.$root.$emit('snackbar-event', 'add-user');
		},
		previousStep() {
			this.$root.$emit('stepper-event', ['user-access', this.accountData]);
		},
		nextStep() {
			this.$root.$emit('stepper-event', 'add-user');
		},
		edit() {
			this.$root.$emit('stepper-event', ['user-details', this.accountData]);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
