// component
const component = {
	name: 'SclPieChartPanel',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.chartdata ? Array.isArray(obj.chartdata) : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			legendMarkup: '',
			chartdata: [
				{
					datasets: []
				}
			]
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					chartdata: []
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.chartdata': {
			handler: function (newVal) {
				this.chartdata = newVal;
			}
		}
	},
	methods: {
		clickEvent(event) {
			if (this.propApi.modal) {
				this.$root.$emit(event);
			} else {
				window.location.href = this.propApi.href;
			}
		},
		onLegendMarkupReady(markup) {
			this.legendMarkup = markup;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
