/**
 * SclHireTableHeader
 */

// template
const template = `
	<div class="scl-hire-table-header">
		<v-container fluid>
			<v-layout row wrap>
				<v-flex xs10 md7 mb-2 class="scl-hire-table-header__title-filter">
					<h1 class="font-weight-bold scl-hire-table-header__title" :class="{'title pr-2': $vuetify.breakpoint.smAndDown, 'pr-3': $vuetify.breakpoint.mdAndUp}">MY HIRES</h1>
					<v-select @change="applyFilter" :class="{'d-none': $vuetify.breakpoint.smAndDown}" v-model="filter" :items="itemKeys" label="Current Hires" outline hide-details solo flat class="scl-hire-table-header__title-select text-capitalize"></v-select>
					<div class="divider-vertical mr-2" :class="{'d-none': $vuetify.breakpoint.mdAndUp}"></div>
					</v-flex>
				<v-flex xs2 md5 text-xs-right class="scl-hire-table-header__icon-group">
					<div class="d-flex align-center" :class="{'d-none': $vuetify.breakpoint.smAndDown}">
						<v-btn v-if="propApi.buttons.showPrint" flat icon class="border mr-0"><v-icon class="scl-hire-table-header__icon" color="primary" @click="submit('Pdf')">fas fa-print</v-icon></v-btn>
						<v-btn v-if="propApi.buttons.showCsv" flat icon class="border mr-0"><v-icon class="scl-hire-table-header__icon" color="primary" @click="submit('Csv')">fas fa-file-csv</v-icon></v-btn>
						<v-btn v-if="propApi.buttons.showExcel" flat icon class="border"><v-icon class="scl-hire-table-header__icon" color="primary" @click="submit('Excel')">fas fa-file-excel</v-icon></v-btn>
						<div v-if="propApi.buttons.showHelp && (propApi.buttons.showPrint || propApi.buttons.showCsv || propApi.buttons.showExcel)" class="divider-vertical"></div>
						<v-btn v-if="propApi.buttons.showHelp" flat icon :href="propApi.buttonsLink.helpLink"><v-icon class="scl-hire-table-header__icon" color="primary" size="35">far fa-question-circle</v-icon></v-btn>
					</div>
					<div :class="{'d-none': $vuetify.breakpoint.mdAndUp}">
						<v-btn flat icon class="mr-0" no-ripple @click="clickEvent('hire-mobile-menu-event', filter)"><v-icon class="scl-hire-table-header__icon" color="primary">fas fa-ellipsis-h</v-icon></v-btn>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
