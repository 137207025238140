/**
 * SclEmailDomainModal
 */

// template
const template = `
	<div class="scl-email-domain-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-email-domain-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{propApi.modalLabel}}
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form ref="form">
						<v-container grid-list-xl class="scl-email-domain-modal__container pt-0">
							<v-layout row wrap px-2 class="scl-email-domain-modal__container-layout">
								<v-flex xs12 pa-0>
									<p>By proceeding with the Activation of the Email Domain Names restrictions, the following user profiles will be placed ON HOLD:</p>
								</v-flex>
								<v-flex xs12 :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
									<div class="scl-email-domain-modal__listing-table pt-2" :class="{'pl-2': $vuetify.breakpoint.smAndDown}">
										<v-layout row wrap class="scl-email-domain-modal__listing-header">
											<v-flex xs6 md4 class="mb-2">
												Email domain name
											</v-flex>
											<v-flex xs md4 class="mb-2 ml-2">
												No. of users
											</v-flex>
										</v-layout>
										<v-layout row wrap v-for="(item, index) in modalData" :key="index" class="scl-email-domain-modal__listing">
											<v-flex xs6 md4 class="mb-2">
												{{item.name}}
											</v-flex>
											<v-flex xs md4 class="mb-2 ml-2">
												{{item.users}}# of users
											</v-flex>
										</v-layout>
									</div>
								</v-flex>
								<v-flex xs12 class="px-0 mt-4">
									<p class="body-1">Please confirm that you would like to apply this restriction.</p>
								</v-flex>
							</v-layout>
						</v-container>
						<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
							<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
							<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit" >confirm</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
