// component
const component = {
	name: 'SclButton',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.href ? typeof obj.href === 'string' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				set.add(!!(obj.block ? typeof obj.block === 'boolean' : true));
				set.add(!!(obj.copy ? typeof obj.copy === 'string' : true));
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				set.add(!!(obj.target ? typeof obj.target === 'string' : true));
				set.add(!!(obj.modal ? typeof obj.modal === 'string' : true));
				set.add(!!(obj.color ? typeof obj.color === 'string' : true));
				set.add(!!(obj.tally ? typeof obj.tally === 'string' : true));
				set.add(!!(obj.emitData ? typeof obj.emitData === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					href: null,
					outline: true,
					block: false,
					copy: null,
					icon: null,
					type: 'button',
					target: null,
					modal: null,
					color: null,
					tally: null,
					emitData: null
				},
				this.api
			);
		}
	},
	methods: {
		clickEvent(event) {
			if (this.propApi.modal) {
				this.$root.$emit(event, this.propApi.emitData);
			} else {
				window.location.href = this.propApi.href;
			}
		},
		// emit(event) {
		// 	this.$root.$emit(event, this.propApi.emitData);
		// },
		redirect() {
			window.location.href = this.propApi.href;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
