// Mixins
import mxDate from '@portals/mixins/common/mxDate.js';

// component
const component = {
	name: 'SclBottomSheetHireMobileMenu',
	mixins: [mxDate],
	data() {
		return {
			sheet: false,
			filter: {
				duration: [],
				package: false,
				dateFrom: '',
				dateTo: ''
			},
			filterItems: ['current hire', 'all hire activity (by date)', 'previous hires only (by date)'],
			dateFromMenu: false,
			dateToMenu: false,
			dateFrom: new Date().toISOString().substr(0, 10),
			dateTo: new Date().toISOString().substr(0, 10),
			dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10))
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.inset ? typeof obj.inset === 'boolean' : true));
				set.add(!!(obj.filterItems ? Array.isArray(obj.filterItems) : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'hire-mobile-menu-event',
					inset: false
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.sheet = true;
		});
		this.filter.dateFrom = this.dateFrom;
		this.filter.dateTo = this.dateTo;
		this.filter.duration = this.propApi.filterItems ? this.propApi.filterItems : [];
	},
	watch: {
		dateFrom(date) {
			this.dateFromFormatted = this.formatDate(date);
		},
		dateTo(date) {
			this.dateToFormatted = this.formatDate(date);
		}
	},
	methods: {
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},
		parseDate(date) {
			if (!date) {
				return null;
			}

			const [day, month, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
			this.sheet = false;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
