/**
 * SclPastThresholdSetting
 *
 **/

const template = `
	<div class="scl-past-threshold-setting scl-form">
		<v-container grid-list-xl fluid>
			<v-layout row wrap>
				<v-flex xs12>
					<h2 class="scl-past-threshold-setting__title pb-4 font-weight-black">Global ‘past due threshold’ SETTINGS</h2>
				</v-flex>
				<v-flex xs12 class="pb-0">
					<h3 class="pb-2">Current 'Due within X Days' Status:</h3>
				</v-flex>
				<v-flex xs12 class="pt-0">
					<v-chip label outline text-color="#4E4A46" class="font-weight-bold scl-threshold-setting__chip subheading">
						<v-icon size="16" left color="#ff6105">fas fa-circle</v-icon>{{thresholdSetting.threshold}}+ Days past due
					</v-chip>
					<v-chip label outline text-color="#4E4A46" class="font-weight-bold scl-threshold-setting__chip subheading">
						<v-icon size="16" left color="#ECAF1E">fas fa-circle</v-icon>1-{{thresholdSetting.threshold}} Days past due
					</v-chip>
				</v-flex>
				<v-flex xs12 class="pb-0">
					<h3 class="pb-2">Change Threshold (1-30 Days)</h3>
				</v-flex>
				<v-flex xs12 class="scl-past-threshold-setting__step-input pt-0">
					<v-text-field v-model="thresholdSetting.threshold" class="scl-form__step-number font-weight-bold" hide-details solo flat type="number" label="Number" prepend-icon="fas fa-minus" append-outer-icon="fas fa-plus" @click:append-outer="incrementThreshold" @click:prepend="decrementThreshold"></v-text-field>
				</v-flex>
				<v-flex xs12>
					<h3 class="pb-2 font-weight-bold text-uppercase">self registration</h3>
					<h3 class="pb-2">Assign capabilities (Please select at least one)</h3>
				</v-flex>
				<v-flex xs-12 d-inline-flex shrink class="scl-past-threshold-setting__capabilites">
					<v-checkbox class="mt-0" label="My hires" v-model="thresholdSetting.capabilities.hires"></v-checkbox>
					<v-radio-group :disabled="!thresholdSetting.capabilities.hires" v-model="rateGroup" class="mt-0" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
						<v-radio label="With rates" value="rates"></v-radio>
						<v-radio label="Without rates" value="norates"></v-radio>
					</v-radio-group>
					<v-checkbox class="mt-0" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}" label="My-invoices" v-model="thresholdSetting.capabilities.invoices"></v-checkbox>
				</v-flex>
				<v-flex xs12>
					<h3>Maximum number of sites</h3>
				</v-flex>
				<v-flex xs12 class="scl-past-threshold-setting__step-input">
					<v-text-field v-model="thresholdSetting.site" class="scl-form__step-number font-weight-bold" hide-details solo flat type="number" label="Number" prepend-icon="fas fa-minus" append-outer-icon="fas fa-plus" @click:append-outer="incrementSite" @click:prepend="decrementSite"></v-text-field>
				</v-flex>
				<v-flex xs12>
					<v-btn class="scl-component scl-button" flat @click="submit">
						update
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
