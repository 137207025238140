/**
 * This mixin contains the functionality required for fetching data
 */
import axios from 'axios';

const mxApi = {
	created: function () {},
	methods: {
		async _mxApi_getData(payload, postbackURL) {
			try {
				const response = await axios.get(postbackURL, payload);

				return response;
			} catch (e) {
				return;
			}
		},
		async _mxApi_postData(payload, postbackURL, config) {
			try {
				const response = await axios.post(postbackURL, payload, { ...config });

				return response;
			} catch (e) {
				return;
			}
		}
	}
};

export default mxApi;
