// imports
import VueUploadComponent from 'vue-upload-component';

// component
const component = {
	name: 'SclFileUpload',
	components: {
		FileUpload: VueUploadComponent
	},
	data: () => {
		return {
			files: []
		};
	},
	watch: {
		files: {
			handler(newVal) {
				this.$emit('selected-files', newVal);
			}
		}
	},
	methods: {
		/**
		 * Has changed
		 * @param  Object|undefined   newFile   Read only
		 * @param  Object|undefined   oldFile   Read only
		 * @return undefined
		 */
		inputFile: function (newFile, oldFile) {
			if (newFile && oldFile && !newFile.active && oldFile.active) {
				// Get response data
				console.log('response', newFile.response);
				if (newFile.xhr) {
					//  Get the response status code
					console.log('status', newFile.xhr.status);
				}
			}
		},
		/**
		 * Pretreatment
		 * @param  Object|undefined   newFile   Read and write
		 * @param  Object|undefined   oldFile   Read only
		 * @param  Function           prevent   Prevent changing
		 * @return undefined
		 */
		inputFilter: function (newFile, oldFile) {
			if (newFile && !oldFile) {
				// Filter non-image file
				if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
					// return prevent();
				}
			}

			// Create a blob field
			newFile.blob = '';
			const URL = window.URL || window.webkitURL;
			if (URL && URL.createObjectURL) {
				newFile.blob = URL.createObjectURL(newFile.file);
			}
		},
		remove(file) {
			this.$refs.upload.remove(file);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
