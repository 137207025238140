// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclEmailDomainModal',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			valid: true,
			modalData: []
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, data => {
			this.dialog = true;
			this.modalData = [data];
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.modalData ? Array.isArray(obj.modalData) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: '',
					width: '960',
					persistent: false,
					modalLabel: '',
					modalData: []
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.modalData': {
			handler: function (newVal) {
				this.modalData = newVal;
			}
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		closeModal() {
			this.dialog = false;
			this.clickEvent('card-cancel-event');
		},
		async submit() {
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = this.domains;

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Domain updated successfully added',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Domain update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
