// template
const template = `
	<a @click="clickEvent()" class="scl-route-button" :style="{borderBottomColor: propApi.theme}">
		<v-icon :color="propApi.theme" size="14" v-if="propApi.showChevron" class="mr-2">fas fa-chevron-left</v-icon>
		<span class="font-weight-bold scl-route-button__label" :style="{color: propApi.theme}">{{ propApi.label }}</span>
	</a>
`;

// export template
export default template;
