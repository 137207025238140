// component
const component = {
	name: 'SclQuoteConfirmation',
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			backBtn: {
				url: '/my-quote-requests',
				text: 'Back to My Quote Requests'
			},
			quoteId: null
		};
	},
	computed: {
		propApi() {
			return Object.assign(
				{
					contactTitle: 'Contact Coates about your request',
					contactIntro: 'A Coates representative will be in touch shortly.'
				},
				this.api
			);
		}
	},
	mounted() {
		this.replaceQuoteToken();
		this.updateBackgroundColor();
		this.addListeners();
	},
	destroyed() {
		document.removeEventListener('click', this.addListeners);
	},
	methods: {
		addListeners() {
			// Eventlistener has been added because v-btn is replaced when we do the token replace, so @click doesn't work.
			document.addEventListener(
				'click',
				e => {
					let elem = e.target.id;
					if (elem === 'openModal') {
						this.openContactModal();
					}
				},
				false
			);
		},
		replaceQuoteToken() {
			const params = new URLSearchParams(window.location.search);
			const quote = this.$refs.quote.innerHTML;
			if (params.has('quote') && quote.includes('[QuoteNumber]')) {
				this.$refs.quote.innerHTML = quote.replace('[QuoteNumber]', `${params.get('quote')}`);
				this.quoteId = params.get('quote');
			}
		},
		updateBackgroundColor() {
			const elem = document.querySelector('.wrapper-content__container');
			elem.classList.add('wrapper-content__container--grey');
		},
		openContactModal() {
			this.$root.$emit('open-contact-modal');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
