/**
 * SclConfirmationModal
 */

// template
const template = `
	<div class="scl-history-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-history-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container class="scl-history-modal__container px-3 py-0">
						<v-layout row wrap fill-height d-flex>
							<v-flex xs12>
								<p>Plant No: <strong class="font-weight-bold ml-2">{{propApi.plantNo}}</strong></p>
								<p>Description: <strong class="font-weight-bold ml-2">{{propApi.plantInfo}}</strong></p>
							</v-flex>
							<v-data-table
								:headers="propApi.tableData.headers"
								:items="propApi.tableData.history"
								hide-actions
								class="scl-history-modal__data-table"
							>
								<template v-slot:items="props">
									<td>{{ props.item.updatedBy }}</td>
									<td class="text-xs-left">{{ props.item.dateTimeUpdated }}</td>
									<td class="text-xs-left">{{ props.item.preOffHire }}</td>
									<td class="text-xs-left font-weight-bold">{{ props.item.newOffHire }}</td>
								</template>
								<template v-slot:footer>
									<td :colspan="propApi.tableData.headers.length" class="pl-0 py-4">
										<p class="mb-0 caption">{{propApi.historyFooterMsg}}</p>
									</td>
								</template>
							</v-data-table>
						</v-layout>
					</v-container>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
