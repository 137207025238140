/**
 * SclSetupDomainNameHeader
 *
 **/

const template = `
<div class="scl-setup-domain-name-header scl-form">
	<v-container grid-list-xl fluid class="px-0">
		<v-toolbar flat>
			<v-toolbar-title class="font-weight-bold headline">Set up email domain names</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items>
        <scl-button
          color="accent"
          class="scl-component scl-button"
          depressed :api="{modal: 'new-email-domain-event', copy: 'Add new', icon: 'fas fa-plus'}"
        ></scl-button>
			</v-toolbar-items>
		</v-toolbar>
	</v-container>
	</div>
`;

export default template;
