/**
 * SclPersonalDetailForm api
 */

// template
const template = `
	<div class="scl-personal-detail-form scl-form">
	<v-container grid-list-xl fluid>

	<v-form ref="form">
		<v-layout row wrap>
			<v-flex xs12 pa-0>
				<p class="font-weight-bold title text-uppercase mb-4 text-uppercase">Edit Details</p>
			</v-flex>
			<v-flex xs12 pa-0>
				<p class="font-weight-bold caption text-uppercase pb-0 mb-0">personal information</p>
			</v-flex>
			<v-flex class="xs12 md2 pl-0 pb-0">
				<v-select
					v-model="formData.title"
					:items="formOptions.title"
					class="scl-form__input scl-form__select"
					outline
					label="Title" append-icon="fas fa-chevron-down"
				></v-select>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.firstName"
				class="scl-form__input"
				outline
				label="first name"
			></v-text-field>
			</v-flex>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.surname"
				class="scl-form__input"
				outline
				label="surname"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.jobTitle"
				class="scl-form__input"
				outline
				label="job title"
			></v-text-field>
			</v-flex>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-select
				v-model="formData.role"
				:items="formOptions.role"
				class="scl-form__input scl-form__select"
				outline
				label="role" append-icon="fas fa-chevron-down"
			></v-select>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs12 pa-0>
				<p class="font-weight-bold caption text-uppercase pb-0 mb-0">contact information</p>
			</v-flex>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.phoneMobile"
				class="scl-form__input"
				outline
				label="mobile phone number"
			></v-text-field>
			</v-flex>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.phoneBusiness"
				class="scl-form__input"
				outline
				label="business phone number"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.emailBusiness"
				class="scl-form__input"
				outline
				label="business email"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md2 pl-0 pb-0">
				<v-text-field
				v-model="formData.addressNumber"
				class="scl-form__input"
				outline
				label="unit/number"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.addressStreet"
				class="scl-form__input"
				outline
				label="street name"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md3 pl-0 pb-0">
				<v-text-field
				v-model="formData.addressSuburb"
				class="scl-form__input"
				outline
				label="suburb"
			></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex class="xs12 md2 pl-0 pb-0">
				<v-select
				v-model="formData.addressState"
				:items="formOptions.state"
				class="scl-form__input scl-form__select"
				outline
				label="state" append-icon="fas fa-chevron-down"
			></v-select>
			</v-flex>
		</v-layout>
		</v-form>
	</v-container>
	</div>
`;

// export template
export default template;
