/**
 * SclCardSpotlight
 */

// template
const template = `
	<v-card class="scl-card-spotlight mb-2" flat>
		<v-container pa-0>
			<v-layout column ma-0>
				<v-flex xs12 class="scl-card-spotlight__title font-weight-bold text-capitalize pa-2">
					{{propApi.title}}
				</v-flex>
				<v-flex xs12 pa-3 class="scl-card-spotlight__content">
					<v-layout column>
						<v-flex xs12 mb-3>
							<div>{{propApi.message}}</div>
						</v-flex>
						<v-flex xs-12>
							<div><a :href="'mailto:'+propApi.emailAddress">{{propApi.emailAddress}}</a></div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
`;

// export template
export default template;
