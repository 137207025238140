/**
 * SclCardToggle
 */

// template
const template = `
	<v-card class="scl-card-profile subheading mb-2" flat>
		<v-container pa-0>
			<v-layout column ma-0>
				<v-flex xs12 pa-3 class="scl-card-profile__content">
					<v-layout column>
						<v-flex xs12 mb-3>
							<h3>{{accountData.title}} {{accountData.firstName}} {{accountData.lastName}}</h3>
						</v-flex>
					</v-layout>
					<v-layout row wrap class="mb-2">
						<v-flex xs12>
							<p class="caption mb-0">Role</p>
						</v-flex>
						<v-flex xs12>
							<p class="mb-0">{{accountData.role}}</p>
						</v-flex>
					</v-layout>
					<v-layout row wrap class="mb-2">
						<v-flex xs12>
							<p class="caption mb-0">Managed by</p>
						</v-flex>
						<v-flex xs12>
							<p class="mb-0">{{accountData.managed}}</p>
						</v-flex>
					</v-layout>
					<v-layout row wrap class="mb-2">
						<v-flex xs12>
							<p class="caption mb-0">Status</p>
						</v-flex>
						<v-flex xs12>
							<p class="mb-0">{{accountData.status}}</p>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
`;

// export template
export default template;
