// component
const component = {
	name: 'SclPageTabs',
	data() {
		return {
			activeTab: null,
			currentEvent: '',
			event: '',
			buttonList: [],
			tab: null,
			tabPreloaded: false
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			const searchParams = new URLSearchParams(window.location.search);
			const { tab, ...api } = this.api || {};
			return Object.assign(
				{
					data: []
				},
				api,
				{
					tab: parseFloat(tab || searchParams.get('tab')) || 0
				}
			);
		},
		buttons() {
			const { data = [] } = this.propApi || {};
			return data[this.tab] || [];
		}
	},
	methods: {
		onChange(e) {
			this.activeTab = e;
			this.$root.$emit('tab-change', e);
			this.updateInteractionEvent(e);
			this.updateUrlParam(e);
			this.tabPreloaded = true;
		},
		updateInteractionEvent(tab) {
			const currIndex = tab;
			this.tab = tab;
			if (typeof this.propApi.data[currIndex] !== 'undefined') {
				this.currentEvent = this.propApi.data[currIndex].event;
				const buttonGroup = this.propApi.data[currIndex];
				if (!buttonGroup?.buttons?.length) {
					this.event = null;
					this.buttonList = [];
					return;
				}
				this.event = buttonGroup.buttons[0].event;
				this.buttonList = buttonGroup.buttons;
			}
		},
		updateUrlParam(index) {
			if ('URLSearchParams' in window) {
				var searchParams = new URLSearchParams(window.location.search);
				searchParams.set('tab', index);
				var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + window.location.hash;
				history.pushState(null, '', newRelativePathQuery);
			}
		}
	},
	mounted() {
		this.onChange(this.propApi.tab);
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
