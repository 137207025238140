/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-expandable-table scl-form">
		<v-container class="scl-expandable-table__container fluid">
			<v-layout row wrap fill-height>
				<v-flex shrink>
					<v-checkbox class="scl-expandable-table__checkbox mt-0 pt-0" :class="{'scl-expandable-table__checkbox--indeterminate': indeterminate}" hide-details value="all" @click.prevent="toggleRowSelect" :ripple="false" :indeterminate="indeterminate"></v-checkbox>
				</v-flex>
				<v-flex class="scl-expandable-table__selection-cta">
					<div class="scl-expandable-table__selection-cta-divider"></div>
					<a href="#" @click.stop="clickEvent('confirmation-event', selected)" :class="{'disabled': selected.length === 0}">Edit Exp. Off Hire Dates</a>
				</v-flex>
				<v-flex class="xs12">
					<v-data-table
						v-model="selected"
						:headers="headers"
						:items="indexedResults"
						:must-sort="true"
						:loading="loading"
						:pagination.sync="pagination"
						@update:pagination="updatePagination"
						:search="tableQuery.query"
						:total-items="pagination.total"
						item-key="id"
						single-expand
						show-expand>

						<template v-slot:items="props">
							<tr>
								<td class="text-xs-left px-2 hidden-md-and-up">
									<a href="#">{{ props.item.plantNumber }}</a>
								</td>
								<td class="text-xs-left px-2 align-center scl-expandable-table__zindex hidden-sm-and-down">
									<v-checkbox class="scl-expandable-table__checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
								</td>
								<td class="text-xs-center px-2">
									<v-icon size="18" :color="hireStatus(props.item.status)">fas fa-circle</v-icon>
								</td>
								<td class="text-xs-center px-2 hidden-sm-and-down">
									<a href="#">{{ props.item.plantNumber }}</a></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.qty, 'quantity')"></td>
								<td class="text-xs-left px-2" v-html="searched(props.item.description, 'description')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.hireSchedule, 'hireSchedule')"></td>
								<td class="text-xs-left px-2" v-html="searched(props.item.site, 'siteAccontName')"></td>
								<td class="text-xs-left px-2" v-html="searched(props.item.orderedBy, 'orderedBy')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.purchaseNo, 'purchaseNo')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.customerReference, 'customerReference')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.hiredDate, 'hiredDate')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.deliveryDoc, 'deliveryDoc')"></td>
								<td class="text-xs-center px-2">
									<a href="#" @click.stop="clickEvent('date-picker-event', props.item)" class="scl-expandable-table__modal-link" v-html="searched(props.item.offHireDate, 'offHireDate')">
									</a>
								</td>
								<td class="text-xs-left scl-expandable-table__expand-btn px-2" @click="props.expanded = !props.expanded"><v-icon size="16" color="#ff6105">fas fa-chevron-down</v-icon></td>
								<td class="hidden-md-and-up">
									<v-checkbox class="scl-expandable-table__checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
								</td>
							</tr>
						</template>
						<template v-slot:expand="props">
							<v-layout row px-3 pt-4 pb-2 justify-space-between wrap>
								<v-flex class="font-weight-bold scl-expandable-table__expanded-item" v-for="(item, index) in props.item.tableExpandItems" :key="index">
									<p class="mb-1 pb-1 underline">{{item.label}}</p>
									<ul class="font-weight-regular pl-0">
										<li v-for="(listItem, j) in item.list" :key="j">
											{{listItem}}
										</li>
									</ul>
								</v-flex>
							</v-layout>
						</template>
						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
				<v-flex class="caption text-capitalize scl-expandable-table__footer pt-3 xs12">
					<ul pl-0 class="d-inline-flex pl-0">
						<li>
							<p class="mb-0">Expected Off Hire Date Status:</p>
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="hireStatus('R')">fas fa-circle</v-icon>
							> 30 Days Past Due
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="hireStatus('N')">fas fa-circle</v-icon>
							1 - 30 Days Past Due
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="hireStatus('A')">fas fa-circle</v-icon>
							Due Within 5 Days
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="hireStatus('G')">fas fa-circle</v-icon>
							Current
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="hireStatus('E')">fas fa-circle</v-icon>
							No Data
						</li>
					</ul>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
