/**
 * SclBottomSheet api
 *
 * @param {boolean} inset - Reduces the maximum width of the sheet
 * @param {boolean} persistent - Clicking outside will not dismiss the dialog
 * @param {boolean} value - Controls visibility
 */

// template
const template = `
<scl-bottom-sheet inline-template :api="{ value: true, inset: false, persistent: false }" class="scl-component scl-bottom-sheet">
	<v-bottom-sheet :inset="propApi.inset" :persistent="propApi.persistent" :value="propApi.value">
		<template v-slot:activator>
			<v-btn color="primary" block depressed>
				Bottom Sheet
			</v-btn>
		</template>
		<v-list>

			<v-subheader>Subheading 1</v-subheader>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-biking-mountain</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 1</v-list-tile-title>
			</v-list-tile>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-bacon</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 2</v-list-tile-title>
			</v-list-tile>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-biohazard</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 3</v-list-tile-title>
			</v-list-tile>

			<v-subheader>Subheading 2</v-subheader>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-mask</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 1</v-list-tile-title>
			</v-list-tile>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-narwhal</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 2</v-list-tile-title>
			</v-list-tile>
			<v-list-tile href="https://www.switchit.com/">
				<v-list-tile-action>
					<v-icon>far fa-otter</v-icon>
				</v-list-tile-action>
				<v-list-tile-title>Link 3</v-list-tile-title>
			</v-list-tile>

		</v-list>
	</v-bottom-sheet>
</scl-bottom-sheet>
`;

// export template
export default template;
