/**
 * SclBarChartPanel api
 */

// template
const template = `
	<v-layout class="scl-bar-chart-panel" row wrap>
		<v-flex xs12 class="scl-pie-chart-panel__no-data" v-if="propApi.chartdata[0].datasets.length === 0 || !propApi.chartdata[0].datasets">
			<v-icon size="48">far fa-file-search</v-icon>
			<p class="mb-0 pt-3 subheading">No data available for selected sites</p>
		</v-flex>
		<v-flex xs12 v-if="propApi.chartdata[0].datasets.length > 0 && propApi.chartdata[0].datasets">
			<scl-bar-chart :api="{chartdata: propApi.chartdata}"></scl-bar-chart>
		</v-flex>
	</v-layout>
`;

// export template
export default template;
