/**
 * This mixin contains the functionality required for generating data
 */

const mxAccount = {
	created: function () {},
	methods: {
		_mxAccount_generateFormDetailsData(data) {
			const accountData = {
				title: null,
				firstName: null,
				lastName: null,
				jobTitle: null,
				role: null,
				roleItems: [],
				email: null,
				mobileNumber: null,
				unit: null,
				street: null,
				suburb: null,
				state: null,
				postcode: null,
				type: null,
				managed: null,
				status: null,
				loginDate: null
			};
			const assignedData = Object.assign(accountData, data);
			return assignedData;
		}
	}
};

export default mxAccount;
