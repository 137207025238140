import mxApi from '@portals/mixins/common/mxApi.js';

// component
const component = {
	name: 'SclAddCustomerModal',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			account: null,
			errorMessage: null,
			selected: [],
			pagination: {
				descending: false,
				page: 0,
				rowsPerPage: 25,
				sortBy: 'tiers',
				total: 0
			},
			loading: null,
			total: 0,
			results: [],
			tiers: ['PG', 'MA', 'ALL'],
			tier: 'ALL',
			tableQuery: {
				query: ''
			},
			searchTrigger: '',
			searchTimer: null
		};
	},
	async mounted() {
		this.$root.$on(this.propApi.event, async () => {
			this.dialog = true;
			this.resetPagination();
			await this.searchSubmit();
		});
		if (this.propApi.tiers) {
			this.tiers = this.propApi.tiers;
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.customerData ? typeof obj.customerData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackSelectURL ? typeof obj.postbackSelectURL === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		items() {
			if (!this.results || this.results.length == 0) {
				return this.propApi.customerData ? this.propApi.customerData.customers : [];
			}
			return this.results;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '915',
					modalLabel: '',
					persistent: false,
					event: 'add-customer-event',
					redirectURL: '',
					postbackURL: 'http://portal.sc92.sc/api/v1/mch/accounts/search',
					postbackSubmitURL: 'http://portal.sc92.sc/api/v1/mch/admin/exceptions/update',
					customerData: {
						headers: [
							{
								text: '',
								value: '',
								class: '',
								sortable: false
							},
							{
								text: 'customer name',
								align: 'left',
								sortable: true,
								value: 'name',
								class: 'text-uppercase font-weight-black pt-0',
								width: '60%'
							},
							{ text: 'tiers', value: 'tiers', align: 'left', sortable: true, class: 'text-uppercase font-weight-black', width: '10%' },
							{ text: 'ma code', value: 'ma', align: 'left', sortable: true, class: 'text-uppercase font-weight-black', width: '30%' }
						],
						customers: []
					}
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.customerData.customers': function (newVal) {
			this.results = newVal;
		},
		'propApi.tiers': function (newVal) {
			this.tiers = newVal;
		},
		'tableQuery.query': {
			handler(newVal) {
				const self = this;
				clearTimeout(this.searchTimer);
				this.searchTimer = setTimeout(async () => {
					this.searchTrigger = newVal;
					await self.searchSubmit();
				}, 1000);
			}
		}
	},
	methods: {
		closeModal() {
			this.tableQuery.query = '';
			this.dialog = false;
		},
		resetPagination() {
			this.pagination.page = 0;
		},
		async updatePagination(data) {
			this.pagination.page = data.page - 1;
			this.pagination.rowsPerPage = data.rowsPerPage;
			this.pagination.sortBy = data.sortBy;
			this.pagination.descending = data.descending;
			await this.searchSubmit();
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async addWithContact() {
			const url = `${this.propApi.redirectURL}?contact=${this.selected[0].Guid}`;
			window.location.replace(url);
		},
		async searchSubmit() {
			this.errorMessage = null;
			this.loading = true;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					page: this.pagination.page,
					pageSize: this.pagination.rowsPerPage,
					sortBy: this.pagination.sortBy,
					descending: this.pagination.descending,
					segments: '',
					tier: this.tier || this.tiers[0] || 'ALL',
					text: this.tableQuery.query || ''
				};
				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					this.showRequestSuccess = true;
					var result = response.data.result;
					this.pagination.total = result.total;
					this.total = null;
					this.total = result.total;
					this.pagination.page = result.page;
					this.results = result.results;
				} else {
					this.errorMessage = 'Table update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				if (this.$refs.dataTable) {
					this.$refs.dataTable.$forceUpdate();
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Table update failed, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage,
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
				this.pagination.total = 0;
			} finally {
				this.loading = false;
				this.$root.$emit('loadingSpinner', false);
			}
		},
		searched(val) {
			if (!val) {
				return '';
			}
			let plantText = val;
			const textRegExp = new RegExp(this.tableQuery.query, 'gi');
			if (!!this.results && this.results.length > 0 && this.tableQuery.query != '') {
				plantText = val.replace(textRegExp, function (r) {
					return '<span class="scl-expandable-table__table-row--searchmatch">' + r + '</span>';
				});
			}
			return plantText;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
