/**
 * SclLoginForm api
 */

// template
const template = `
	<div class="scl-login-form scl-form">
		<v-form ref="form" class="scl-form">
			<v-container>
				<v-layout row wrap>
					<v-flex class="xs12">
						<p class="font-weight-bold mb-2">Your email</p>
						<v-text-field
						v-model="email"
						class="scl-form__input"
						outline
						:rules="[rules.required, rules.email]"
						required
						type="email"
						validate-on-blur
					></v-text-field>
					</v-flex>
					<v-flex class="xs12">
						<p class="font-weight-bold mb-2">Password</p>
						<v-text-field
						v-model="password"
						class="scl-form__input"
						outline
						:rules="[rules.required]"
						required
						type="password"
						valid-on-blur
						data-vv-name="Password"
					></v-text-field>
					</v-flex>
					<v-flex xs12 clearfix>
						<div class="login-form__check">
							<v-checkbox
								label="Remember me"
								off-icon="fas fa-square"
								on-icon="fas fa-check-square"
								hide-details
							></v-checkbox>
						</div>
						<div>
							<a @click.prevent="clickEvent('reset-password-event')">Forgot Password</a>
						</div>
					</v-flex>
					<v-flex xs12 mt-4>
						<v-btn
							color="accent"
							class="scl-component scl-button"
							depressed
							block
							@click="submit"
						>
						click to login
						</v-btn>
					</v-flex>
					<v-alert type="error" :value="errorMessage" outline>{{ errorMessage }}</v-alert>
				</v-layout>
			</v-container>
		</v-form>
	</div>
`;

// export template
export default template;
