import axios from 'axios';

// component
const component = {
	name: 'SclChangePasswordModal',
	data() {
		return {
			dialog: false,
			valid: true,
			isPersistentModal: false,
			showResultMessage: false,
			resultMessage: '',
			enquiry: {
				username: null,
				currentPassword: '',
				newPassword: null,
				confirmPassword: null
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				min: value => (value !== null && value !== undefined && value.length >= 8) || 'Minimum 8 characters',
				passwordMatch: () => this.enquiry.newPassword === this.enquiry.confirmPassword || 'The password you entered does not match',
				passwordSecurity: value => {
					const pattern = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;
					return pattern.test(value) || 'Invalid password, you must have 1 uppercase, number and a special character.';
				}
			}
		};
	},
	mounted() {
		if (this.propApi.event !== '') {
			this.$root.$on(this.propApi.event, () => {
				this.$refs.changePasswordForm.reset();
				this.dialog = true;
			});
		} else {
			this.dialog = true;
			this.isPersistentModal = true;
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'change-password-event',
					width: '330',
					persistent: false,
					accountData: {},
					modalLabel: 'change password',
					postbackPipe: null,
					postbackURL: null
				},
				this.api
			);
		}
	},
	methods: {
		async submit() {
			if (!this.$refs.changePasswordForm.validate()) {
				return false;
			}

			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				var payload = this.populateChangePasswordPayload();
				var url = new URL(window.location.href);
				if (url.searchParams.get('rptoken')) {
					payload = this.populateResetPasswordPayload();
				}

				const response = await axios.post(this.propApi.postbackURL, payload);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Password successfully changed',
						type: 'success'
					};
					this.handleResult(snackBarMessage);
				} else {
					this.errorMessage = 'Password change request failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.handleResult(snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.handleResult(snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async changePassword(data, url, pipe) {
			if (pipe === 'get') {
				return await axios.get(url, data);
			}

			if (pipe === 'post') {
				return await axios.post(url, data);
			}

			return await axios.post(url, data);
		},
		closeModal() {
			this.dialog = false;
		},
		handleResult(snackBarMessage) {
			if (this.isPersistentModal) {
				this.resultMessage = snackBarMessage.copy;
				this.showResultMessage = true;
			} else {
				this.$root.$emit('snackbar-event', snackBarMessage);
				this.dialog = false;
			}
		},
		populateChangePasswordPayload() {
			const payload = {
				currentPassword: this.enquiry.currentPassword,
				newPassword: this.enquiry.newPassword
			};
			if (this.propApi.accountData) {
				payload.username = this.propApi.accountData.username;
			}
			return payload;
		},
		populateResetPasswordPayload() {
			var url = new URL(window.location.href);
			const payload = {
				Token: url.searchParams.get('rptoken'),
				Password: this.enquiry.newPassword
			};
			return payload;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
