/**
 * SclHeader api
 *
 * @param {boolean} logo - whether the header has a logo or not
 * @param {string} name - this text will be displayed in the toolbar
 */

// template
const template = `
	<scl-header inline-template :api="{ logo: true, name: 'Recursion' }" class="scl-component scl-header">
		<div>
			header
		</div>
	</scl-header>
`;

// export template
export default template;
