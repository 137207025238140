import axios from 'axios';

// component
const component = {
	name: 'SclUserStatusForm',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			accountData: {
				status: '',
				availableStatus: ['on hold', 'active', 'cancelled']
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.accountData = event;
		});
	},
	computed: {
		propApi() {
			return Object.assign({
				event: 'user-status-event'
			});
		}
	},
	methods: {
		cancelChanges() {
			this.clickEvent();
		},
		confirmChanges() {
			this.submit(this.accountData);
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					threshold: this.stepNumber
				};

				const result = await this.getResults(payload, this.propApi.postbackSelectURL);

				if (result.status == 200 && result.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Form submitted successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Form submit failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
