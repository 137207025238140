// component
const component = {
	name: 'SclAccountDetail',
	data() {
		return {
			accountData: []
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? Array.isArray(obj.accountData) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: []
				},
				this.api
			);
		}
	},
	methods: {
		clickEvent(event) {
			this.$root.$emit(event);
		}
	},
	watch: {
		'propApi.accountData': function (newVal) {
			this.accountData = newVal[0];
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
