/**
 * SclManageModal
 */

// template
const template = `
	<div class="scl-manage-modal scl-dialog">
		<div class="text-xs-center">
		<v-dialog v-model="dialog"
			:width="propApi.width"
			:persistent="propApi.persistent"
			content-class="scl-manage-modal__component">
				<template v-slot:activator="{ on }">
					<v-btn color="accent"
							class="scl-component scl-button d-none"
							depressed
							v-on="on">
						account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title" v-if="!accountItem.managedBy">
									Assign Manager
								</legend>
								<legend class="scl-dialog__toolbar-title" v-else>
									Change Manager
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-manage-modal__container pt-0">
						<v-layout row wrap px-2 class="scl-manage-modal__container-layout">
							<v-flex xs12 px-0 pt-0 pb-4 class="underline">
								<p class="mb-4" v-if="!accountItem.managedBy">Select a manager for:</p>
								<p class="mb-4" v-else>Are you sure you want to Change the manager for:</p>
								<div class="ml-4">
									<v-icon size="18" class="pr-3">fas fa-user-alt</v-icon>{{accountItem.email}}
								</div>
							</v-flex>
							<v-flex xs12 pt-3 px-0 class="scl-manage-modal__selection-item">
								<p class="my-0 mr-4" v-if="!accountItem.managedBy">Assign Manager:</p>
								<p class="my-0 mr-4" v-else>Change Manager:</p>

								<v-select v-model="manager"
											:items="managerList"
											class="scl-form__input scl-form__select"
											outline
											@change="selectManager"
											item-text="name"
											item-value="id"
											label="Select manager"
											hide-details
											solo>
								</v-select>
							</v-flex>
							<v-flex xs12 class="px-0" v-if="!accountItem.managedBy">
								<p class="body-1"><strong>Note:</strong> This user's manager could have been deleted by you or a Coates Hire Administrator. Please assign a new manager promptly.</p>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">assign</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
