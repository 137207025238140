import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Frequencies } from '../constants';

Vue.use(VueI18n);

export const en = {
	profile: {
		[Frequencies.ThreeTimesAWeek]: '3x a Week (Mon, Wed & Fri)'
	}
};

export const messages = {
	en
};

const findDeepCaseInsensitive = (lang = {}, path = []) =>
	path.reduce((acc = {}, key) => {
		const item = Object.keys(acc).find(index => index.toLowerCase() === key.toLowerCase());
		return acc[item];
	}, lang);

export const missing = (locale, keypath, vm, options) => {
	const lang = messages[locale] || Object.values(messages)[0];
	const path = keypath.split('.');
	const fallback = options[1];

	// Check messages without worrying about case and if no value use the fallback
	// or last key in the path
	return findDeepCaseInsensitive(lang, path) || fallback || path.pop();
};

export default new VueI18n({
	locale: 'en',
	messages,
	missing
});
