/**
 * SclConfirmationDetail api
 *
 */

// template
const template = `
	<div class="scl-confirmation-detail">
		<v-layout row wrap>
			<v-flex xs12 d-flex class="mb-2">
				<h3 class="title-2 font-weight-bold">Confirm new user</h3>
				<v-spacer></v-spacer>
				<a href="#" class="text-xs-right subheading pr-3" @click.prevent="edit">Edit</a>
			</v-flex>
			<v-flex xs12 class="scl-confirmation-detail__card">
				<v-layout row wrap class="px-3 py-4">
					<v-flex xs12 mb-4>
						<div class="d-inline-flex">
							<v-icon size="18" color="#383838" class="pr-4 d-flex">fas fa-user-alt</v-icon>
							<p class="title-2 font-weight-bold mb-0">{{accountData.title}} {{accountData.firstName}} {{accountData.lastName}}</p>
						</div>
					</v-flex>

					<v-flex xs12 class="mb-3">
						<p class="font-weight-bold subheading mb-2">Capabilities</p>
						<ul class="pl-0 subheading">
							<li class="d-flex" v-if="accountData.coatesHireOwner">
								My CoatesHire Admin
							</li>
							<li class="d-flex" v-if="accountData.coatesHireManager">
								My CoatesHire Manager
							</li>
							<li class="d-flex" v-if="accountData.myHires">
								My Hires&nbsp;
								<div v-if="accountData.myHiresWithRates || accountData.myHiresWithEOHD">
									(<span v-if="accountData.myHiresWithRates">{{accountData.myHiresWithRates}}<span v-if="accountData.myHiresWithRates && accountData.myHiresWithEOHD">,</span></span>
									<span v-if="accountData.myHiresWithEOHD">{{accountData.myHiresWithEOHD}}</span>)
                                </div>
							</li>
							<li class="d-flex" v-if="accountData.myInvoices">
								My Invoices
							</li>
							<li class="d-flex" v-if="accountData.myPricebook">
								My Pricebook&nbsp;
								<div v-if="accountData.myPricebookWithExport">
									<span>({{accountData.myPricebookWithExport}})</span>
								</div>
							</li>
							<li class="d-flex" v-if="accountData.myQuoteRequests">
								My Quote Requests
							</li>
							<li class="d-flex" v-if="accountData.myTelemetry">
								My Telemetry
							</li>
						</ul>
					</v-flex>
					<v-flex xs12 class="mb-4">
						<p class="font-weight-bold subheading mb-2">Site Access</p>
						<ul class="pl-0 subheading" v-for="ma in selectedAccounts" v-if="ma && ma.value">
							<li>
								{{ma.masterAccountName}}
								<v-list>
									<v-list-tile v-for="(site, index) in ma.siteAccounts" :key="index" v-if="site && site.value">
										<p class="mb-0">{{site.siteAccountName}}</p>
									</v-list-tile>
								</v-list>
							</li>
						</ul>
					</v-flex>
					<v-flex xs12>
						<p class="subheading font-italic mb-0">*Invitation <span v-if="accountData.sendInvite === 'no'">not</span> sent on creation</p>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
`;

// export template
export default template;
