// component
const component = {
	name: 'SclSimpleList',
	data() {
		return {
			tableData: {},
			tableTotal: 0,
			pagination: {
				sortBy: null,
				page: 0,
				rowsPerPage: 25,
				total: 0
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.showTotal ? typeof obj.showTotal === 'boolean' : true));
				set.add(!!(obj.showTotalKey ? typeof obj.showTotalKey === 'string' : true));
				set.add(!!(obj.hideFooter ? typeof obj.hideFooter === 'boolean' : true));
				set.add(!!(obj.showTableUpdated ? typeof obj.showTableUpdated === 'boolean' : true));
				set.add(!!(obj.tableUpdated ? typeof obj.tableUpdated === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: null,
					showTotal: false,
					showTotalKey: '',
					hideFooter: true,
					showTableUpdated: false,
					tableUpdated: null,
					tableData: {
						headers: {},
						tableItems: {}
					}
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.tableData': {
			handler(newVal) {
				if (!newVal) {
					return;
				}
				this.tableData = newVal;
				this.calculateTotal();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		calculateTotal() {
			this.tableTotal = 0;
			this.tableData.tableItems.forEach(item => {
				if (item[this.propApi.showTotalKey]) {
					this.tableTotal += item[this.propApi.showTotalKey].val;
				}
			});

			if (this.propApi.isCurrency) {
				this.tableTotal = this.tableTotal.toLocaleString('en-US', {
					style: 'currency',
					currency: 'USD'
				});
			}
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
