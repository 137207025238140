// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclEmailDomainForm',
	mixins: [mxApi],
	data() {
		return {
			headers: [
				{ text: 'Email domain', value: 'emailDomain', align: 'left', sortable: false, width: '82%', class: 'pl-0' },
				{ text: 'Edit', value: 'edit', align: 'center', sortable: false, width: '9%' },
				{ text: 'Remove', value: 'remove', align: 'center', sortable: false, width: '9%' }
			],
			results: []
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.noDataText ? typeof obj.noDataText === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					postbackURL: '',
					postbackPipe: '',
					noDataText: 'No domains added',
					tableData: {
						tableItems: []
					},
					data: []
				},
				this.api
			);
		}
	},
	watch: {
		'propApi.data': function (newVal) {
			this.results = newVal;
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		//update to allow email domains to be deleted
		async submitRemove(item) {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					domain: item.name
				};

				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result.status == 200 && result.success) {
					const snackBarMessage = {
						copy: result.message || 'User new email domain successfully added',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: result.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},

		//update to allow email domains to be deleted
		async submit() {
			if (!this.$refs.form.validate()) {
				return false;
			}

			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					domain: this.accountData.emailDomain
				};

				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result.status == 200 && result.data.success) {
					const snackBarMessage = {
						copy: 'User new email domain successfully added',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Update failed, please try again later.';
				const snackBarMessage = {
					copy: this.errorMessage,
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
