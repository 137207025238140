/**
 * SclAccordion api
 *
 */

// template
const template = `
<div class="scl-site-access-accordion">
	<v-container class="fluid pa-0">
		<v-layout row wrap>
			<v-flex xs6 class="align-center d-flex mb3" v-if="headerLabel">
				<p class="subheading mb-0 font-weight-bold">Site access</p>
			</v-flex>
			<v-flex xs6 class="text-xs-right" v-if="btnEvent">
				<v-btn class="scl-component scl-button scl-button--outline" outline flat @click.prevent="clickEvent(btnEvent, ['emitData', 'site-access-accordion'])">Manage site access</v-btn>
			</v-flex>
			<v-flex xs12>
				<div v-if="!siteAccessAssigned">
					<p class="mb-0">User Access To Sites Not Yet Set</p>
				</div>
				<v-expansion-panel class="scl-site-access-accordion__expansion-panel" v-if="siteAccessAssigned">
					<v-expansion-panel-content lazy :expand-icon="propApi.icon" v-for="(ma, index) in siteAccess" :key="index" v-if="ma.value">
						<template v-slot:header>
							<div>
								<p>{{ma.masterAccountName}}</p>
								<p class="mb-0"><span class="font-weight-regular">Customer code:</span> {{ma.masterAccountCode}}</p>
							</div>
						</template>

						<v-divider></v-divider>

						<v-card>
							<v-list>
								<v-list-tile v-for="(site, index) in ma.siteAccounts" :key="index" v-if="site.value">
									<p class="mb-0">{{site.siteAccountName}}</p>
								</v-list-tile>
							</v-list>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-flex>
		</v-layout>
	</v-container>
</div>
`;

// export template
export default template;
