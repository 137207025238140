// Mixins
import mxDate from '@portals/mixins/common/mxDate';
import mxApi from '@portals/mixins/common/mxApi';

// component
const component = {
	name: 'SclLoginMessageModal',
	mixins: [mxDate, mxApi],
	data() {
		return {
			dialog: false,
			toggle: false,
			modalData: {
				id: 0,
				state: false,
				dateFrom: '',
				dateTo: '',
				title: '',
				message: ''
			},
			dateFromMenu: false,
			dateToMenu: false,
			dateFrom: new Date().toISOString().substr(0, 10),
			dateTo: new Date().toISOString().substr(0, 10),
			dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10))
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
		if (this.api.modalData) {
			this.modalData = this.api.modalData;
		}

		if (this.modalData.dateFrom) {
			this.dateFromFormatted = this.modalData.dateFrom;
		}
		if (this.modalData.dateTo) {
			this.dateToFormatted = this.modalData.dateTo;
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.messaging ? typeof obj.messaging === 'string' : true));
				set.add(!!(obj.modalData ? Array.isArray(obj.modalData) : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'login-message-event',
					width: '590',
					persistent: true,
					modalLabel: '',
					modalData: {
						state: false,
						dateFrom: '',
						dateTo: '',
						title: '',
						message: ''
					},
					postbackPipe: 'post',
					postbackURL: null
				},
				this.api
			);
		}
	},
	watch: {
		dateFrom(date) {
			this.dateFromFormatted = this.formatDate(date);
		},
		dateTo(date) {
			this.dateToFormatted = this.formatDate(date);
		}
		/*
		'propApi.modalData': function(newVal) {
			this.modalData = newVal[0];
			if (newVal[0].dateFrom) {
				this.dateFrom = newVal[0].dateFrom;
			}
			if (newVal[0].dateTo) {
				this.dateTo = newVal[0].dateTo;
			}
		}*/
	},
	methods: {
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},
		parseDate(date) {
			const parsedDate = this._mxDate_parseDate(date);
			return parsedDate;
		},
		closeModal() {
			this.dialog = false;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit() {
			this.errorMessage = null;
			this.modalData.dateFrom = this.dateFrom;
			this.modalData.dateTo = this.dateTo;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					modalData: this.modalData
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Successfully updated the login message',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					this.dialog = false;

					//location.reload();
				} else {
					this.errorMessage = 'Update login message failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
