<template>
	<div class="date-selector">
		<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			:nudge-right="40"
			:disabled="disableUi"
			:readonly="readonly"
			lazy
			transition="scale-transition"
			offset-y
			full-width
			max-width="290px"
			min-width="290px"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					ref="dateSelector"
					v-model="userDate"
					:disabled="disableUi"
					:readonly="readonly"
					:rules="[...rules, () => !validDate || 'Please enter a valid date.', () => !showValidation || 'Please enter a valid date range.']"
					data-cy="date selector"
					class="scl-form__input"
					outline
					:label="label"
					hint="dd/mm/yyyy"
					@change="applyManualDate"
					@blur="applyManualDate"
					v-on="on"
					@click:append="menu = true"
					:append-icon="'far fa-calendar'"
				>
				</v-text-field>
			</template>
			<v-date-picker v-model="isoDate" no-title class="date-selector__picker" :min="min" :max="max" @input="onDateRangeChange"></v-date-picker>
		</v-menu>
	</div>
</template>

<script>
// Mixins
import mxForm from '../../mixins/components/mxForm.ts';
import moment from 'moment';

const dateFormat = new Intl.DateTimeFormat('en-GB');

export default {
	name: 'SclDateSelector',
	mixins: [mxForm],
	props: {
		// indicates if it's a secondary date picker within a parent component
		isSecondary: {
			type: Boolean
		},
		dateInputTitle: {
			type: String
		},
		dataInputRequired: {
			type: String
		},
		disableUi: {
			type: Boolean
		},
		readonly: {
			type: Boolean
		},
		startingDate: {
			type: String
		},
		validForm: {
			type: Boolean
		},
		minDate: {
			type: Date
		},
		maxDate: {
			type: Date
		},
		rules: {
			type: Array,
			default: () => []
		},
		label: {
			type: String
		}
	},
	data() {
		return {
			isoDate: this.startingDate,
			dateFormatted: this._mxForm_formatDate(new Date().toISOString().substr(0, 10)),
			userDate: this._mxForm_formatDate(this.startingDate),
			menu: false,
			showValidation: false,
			validDate: false
		};
	},
	computed: {
		min() {
			return this.minDate ? this.minDate.toISOString().substr(0, 10) : null;
		},
		max() {
			return this.maxDate ? this.maxDate.toISOString().substr(0, 10) : null;
		}
	},
	watch: {
		isoDate() {
			this.userDate = this._mxForm_formatDate(this.isoDate);
			// this.emitDate(); // this is commented because besides of value of isoDate change, there could be a lot other related changes need to be done before trigger the event. logic in applyManualDate shows this kind of possibility. with this line, the change event will be fired twice everytime when date selected.
		},
		validForm(isValid) {
			// revalidate the field
			this.showValidation = !isValid;
			this.$refs.dateSelector.validate();
		},
		userDate() {
			this.$refs.dateSelector.validate();
		}
	},
	mounted() {
		this.emitDate();
		console.log(this.min, this.max, this.minDate, this.maxDate);
	},
	methods: {
		onDateRangeChange() {
			this.menu = false;
			this.showValidation = false;
			this.validDate = false;
			this.emitDate();
		},
		isValidate(date, dateObject) {
			if (date < this.min || date > this.max || Object.prototype.toString.call(dateObject) !== '[object Date]') {
				this.showValidation = true;
			} else {
				this.showValidation = false;
			}
			this.$refs.dateSelector.validate();
		},
		applyManualDate() {
			if (this.userDate !== null) {
				const isoDateObject = this._mxForm_parseManualDateInput(this.userDate);
				if (moment(isoDateObject, 'YYYY-MM-DD').format('DD/MM/YYYY') === 'Invalid date') {
					this.validDate = true;
					this.isValidate(this.isoDate, isoDateObject);
				} else if (isoDateObject === null) {
					this.validDate = true;
					this.isValidate(this.isoDate, isoDateObject);
					this.$emit('update-date', this.isSecondary, null);
				} else {
					this.userDate = dateFormat.format(isoDateObject);
					this.isoDate = isoDateObject.toISOString().substr(0, 10);
					this.validDate = false;
					this.isValidate(this.isoDate, isoDateObject);
					this.emitDate();
				}
			}
		},
		emitDate() {
			this.$emit('update-date', this.isSecondary, this.isoDate);
		}
	}
};
</script>
