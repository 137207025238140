/**
 * SclPageTabs api
 *
 */

// template
const template = `
<slot />
`;

// export template
export default template;
