/**
 * This mixin contains the functionality required for Form components
 */
const mxForm = {
	methods: {
		_mxForm_formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		_mxForm_formatDateForApi(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}${month}${year}`;
		},
		_mxForm_parseDate(date) {
			if (!date) return null;

			const [day, month, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		_mxForm_formatStringDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}${month}${year}`;
		},
		_mxForm_parseManualDateInput(dateString) {
			let formattedDate = null;
			const dateParts = dateString.split('/');
			if (dateParts.length !== 3) {
				return formattedDate;
			}
			const isoFormattedDateParts = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
			formattedDate = new Date(isoFormattedDateParts);
			return formattedDate;
		},
		_mxForm_formatDateTime(dateTimeString) {
			if (!dateTimeString) return null;
			let formattedDate = null;
			const dateTimeParts = dateTimeString.split('T');
			const datePart = dateTimeParts[0];
			const timePart = dateTimeParts[1];
			const formattedDatePart = this._mxForm_formatDate(datePart);
			const formattedTimePart = this._mxForm_formatStringTime(timePart);
			formattedDate = `${formattedDatePart} ${formattedTimePart}`;
			return formattedDate;
		},
		_mxForm_formatStringTime(time) {
			if (!time) return null;
			const [hours, minutes] = time.split(':');
			return `${hours}:${minutes}`;
		},
		_mxForm_getDateInDDMMYYY() {
			const d = new Date();
			const formattedDate = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
			const formattedTime = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
			const formattedDateTime = `${formattedDate} ${formattedTime}`;
			return formattedDateTime;
		},
		_mxForm_getDateWithoutTime(dateTimeString) {
			if (!dateTimeString) return null;
			const dateTimeParts = dateTimeString.split('T');
			const datePart = dateTimeParts[0];
			return datePart;
		},
		_mxForm_parseDateTime(dateTimeString) {
			return Date.parse(dateTimeString);
		}
	}
};

export default mxForm;
