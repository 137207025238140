/**
 * SclSelectContactModal
 */

// template
const template = `
	<div class="scl-select-contact-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-select-contact-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					select contact
					</v-btn>
				</template>
				<v-card class="fill-height">
				<v-toolbar class="scl-dialog__toolbar" flat>
					<v-layout row>
						<v-card-title class="pa-0" primary-title>
							<legend class="scl-dialog__toolbar-title">
								{{ propApi.modalLabel }}
							</legend>
						</v-card-title>
						<v-btn icon dark @click="closeModal">
							<v-icon>fas fa-times</v-icon>
						</v-btn>
					</v-layout>
				</v-toolbar>
				<v-form class="scl-form ">
					<v-container grid-list-xl class="scl-add-customer-modal__container pt-0">
						<v-layout row wrap fill-height>
							<v-flex xs12 md9 pt-0 px-3 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}" class="scl-add-customer-modal__search">
								<v-layout row wrap>
									<v-flex xs8>
										<v-text-field v-model="query"
														class="scl-form__input"
														outline
														label="Search"
														append-icon="fas fa-search"
														@click:append="searchSubmit"
														@keydown.enter="searchSubmit"
														hide-details></v-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
						<v-layout row wrap fill-height px-3>
							<v-flex xs12>
								<v-data-table :headers="propApi.customerData.headers"
										:items="results"
										item-key="guid"
										:rows-per-page-items="[10,20,30]"
										:loading="loading"
										@update:pagination="updatePagination"
										:total-items="pagination.total"
										:pagination.sync="pagination"
										v-model="selected">
									<template v-slot:items="props">
										<tr>
											<td class="body-1 pl-0" v-if="!props.item.disabled">
												<a :href="propApi.redirectURL+'?contact='+props.item.guid" disabled="props.item.disabled" class="grey-link">{{props.item.firstname}}</a>
											</td>
											<td v-else class="body-1 pl-0" v-html="searched(props.item.firstname)"></td>
											<td class="text-xs-left body-1" v-html="searched(props.item.lastname)"></td>
											<td class="text-xs-left body-1" v-html="searched(props.item.email)"></td>
											<td class="text-xs-left body-1" v-html="searched(props.item.mobile)"></td>
											<td class="text-xs-left body-1" v-html="searched(props.item.managedBy)"></td>
											<td class="text-xs-left body-1" v-html="searched(props.item.status)"></td>
										</tr>
										<!-- Update the above content with below TR click
										<tr class="subheading" @click="selectRow('add-user-to-form-event', props.item)" :disabled="props.item.disabled">
											<td class="subheading pl-0" v-html="searched(props.item.firstname)"></td>
											<td class="pl-0 subheading" v-html="searched(props.item.lastname)"></td>
											<td class="pl-0 subheading" v-html="searched(props.item.mobileNumber)"></td>
											<td class="pl-0 subheading" v-html="searched(props.item.managed)"></td>
											<td class="pl-0 subheading" v-html="searched(props.item.status)"></td>
										</tr>
										-->
									</template>
								</v-data-table>
							</v-flex>
							<v-flex xs12 class="scl-select-contact-modal__action">
								<p class="subheading">Contact doesn't exist?</p>
								<a :href="propApi.redirectURL" class="text-uppercase title text--primary font-weight-bold">create new contact</a>
							</v-flex>
						</v-layout>
					</v-container>
				</v-form>
			</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
