// component
const component = {
	name: 'SclTopNav',
	data() {
		return {};
	},
	template: null,
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					image: '/assets/img/logo-coates-hire-white.svg',
					accountEmail: 'test@email.com',
					manageLinks: [
						{
							text: 'My Subscriptions',
							url: 'http://www.coateshire.com.au'
						},
						{
							text: 'My Notifications',
							url: 'http://www.coateshire.com.au'
						}
					],
					accountLinks: [
						{
							text: 'CH Contact Details',
							url: 'http://www.coateshire.com.au'
						}
					]
				},
				this.api
			);
		},
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.name}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	watch: {
		search() {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			window
				.fetch('./assets/data/search.json')
				.then(res => res.json())
				.then(res => {
					this.entries = res;
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => (this.isLoading = false));
		}
	}
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

export default component;
