// component
const component = {
	name: 'SclDatePickerModal',
	data() {
		return {
			dialog: false,
			valid: true,
			hireItems: {
				date: new Date().toISOString().substr(0, 10),
				hires: []
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			this.hireItems.hires = [];
			if (event) {
				this.hireItems.hires.push(event);
				this.hireItems.date = event.offhiredate;
			}
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'date-picker-event',
					width: '335',
					persistent: false,
					modalLabel: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		clickEvent(event) {
			this.$root.$emit(event, this.hireItems);
			this.dialog = false;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
