<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:loading="loading"
		:pagination.sync="pagination"
		:total-items="totalItems"
		:rows-per-page-items="rowsPerPageList"
	>
		<v-progress-linear color="primary" indeterminate></v-progress-linear>
		<template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
			<slot :name="name" v-bind="slotData" />
		</template>
	</v-data-table>
</template>
<script>
import uniq from 'lodash/uniq';
const toNumber = (str = '', fallback = 0) => {
	const num = parseInt(str);
	if (typeof num === 'number' && !Number.isNaN(num)) {
		return num;
	}
	return fallback;
};

const maxMin = (max, min = 0) => {
	return value => Math.min(Math.max(value, min), max) ?? min;
};

const SclServerPaginatedTable = {
	name: 'SclServerPaginatedTable',
	data() {
		const { totalItems } = this;
		return {
			initialised: false,
			loading: false,
			pagination: {
				descending: false,
				sortBy: null,
				totalItems,
				page: 1,
				rowsPerPage: maxMin(totalItems)(25)
			}
		};
	},
	props: {
		headers: {
			default: () => []
		},
		items: {
			default: () => []
		},
		rowsPerPage: {
			default: 10
		},
		rowsPerPageItems: {
			default: () => [10, 25, 50]
		},
		totalItems: {
			type: Number,
			default: 0
		}
	},
	created() {
		const q = new URLSearchParams(window.location.search);
		// Server pagination starts from 0, not 1
		this.pagination.page = toNumber(q.get('page'), 0) + 1;
		const rowsPerPage = toNumber(q.get('rowsPerPage'), this.rowsPerPage);
		this.pagination.rowsPerPage = maxMin(this.totalItems)(rowsPerPage);
	},
	watch: {
		pagination: {
			handler({ page, rowsPerPage }) {
				// We skip the first update from the data-table as it is set by the component
				if (!this.initialised) {
					this.initialised = true;
				} else {
					const queryString = new URLSearchParams(window.location.search);
					queryString.set('page', page - 1);
					queryString.set('rowsPerPage', rowsPerPage);
					window.location.search = queryString.toString();
				}
			},
			deep: true
		}
	},
	computed: {
		rowsPerPageList() {
			return uniq([
				...this.rowsPerPageItems.filter(v => v < this.totalItems),
				{
					text: '$vuetify.dataIterator.rowsPerPageAll',
					value: this.totalItems
				}
			]);
		}
	}
};
export default SclServerPaginatedTable;
</script>
