// css import
require('./_scl-bottom-sheet.scss');

// component
const component = {
	name: 'SclBottomSheet',
	data() {
		return {};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.inset ? typeof obj.inset === 'boolean' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.value ? typeof obj.value === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					inset: false,
					persistent: false,
					value: false
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// export component
export default component;
