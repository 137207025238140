// template
const template = `

	<div>
		<scl-page-header-new
			:api="propApi"
			:isReadOnly="isReadOnly"
			:show-error="showError"
			:masterAccountSection="false"
			class="scl-new-header"
			>
			<template v-slot:routebtn>
				<div class="mb-2 scl-route-button--orange">
					<scl-route-button data-cy="route-button" class="mb-1" :api="{ href: backBtn.url, label: backBtn.text }"></scl-route-button>
				</div>
			</template>
		</scl-page-header-new>
        <div ref="quote" class="scl-quote-confirmation__content">
			<a id="openModal">Contact Us</a>
			Quote request details for: QR[QuoteNumber]
		</div>
		<scl-quote-contact-modal
			class="scl-quote-contact-modal scl-component"
			:api="{
				title: propApi.contactTitle,
				subHeader: propApi.contactIntro,
				quoteId: quoteId,
				firstName: propApi.firstName,
				lastName: propApi.lastName,
				state: propApi.state
			}"
		></scl-quote-contact-modal>
	</div>
`;

// export template
export default template;
