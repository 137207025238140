/**
 * SclCard api
 *
 * displayAccordion prop
 * @param {boolean} true | false
 *
 */

// template
const template = `
	<div>
		<v-card class="scl-card__card">
			<a href="#">
				<v-img class="scl-card__img" src="https://www.coateshire.com.au/getmedia/18dccf4e-fc1d-47d7-a3c8-f549f9678d85/scissorliftelectric.jpg?width=350&height=350" height="350" width="100%"></v-img>
			</a>
			<v-card-title class="scl-card__title-container">
				<p v-if="propApi.description">Scissor Lift - Electric - 5.6M (18.5ft)</p>
				<a class="scl-card__title-anchor" href="#">
					<h4 class="scl-card__title" :class="{ 'with-description' : propApi.description }">hire now</h4>
				</a>
			</v-card-title>
			<v-card-actions v-if="propApi.displayAccordion" class="scl-card__actions">
				<v-expansion-panel v-model="panel" class="scl-card__accordion">
					<v-expansion-panel-content
					:hide-actions="true"
					class="scl-card__accordion-container">
						<template v-slot:header>
							{{ panelContentText }}
						</template>
						<v-card class="scl-card__accordion-item">
							<v-card-text class="scl-card__accordion-item-container">
								<a class="scl-card__accordion-anchor" href="#"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></a>
							</v-card-text>
						</v-card>
						<v-card class="scl-card__accordion-item">
							<v-card-text class="scl-card__accordion-item-container">
								<a class="scl-card__accordion-anchor" href="#"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></a>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-card-actions>
		</v-card>
	</div>
`;

// export template
export default template;
