/**
 * SclProductSummary api
 */

// template
const template = `
	<div class="scl-account-detail scl-form">
		<v-container grid-list-xl fluid>
			<v-layout row wrap class="scl-account-detail__layout underline mb-3 pb-2 pl-2">
				<v-flex xs12 class="scl-account-detail__layout-inner pa-0 mb-4">
					<p class="font-weight-bold caption mb-4">personal information</p>
					<div class="mb-1 ml-2">
						<span class="title-2 font-weight-bold text-capitalize pr-2">{{accountData.title}} {{accountData.firstName}} {{accountData.lastName}}</span>
						<span class="subheading">- {{accountData.role}}</span>
					</div>
					<div class="mb-1 ml-2">
						<span class="title-2 font-weight-bold pr-2">{{accountData.email}}</span>
					</div>
					<div class="ml-2">
						<a href="#" @click.prevent="clickEvent('change-password-event')">Change Password</a>
						<a href="#" @click.prevent="clickEvent('reset-password-event')">Reset Password</a>
					</div>
				</v-flex>
			</v-layout>
			<v-layout row wrap class="scl-account-detail__layout underline mb-3 pb-3 pl-2">
				<v-flex xs12 class="scl-account-detail__layout-inner pa-0">
					<p class="font-weight-bold caption">contact information</p>
				</v-flex>
				<v-flex xs12 v-for="(contact, index) in accountData.contact" :key="index" :class="{'md12': index === 3, 'md3': index < 3}">
					<div class="mb-1">{{contact.type}}</div>
					<span class="font-weight-bold subheading">{{contact.value}}</span>
				</v-flex>
			</v-layout>
			<v-layout row wrap class="scl-account-detail__layout underline mb-3 pb-2 pl-2">
				<v-flex xs8 class="scl-account-detail__layout-inner pa-0">
					<p class="font-weight-bold caption">access information</p>
				</v-flex>
				<v-flex xs4 class="text-xs-right">
					<scl-button
						color="accent"
						class="scl-component scl-button"
						depressed :api="{modal: 'user-access-event', copy: 'request access'}"
					></scl-button>
				</v-flex>
				<v-flex xs12 md3 class="pt-1" v-for="(access, index) in accountData.access" :key="index">
					<p class="mb-1">{{access.type}}</p>
					<div v-for="(specification, index) in access.value" :key="index">
						<span class="mb-1 font-weight-bold subheading">{{specification.name}}</span>
						<span>{{specification.conditions}}</span>
						<div v-show="specification.linkName">
							<a @click.prevent="clickEvent('account-access-event')" :href="specification.link">{{specification.linkName}}</a>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
