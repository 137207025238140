/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-admin-table scl-form">
		<v-container class="scl-admin-table__container fluid">
			<v-layout row wrap fill-height>
				<v-flex shrink>
					<v-checkbox class="scl-admin-table__checkbox mt-0 pt-0" :class="{'scl-admin-table__checkbox--indeterminate': indeterminate}" hide-details value="all" @click.prevent="toggleRowSelect" :ripple="false" :indeterminate="indeterminate"></v-checkbox>
				</v-flex>
				<v-flex class="scl-admin-table__selection-cta">
					<div class="scl-admin-table__selection-cta-divider"></div>
					<v-btn flat v-if="propApi.tableData.userType === 'internal'" :disabled="selected.length === 0" @click="clickEvent('send-invitation-event', selected);" class="px-0 text-capitalize">Send invitations</v-btn>
					<v-btn flat v-if="propApi.tableData.userType === 'external'" :disabled="selected.length === 0" @click="clickEvent('expected-offhire-event', selected);" class="px-0 text-capitalize">Edit exp. off hire date</v-btn>
				</v-flex>
				<v-flex class="xs12">
					<v-data-table
						v-model="selected"
						:headers="tableData.headers"
						:loading="loading"
						:items="tableData.tableItems"
						:pagination.sync="pagination"
						:rows-per-page-items="[5,10,25]"
						@update:pagination="updatePagination"
						:search="tableQuery.query"
						:total-items="pagination.total"
						item-key="email">
						<template v-slot:items="props">
							<tr>
								<td class="text-xs-left px-0 align-center scl-admin-table__zindex hidden-sm-and-down">
									<v-checkbox :disabled="props.item.disabled" class="scl-admin-table__checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
								</td>
								<td class="text-xs-center px-0">
									<a href="#" @click.prevent="clickEvent('user-details-event', props.item)" class="text--primary" v-html="searched(props.item.firstName, props.item)"></a>
								</td>
								<td class="text-xs-center px-0">
									<a href="#" @click.prevent="clickEvent('user-details-event', props.item)" class="text--primary" v-html="searched(props.item.lastName, props.item)"></a>
								</td>
								<td class="text-xs-center px-0" v-html="searched(props.item.email, props.item)"></td>
								<td class="text-xs-center px-0">
									<div v-if="props.item.managed">
										<a href="#" @click.prevent="clickEvent('manage-event', props.item)" class="grey-link" v-html="searched(props.item.managed, props.item)"></a>
									</div>
									<div v-else>
										<a href="#" @click.prevent="clickEvent('manage-event', props.item)">Assign Manager</a>
									</div>
								</td>
								<td class="text-xs-center px-0" v-html="searched(props.item.capabilities, props.item)"></td>
								<td class="text-xs-center px-0">
									<div v-if="props.item.status.toLowerCase() === 'active'">
										<p class="mb-0">{{props.item.status}}</p>
									</div>
									<div v-if="props.item.status.toLowerCase() === 'invite expired'" class="my-1">
										<p class="mb-1">{{props.item.status}}</p>
										<a href="#" @click.prevent="clickEvent('resend-email-event', props.item)" class="mb-1">Resend Email</a>
									</div>
								</td>
								<td class="text-xs-center px-0" v-html="searched(props.item.lastLogin, props.item)"></td>
								<td class="hidden-md-and-up">
									<v-checkbox class="scl-expandable-table__checkbox" hide-details v-model="props.selected" :ripple="false"></v-checkbox>
								</td>
							</tr>
						</template>
						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
