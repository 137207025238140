/**
 * SclAccessRequestTable
 */

// template
const template = `
	<div class="scl-access-request-table scl-form">
		<v-container class="scl-access-request-table__container fluid">
			<v-layout row wrap fill-height>
				<v-flex class="xs12">
					<v-data-table
						v-model="selected"
						:headers="headers"
						:items="results"
						:pagination.sync="pagination"
						item-key="email">

						<template v-slot:no-data>
							<td colspan="5" class="scl-access-request-table__empty-row text-xs-center pt-3">No pending requests.</td>
						</template>

						<template v-slot:items="props">
							<tr>
								<td class="pr-0">{{props.item.surname}}</td>
								<td class="pr-0">{{props.item.firstName}}</td>
								<td class="pr-0">{{props.item.email}}</td>
								<td class="pr-0 py-2">
									<ul class="pl-0 scl-access-request-table__list">
										<li v-for="capabilityItem in props.item.capability" @click="clickEvent('user-access-event')"><a href="javascript:void(0)">{{capabilityItem.name}}</a></li>
									</ul>
								</td>
								<td><a href="#" @click.prevent="clickEvent('role-access-event', props.item)">{{props.item.request}}</a></td>
							</tr>
						</template>
						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
