// component
const component = {
	name: 'SclSideNav',
	data() {
		return {
			mini: true,
			currentPage: ''
		};
	},
	template: null,
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'My account',
					overviewLinks: [
						{
							text: 'Dashboard',
							url: 'http://dev-my.coateshire.com.au/dashboard/reporting#1134',
							icon: 'fas fa-chart-bar'
						},
						{
							text: 'my hires',
							url: 'http://dev-my.coateshire.com.au/hires',
							icon: 'fas fa-list-alt'
						},
						{
							text: 'my invoice',
							url: 'http://dev-my.coateshire.com.au/invoice',
							icon: 'fas fa-file-invoice-dollar'
						}
					],
					accountLinks: {
						name: 'my account',
						links: [
							{
								text: 'My Subscriptions',
								url: 'https://dev-my.coateshire.com.au/subs/subscriptions/test/user-account',
								icon: 'fas fa-bell'
							},
							{
								text: 'My Notifications',
								url: '/notification/account',
								icon: 'fas fa-chart-bar'
							}
						]
					},
					footerLinks: [
						{
							text: 'Send feedback',
							url: '/feedback/contact',
							icon: 'fas fa-info-circle'
						},
						{
							text: 'feedback 2',
							url: '/feedback2',
							icon: 'fas fa-info-circle'
						}
					]
				},
				this.api
			);
		},
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.name}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	mounted() {
		this.currentPage = this.retrievePageName(window.location);
	},
	watch: {
		search() {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			fetch('./assets/data/search.json')
				.then(res => res.json())
				.then(res => {
					this.entries = res;
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => (this.isLoading = false));
		}
	},
	methods: {
		retrievePageName(path) {
			const parser = document.createElement('a');
			parser.href = path;

			const pageName = parser.pathname.split('/')[1].toLowerCase();
			return pageName;
		}
	}
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

export default component;
