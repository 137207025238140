// component
const component = {
	name: 'SclConfirmationModal',
	data() {
		return {
			dialog: false,
			valid: true,
			modalData: {}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			this.modalData = event;
		});
	},
	watch: {
		propApi: {
			handler(newVal) {
				this.dialog = newVal.dialog;
			},
			immediate: true,
			deep: true
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.dialog ? typeof obj.dialog === 'boolean' : true));
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.messaging ? typeof obj.messaging === 'string' : true));
				set.add(!!(obj.footnote ? typeof obj.footnote === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'confirmation-event',
					width: '590',
					persistent: false,
					modalLabel: '',
					messaging: '',
					footnote: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		nextStep() {
			this.dialog = false;
			this.clickEvent('expected-offhire-event', this.modalData);
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
