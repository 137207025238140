// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';
import mxDate from '@portals/mixins/common/mxDate.js';

// component
const component = {
	name: 'SclUpdateHireModal',
	mixins: [mxDate, mxApi],
	data() {
		return {
			dialog: false,
			valid: true,
			hireItems: {}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, data => {
			this.dialog = true;
			this.hireItems = data === null ? {} : data;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'update-hire-event',
					width: '420',
					persistent: false,
					modalLabel: '',
					postbackURL: '',
					postbackPipe: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		clickEvent(event, message) {
			this.$root.$emit(event, message);
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const self = this;
				const orders = this.hireItems.hires.map(x => {
					return {
						orderLineId: x.orderLineId,
						orderLineNo: x.orderLineNo,
						hireSchedule: x.hireSchedule,
						offHireDate: self.hireItems.date
					};
				});

				const payload = {
					orderUpdates: orders,
					offHireDate: this.hireItems.date
				};
				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					this.items = response.data.result;
					const snackBarMessage = {
						copy: response.data.message || 'You have successfully updated ' + this.hireItems.hires.length + ' transaction item(s)!',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					this.dialog = false;
					location.reload();
				} else {
					this.errorMessage = 'Your request to update failed!';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
