<template>
	<v-container fluid>
		<v-layout row wrap class="mb-2">
			<v-flex xs12 md7 order-xs1 mb-2>
				<slot name="routebtn"></slot>
				<h1 class="scl-new-header__title">
					{{ propApi.title }}
					<span v-if="isReadOnly"> QR{{ propApi.quoteId }}</span>
					<slot name="titleSection"></slot>
				</h1>
			</v-flex>
			<v-flex xs12 md5 order-xs3 order-md2 text-xs-right class="scl-new-header__icon-group">
				<slot name="iconGroup"></slot>
				<v-btn :href="getHelpUrl" flat icon>
					<v-icon class="scl-new-header__icon scl-new-header__icon--help" color="accent">far fa-question</v-icon>
				</v-btn>
			</v-flex>
		</v-layout>
		<v-layout row wrap class="mb-2 scl-quotes-header__subtitle" v-if="getHeaderText">
			<v-flex xs12>
				<p class="mb-4" v-html="getHeaderText"></p>
			</v-flex>
		</v-layout>

		<v-layout mb-2 row wrap v-if="masterAccountSection">
			<v-flex shrink class="mr-3">
				<p class="mb-2">Master Account <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></p>
				<v-text-field
					v-if="isReadOnly"
					v-model="masterAccountName"
					class="scl-new-header__title-select scl-new-header__title-select--wide"
					aria-label="master account readonly"
					data-cy="master account"
					outline
					:disabled="isReadOnly"
					:readonly="isReadOnly"
				></v-text-field>
				<v-select
					v-else
					v-model="selectedMasterAccount"
					@change="selectMasterAccount"
					item-text="masterAccountName"
					:items="propApi.accountItems"
					return-object
					label="Select Master Account"
					outline
					hide-details
					solo
					flat
					:append-icon="'far fa-chevron-down'"
					class="scl-new-header__title-select scl-new-header__title-select--wide text-capitalize"
				>
				</v-select>
				<div class="v-text-field__details mt-2" v-if="showError && !selectedMasterAccount.masterAccountId">
					<div class="v-messages theme--light error--text">
						<div class="v-messages__wrapper"><div class="v-messages__message" data-cy="master account error">Required</div></div>
					</div>
				</div>
			</v-flex>
			<v-flex shrink class="mr-3">
				<p class="mb-2">Site Account <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></p>
				<v-text-field
					v-if="isReadOnly"
					v-model="selectedSiteAccount.siteAccountName"
					class="scl-new-header__title-select scl-new-header__title-select--wide"
					aria-label="Site account readonly"
					data-cy="Site account"
					outline
					:disabled="isReadOnly"
					:readonly="isReadOnly"
				></v-text-field>
				<v-menu v-else :disabled="hasValue(selectedMasterAccount)" :close-on-content-click="false" ref="siteAccountMenu" nudge-top="8">
					<template v-slot:activator="scope">
						<div
							v-on="scope.on"
							:class="siteAccountMenuActive ? 'scl-new-header__title-input--active' : ''"
							class="scl-new-header__title-input scl-new-header__title-select scl-new-header__title-select--wide text-capitalize"
						>
							<span>
								<label v-if="hasValue(selectedSiteAccount)" class="v-label theme--light">Select Site Account</label>
								<label v-else class="scl-new-header__title-input--label">{{ selectedSiteAccount.siteAccountName }}</label>
							</span>
							<v-icon color="#ff4600"> {{ siteAccountMenuActive ? 'far fa-times' : 'far fa-chevron-down' }} </v-icon>
						</div>
					</template>
					<div class="scl-new-header__no-site-available" :class="filteredSitesClass">
						<v-list>
							<v-text-field
								v-model="searchTerm"
								placeholder="Search"
								@input="filterSites"
								data-cy="Site account Search"
								outline
								class="scl-form__input scl-new-header__no-site-available__search"
								append-icon="far fa-search"
								hide-details
							></v-text-field>

							<v-list-tile v-for="(item, index) in filteredSites" :key="index" @click="selectSiteAccount(item)" data-cy="Site account">
								<v-list-tile-title>{{ item.siteAccountName }}</v-list-tile-title>
							</v-list-tile>
						</v-list>
						<div class="scl-new-header__no-site-available__item" @click="selectSiteAccount('unknown')">
							<v-icon color="#ff4600" left>fal fa-map-marker-question</v-icon>
							<div>
								<small>If you don't know which site to choose, select:</small><br />
								<span>Unknown</span>
							</div>
						</div>
					</div>
				</v-menu>
				<div class="v-text-field__details mt-2" v-if="showError && !selectedSiteAccount.siteAccountId">
					<div class="v-messages theme--light error--text">
						<div class="v-messages__wrapper"><div class="v-messages__message" data-cy="Site account error">Required</div></div>
					</div>
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
// component
const SclPageHeaderNew = {
	name: 'SclPageHeaderNew',
	data() {
		return {
			contactModal: false,
			drawer: false,
			selectedMasterAccount: {},
			selectedSiteAccount: {},
			query: {},
			masterAccountName: '',
			rules: {
				required: value => !!value || 'Required'
			},
			filteredSites: [],
			searchTerm: ''
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.helpPageUrl ? typeof obj.helpPageUrl === 'string' : true));
				set.add(!!(obj.formHeaderText ? typeof obj.formHeaderText === 'string' : true));
				set.add(!!(obj.site ? typeof obj.site === 'string' : true));
				set.add(!!(obj.account ? typeof obj.account === 'string' : true));
				set.add(!!(obj.siteAccount ? typeof obj.siteAccount === 'object' : true));
				set.add(!!(obj.masterAccount ? typeof obj.masterAccount === 'object' : true));
				set.add(!!(obj.pageHeader ? typeof obj.pageHeader === 'object' : true));
				set.add(!!(obj.contactTitle ? typeof obj.title === 'string' : true));
				set.add(!!(obj.contactIntro ? typeof obj.title === 'string' : true));

				return !set.has(false);
			}
		},
		showError: {
			type: Boolean
		},
		backBtn: {
			type: Object
		},
		masterAccountSection: {
			type: Boolean
		},
		isReadOnly: {
			type: Boolean
		},
		isDuplicate: {
			type: Boolean
		}
	},
	mounted() {
		if (this.propApi.masterAccount) {
			this.selectedMasterAccount = this.propApi.masterAccount;
			this.masterAccountName = this.propApi.masterAccount.masterAccountName;
		}
		if (this.propApi.siteAccount) {
			if (this.isDuplicate || this.isReadOnly) {
				if (this.propApi.siteAccount.length === 0) {
					this.selectedSiteAccount = {
						siteAccountName: 'Unknown',
						siteAccountId: null
					};
				} else {
					this.selectedSiteAccount = this.propApi.siteAccount[0];
				}
			} else {
				this.selectedSiteAccount = this.propApi.siteAccount;
			}
		}
		this.filteredSites = this.selectedMasterAccount?.siteAccounts;
	},
	watch: {
		selectedMasterAccount: {
			handler(newVal) {
				this.query = {
					...this.query,
					masterAccountId: newVal.masterAccountId
				};
				this.emitAccounts(this.query);
			},
			deep: true
		},
		selectedSiteAccount: {
			handler(newVal) {
				if (!newVal) return;

				this.query = {
					...this.query,
					siteAccountId: newVal ? newVal.siteAccountId : null,
					siteAccountName: newVal ? newVal.siteAccountName : 'Unknown'
				};
				this.emitAccounts(this.query);
			},
			deep: true
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					title: '',
					helpPageUrl: '',
					formHeaderText: '',
					site: '',
					account: '',
					siteAccount: {},
					masterAccount: {},
					accountItems: [],
					pageHeader: {},
					contactTitle: '',
					contactIntro: ''
				},
				this.api
			);
		},
		siteAccounts() {
			if (this.selectedMasterAccount == null) {
				return [];
			}
			return this.selectedMasterAccount.siteAccounts;
		},
		getHeaderText() {
			return this.propApi.formHeaderText || this.propApi.pageHeader?.formHeaderText;
		},
		getHelpUrl() {
			return this.propApi.helpPageUrl || this.propApi.pageHeader?.helpPageUrl;
		},
		siteAccountMenuActive() {
			return this.$refs.siteAccountMenu?.$data.isActive;
		},
		filteredSitesClass() {
			return this.filteredSites?.length > 7 ? 'scl-new-header__no-site-available--show-search' : '';
		}
	},
	methods: {
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		selectMasterAccount(masterAccount) {
			this.selectedMasterAccount = masterAccount;
			this.filteredSites = this.selectedMasterAccount?.siteAccounts;
		},
		emitAccounts(query) {
			this.$root.$emit('site-account-update', query);
		},
		selectSiteAccount(siteAccount) {
			if (siteAccount === 'unknown') {
				siteAccount = {
					siteAccountName: 'Unknown',
					siteAccountId: null
				};
			}
			this.selectedSiteAccount = siteAccount;
			this.$refs.siteAccountMenu.$data.isActive = false;
			this.searchTerm = '';
		},
		hasValue(val) {
			return Object.keys(val).length === 0;
		},
		filterSites() {
			if (!this.searchTerm) {
				this.filteredSites = this.siteAccounts;
			}

			this.filteredSites = this.siteAccounts.filter(item => {
				return item.siteAccountName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
			});
		}
	},
	template: null
};

export default SclPageHeaderNew;
</script>
