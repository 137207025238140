<template>
	<div>
		<scl-page-header-new
			:api="propApi"
			:isReadOnly="isReadOnly"
			:isDuplicate="isDuplicate"
			:show-error="showError"
			:masterAccountSection="true"
			class="scl-new-header"
		>
			<template v-slot:routebtn>
				<div class="mb-2 scl-route-button--orange">
					<scl-route-button data-cy="route-button" class="mb-1" :api="{ href: backBtn.url, label: backBtn.text }"></scl-route-button>
				</div>
			</template>
			<template v-slot:iconGroup v-if="isReadOnly">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" :ripple="false" icon @click="OpenModal" class="scl-new-header__icon scl-new-header__icon--contact">
							<v-icon color="accent">far fa-envelope</v-icon>
						</v-btn>
					</template>
					<span>Contact Us</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" :ripple="false" icon @click="doCopy()" class="scl-new-header__icon border v-btn">
							<v-icon color="accent">far fa-copy</v-icon>
						</v-btn>
					</template>
					<span>Duplicate request</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" :ripple="false" icon @click="exportPDF()" class="scl-new-header__icon border v-btn">
							<v-icon color="accent">far fa-file-pdf</v-icon>
						</v-btn>
					</template>
					<span>Download PDF</span>
				</v-tooltip>
				<div class="divider-vertical"></div>
			</template>
		</scl-page-header-new>
		<scl-quote-contact-modal
			class="scl-quote-contact-modal scl-component"
			:api="{
				title: propApi.contactTitle,
				subHeader: propApi.contactIntro,
				state: state,
				quoteId: propApi.quoteId,
				firstName: propApi.personal.firstname.value,
				lastName: propApi.personal.lastname.value
			}"
		></scl-quote-contact-modal>
		<v-form class="scl-form create-quote" ref="form" lazy-validation>
			<v-container class="grid-list-xl fluid">
				<!-- PERSONAL DETAILS -->
				<div class="scl-quote-request__container">
					<v-layout row wrap class="my-0">
						<v-flex xs12>
							<h2 class="scl-quote-request__title">Personal Details</h2>
						</v-flex>
						<v-flex xs12>
							<p class="mb-4">{{ propApi.personal.subTitle }}</p>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"
								>{{ propApi.personal.firstname.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label
							>
							<v-text-field
								v-model="propApi.personal.firstname.value"
								class="scl-form__input"
								aria-label="First Name"
								data-cy="First Name"
								:rules="[rules.required]"
								outline
								:disabled="isReadOnly"
								readonly
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"
								>{{ propApi.personal.lastname.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label
							>
							<v-text-field
								v-model="propApi.personal.lastname.value"
								class="scl-form__input"
								aria-label="Last Name"
								data-cy="Last Name"
								:rules="[rules.required]"
								outline
								:disabled="isReadOnly"
								readonly
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"
								>{{ propApi.personal.phoneNumber.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label
							>
							<v-text-field
								v-model="propApi.personal.phoneNumber.value"
								class="scl-form__input"
								aria-label="Phone Number"
								data-cy="Phone Number"
								:rules="[rules.required, rules.australianPhoneNumber]"
								outline
								:placeholder="propApi.personal.phoneNumber.placeholder"
								:disabled="isReadOnly"
								readonly
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"
								>{{ propApi.personal.email.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span></label
							>
							<v-text-field
								v-model="propApi.personal.email.value"
								class="scl-form__input"
								aria-label="Email"
								data-cy="Email"
								:rules="[rules.required, rules.email]"
								outline
								:disabled="isReadOnly"
								readonly
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"
								>{{ propApi.personal.customerReference.label }}
								<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span></label
							>
							<v-text-field
								v-model="propApi.personal.customerReference.value"
								:placeholder="propApi.personal.customerReference.placeholder"
								class="scl-form__input"
								data-cy="Customer Reference"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								outline
							></v-text-field>
						</v-flex>
					</v-layout>
				</div>
				<!-- Equipment Required -->
				<scl-quote-equipment-required
					ref="equipmentRequired"
					:subTitle="propApi.equipment.subTitle"
					@update-equipment="updateEquipment"
					:isReadOnly="isReadOnly"
					:equipmentList="propApi.equipment.equipmentList"
				></scl-quote-equipment-required>

				<!-- Hire Period -->
				<scl-quote-hire-period
					ref="hirePeriod"
					:api="propApi"
					@update-date="updateDatePeriod"
					:isReadOnly="isReadOnly"
					:hire-period="propApi.hirePeriod"
				>
				</scl-quote-hire-period>

				<!-- Site Contact DETAILS -->
				<div class="scl-quote-request__container">
					<v-layout row wrap class="my-0">
						<v-flex xs12>
							<h2 class="scl-quote-request__title">Site Contact</h2>
							<p class="mb-4">{{ propApi.siteContact.subTitle }}</p>
						</v-flex>
						<v-flex xs12>
							<v-checkbox
								v-if="!isReadOnly"
								v-model="siteContact.samePersonalDetails.value"
								:label="siteContact.samePersonalDetails.label"
								@change="ChangeSameAsPersonalDetails($event)"
								aria-label="Same As Personal Details"
								data-cy="same Personal Details checkbox"
							></v-checkbox>
						</v-flex>

						<v-flex xs12 sm6>
							<label class="scl-form__label"> First Name <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span> </label>
							<v-text-field
								v-model="siteContact.firstname.value"
								class="scl-form__input"
								aria-label="Contact First Name"
								data-cy="Contact First Name"
								:rules="[rules.required, rules.counter50, rules.lettersOnly]"
								height="52"
								@input="ChangeSiteContact"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								outline
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"> Last Name <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span> </label>
							<v-text-field
								v-model="siteContact.lastname.value"
								class="scl-form__input"
								aria-label="Contact Last Name"
								data-cy="Contact Last Name"
								:rules="[rules.required, rules.counter50, rules.lettersOnly]"
								height="52"
								@input="ChangeSiteContact"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								outline
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6>
							<label class="scl-form__label"> Phone Number <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span> </label>
							<v-text-field
								v-model="siteContact.phoneNumber.value"
								class="scl-form__input"
								aria-label="Contact Phone Number"
								data-cy="Contact Phone Number"
								:rules="[rules.required, rules.australianPhoneNumber]"
								height="52"
								@input="ChangeSiteContact"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								outline
							></v-text-field>
						</v-flex>
					</v-layout>
				</div>
				<!-- Delivery or Pickup -->
				<scl-quote-delivery-pickup
					ref="deliveryPickup"
					class="scl-component scl-quote-delivery-pickup"
					@delivery-or-pickup="handleDeliveryorPickup"
					@update-delivery="updateDelivery"
					@branch-selected="updateBranch"
					@update-hazardous="updateHazardous"
					:api="propApi"
					:isReadOnly="isReadOnly"
				></scl-quote-delivery-pickup>

				<v-layout row wrap class="scl-quote-request__container">
					<!-- Additonal Comments -->
					<v-flex xs12>
						<v-flex sm6>
							<label class="scl-form__label">
								{{ additionalComments.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
							</label>
							<v-textarea
								v-model="additionalComments.value"
								:placeholder="isReadOnly ? '' : additionalComments.placeholder"
								class="scl-form__input scl-form__textarea"
								data-cy="additional Comments"
								outline
								:disabled="isReadOnly"
								:readonly="isReadOnly"
								type="text"
								height="165"
							></v-textarea>
						</v-flex>
					</v-flex>
					<!-- Sales Person -->
					<v-flex xs12 v-if="this.propApi.notifySalesPersonFlag">
						<v-checkbox
							v-model="salesPerson.notify.value"
							:readonly="isReadOnly"
							:disabled="isReadOnly"
							:ripple="false"
							data-cy="notify SalesPerson checkbox"
						>
							<template v-slot:label>
								{{ salesPerson.notify.label }} <span class="scl-form__label--additional" v-if="!isReadOnly">&nbsp; Optional</span>
							</template>
						</v-checkbox>
						<v-flex xs12 sm5 v-if="salesPerson.notify.value">
							<label class="scl-form__label">
								{{ salesPerson.label }}
								<span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
							</label>
							<v-autocomplete
								v-model="salesRep"
								:items="salesRepList"
								:rules="[rules.required]"
								auto-select-first
								cache-items
								item-text="name"
								item-value="code"
								placeholder="Search"
								data-cy="sales person list"
								class="scl-form__input"
								hide-no-data
								solo
								flat
								outline
								single-line
								:append-icon="isReadOnly ? '' : 'far fa-chevron-down select-margin-top'"
								:clearable="!isReadOnly"
								:disabled="isReadOnly"
								:readonly="isReadOnly"
							></v-autocomplete>
						</v-flex>
					</v-flex>

					<!-- Submit and Cancel -->
					<v-flex xs12 sm2 v-if="!isReadOnly">
						<v-btn
							color="accent"
							class="scl-component scl-button"
							style="width: 100%"
							data-cy="submit button"
							:class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
							@click.prevent="submit"
							:disabled="loading || this.propApi.isPortalStaff"
						>
							<v-icon class="scl-button__icon">fas fa-chevron-right</v-icon> {{ loading ? 'Submitting' : 'Submit' }}
						</v-btn>
					</v-flex>
					<v-flex xs12 sm1 v-if="!isReadOnly">
						<v-btn class="scl-component scl-button v-btn--outline accent--text" data-cy="cancel button" depressed @click="resetForm">Cancel</v-btn>
					</v-flex>
					<v-flex xs12 sm6 v-if="showError">
						<span class="error--text">The form isn't quite complete. Please review the highlighted fields and try again.</span>
					</v-flex>
				</v-layout>
			</v-container>
		</v-form>
	</div>
</template>

<script>
// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';

import SclRouteButton from '@portals/components/button/SclRouteButton';
import SclPageHeaderNew from '@portals/components/header/SclPageHeaderNew.vue';
import SclQuoteEquipmentRequired from '@portals/components/quotes/SclQuoteEquipmentRequired.vue';
import SclQuoteHirePeriod from '@portals/components/quotes/SclQuoteHirePeriod.vue';
import SclQuoteDeliveryPickup from '@portals/components/quotes/SclQuoteDeliveryPickup.vue';
import SclQuoteContactModal from '@portals/components/modal/SclQuoteContactModal';

const sclCreateQuotesView = {
	name: 'SclCreateQuotesView',
	mixins: [mxApi],
	components: {
		SclRouteButton,
		SclPageHeaderNew,
		SclQuoteEquipmentRequired,
		SclQuoteHirePeriod,
		SclQuoteDeliveryPickup,
		SclQuoteContactModal
	},
	data() {
		return {
			loading: false,
			isDeliveryorPickup: 'Delivery',
			isReadOnly: false,
			isDuplicate: false,
			showError: false,
			customQuery: null,
			state: '',
			backBtn: {
				url: '/my-quote-requests',
				text: 'Back to My Quote Requests'
			},
			siteContact: {
				samePersonalDetails: {
					label: 'Same As Personal Details',
					placeholder: '',
					value: false
				},
				firstname: {
					label: '',
					value: ''
				},
				lastname: {
					label: '',
					value: ''
				},
				phoneNumber: {
					label: '',
					value: ''
				},
				email: {
					value: ''
				}
			},
			additionalComments: {
				label: 'Other comments',
				value: '',
				placeholder: 'Can I organise a site inspection?'
			},
			salesPerson: {
				label: 'Please select sales person you would like to notify',
				notify: {
					label: 'Notify Coates sales person',
					value: false
				},
				salesPerson: {
					value: 'someone'
				}
			},
			salesRepList: [],
			salesRep: null,
			hirePeriod: {},
			deliveryDetails: {
				locationName: '',
				unitNumber: '',
				street: '',
				suburb: '',
				postcode: '',
				comments: ''
			},
			selectedBranch: {
				Branch: {
					StoreName: '',
					StorePostcode: '',
					StoreCode: ''
				}
			},
			hazardous: {},
			equipments: [],
			rules: {
				required: value => !!value || 'Required',
				counter50: value => value?.length <= 50 || 'Max 50 characters',
				lettersOnly: value => {
					const lettersPattern = /^[a-zA-Z\s'-]+$/;
					return lettersPattern.test(value) || 'Name is invalid';
				},
				australianPhoneNumber: value => {
					const pattern = /^0([0-9]{9})$/;
					return pattern.test(value?.split(' ').join('')) || 'Phone number is invalid';
				},
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Please enter a valid email address';
				}
			}
		};
	},
	props: {
		api: {
			type: Object,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.personal ? typeof obj.peronal === 'object' : true));

				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					personal: {
						firstname: {
							label: 'First Name',
							placeholder: 'John',
							value: ''
						},
						lastname: {
							label: 'Last Name',
							placeholder: 'Smith',
							value: ''
						},
						phoneNumber: {
							label: 'Phone Number',
							placeholder: '0401123456',
							value: ''
						},
						email: {
							label: 'Email Address',
							placeholder: 'example@example.com.au',
							value: ''
						},
						customerReference: {
							label: 'Customer Reference',
							placeholder: 'Enter your reference ID',
							value: ''
						}
					},
					siteContact: {},
					comments: {},
					hirePeriod: {
						startDate: '',
						endDate: '',
						startTime: '0700',
						endTime: '1700'
					},
					equipment: {},
					contactTitle: 'Contact Coates about your request',
					contactIntro: 'A Coates representative will be in touch shortly.',
					salesRep: []
				},
				this.api
			);
		}
	},
	created() {
		switch (this.propApi.formMode) {
			case 'Read':
				this.isReadOnly = true;
				break;
			case 'Duplicate':
				this.isDuplicate = true;
				break;
		}
	},
	mounted() {
		if (typeof this.propApi.customAccount === 'string') {
			// try to set master account text in master account selector
			this.$root.$emit('updateConstumAccount', this.propApi.customAccount);
		}
		if (this.propApi.deliveryOrPickup === 'Delivery') {
			this.state = this.propApi.delivery.state;
		} else if (this.propApi.deliveryOrPickup === 'Pickup') {
			this.state = this.propApi.pickup.branch?.storeState;
		}
		if (this.propApi.salesRep) {
			this.salesRepList = this.propApi.salesRep;

			if (this.propApi.salesPersonCode) {
				this.salesRep = this.propApi.salesRep.find(item => {
					return item.code === this.propApi.salesPersonCode;
				});
			}
		}

		if (this.propApi.comments) {
			this.additionalComments.value = this.propApi.comments.value;
		}
		if (this.propApi.salesPerson) {
			this.salesPerson.notify.value = JSON.parse(this.propApi.salesPerson.notify.value);
			// this.salesPerson.salesPerson.value = this.propApi.salesPerson.salesPerson.value;
		}
		if (this.propApi.siteContact) {
			this.siteContact = Object.assign(this.siteContact, this.propApi.siteContact);
			this.siteContact.email.value = this.propApi.personal.email.value;
		}
		if (this.propApi.equipment?.equipmentList?.length) {
			this.updateEquipment(this.propApi.equipment.equipmentList);
		}
		if (this.propApi.hirePeriod) {
			this.hirePeriod = this.propApi.hirePeriod;
		}

		this.$root.$on('site-account-update', query => {
			this.customQuery = query;
		});
	},
	methods: {
		OpenModal() {
			this.$root.$emit('open-contact-modal');
		},
		doCopy() {
			window.open(`/my-quote-requests/Duplicate/${this.propApi.quoteId}`, '_blank');
		},
		async exportPDF() {
			try {
				this.showError = false;
				this.loading = true;
				this.errorMessage = null;
				this.$root.$emit('loadingSpinner', true);
				const url = `${process.env.VUE_APP_SITE_ROOT}/api/v1/mch/quotes/ExportQuote/${this.propApi.quoteId}`;
				let config = {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					responseType: 'blob' // important
				};

				const result = await this._mxApi_postData(null, url, config);

				if (result.status === 200) {
					const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
					const contentDisposition = result.headers['content-disposition'];
					const fileName = contentDisposition.match(/filename="(.+)"/)[1] || '';
					const link = document.createElement('a');

					link.href = downloadUrl;
					link.setAttribute('download', fileName); //any other extension

					document.body.appendChild(link);
					link.click();

					link.remove();
					const snackBarMessage = {
						copy: 'Your Quote Request pdf downloaded successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					this.errorMessage = 'Submit failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.loading = false;
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Submit failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
				this.loading = false;
			}
		},
		updateDatePeriod(selectedPeriod) {
			this.hirePeriod = Object.assign({}, selectedPeriod);
		},
		handleDeliveryorPickup(val) {
			this.isDeliveryorPickup = val;
		},
		updateDelivery(val) {
			this.deliveryDetails = Object.assign({}, val);
		},
		updateBranch(val) {
			this.selectedBranch = Object.assign({}, val);
		},
		updateHazardous(val) {
			this.hazardous = Object.assign({}, val);
		},
		updateEquipment(arr) {
			this.equipments = arr.map(obj => ({
				Quantity: obj.quantity,
				EquipmentType: obj.type,
				EquipmentUseCase: obj.description
			}));
		},
		validate() {
			if (this.$refs.form.validate()) {
				console.warn('VALID');
			}
		},
		ChangeSameAsPersonalDetails(val) {
			if (val) {
				this.siteContact.firstname.value = this.propApi.personal.firstname.value;
				this.siteContact.lastname.value = this.propApi.personal.lastname.value;
				this.siteContact.phoneNumber.value = this.propApi.personal.phoneNumber.value;
				this.siteContact.email.value = this.propApi.personal.email.value;
			}
		},
		ChangeSiteContact() {
			if (this.siteContact.samePersonalDetails.value) {
				this.siteContact.samePersonalDetails.value = false;
			}
		},
		resetForm() {
			window.location.href = '/my-quote-requests';
			// Object.assign(this.$data, this.$options.data());
			// this.$refs.hirePeriod.clear();
			// this.$refs.equipmentRequired.clear();
			// this.$refs.deliveryPickup.handleClearItem();
		},
		async submit() {
			if (
				!this.$refs.form.validate() ||
				!this.customQuery?.masterAccountId ||
				(!this.customQuery?.siteAccountId && this.customQuery?.siteAccountName !== 'Unknown')
			) {
				this.showError = true;
				return;
			}

			this.showError = false;
			this.loading = true;
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);

			const url = `${process.env.VUE_APP_SITE_ROOT}/api/v1/mch/quotes/add`;

			try {
				const payload = {
					SiteAccount_Id: this.customQuery?.siteAccountId,
					MasterAccount_Id: this.customQuery?.masterAccountId,
					PersonalDetailsFirstName: this.propApi.personal.firstname.value,
					PersonalDetailsLastName: this.propApi.personal.lastname.value,
					PersonalDetailsPhoneNumber: this.propApi.personal.phoneNumber.value,
					PersonalDetailsEmailAddress: this.propApi.personal.email.value,
					CustomerReference: this.propApi.personal.customerReference.value,
					SiteContactFirstName: this.siteContact.firstname.value,
					SiteContactLastName: this.siteContact.lastname.value,
					SiteContactPhoneNumber: this.siteContact.phoneNumber.value,
					StartDate: this.hirePeriod.startDate /* yyyy-MM-dd */,
					EndDate: this.hirePeriod.endDate,
					StartTime: this.hirePeriod?.startTime /* HHmm */,
					EndTime: this.hirePeriod.endTime,
					DeliveryOrPickup: this.isDeliveryorPickup,
					DeliveryLocationName: this.deliveryDetails.locationName,
					DeliveryUnitNumber: this.deliveryDetails.unitNumber,
					DeliveryStreet: this.deliveryDetails.street,
					DeliverySuburb: this.deliveryDetails.suburb,
					DeliveryPostCode: this.deliveryDetails.postcode,
					DeliveryState: this.deliveryDetails.state,
					DeliveryComments: this.deliveryDetails.comments,
					DeliveryCode: this.deliveryDetails.selectedBranchCode,
					HazardousMaterialsOnSiteFlag: this.hazardous?.isHazardous,
					HazardousMaterialsComment: this.hazardous?.hazardousDetails,
					PickupBranch: this.selectedBranch.Branch?.StoreName,
					PickupPostCode: this.selectedBranch.Branch?.StorePostcode,
					PickupCode: this.selectedBranch.Branch?.StoreCode,
					PickupState: this.selectedBranch.Branch?.StoreState,
					GoogleMapID: null,
					Comments: this.additionalComments.value || null,
					SalesPerson: this.salesPerson.notify.value || null,
					SalesPersonCode: this.salesRep?.code || this.salesRep || null,
					QuoteRequestLines: this.equipments
				};

				const result = await this._mxApi_postData(payload, url);

				if (result.status === 200 && result.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Your Quote Request was sent successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					window.location.href = `${this.api.redirectUrl}?quote=${result.data.result.id}`;
				} else {
					this.errorMessage = 'Submit failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.loading = false;
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Submit failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
				this.loading = false;
			}
		}
	}
};

export default sclCreateQuotesView;
</script>
<style lang="scss">
@import '@portals/components/quotes/_scl-quotes.scss';
@import '@portals/components/header/_SclPageHeaderNew.scss';
</style>
