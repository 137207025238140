// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';

// component
const component = {
	name: 'SclResetPasswordModal',
	mixins: [mxApi],
	data() {
		return {
			dialog: false,
			showRequestSuccess: false,
			errorMessage: '',
			email: '',
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				}
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.copy ? typeof obj.copy === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'reset-password-event',
					width: '330',
					persistent: false,
					modalLabel: 'reset password',
					copy: 'Enter your email address below and we’ll send you an email with password reset instructions',
					postbackURL: '',
					postbackPipe: ''
				},
				this.api
			);
		}
	},
	methods: {
		async submit() {
			if (!this.$refs.resetPasswordForm.validate()) {
				return false;
			}

			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					email: this.email
				};
				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Reset password successful',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					this.errorMessage = 'Password reset request failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.dialog = false;
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.dialog = false;
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
