/**
 * SclSimpleList
 */

// template
const template = `
	<div class="scl-expandable-invoice-table scl-form">
		<v-container class="scl-expandable-invoice-table__container fluid">
			<v-layout row wrap fill-height>
				<v-flex class="xs12">
					<v-data-table
						v-model="selected"
						:headers="tableData.headers"
						:items="tableData.tableItems"
						:pagination.sync="pagination"
						@update:pagination="updatePagination"
						:search="tableQuery.query"
						:total-items="pagination.total"
						item-key="invoiceNo">

						<template v-slot:items="props">
							<tr>
								<td class="text-xs-center px-2">
									<v-icon size="18" :color="invoiceStatus(props.item.status)">fas fa-circle</v-icon>
								</td>
								<td class="text-xs-center px-2">
									<a href="#" @click.prevent="clickEvent('invoice-amount-event', props.item)">{{ props.item.invoiceNo }}</a>
								</td>
								<td class="text-xs-center px-2" v-html="searched(props.item.type, 'type')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.invoiceDate, 'invoiceDate')"></td>
								<td class="text-xs-left px-2" v-html="searched(props.item.site, 'siteAccountName')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.hireSchedule, 'hireSchedule')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.purchaseNo, 'purchaseNo')"></td>
								<td class="text-xs-right px-2" :class="parseFloat(props.item.invoiceAmount, 10) < 0 ? 'scl-expandable-invoice-table__disputed-invoice' : ''" v-html="searched(props.item.invoiceAmount, 'invoiceAmount')"></td>
								<td class="text-xs-center px-2" v-html="searched(props.item.dueDate, 'dueDate')"></td>

								<td class="text-xs-right px-2" v-if="parseFloat(props.item.applied, 10) !== 0">
									<a href="#" @click.stop="clickEvent('invoice-amount-event', props.item)" :class="parseFloat(props.item.applied, 10) < 0 ? 'scl-expandable-invoice-table__disputed-invoice' : 'text--primary'" class="text-xs-right px-2" v-html="searched(props.item.applied, 'applied')">
									</a>
								</td>
								<td class="text-xs-right px-2" v-else v-html="searched(props.item.applied, 'applied')"></td>

								<td class="text-xs-right px-2" v-if="props.item.balance" :class="parseFloat(props.item.balance, 10) < 0 ? 'scl-expandable-invoice-table__disputed-invoice' : ''" v-html="searched(props.item.balance, 'balance')"></td>
								<td class="text-xs-left scl-expandable-invoice-table__expand-btn px-2" @click="props.expanded = !props.expanded"><v-icon size="16" color="#ff6105">fas fa-chevron-down</v-icon></td>
							</tr>
						</template>
						<template v-slot:expand="props">
							<v-layout row px-3 pt-4 pb-2 justify-space-between wrap>
								<v-flex class="font-weight-bold scl-expandable-invoice-table__expanded-item" v-for="(item, index) in props.item.tableExpandItems" :key="index">
									<p class="mb-1 pb-1 underline">{{item.label}}</p>
									<p v-if="!item.list.length" class="scl-expandable-invoice-table__expanded-item--empty font-italic font-weight-light">
										No disputes lodged
									</p>
									<ul class="font-weight-regular pl-0">
										<li v-for="listItem in item.list" :key="index">
											{{listItem}}
										</li>
									</ul>
								</v-flex>
							</v-layout>
						</template>
						<template v-slot:actions-prepend>
							<p class="mb-0 caption text-capitalize scl-datatable__action-date">
								<span v-if="propApi.tableData.tableUpdated !== undefined">
									Data last updated on {{propApi.tableData.tableUpdated}}
								</span>
							</p>
						</template>
					</v-data-table>
				</v-flex>
				<v-flex class="caption text-capitalize scl-expandable-invoice-table__footer pt-3 xs12">
					<ul pl-0 class="d-inline-flex pl-0">
						<li>
							<p class="mb-0">Invoice status:</p>
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="invoiceStatus('R')">fas fa-circle</v-icon>
							Overdue
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="invoiceStatus('Y')">fas fa-circle</v-icon>
							In Dispute
						</li>
						<li class="ml-3">
							<v-icon size="14" :color="invoiceStatus('G')">fas fa-circle</v-icon>
							Current
						</li>
					</ul>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
