// Mixins
import mxApi from '../../mixins/common/mxApi.js';

// component
const component = {
	name: 'SclThresholdSetting',
	mixins: [mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.data ? Array.isArray(obj.data) : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			dueStatus: null,
			label: null,
			threshold: 0,
			data: []
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					postbackURL: null,
					threshold: 0,
					label: 'Global `due within threshold` SETTINGS',
					data: []
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.data = event;
		});
	},
	watch: {
		propApi: {
			handler(newVal) {
				this.data = newVal.data;
				this.threshold = newVal.threshold;
				this.label = newVal.label;
			}
		}
	},
	methods: {
		increment() {
			this.threshold = parseInt(this.threshold, 10) + 1;
		},
		decrement() {
			if (this.threshold > 0) {
				this.threshold = parseInt(this.threshold, 10) - 1;
			}
		},
		hireStatus(hireDays) {
			if (hireDays > 30) {
				return '#FF6105';
			}
			if (hireDays <= 5 && hireDays >= 1) {
				return '#63BF00';
			}
			if (hireDays <= 30 && hireDays >= 1) {
				return '#ECAF1E';
			}
			if (hireDays === 0) {
				return '#006600';
			}
			if (!hireDays) {
				return '#B6B5B3';
			}
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					threshold: this.threshold
				};

				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result.status == 200 && result.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Threshold updated successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Threshold update failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
