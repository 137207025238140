/**
 * SclSnackbar api
 *
 * buttonColor prop
 * @param {string} '#333333'
 *
 * vertical prop
 * @param {string} 'top'
 * @param {string} 'bottom'
 *
 * horizontal prop
 * @param {string} 'left'
 * @param {string} 'right'
 *
 * timeDelay prop - auto close after time
 * @param {number} 6000
 *
 * autoHeight prop
 * @param {boolean} false
 *
 * snackColor prop
 * @param {boolean} '#0000ff'
 *
 * snackButtonColor prop
 * @param {boolean} '#ffffff'
 **/

const template = `
 <v-snackbar
	 v-model="snackbar"
	 :color="propApi.snackColour"
	 :auto-height="propApi.autoHeight"
	 :timeout="propApi.timeout"
	 :bottom="propApi.vertical === 'bottom'"
	 :left="propApi.horizontal === 'left'"
	 :right="propApi.horizontal === 'right'"
	 :top="propApi.vertical === 'top'"
	 :class="{ 'scl-snackbar__theme-error': propApi.type === 'error', 'scl-snackbar__theme-success': propApi.type === 'success', 'scl-snackbar': true }"
	 >
	 <v-layout row wrap>
		 <v-flex xs2 d-flex align-center>
			 <v-icon v-if="propApi.type === 'success'" size="22" color="#63BF00" class="pr-3">fas fa-check</v-icon>
			 <v-icon v-if="propApi.type === 'error'" size="22" color="#e80000" class="pr-3">fas fa-times</v-icon>
		 </v-flex>
		 <v-flex xs9>
			 <p>{{ propApi.copy }}</p>
			 <p class="caption">{{ propApi.subcopy }}</p>
		 </v-flex>
		 <a v-if="propApi.showCloseButton" class="v-closeBtn" href="#" @click="snackbar = false"><v-icon size="14" color="#ff6105">fas fa-times</v-icon></a>
	 </v-layout>
 </v-snackbar>
`;

export default template;
