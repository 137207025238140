import axios from 'axios';

// component
const component = {
	name: 'SclInviteModal',
	data() {
		return {
			dialog: false,
			valid: true,
			accountItem: {
				emailAddress: ''
			},
			rules: {
				required: value => !!value || 'This field is Required.',
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail.';
				}
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			this.accountItem = typeof event === 'undefined' ? [] : event;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'invite-event',
					width: '650',
					persistent: false,
					modalLabel: ''
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			if (!this.$refs.form.validate()) {
				return false;
			}

			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					account: this.accountItem
				};

				const response = await this.getResults(payload, this.propApi.postbackSelectURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Email sent successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Send email failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
