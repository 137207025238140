// component
const component = {
	name: 'Scl1Col',
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// export component
export default component;
