// component
const component = {
	name: 'SclSubscribeForm',
	data() {
		return {
			valid: false,
			emailAddress: '',
			emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid']
		};
	},
	methods: {
		subscribe() {
			this.valid = true;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
