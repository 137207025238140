/**
 * SclExpectedOffhireModal
 */

// template
const template = `
	<div class="scl-expected-offhire-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-expected-offhire-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="closeModal">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container class="scl-expected-offhire-modal__container px-3 pt-0 pb-4">
						<v-layout row wrap fill-height>
							<v-flex xs12 md8>
								<h3 class="text-capitalize font-weight-bold mb-0">transaction</h3>
								<v-data-table
									:headers="propApi.transactionsData.headers"
									:items="hireItems.hires"
									hide-actions
									class="scl-expected-offhire-modal__data-table"
								>
									<template v-slot:items="props">
										<td>{{ props.item.plantno }}</td>
										<td class="text-xs-left">{{ props.item.qty }}</td>
										<td class="text-xs-left">{{ props.item.site }}</td>
										<td class="text-xs-left">{{ props.item.orderedby }}</td>
										<td class="text-xs-left">{{ props.item.ponumber }}</td>
										<td class="text-xs-left font-weight-bold">{{ props.item.offhiredate }}</td>
										<td class="text-xs-left font-weight-bold">
											<a @click.prevent="removeItem(props.item)">
												<v-icon size="16" color="#ff6105">fas fa-times</v-icon>
											</a>
										</td>
									</template>
								</v-data-table>
							</v-flex>
							<v-flex xs12 md4 text-xs-right>
								<h3 class="text-capitalize font-weight-bold text-xs-left" :class="{'ml-4' : $vuetify.breakpoint.mdAndUp}">select desired date</h3>
								<v-date-picker v-model="hireItems.date" :min="today" full-width color="#ff6105" class="scl-expected-offhire-modal__date-picker"></v-date-picker>
								<div class="scl-expected-offhire-modal__action mt-2">
									<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
									<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="clickEvent('update-hire-event')">update</v-btn>
								</div>
								<v-alert type="error" :value="errorMessage" outline>{{ errorMessage }}</v-alert>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
