// component
const component = {
	name: 'SclAdminPageHeader',
	data() {
		return {
			drawer: false
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					title: '',
					copy: '',
					buttons: []
				},
				this.api
			);
		}
	},
	methods: {
		gotoUrl(url) {
			if (!url) {
				return;
			}
			window.location.href = url;
		},
		clickEvent(event) {
			this.$root.$emit(event);
		},
		addUser() {
			this.clickEvent('add-admin-event');
		},
		updateMessage() {
			this.clickEvent('login-message-event');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
