/**
 * SclCardContactSimple
 */

// template
const template = `
	<v-card class="scl-card-contact-simple ma-3" flat>
		<v-icon color="black" size="18">{{propApi.icon}}</v-icon>
		<div class="ml-2">
			<div class="scl-card-contact-simple__name">
				<p class="font-weight-bold text-capitalize mb-1 title-2">{{propApi.name}}</p>
				<scl-tooltip :api="{icon: 'far fa-question-circle', message: propApi.tooltipMessage}"></scl-tooltip>
			</div>
			<p class="mb-1">Phone: <a :href="'tel:'+propApi.phone">{{propApi.phone}}</a></p>
			<p>Email: <a :href="'mailto:'+ propApi.email">{{propApi.email}}</a></p>
		</div>
	</v-card>
`;

// export template
export default template;
