/**
 * SclPageHeader api
 */

// template
const template = `
	<v-sheet class="scl-page-header" :color="data.bgColor">
		<v-container fluid class="pb-2">
			<v-layout wrap :class="data.layout" class="d-flex justify-space-between">
				<v-flex grow xs7 pb-3>
					<h1 class="font-weight-bold scl-page-header__title">{{data.title}}</h1>
					<p class="mb-0" v-html="data.subtitle"></p>
				</v-flex>
				<v-flex shrink class="align-self-end" :class="{'text-xs-right': data.layout === 'row'}">
					<scl-button v-for="(link, index) in data.links" :key="index" :api="{modal: link.event, copy:link.label, outline: link.outline, block: false, icon: link.icon, tally: link.tally}" class="mr-2">{{link}}</scl-button>
				</v-flex>
			</v-layout>
		</v-container>
	</v-sheet>
`;

// export template
export default template;
