// component
const component = {
	name: 'SclContactForm',
	data() {
		return {
			items: ['General', 'Equipment', 'Feedback', 'Accounts', 'Careers', 'Training']
		};
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
