// component
const component = {
	name: 'SclExpectedOffhireModal',
	data() {
		return {
			dialog: false,
			valid: true,
			errorMessage: '',
			today: new Date().toISOString().substr(0, 10),
			hireItems: {
				date: new Date().toISOString().substr(0, 10),
				hires: []
			}
		};
	},
	mounted() {
		this.hireItems.hires = this.propApi.transactionsData.transactions;
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			this.hireItems.hires = event;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'expected-offhire-event',
					width: '1060',
					persistent: false,
					modalLabel: '',
					transactionsData: {
						headers: [
							{
								text: 'Plant No.',
								align: 'left',
								sortable: false,
								value: 'plantno',
								width: '10%'
							},
							{ text: 'Qty', value: 'qty', align: 'left', sortable: false, width: '10%' },
							{ text: 'Site', value: 'site', align: 'left', sortable: false, width: '30%' },
							{ text: 'Ordered By', value: 'orderedby', align: 'left', sortable: false, width: '15%' },
							{ text: 'PO Number', value: 'ponumber', align: 'left', sortable: false, width: '15%' },
							{ text: 'Exp. Off hire Date', value: 'offhiredate', align: 'left', sortable: false, width: '20%' },
							{ text: '', value: 'remove', align: 'left', sortable: false, width: '10%' }
						],
						transactions: [
							{
								plantno: '92074',
								qty: '1164',
								site: 'NSW_SLR - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Wick',
								ponumber: '1a545g',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '86578',
								qty: '43',
								site: 'WA - NORTH (LIGHT RAIL)',
								orderedby: 'Bruce',
								ponumber: 'zfd43534',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '4567',
								qty: '876',
								site: 'SA - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Rick',
								ponumber: 'gt34a',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '12234',
								qty: '6',
								site: 'VIC - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Carter',
								ponumber: 'poi567',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '92074',
								qty: '1164',
								site: 'NSW_SLR - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Wick',
								ponumber: '1a545g',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '86578',
								qty: '43',
								site: 'WA - NORTH (LIGHT RAIL)',
								orderedby: 'Bruce',
								ponumber: 'zfd43534',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '4567',
								qty: '876',
								site: 'SA - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Rick',
								ponumber: 'gt34a',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '12234',
								qty: '6',
								site: 'VIC - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Carter',
								ponumber: 'poi567',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '92074',
								qty: '1164',
								site: 'NSW_SLR - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Wick',
								ponumber: '1a545g',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '86578',
								qty: '43',
								site: 'WA - NORTH (LIGHT RAIL)',
								orderedby: 'Bruce',
								ponumber: 'zfd43534',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '4567',
								qty: '876',
								site: 'SA - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Rick',
								ponumber: 'gt34a',
								offhiredate: '21/01/2050',
								remove: ''
							},
							{
								plantno: '12234',
								qty: '6',
								site: 'VIC - NORTH (SYDNEY LIGHT RAIL)',
								orderedby: 'Carter',
								ponumber: 'poi567',
								offhiredate: '21/01/2050',
								remove: ''
							}
						]
					}
				},
				this.api
			);
		}
	},
	methods: {
		closeModal() {
			this.hireItems.date = new Date().toISOString().substr(0, 10);
			this.dialog = false;
		},
		clickEvent(event) {
			this.dialog = false;
			this.$root.$emit(event, this.hireItems);
		},
		removeItem(item) {
			const index = this.hireItems.hires.map(x => x.plantNumber).indexOf(item.plantNumber);
			if (index == -1) {
				return;
			}
			this.hireItems.hires.splice(index, 1);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
