/**
 * SclFooter api
 */

// template
const template = `
	<v-layout row wrap>
		<v-flex xs12 md6 px-4 class="scl-footer__list-container py-3">
			<div class="scl-footer__list-item link-divider">Copyright &copy; {{ new Date().getFullYear() }} Coates</div>
			<div>
				<a href="#" target="_blank" class="scl-footer__list-item link-divider">Privacy Policy</a>
				<a href="#" target="_blank" class="scl-footer__list-item">Terms &amp; Conditions</a>
			</div>
		</v-flex>
		<v-flex xs12 md6 d-flex align-center text-md-right px-3>
			<a href="tel:131552" class="scl-footer__call">
				<v-icon size="14">fas fa-phone fa-flip-horizontal</v-icon>
				<span class="font-weight-black">13 15 52</span>
			</a>
		</v-flex>
	</v-layout>
`;

// export template
export default template;
