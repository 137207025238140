/**
 * Scl2Col
 */

// template
const template = `
	<scl-2-Col inline-template class="scl-component scl-2-col">
		<v-container grid-list-lg fluid>
			<v-layout row wrap>
				<v-flex xs12 md10>
					<scl-preferences-list></scl-preferences-list>
				</v-flex>
				<v-flex xs12 md2>
					<scl-card-spotlight :api="{title: 'need some help?', message: 'In case of needing help with XXX, please send an email to the following address:', emailAddress: 'email@email.com'}"></scl-card-spotlight>

					<scl-card-contact></scl-card-contact>
				</v-flex>
			</v-layout>
		</v-container>
	</scl-2-Col>
`;

// export template
export default template;
