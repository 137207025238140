export const CapitalCities = {
	Adelaide: 'adelaide',
	Brisbane: 'brisbane',
	Canberra: 'canberra',
	Darwin: 'darwin',
	Hobart: 'hobart',
	Melbourne: 'melbourne',
	Perth: 'perth',
	Sydney: 'sydney'
};

export const DayOfWeek = {
	Monday: 'monday',
	Tuesday: 'tuesday',
	Wednesday: 'wednesday',
	Thursday: 'thursday',
	Friday: 'friday',
	Saturday: 'saturday',
	Sunday: 'sunday'
};
