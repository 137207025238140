/**
 * SclEmailDomainForm
 */

// template
const template = `
	<div class="scl-email-domain-form scl-form">
		<v-container grid-list-xl fluid>
			<v-layout row wrap class="scl-email-domain-form__layout mb-3">
				<v-flex xs12 class="pa-0 mb-5">
					<h2 class="mb-3">Allowed domains</h2>
					<v-data-table
						:headers="headers"
						:items="results"
						hide-actions
					>

						<template v-slot:no-data>
							<td class="pl-0 scl-email-domain-form__empty-row">{{ propApi.noDataText }}</td>
						</template>

						<template v-slot:headers="props">
							<tr>
								<th
									v-for="(header, index) in headers"
									:key="header.text"
									:class="[index === 0 ? 'pl-0 text-xs-left scl-email-domain-form__email-domain' : results.length ? 'text-xs-center' : 'd-none']"
								>
									{{ header.text }}
								</th>
							</tr>
						</template>

						<template v-slot:items="props">
							<tr>
								<td class="pl-0">{{props.item.name}}</td>
								<td><v-icon class="d-flex justify-center scl-email-domain-form__action-icon" small @click.prevent="clickEvent('edit-email-domain-event', props.item)">fas fa-pencil-alt</v-icon></td>
								<td><v-icon class="d-flex justify-center scl-email-domain-form__action-icon" small @click="submitRemove(props.item)">fas fa-trash-alt</v-icon></td>
							</tr>
						</template>

					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
