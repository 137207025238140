/**
 * SclPieChartPanel api
 */

// template
const template = `
	<v-layout class="scl-pie-chart-panel" row wrap>
		<v-flex xs12 class="scl-pie-chart-panel__no-data" v-if="chartdata[0].datasets.length === 0 || !chartdata[0].datasets">
			<v-icon size="48">far fa-file-search</v-icon>
			<p class="mb-0 pt-3 subheading">No data available for selected sites</p>
		</v-flex>
		<v-flex xs5 v-if="chartdata[0].datasets.length > 0 && chartdata[0].datasets">
			<scl-pie-chart :api="{data: chartdata}" @legend-markup="onLegendMarkupReady"></scl-pie-chart>
		</v-flex>
		<v-flex xs7 d-flex align-center v-if="chartdata[0].datasets.length > 0 && chartdata[0].datasets">
			<v-layout class="scl-pie-chart-panel__legend" row wrap v-html="legendMarkup"/>
		</v-flex>
	</v-layout>
`;

// export template
export default template;
