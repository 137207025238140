// component
const component = {
	name: 'SclHireTableHeader',
	data() {
		return {
			filter: ''
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.filter = event;
		});

		if (!this.filterParameter) {
			this.filter = this.propApi.items[0].key;
		} else {
			const filterItem = this.api.items.filter(x => x.value == this.filterParameter)[0];
			this.filter = filterItem ? filterItem.key : null;
		}
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.hideActions ? typeof obj.hideActions === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		filterParameter() {
			const url = new URL(window.location.href);
			const urlParam = url.searchParams.get('filter');
			return urlParam;
		},
		showDateFilters() {
			//TODO: Replace with vue-router or move to mixin
			if (!this.filterParameter) {
				return false;
			}
			return this.filterParameter == 'All' || this.filterParameter == 'Previous';
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'hire-table-update-event',
					text: 'profile details',
					items: [],
					postbackURL: '',
					postbackPipe: '',
					buttons: {
						showHelp: true,
						showPrint: true,
						showCsv: true,
						showExcel: true
					},
					buttonsLink: {
						helpLink: ''
					}
				},
				this.api
			);
		},
		itemKeys() {
			return (this.api?.items || []).map(x => x.key);
		}
	},
	watch: {
		filter() {}
	},
	methods: {
		applyFilter() {
			const filterItem = this.api?.items?.filter(x => x.key == this.filter)[0];
			if (!filterItem) {
				return;
			}
			//TODO: Replace with vue-router or move to mixin
			var url = new URL(window.location.href);
			if (!url.searchParams.get('filter')) {
				url.searchParams.append('filter', filterItem.value);
			} else {
				url.searchParams.set('filter', filterItem.value);
			}

			window.location.replace(url.href);
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit(fileType) {
			this.clickEvent('table-export-results', fileType);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
