// component
const component = {
	name: 'SclUserAccessForm',
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.accounts ? typeof obj.accounts === 'object' : true));
				set.add(!!(obj.addOwnerAccess ? typeof obj.addOwnerAccess === 'boolean' : true));
				set.add(!!(obj.addManagerAccess ? typeof obj.addManagerAccess === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		accountList() {
			return this.accounts;
		},
		propApi() {
			return Object.assign(
				{
					accountData: [],
					accounts: {},
					addOwnerAccess: false,
					addManagerAccess: false
				},
				this.api
			);
		},
		hasAccountSelected() {
			if (!this.accounts) {
				return false;
			}

			const self = this;
			var keys = Object.keys(this.accounts);
			if (this.accounts <= 0) {
				return false;
			}
			let maCount = 0;
			let saCount = 0;
			keys.forEach(key => {
				var item = self.accounts[key];
				if (!item) {
					return;
				}
				if (item.value) {
					maCount++;
				}
				item.siteAccounts.forEach(site => {
					if (site.value) {
						saCount++;
					}
				});
			});
			return maCount > 0 && saCount > 0;
		},
		isValid() {
			return (this.accountData.sendInvite === 'yes' && this.hasAccountSelected) || this.accountData.sendInvite === 'no';
		}
	},
	mounted() {
		this.accountData = this.propApi.accountData;
		this.accountData.accounts = this.propApi.accounts;
		this.accountData.sendInvite = 'yes';
		const self = this;
		//On the modal confirming a change, update the accounts
		this.$root.$on('select-account-access-event', data => {
			self.accounts = Object.assign({}, data);
		});
	},
	watch: {
		isValid: {
			handler(newVal) {
				this.$root.$emit('user-access-valid', newVal);
			}
		},
		accountData: {
			handler(newVal) {
				this.$root.$emit('stepper-data-access', newVal);
			},
			deep: true
		},
		managerType: {
			handler(newVal) {
				if (newVal == 'Admin') {
					this.accountData.coatesHireOwner = true;
					this.accountData.coatesHireManager = false;
				}
				if (newVal == 'Manager') {
					this.accountData.coatesHireOwner = false;
					this.accountData.coatesHireManager = true;
				}
			},
			deep: true
		},
		isManager: {
			handler(newVal) {
				if (!newVal) {
					this.managerType = null;
					this.accountData.coatesHireOwner = false;
					this.accountData.coatesHireManager = false;
				} else {
					//default to Admin if enabled
					this.accountData.managerType = 'Admin';
					this.accountData.coatesHireOwner = true;
					this.accountData.coatesHireManager = false;
				}
			},
			deep: true
		},
		'propApi.accountData': {
			handler(newVal) {
				this.$root.$emit('stepper-data-access', newVal);
				this.accountData = newVal;
				if (newVal.coatesHireOwner) {
					this.managerType = 'Admin';
				}
				if (newVal.coatesHireManager) {
					this.managerType = 'Manager';
				}
			},
			deep: true
		},
		'propApi.accountData.myHires': {
			handler(newVal) {
				if (newVal) {
					if (!this.accountData.myHiresWithRates) {
						this.accountData.myHiresWithRates = 'With Rates';
					}
					if (!this.accountData.myHiresWithEOHD) {
						this.accountData.myHiresWithEOHD = 'Edit EOHD';
					}
				} else {
					this.accountData.myHiresWithRates = null;
					this.accountData.myHiresWithEOHD = null;
				}
			},
			deep: true
		},
		'propApi.accountData.myPricebook': {
			handler(newVal) {
				if (newVal) {
					if (!this.accountData.myPricebookWithExport) {
						this.accountData.myPricebookWithExport = 'Without export';
					}
				} else {
					this.accountData.myPricebookWithExport = null;
				}
			},
			deep: true
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || 'This field is Required.'
			},
			accounts: {},
			accountSelected: null,
			isManager: null,
			managerType: null,
			tooltips: {
				coatesHireOwner:
					'‘Owner’ capability provides the user with full access to My Coates Hire functionality. This includes access to all hire and invoice related content across all accounts and sites. Importantly, it also allows the user to manage all My Coates Hire users, including the creation of ‘Managers’ who can in turn create & manage their own users.',
				coatesHireManager:
					'Over and above any other capabilities assigned, ‘Manager’ capability allows the user to create & manage his/her own users. NB. The access that a manager can assign to users cannot exceed his/her own capabilities to hires, invoices & sites.',
				myHires:
					'‘My Hires’ capability provides the user with access to hire related information. This includes the ‘My Hires’ tab within the ‘MY DASHBOARD’ page and all tabs within the ‘MY HIRES’ page. NB. ‘With Rates’ and ‘Without Rates’ displays or hides Hire Schedule rates/prices.',
				myInvoices:
					'‘My Invoices’ capability provides the user with access to invoice related information. This includes the ‘My Invoices’ tab within the ‘MY DASHBOARD’ page and all tabs within the ‘MY INVOICES’ page.',
				myPricebooks:
					'‘My Pricebook’ capability provides the user with access to pricing related information. This is viewed in the My Pricebook tab. ‘With Export’ allows the export of Pricing data whereas ‘Without Export’ is view only.',
				myQuoteRequests:
					'‘My Quote Requests capability provides the user with access to quoting related information. This is viewed in the My Quote Requests tab.'
			},
			accountData: {
				coatesHireOwner: false,
				coatesHireManager: false,
				myHires: true,
				myHiresWithRates: true,
				myHiresWithEOHD: true,
				myInvoices: true,
				myPricebook: false,
				myPricebookWithExport: null,
				myQuoteRequests: true,
				myTelemetry: false,
				sendInvite: 'yes',
				accounts: {},
				siteAccess: {}
			},
			rateGroup: ''
		};
	},
	methods: {
		previousStep() {},
		nextStep() {},
		onSiteAccessEvent(data) {
			this.accountData.siteAccess = data;
		}
	},
	template: null
};

// css import
require(`./_${component.name}.scss`);

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

export default component;
