/**
 * SclSocialShare api
 *
 **/
const template = `
<div class="scl-user-details scl-form">
		<v-container fluid pl-0>
			<v-layout row wrap>
				<v-flex xs12 class="scl-user-details__layout-inner pa-0">
					<h3>Personal details</h3>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Title:</span> {{accountData.title}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">First name:</span> {{accountData.firstName}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Surname:</span> {{accountData.lastName}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Job title:</span> {{accountData.jobTitle}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Role:</span> {{accountData.role}}</p>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 class="scl-user-details__layout-inner pa-0 mt-4">
					<h3>Login details</h3>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Email:</span> {{accountData.email}}</p>
				</v-flex>
			</v-layout>

			<v-layout row wrap>
				<v-flex xs12 class="scl-user-details__layout-inner pa-0 mt-4">
					<h3>Contact details</h3>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Phone:</span> {{accountData.mobileNumber}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Unit:</span> {{accountData.unit}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Street:</span> {{accountData.street}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Surburb:</span> {{accountData.suburb}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">State:</span> {{accountData.state}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 pt-2">
					<p class="mb-0"><span class="font-weight-regular ">Postcode:</span> {{accountData.postcode}}</p>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
