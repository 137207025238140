/**
 * SclGroupList
 *
 */

const template = `
	<div class="scl-group-list">
		<v-container grid-list-xl fluid>
			<v-layout class="scl-group-list__status-item" :class="{'pb-4':index < data.length-1}" row wrap v-for="(listItem, index) in data">
				<v-flex xs12 class="pa-0">
					<h3>{{listItem.label}}</h3>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0" v-if="listItem.type === 'chips'">
					<v-chip v-for="(item, k) in listItem.value" label outline text-color="#4E4A46" class="font-weight-bold scl-threshold-setting__chip subheading" :key="k">
						<v-icon size="16" left :color="item.color">fas fa-circle</v-icon>{{item.label}}
					</v-chip>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0" v-if="listItem.type === 'text' || typeof listItem.type === 'undefined'">
					<p class="mb-3" v-for="(item, k) in listItem.value" :key="k">{{item}}</p>
				</v-flex>
				<v-flex xs12 class="pb-0 pl-0 d-flex justify-space-between" v-if="listItem.type === 'password'">
					<div class="mb-3" v-for="(item, k) in listItem.value" :key="k">
						<v-icon size="14" class="scl-group-list__password-mask" left color="#000000" v-for="(item, j) in item.password" :key="j">fas fa-circle</v-icon>
					</div>
					<div class="flex text-xs-right scl-group-list__password-button">
						<scl-button
							color="accent"
							class="scl-component scl-button"
							depressed :api="{modal: 'change-password-event', copy: 'Change password'}"
						></scl-button>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
