/**
 * SclUpdateAccountAccessModal
 */

// template
const template = `
<div class="scl-update-user-access-modal scl-dialog">
	<div class="text-xs-center">
		<v-dialog v-model="dialog" :width="propApi.width" :persistent="propApi.persistent" content-class="scl-modal__component">
			<template v-slot:activator="{ on }">
				<v-btn color="accent" class="scl-component scl-button d-none" depressed v-on="on"> modal </v-btn>
			</template>

			<v-card class="fill-height">
				<div class="scl-user-access-form scl-form">
					<v-toolbar class="scl-dialog__toolbar" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{ propApi.modalLabel }}
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl fluid pa-4>
						<v-layout row wrap>
							<!-- Role -->
							<v-flex pb-0 xs12>
								<div class="d-inline-flex">
									<v-checkbox v-model="isManager" label="Administrative users" hide-details class="mt-0 pt-0"></v-checkbox>
									<scl-tooltip
										v-if="managerType == 'Admin' || managerType == ''"
										:api="{ icon: 'far fa-question-circle', message: tooltips.coatesHireOwner }"
									></scl-tooltip>
									<scl-tooltip v-else :api="{ icon: 'far fa-question-circle', message: tooltips.coatesHireManager }"></scl-tooltip>
								</div>
							</v-flex>
							<v-flex pt-4 pb-0 xs12>
								<v-radio-group
									:disabled="!isManager"
									v-model="managerType"
									class="mt-0 pb-0 pl-4 d-flex"
									:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
								>
									<v-layout row wrap>
										<v-flex xs4>
											<v-radio label="Coates Connect Administrator" value="Admin"></v-radio>
										</v-flex>
										<v-flex>
											<v-radio label="Coates Connect Manager" value="Manager"></v-radio>
										</v-flex>
									</v-layout>
								</v-radio-group>
							</v-flex>

							<v-divider class="scl-update-user-access-modal__divider"></v-divider>

							<v-flex xs12>
								<h3 class="pb-3 font-weight-black title">Capabilities</h3>
								<v-layout row wrap>
									<v-flex xs3>
										<v-checkbox v-model="accountData.myHires" label="My hires" hide-details class="mt-0 pt-0"></v-checkbox>
									</v-flex>
									<v-flex xs9>
										<v-layout row wrap>
											<v-flex xs12>
												<v-radio-group
													:disabled="!accountData.myHires"
													row
													v-model="accountData.myHiresWithRates"
													class="mt-0 d-flex"
													:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
												>
													<v-layout row wrap>
														<v-flex xs4>
															<v-radio label="With rates" value="With Rates"></v-radio>
														</v-flex>
														<v-flex>
															<v-radio label="Without rates" value="Without Rates"></v-radio>
														</v-flex>
													</v-layout>
												</v-radio-group>
											</v-flex>
											<v-flex xs12>
												<v-radio-group
													:disabled="!accountData.myHires"
													row
													v-model="accountData.myHiresWithEOHD"
													class="mt-0 d-flex"
													:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
												>
													<v-layout row wrap>
														<v-flex xs4>
															<v-radio label="Edit EOHD" value="Edit EOHD"></v-radio>
														</v-flex>
														<v-flex>
															<v-radio label="Disable Edit EOHD" value="Disable Edit EOHD"></v-radio>
														</v-flex>
													</v-layout>
												</v-radio-group>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</v-flex>

							<v-flex xs12>
								<v-checkbox v-model="accountData.myInvoices" label="My Invoices" hide-details class="mt-0 pt-0 mb-3"></v-checkbox>
							</v-flex>

							<v-flex xs12>
								<v-layout row wrap>
									<v-flex xs3>
										<v-checkbox v-model="accountData.myPricebook" label="My Pricebook" hide-details class="mt-0 pt-0"></v-checkbox>
									</v-flex>
									<v-flex xs9>
										<v-radio-group
											:disabled="!accountData.myPricebook"
											row
											v-model="accountData.myPricebookWithExport"
											class="mt-0 d-flex"
											:class="{ 'ml-3': $vuetify.breakpoint.mdAndUp }"
										>
											<v-layout row wrap>
												<v-flex xs4>
													<v-radio label="With export" value="With export"></v-radio>
												</v-flex>
												<v-flex>
													<v-radio label="Without export" value="Without export"></v-radio>
												</v-flex>
											</v-layout>
										</v-radio-group>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12>
								<v-checkbox v-model="accountData.myQuoteRequests" label="My Quote Requests" hide-details class="mt-0 pt-0 mb-3"></v-checkbox>
							</v-flex>
							<v-flex xs12>
								<v-checkbox v-model="accountData.myTelemetry" label="My Telemetry" hide-details class="mt-0 pt-0 mb-3"></v-checkbox>
							</v-flex>

							<v-divider class="scl-update-user-access-modal__divider"></v-divider>

							<v-flex xs12>
								<v-card-actions class="justify-end pa-0" :class="{ 'd-flex': $vuetify.breakpoint.smAndDown }">
									<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">Cancel</v-btn>
									<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit">Save</v-btn>
								</v-card-actions>
							</v-flex>
						</v-layout>
					</v-container>
				</div>
			</v-card>
		</v-dialog>
	</div>
</div>
`;

// export template
export default template;
