/**
 * SclEnquireModal
 */

// template
const template = `
	<v-tooltip top content-class="scl-tooltip">
		<template v-slot:activator="{ on }">
			<v-icon color="#ff6105" dark v-on="on" size="18" class="ml-2 mb-1">{{propApi.icon}}</v-icon>
		</template>
		<span>{{propApi.message}}</span>
	</v-tooltip>
`;

// export template
export default template;
