// component
const component = {
	name: 'SclBottomSheetFeatured',
	data() {
		return {
			sheet: false,
			buttonLabel: 'watch video',
			disabledSheet: false
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.inset ? typeof obj.inset === 'boolean' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.value ? typeof obj.value === 'boolean' : true));
				set.add(!!(obj.hideOverlay ? typeof obj.hideOverlay === 'boolean' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.subtitle ? typeof obj.subtitle === 'string' : true));
				set.add(!!(obj.videoURLMp4 ? typeof obj.videoURLMp4 === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'bottom-sheet-event',
					inset: false,
					persistent: false,
					value: false,
					hideOverlay: false,
					title: '',
					subtitle: '',
					videoURLMp4: '',
					videoURLMOgg: '',
					link: {
						label: 'watch video',
						link: 'https://',
						outline: false,
						icon: null
					}
				},
				this.api
			);
		}
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.sheet = true;
		});
	},
	methods: {
		playPause() {
			if (this.$refs.featuredVideo.paused) {
				this.$refs.featuredVideo.play();
				this.buttonLabel = 'pause video';
			} else {
				this.$refs.featuredVideo.pause();
				this.buttonLabel = 'watch video';
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
