import axios from 'axios';

// component
const component = {
	name: 'SclUpdateAdminAccessModal',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.accountData ? typeof obj.accountData === 'object' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.label ? typeof obj.label === 'string' : true));
				set.add(!!(obj.copy ? typeof obj.copy === 'string' : true));
				set.add(!!(obj.buttonCancelLabel ? typeof obj.buttonCancelLabel === 'string' : true));
				set.add(!!(obj.buttonCommitLabel ? typeof obj.buttonCommitLabel === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.modalData ? typeof obj.modalData === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					accountData: {},
					email: '',
					event: '',
					width: '',
					label: '',
					copy: '',
					buttonCancelLabel: '',
					buttonCommitLabel: '',
					postbackURL: '',
					modalData: ''
				},
				this.api
			);
		}
	},
	data() {
		return {
			dialog: false,
			modalD: '0011',
			accountData: {
				status: '',
				availableStatus: ['on hold', 'active', 'cancelled']
			}
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					email: this.propApi.email,
					assignedCapabilities: {
						coatesSales: this.propApi.accountData.coatesSales,
						coatesSalesSupport: this.propApi.accountData.coatesSalesSupport,
						coatesSystemAdministrator: this.propApi.accountData.coatesSystemAdministrator,
						viewOnlyAdmin: this.propApi.accountData.viewOnlyAdmin
					}
				};

				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response.status == 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'Admin access updated successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				} else {
					const snackBarMessage = {
						copy: response.data.message || 'Admin access update failed, please try again later.',
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
				this.dialog = false;
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},
		async getResults(data, url) {
			return await axios.post(url, data);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
