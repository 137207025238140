import axios from 'axios';

// Mixins
import mxDate from '../../mixins/common/mxDate.js';

// component
const component = {
	name: 'SclAdminTable',
	mixins: [mxDate],
	data() {
		return {
			selected: [],
			pagination: {
				sortBy: null,
				page: 0,
				rowsPerPage: 25,
				total: 0
			},
			indeterminate: false,
			tableQuery: [],
			results: [],
			loading: null,
			headers: [],
			total: 0,
			tableLoaded: 0,
			tableData: {
				tableItems: []
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.header ? typeof obj.header === 'string' : true));
				set.add(!!(obj.tableData ? typeof obj.tableData === 'object' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					header: 'Current Subscription',
					event: 'add-user-to-form-event',
					postbackURL: '',
					postbackPipe: '',
					navigationEditURL: '',
					tableData: {}
				},
				this.api
			);
		},
		pageSizeQueryParam() {
			const url = new URL(window.location.href);
			const param = url.searchParams.get('rowsPerPage');
			const pageSize = param ? parseInt(param) : 25;
			if (pageSize > 0 && pageSize <= 25) {
				return pageSize;
			}
			return 25;
		},
		filteredResults() {
			return this.results;
		}
	},
	watch: {
		selected() {
			if (!!this.selected && this.selected.length > 0 && this.selected.length != this.propApi.tableData.tableItems) {
				this.indeterminate = true;
			}
			if (!!this.selected && this.selected.length === 0) {
				this.indeterminate = false;
			}
		},
		'propApi.tableData': function (newVal) {
			if (!newVal) {
				return;
			}
			this.tableData = newVal;
			this.tableQuery = newVal.tableQuery ? newVal.tableQuery : [];
		}
	},
	async mounted() {
		const self = this;
		this.$root.$on('loadingSpinner', ev => {
			this.loading = ev;
		});
		this.$root.$on('table-filter-query', filters => {
			self.tableQuery = filters;
		});
		this.$root.$on('table-filter-results', event => {
			if (!event || !event.result) {
				return;
			}
			self.results = event.result.results;
			self.pagination.total = event.result.total;
			self.total = event.result.total;
			if (self.$refs.dataTable) {
				self.$refs.dataTable.$forceUpdate();
			}
		});
		this.pagination.rowsPerPage = this.pageSizeQueryParam;
		this.$root.$emit('table-update-pagination', this.pagination);
		this.results = !this.api ? [] : this.api.tableData.tableItems;
		this.headers = !this.api ? [] : this.api.tableData.headers;
	},
	methods: {
		toggleRowSelect() {
			const self = this;
			if (!!this.selected && this.selected.length > 0) {
				this.selected = [];
				this.indeterminate = false;
				return;
			}
			if (!!this.selected && this.selected.length === 0) {
				this.results.forEach(item => self.selected.push(item));
				return;
			}
		},
		searched(val, key) {
			if (!val) {
				return '';
			}
			let tableCellString = val;
			if (!key) {
				return tableCellString;
			}
			const itemKey = String(key).toLowerCase();

			this.tableQuery.forEach(queryItem => {
				if (itemKey === queryItem.category.toLowerCase() || queryItem.category.toLowerCase() === 'all') {
					const textRegExp = new RegExp(queryItem.query, 'gi');
					if (!!this.results && this.results.length > 0 && queryItem.query != '') {
						tableCellString = tableCellString.replace(textRegExp, function (r) {
							return '<span class="scl-expandable-table__table-row--searchmatch">' + r + '</span>';
						});
					}
				}
			});
			return tableCellString;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		getEditUrl(item) {
			return `${this.api.navigationEditURL}${item.userId}`;
		},
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		},
		async updatePagination(data) {
			this.pagination.page = data.page;
			this.pagination.rowsPerPage = data.rowsPerPage;
			this.pagination.descending = data.descending;
			this.pagination.sortBy = data.sortBy;
			this.$root.$emit('table-update-pagination', this.pagination);
		},
		getKeyByValue(object, value) {
			if (!object || !value) {
				return;
			}
			return Object.keys(object).find(key => object[key] === value);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
