import axios from 'axios';
import JsonCsv from 'vue-json-excel';

// Mixins
import mxDate from '@portals/mixins/common/mxDate.js';

// component
const component = {
	name: 'SclTableFilter',
	mixins: [mxDate],
	components: {
		JsonCsv
	},
	props: {
		api: {
			type: Object,
			default: () => ({}),
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.hideActions ? typeof obj.hideActions === 'boolean' : true));
				set.add(!!(obj.filterMasterAccount ? typeof obj.filterMasterAccount === 'string' : true));
				set.add(!!(obj.filterSiteAccount ? typeof obj.filterSiteAccount === 'string' : true));
				set.add(!!(obj.accountListing ? Array.isArray(obj.accountListing) : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.showCSV ? typeof obj.showCSV === 'boolean' : true));
				set.add(!!(obj.showPackageOption ? typeof obj.showPackageOption === 'boolean' : true));
				set.add(!!(obj.statePackageOption ? typeof obj.statePackageOption === 'boolean' : true));
				set.add(!!(obj.isFluid ? typeof obj.isFluid === 'boolean' : true));
				set.add(!!(obj.category ? typeof obj.category === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			loading: false,
			model: null,
			query: '',
			customQuery: null,
			packageDetails: true,
			maxFromDate: null,
			minToDate: null,
			dateFromMenu: false,
			dateToMenu: false,
			dateFrom: new Date().toISOString().substr(0, 10),
			dateTo: new Date().toISOString().substr(0, 10),
			dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			searchChips: [],
			searchQuery: '',
			searchCategory: null,
			searchHeaders: [],
			pagination: {
				page: 0,
				rowsPerPage: 25
			},
			showCSV: true,
			showPackageOption: false,
			selectFilters: [],
			selectFilter1: null,
			selectFilter2: null
		};
	},
	created() {
		if (this.propApi.fromDate) {
			this.dateFromFormatted = this.propApi.fromDate;
			var date = this._mxDate_parseDateString(this.propApi.fromDate);
			this.dateFrom = date.toISOString().substr(0, 10);
		} else {
			this.dateFrom = this.getPastYearDate();
			this.dateFromFormatted = this.formatDate(this.dateFrom);
		}
		this.$root.$on('table-update-pagination', async pagination => {
			this.pagination = pagination;
			this.setQueryStringParameter('rowsPerPage', this.pagination.rowsPerPage);
			await this.submitSearch();
		});
	},
	mounted() {
		this.$root.$on('table-export-results', async fileType => {
			await this.submitExport(fileType);
		});

		this.$root.$on('table-update-search', async query => {
			this.customQuery = query;
			await this.submitSearch();
		});

		this.$root.$on('export-csv-results', async fileType => {
			await this.submitExportAll(fileType);
		});

		this.$nextTick(() => {
			if (this.propApi.selectFilters !== '') {
				this.selectFilters = this.propApi.selectFilters;
			}
			if (typeof this.propApi.tableData !== 'undefined') {
				this.searchHeaders = this.propApi.tableData.headers;
				this.initSearchFilters();
			}
			if (this.propApi.statePackageOption !== null && this.propApi.showPackageOption) {
				this.packageDetails = this.propApi.statePackageOption;
			}

			if (this.propApi.category !== '') {
				this.searchCategory = this.propApi.category;
			}
			this.submitSearch();
		});
	},
	watch: {
		dateFrom(date) {
			this.dateFromFormatted = this.formatDate(date);
		},
		dateTo(date) {
			this.dateToFormatted = this.formatDate(date);
		},
		searchHeader() {
			this.$root.$emit('table-filter-query', this.searchChips);
		},
		packageDetails() {
			this.submitSearch();
		},
		'propApi.tableData': {
			handler(newVal) {
				if (!newVal) {
					return;
				}
				this.tableData = newVal[0];
				this.initSearchFilters();
			},
			deep: true,
			immediate: true
		},
		selectFilter1() {
			this.submitSearch();
		},
		selectFilter2() {
			this.submitSearch();
		}
	},
	computed: {
		showDateFilters() {
			//TODO: Replace with vue-router or move to mixin
			return this.propApi.showDateFilters;
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					text: 'profile details',
					filterMasterAccount: null,
					filterSiteAccount: null,
					postbackURL: null,
					tableData: [],
					showCSV: false,
					showPackageOption: false,
					statePackageOption: null,
					isFluid: false,
					category: ''
				},
				this.api
			);
		},
		pageSizeQueryParam() {
			const url = new URL(window.location.href);
			const param = url.searchParams.get('rowsPerPage');
			const pageSize = param ? parseInt(param) : 25;
			if (pageSize > 0 && pageSize <= 25) {
				return pageSize;
			}
			return 25;
		},
		filterParameter() {
			const url = new URL(window.location.href);
			return url.searchParams.get('filter');
		},
		searchLabel() {
			return 'Search by ' + (this.searchCategory === null ? '' : this.searchCategory?.text);
		}
	},
	methods: {
		getPastYearDate() {
			var lastMonthDate = new Date();
			lastMonthDate.setMonth(lastMonthDate.getMonth() - 12);
			return lastMonthDate.toISOString().substr(0, 10);
		},
		setQueryStringParameter(name, value) {
			const params = new URLSearchParams(window.location.search);
			params.set(name, value);
			window.history.replaceState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
		},
		initSearchFilters() {
			//remove searchHeaders that are also selectFilters, so that you can't search a field by both text and dropdown.
			if (this.selectFilters !== null) {
				for (var i = 0; i < this.selectFilters.length; i++) {
					for (var j = this.searchHeaders.length - 1; j >= 0; j--) {
						if (
							this.searchHeaders[j].text !== null &&
							this.searchHeaders[j].text.toLowerCase() === this.selectFilters[i].categoryName.toLowerCase()
						) {
							this.searchHeaders.splice(j, 1);
						}
					}
				}
			}
			this.searchCategory = this.searchHeaders[0];
		},
		async submitSearch() {
			this.errorMessage = null;
			// prevent search being run twice
			if (this.loading) {
				return;
			}
			this.loading = true;
			this.$root.$emit('loadingSpinner', true);
			try {
				if (this.searchQuery !== '') {
					this.createSearchChip();
				}
				this.$root.$emit('table-filter-query', this.searchChips);
				var page = this.pagination.page > 1 ? this.pagination.page - 1 : 0;
				const collatedSearchChips = this.collateSearch(this.searchChips);

				const payload = {
					page: page,
					pageSize: this.pagination.rowsPerPage || 10,
					sortBy: this.pagination.sortBy,
					descending: !!this.pagination.descending,
					filters: collatedSearchChips,
					packageDetails: this.packageDetails,
					fromDate: this.dateFromFormatted,
					toDate: this.dateToFormatted,
					hireType: this.filterParameter || 'Current'
				};

				// eslint-disable-next-line no-extra-boolean-cast
				if (!!this.customQuery) {
					var keys = Object.keys(this.customQuery);
					for (var i = 0; i < keys.length; i++) {
						payload[keys[i]] = this.customQuery[keys[i]];
					}
				}

				if (
					this.propApi.postbackURL !== '/api/v1/mch/pricebooks/search' ||
					(!!this.customQuery?.masterAccountId && !!this.customQuery?.siteAccountId)
				) {
					const response = await this.getResults(payload, this.propApi.postbackURL);
					if (response && response.status) {
						const result = response.data;
						this.$root.$emit('table-filter-results', result);
					} else {
						this.errorMessage = 'Loading accounts failed, please try again later.';
					}
				}
			} catch (e) {
				this.errorMessage = 'Loading accounts failed, please try again later.';
			} finally {
				this.loading = false;
				this.$root.$emit('loadingSpinner', false);
			}
		},

		async submitExport(fileType) {
			this.loading = true;
			this.$root.$emit('loadingSpinner', true);
			this.errorMessage = null;
			try {
				var page = this.pagination.page > 1 ? this.pagination.page - 1 : 0;
				const collatedSearchChips = this.collateSearch(this.searchChips);
				const payload = {
					page: page,
					pageSize: this.pagination.rowsPerPage || 10,
					sortBy: this.pagination.sortBy,
					descending: !!this.pagination.descending,
					filters: collatedSearchChips,
					packageDetails: this.packageDetails,
					fromDate: this.dateFromFormatted,
					toDate: this.dateToFormatted,
					hireType: this.filterParameter || 'Current',
					fileType: fileType
				};
				// eslint-disable-next-line no-extra-boolean-cast
				if (!!this.customQuery) {
					var keys = Object.keys(this.customQuery);
					for (var i = 0; i < keys.length; i++) {
						payload[keys[i]] = this.customQuery[keys[i]];
					}
				}
				if (this.searchChips.length > 0) {
					payload.filters = this.searchChips;
				}
				const result = await this.getAJAXResults(payload, this.propApi.exportPostbackURL);
				const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));

				const contentDisposition = result.headers['content-type'];
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', contentDisposition); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();

				if (result.statusCode === 200) {
					this.showRequestSuccess = true;
					//this.results = result.results;
				} else {
					this.errorMessage = 'Hire detail update failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Hire detail update failed, please try again later.';
			} finally {
				this.loading = false;
				this.$root.$emit('loadingSpinner', false);
			}
		},

		async submitExportAll(fileType) {
			this.loading = true;
			this.$root.$emit('loadingSpinner', true);
			this.errorMessage = null;
			try {
				const collatedSearchChips = this.collateSearch(this.searchChips);
				const payload = {
					page: 0,
					pageSize: 99999999,
					sortBy: this.pagination.sortBy,
					descending: !!this.pagination.descending,
					filters: collatedSearchChips,
					packageDetails: this.packageDetails,
					fromDate: this.dateFromFormatted,
					toDate: this.dateToFormatted,
					hireType: this.filterParameter || 'Current',
					fileType: fileType
				};
				if (this.customQuery) {
					var keys = Object.keys(this.customQuery);
					for (var i = 0; i < keys.length; i++) {
						payload[keys[i]] = this.customQuery[keys[i]];
					}
				}
				if (this.searchChips.length > 0) {
					payload.filters = this.searchChips;
				}
				const result = await this.getAJAXResults(payload, this.propApi.exportPostbackURL);
				const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
				const contentDisposition = result.headers['content-type'];
				const link = document.createElement('a');

				link.href = downloadUrl;
				link.setAttribute('download', contentDisposition); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();

				if (result.statusCode === 200) {
					this.showRequestSuccess = true;
				} else {
					this.errorMessage = 'Download failed, please try again later.';
				}
			} catch (e) {
				console.error(e);
				this.errorMessage = 'Download failed, please try again later.';
			} finally {
				this.loading = false;
				this.$root.$emit('loadingSpinner', false);
			}
		},

		async getAJAXResults(data, url) {
			const formData = new FormData();
			const keys = Object.keys(data);
			var form = document.createElement('form');
			form.action = url;
			form.method = 'post';
			form.target = '_blank';

			for (var i = 0; i < keys.length; i++) {
				const key = keys[i];
				if (data[key]) {
					//TODO: change to value type of == array
					if (key == 'filters') {
						for (var j = 0; j < data[key].length; j++) {
							const queryFilterKey = `${key}[${j}][query]`;
							const queryFilterValue = data[key][j].query;
							formData.append(queryFilterKey, queryFilterValue);
							const categoryFilterKey = `${key}[${j}][category]`;
							const categoryFilterValue = data[key][j].category;
							formData.append(categoryFilterKey, categoryFilterValue);
						}
					} else {
						formData.append(key, data[key]);
					}
				}
			}
			document.body.appendChild(form);
			return await axios({
				url: url,
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				method: 'POST',
				responseType: 'blob', // important
				data: formData
			});
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		},

		async fetchDownloadData() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				if (this.searchQuery !== '') {
					this.createSearchChip();
				}
				const collatedSearchChips = this.collateSearch(this.searchChips);
				const payload = {
					page: 0,
					pageSize: -1,
					sortBy: this.pagination.sortBy,
					descending: !!this.pagination.descending,
					filters: collatedSearchChips,
					packageDetails: this.packageDetails,
					fromDate: this.dateFromFormatted,
					toDate: this.dateToFormatted,
					hireType: this.filterParameter || 'Current'
				};
				const response = await this.getResults(payload, this.propApi.postbackURL);

				if (response && response.status) {
					const result = response.data;
					return result;
				} else {
					this.errorMessage = 'Download failed, please try again later.';
				}
			} catch (e) {
				this.errorMessage = 'Download failed, please try again later.';
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		},

		clickEvent(event) {
			this.$root.$emit(event);
		},
		formatDate(date) {
			const formattedDate = this._mxDate_formatDate(date);
			return formattedDate;
		},
		parseDateStr(dateStr) {
			return this._mxDate_parseDateStr(dateStr);
		},
		parseDate(date) {
			if (!date) {
				return null;
			}

			const [day, month, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		createSearchChip() {
			const chip = {
				query: this.searchQuery,
				category: this.searchCategory.value
			};

			this.searchChips.push(chip);
			this.searchQuery = '';
		},
		async removeSearchChip(item) {
			this.searchChips.splice(this.searchChips.indexOf(item), 1);
			this.searchChips = [...this.searchChips];
			await this.submitSearch();
		},
		eventFieldFocusOut() {
			if (this.searchQuery !== '') {
				this.createSearchChip();
			}
		},
		async clearChips() {
			if (this.searchChips.length > 0) {
				this.searchChips = [];
			}
			if (this.selectFilter1) {
				this.selectFilter1 = null;
			}
			if (this.selectFilter2) {
				this.selectFilter2 = null;
			}
			await this.submitSearch();
		},
		clearSelectFilter1() {
			this.selectFilter1 = null;
		},
		clearSelectFilter2() {
			this.selectFilter2 = null;
		},
		collateSearch(searchInput) {
			const queryArr = searchInput.length === 0 ? [] : [...searchInput];
			if (this.selectFilter1) {
				const itemObj = {
					category: this.selectFilters[0].categoryName,
					query: this.selectFilter1.value
				};
				queryArr.push(itemObj);
			}
			if (this.selectFilter2) {
				const itemObj = {
					category: this.selectFilters[1].categoryName,
					query: this.selectFilter2.value
				};
				queryArr.push(itemObj);
			}
			return queryArr;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
