<template>
	<div class="scl-quote-request__container">
		<v-layout row wrap class="my-0">
			<v-flex xs12>
				<h2 class="scl-quote-request__title">Equipment Required</h2>
				<p class="mb-4">{{ subTitle }}</p>
			</v-flex>
		</v-layout>

		<!-- Loop over items in model and allow us to update each objects props -->
		<v-layout row v-for="(item, key) in userList" :key="key" class="equipment-row">
			<v-flex xs12 class="equipment-row__number-input">
				<label for="quantity" class="scl-form__label">
					{{ propApi.equipment.quantity.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
				</label>
				<vue-number-input
					v-model="item.quantity"
					:min="1"
					:step="1"
					@change="updateEquipmentList"
					:disabled="isReadOnly"
					:readonly="isReadOnly"
				></vue-number-input>
			</v-flex>
			<v-flex xs12 md4>
				<label for="type" class="scl-form__label">
					{{ propApi.equipment.type.label }} <span v-if="!isReadOnly" class="scl-form__label--additional">Required</span>
				</label>
				<v-text-field
					v-model="item.type"
					name="type"
					type="text"
					:placeholder="propApi.equipment.type.placeholder"
					class="scl-form__input"
					:aria-label="`Equpiment type ${key + 1}`"
					data-cy="Equpiment type"
					:rules="[rules.required]"
					required
					outline
					:disabled="isReadOnly"
					:readonly="isReadOnly"
					@change="updateEquipmentList"
				></v-text-field>
			</v-flex>
			<v-flex xs12 md7>
				<label for="description" class="scl-form__label">
					{{ propApi.equipment.description.label }}<span v-if="!isReadOnly" class="scl-form__label--additional">Optional</span>
				</label>
				<v-text-field
					v-model="item.description"
					name="description"
					type="text"
					class="scl-form__input"
					data-cy="Equpiment description"
					:placeholder="isReadOnly ? item.description : propApi.equipment.description.placeholder"
					outline
					:disabled="isReadOnly"
					:readonly="isReadOnly"
					@change="updateEquipmentList"
				></v-text-field>
			</v-flex>
			<v-flex xs12 v-if="!isReadOnly" class="equipment-row__remove-product">
				<img
					:class="[{ 'scl-form__remove--active': item.isActive }, 'white', 'scl-form__remove', 'd-flex', 'justify-center']"
					src="/assets/img/trash.svg"
					data-cy="remove equipment"
					@click="removeProduct(key)"
				/>
			</v-flex>
		</v-layout>
		<v-btn
			v-if="!isReadOnly"
			:ripple="false"
			color="accent"
			id="addProduct"
			class="scl-form__btn scl-button"
			data-cy="Add another equipment"
			depressed
			@click="addProduct"
			><v-icon class="scl-button__icon">fas fa-plus</v-icon> Add another product
		</v-btn>
	</div>
</template>

<script>
import VueNumberInput from '@portals/components/form/VueNumberInput.vue';

const createNewEquipmentItem = () => ({
	quantity: 1,
	description: '',
	type: '',
	isActive: false
});
const SclQuoteEquipmentRequired = {
	name: 'SclQuoteEquipmentRequired',
	components: {
		VueNumberInput
	},
	data() {
		return {
			rules: {
				required: value => !!value || 'This field is required.'
			},
			userList: []
		};
	},
	props: {
		api: {
			type: Object,
			default: () => {}
		},
		equipmentList: {
			type: Array,
			default: () => Array(1).fill({}).map(createNewEquipmentItem)
		},
		isReadOnly: {
			type: Boolean
		},
		subTitle: {
			type: String
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					equipment: {
						quantity: {
							label: 'Quantity',
							placeholder: '',
							value: null
						},
						type: {
							label: 'Equipment Type',
							placeholder: 'Demolition Saw',
							value: ''
						},
						description: {
							label: 'Description',
							placeholder: 'The slab is 300mm thick and I have approximately 300m of cutting to complete',
							value: ''
						}
					}
				},
				this.api
			);
		}
	},
	mounted() {
		if (this.equipmentList) {
			this.userList = this.equipmentList.map(obj => (obj.type ? { ...obj, isActive: true } : { ...obj }));
		}
	},
	methods: {
		updateEquipmentList() {
			this.$emit('update-equipment', this.userList);
		},
		addProduct() {
			this.userList.forEach(item => {
				item.isActive = true;
			});
			this.userList.push(createNewEquipmentItem());
		},
		removeProduct(key) {
			this.userList.splice(key, 1);
			this.$emit('update-equipment', this.userList);
		},
		clear() {
			this.userList.splice(1);
			this.userList[0] = {
				quantity: 1,
				description: '',
				type: '',
				isActive: false
			};
		}
	}
};
export default SclQuoteEquipmentRequired;
</script>
