<template>
	<div>
		<scl-page-header-new :api="propApi" class="scl-new-header">
			<template v-slot:titleSection>
				<v-select
					v-model="propApi.showAllQuoteRequests"
					:items="propApi.filterQuoteRequestOptions"
					item-text="text"
					item-value="value"
					return-object
					label=""
					outline
					hide-details
					solo
					flat
					class="scl-new-header__title-select scl-form__select_small"
					append-icon="far fa-chevron-down icon-orange"
					@change="showAllQuoteRequestsChanged"
				>
				</v-select>
			</template>
			<template v-slot:iconGroup>
				<v-btn
					:href="propApi.createNewQuoteRequestLinkUrl"
					target="_blank"
					depressed
					class="scl-button fit-content"
					data-cy="request a quote btn"
					outlined
				>
					<v-icon class="scl-button__icon scl-button__light">far fa-plus-circle</v-icon>{{ propApi.createNewQuoteRequestLinkLabel }}
				</v-btn>
			</template>
		</scl-page-header-new>
		<v-container class="fluid">
			<v-layout row wrap flex pa-0 :class="{ xs12: $vuetify.breakpoint.smAndDown, xs6: $vuetify.breakpoint.mdAndUp }">
				<v-flex pa-0 shrink xs4>
					<v-select
						v-model="selectedFilter"
						:items="filterByItems"
						item-text="text"
						item-value="key"
						return-object
						label=""
						outline
						hide-details
						solo
						flat
						class="scl-form__input scl-form__select"
						append-icon="far fa-chevron-down icon-orange"
						@change="filterConfigChange"
					>
					</v-select>
				</v-flex>
				<v-flex pa-0 grow :class="{ xs8: $vuetify.breakpoint.smAndDown, xs8: $vuetify.breakpoint.mdAndUp }">
					<scl-quotes-search-filter :filterConfig="filterConfig" solo outline @filter-updated="filterUpdateHandler"></scl-quotes-search-filter>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs10 class="scl-table-filter__search-chips pl-0">
					<v-chip
						close
						v-for="searchChip in searchChips"
						color="#ff6105"
						:key="searchChip[0]"
						class="scl-table-filter__search-chips"
						@input="removeSearchChip(searchChip[0])"
					>
						<strong class="mx-2">{{ searchChip[1].filterValue }}</strong
						>({{ searchChip[1].text }})
					</v-chip>
				</v-flex>
				<v-flex xs2 text-xs-right>
					<v-btn
						data-cy="clear-all-chips"
						flat
						small
						class="scl-table-filter__clear-btn text-capitalize font-weight-bold"
						color="#ff6105"
						v-if="searchChips.size > 0"
						@click="removeAllChips"
					>
						Clear Tags <v-icon size="18" class="pl-2">fas fa-times</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs12>
					<v-data-table
						:headers="headers"
						:items="quotesData"
						:pagination.sync="pagination"
						item-key="ID"
						@update:pagination="updatePagination"
						:total-items="pagination.total"
						:must-sort="true"
					>
						<template v-slot:no-data>
							<td colspan="8" v-if="!propApi.accountItems.length" class="pl-0 scl-quotes-table__empty-row">
								No data available, please select customer.
							</td>
							<td colspan="8" v-if="propApi.accountItems.length" class="pl-0 scl-quotes-table__empty-row">No data available.</td>
						</template>

						<template v-slot:items="props">
							<tr>
								<td class="pl-0">
									<a :href="'/my-quote-requests/' + props.item.ID" target="_blank" class="quote-id"> QR{{ props.item.ID }} </a>
								</td>
								<td class="pl-0">{{ props.item.CreateDate }}</td>
								<td class="pl-0">{{ props.item.CreateBy }}</td>
								<td class="pl-0">{{ props.item.MasterAccountName }}</td>
								<td class="pl-0">{{ props.item.SiteAccountName }}</td>
								<td class="pl-0" v-html="props.item.Period"></td>
								<td class="pl-0" v-html="props.item.Equipments"></td>
								<td class="pl-0">{{ props.item.CustomerReference }}</td>
								<!-- <td class="pl-0">{{ props.item.Status }}</td> -->
								<td>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<a :href="`/my-quote-requests/Duplicate/${props.item.ID}`" target="_blank" class="quote-dupicate">
												<v-icon v-on="on" class="d-flex justify-center scl-quotes-table__action-icon">far fa-copy</v-icon>
											</a>
										</template>
										<span>Duplicate request</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
import mxApi from '@portals/mixins/common/mxApi.js';
import SclPageHeaderNew from '@portals/components/header/SclPageHeaderNew.vue';
import SclQuotesSearchFilter from '@portals/components/quotes/SclQuotesSearchFilter.vue';
import moment from 'moment';

const sclQuotesView = {
	name: 'SclQuotesView',
	mixins: [mxApi],
	components: {
		SclPageHeaderNew,
		SclQuotesSearchFilter
	},
	props: {
		api: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			selectedFilter: 'CreatedBy',
			filterConfig: {
				text: 'Created By',
				key: 'CreatedBy',
				type: 'text'
			},
			filterByItems: [
				{
					text: 'ID',
					key: 'ID',
					type: 'text'
				},
				{
					text: 'Created By',
					key: 'CreatedBy',
					type: 'text'
				},
				{
					text: 'Created Date',
					key: 'CreatedDate',
					type: 'date'
				},
				{
					text: 'Master Account',
					key: 'MasterAccount',
					type: 'text'
				},
				{
					text: 'Site Account',
					key: 'SiteAccount',
					type: 'text'
				},
				{
					text: 'Hire Period',
					key: 'HirePeriod',
					type: 'daterange'
				},
				{
					text: 'Equipment',
					key: 'Equipment',
					type: 'text'
				},
				{
					text: 'Customer Reference',
					key: 'CustomerReference',
					type: 'text'
				} // ,
				// {
				// 	text: 'Status',
				// 	key: 'Status',
				// 	type: 'text'
				// }
			],
			filters: new Map(),
			filtersUpdateTracker: 1, // this is used because vue2 cannot detect changes in Map
			showAllQuoteRequests: this.api.isInternalUser,
			filterQuoteRequestOptions: [
				{
					text: 'My Requests',
					value: false
				},
				{
					text: 'All Requests',
					value: true
				}
			],
			headers: [
				{
					text: 'ID',
					value: 'ID',
					align: 'left',
					class: 'pl-0',
					width: '95px'
				},
				{
					text: 'Created',
					value: 'CreatedDate',
					align: 'left',
					class: 'pl-0',
					width: '135px'
				},
				{
					text: 'Created By',
					value: 'CreatedBy',
					align: 'left',
					class: 'pl-0',
					width: '140px'
				},
				{
					text: 'Master Account',
					value: 'MasterAccountName',
					align: 'left',
					class: 'pl-0'
				},
				{
					text: 'Site Account',
					value: 'SiteAccountName',
					align: 'left',
					class: 'pl-0'
				},
				{
					text: 'Hire Period',
					value: 'Period',
					align: 'left',
					class: 'pl-0',
					width: '220px'
				},
				{
					text: 'Equipment',
					value: 'Equipments',
					align: 'left',
					class: 'pl-0'
				},
				{
					text: 'Customer Reference',
					value: 'CustomerReference',
					align: 'left',
					class: 'pl-0',
					width: '100px'
				} //,
				// {
				// 	text: 'Status',
				// 	value: 'Status',
				// 	align: 'left',
				// 	class: 'pl-0'
				// }
			],
			pagination: {
				sortBy: 'ID',
				page: 1,
				rowsPerPage: 25,
				descending: true
			},
			quoteRequests: [],

			isMyRequestOnly: false
		};
	},
	async mounted() {
		if (this.api.isInternalUser) {
			this.filterQuoteRequestOptions = this.filterQuoteRequestOptions.slice(1);
		}
		await this.getQuotesData();
	},
	computed: {
		searchChips() {
			return this.filtersUpdateTracker && this.filters;
		},
		quotesData() {
			return this.quoteRequests.map(qr => {
				return {
					ID: qr.id,
					CreateDate: this.formatPeriodDate(qr.createDate),
					CreateBy: qr.createByFirstName + ' ' + qr.createByLastName,
					MasterAccountName: qr.masterAccountName,
					SiteAccountName: qr.siteAccountName || 'N/A',
					Period: this.formatPeriodDate(qr.startDate) + '-' + this.formatPeriodDate(qr.endDate),
					Equipments:
						qr.equipment.length === 0
							? 'N/A'
							: qr.equipment[0].equipmentType + (qr.equipment.length > 1 ? `<br/><small>+ ${qr.equipment.length - 1} more</small>` : ''),
					CustomerReference: qr.customerReference || 'N/A',
					Status: qr.status
				};
			});
		},
		propApi() {
			return Object.assign(
				{
					filterConfig: this.filterConfig,
					filterByItems: this.filterByItems,
					showAllQuoteRequests: this.showAllQuoteRequests,
					filterQuoteRequestOptions: this.filterQuoteRequestOptions,
					showAllQuoteRequestsChanged: this.showAllQuoteRequestsChanged,
					formHeaderText: "Select the applicable site using 'Account Filters' to view quote details",
					accountItems: []
				},
				this.api
			);
		}
	},
	methods: {
		async getQuotesData() {
			const queryParams = {
				IsMyRequestOnly: !this.showAllQuoteRequests,
				MasterAccountId: this.api?.masterAccount?.masterAccountId,
				SortBy: this.pagination.sortBy,
				Descending: this.pagination.descending,
				Filters: Array.from(this.filters).map(e => ({ Category: e[1].key, Query: e[1].filterValue })),
				Page: this.pagination?.page || 0,
				PageSize: this.pagination?.rowsPerPage || 25
			};
			const url = `${process.env.VUE_APP_SITE_ROOT}/api/v1/mch/quotes/query`;
			const resp = await this._mxApi_postData(queryParams, url);

			if (resp && resp.status === 200 && resp.data.success) {
				this.quoteRequests = resp.data.result.results;
				this.pagination.rowsPerPage = resp.data.result.pageSize;
				this.pagination.page = resp.data.result.page;
				this.pagination.total = resp.data.result.total;
			}
		},
		formatPeriodDate(dateStr) {
			if (typeof dateStr !== 'string' || dateStr.length === 0) {
				return '';
			}
			return moment(dateStr, 'YYYY-MM-DD').format('DD/MM/YYYY');
		},
		async updatePagination() {
			// TODO: smart pagination
			//		when people at page 2 with page size 5, they are viewing item 5~10.
			//		now when they change page size to 10, with the default behavour, the request will looking for item 11~20 from db because it is page 2, and each page has 10 items
			//		what smart pagination means is, it will check if this is page size change, it will recaculate the page number, to make sure item 5~10 are still in the new page after data refreshed
			await this.getQuotesData();
		},
		async filterConfigChange(data) {
			this.filterConfig = Object.assign({}, data);
			await this.getQuotesData();
		},
		async showAllQuoteRequestsChanged(data) {
			this.showAllQuoteRequests = data.value;
			await this.getQuotesData();
		},
		async filterUpdateHandler(filter) {
			// add a new chip
			this.filters.set(`${filter.type}-${filter.key}`, filter);
			this.filtersUpdateTracker = (this.filtersUpdateTracker + 1) % 10000;
			// update query result
			await this.getQuotesData();
		},
		async removeSearchChip(filterKey) {
			this.filters.delete(filterKey);
			this.filtersUpdateTracker = (this.filtersUpdateTracker + 1) % 10000;
			await this.getQuotesData();
		},
		async removeAllChips() {
			this.filters.clear();
			this.filtersUpdateTracker = (this.filtersUpdateTracker + 1) % 10000;
			await this.getQuotesData();
		}
	}
};

export default sclQuotesView;
</script>
<style lang="scss">
.scl-form__select_small {
	display: inline-block;
}

.theme--light.v-icon.icon-orange {
	color: $color-orange !important;
}
#app .date-selector .scl-form__input input {
	margin-top: 13px !important;
}
.quote-id,
.scl-quotes-table__action-icon {
	color: #ff6105 !important;
}
.quote-dupicate {
	text-decoration: none;
}
.scl-table-filter__clear-btn {
	&:hover:before {
		background: transparent;
		opacity: 0;
	}
}
</style>
