/**
 * SclContactForm api
 */

// template
const template = `
	<div class="scl-feedback-form scl-form">
		{{ topic }}
		<v-container grid-list-xl fluid>
			<v-layout row wrap class="scl-feedback-form__layout underline mb-3 pb-2">
				<v-flex xs12 class="pa-0 mb-4">
					<h2 class="mb-3">Help us shape a better customer portal for your needs!</h2>
					<p class="font-weight-bold caption text-capitalize pb-3">personal information</p>
					<div class="mb-2 ml-2">
						<span class="title font-weight-black text-capitalize pr-2">{{accountData.title}} {{accountData.firstName}} {{accountData.lastName}}</span>
						<span>- {{accountData.role}}</span>
						<scl-tooltip :api="{icon: 'far fa-question-circle', message: propApi.tooltipMessage}"></scl-tooltip>
					</div>
					<p class="mb-2 ml-2">Phone: {{accountData.mobileNumber}}</p>
					<p class="mb-2 ml-2">Email: {{accountData.email}}</p>
				</v-flex>
			</v-layout>
			<v-layout row wrap class="scl-feedback-form__layout mb-3 pb-2">
				<v-flex xs12>
					<v-form ref="form" class="scl-form">
						<v-layout row wrap>
							<v-flex xs12 class="pa-0">
								<p class="font-weight-bold caption text-capitalize pb-0 mb-0">WHAT CAN WE HELP WITH?</p>
							</v-flex>
							<v-flex class="xs12 md3 pl-0">
								<v-select
									:items="propApi.selectItems"
									class="scl-form__input scl-form__select"
									outline
									v-model="feedback.topic"
									:rules="[rules.required]"
									label="Subject"
								></v-select>
							</v-flex>
							<v-flex class="xs12 md3 pl-0">
								<v-text-field
									class="scl-form__input"
									outline
									label="subject"
									:rules="[rules.required]"
									v-model="feedback.subject"
								></v-text-field>
							</v-flex>
							<v-flex xs12 class="pa-0">
								<p class="font-weight-bold caption text-capitalize mb-0 pb-0">your comments</p>
							</v-flex>
							<v-flex xs12 md3 class='pl-0'>
								<v-textarea
								class="scl-form__input scl-form__textarea"
								outline
								type="text"
								:rules="[rules.required]"
								height="138"
								v-model="feedback.message"
							></v-textarea>
							<scl-file-upload @selected-files="selectedFiles"></scl-file-upload>
							</v-flex>
						</v-layout>
					</v-form>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
