/**
 * SclConfirmationModal
 */

// template
const template = `
	<div class="scl-login-message-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-login-message-modal-component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-login-message-modal__container pt-0">
						<v-layout row wrap fill-height class="scl-login-message-modal__container-layout">
							<v-flex xs12 pa-0 d-flex align-center justify-space-between>
								<v-flex class="scl-login-message-modal__status" py-0>
									<small>State</small>
									<p>{{ modalData.state ? 'On' : 'Off' }}</p>
								</v-flex>
								<v-switch v-model="modalData.state" class="pt-0 mt-0 scl-form__switch"></v-switch>
							</v-flex>
							<v-flex xs12 pb-6>
								<i>*This message will be presented to all users on login.</i>
							</v-flex>

							<v-divider></v-divider>
							<v-flex xs12 class="pb-0">
								<p class="mb-0 font-weight-bold">Duration</p>
							</v-flex>
							<v-flex xs12>
								<v-menu
									ref="dateFromMenu"
									v-model="dateFromMenu"
									:close-on-content-click="false"
									:nudge-right="40"
									lazy
									transition="scale-transition"
									offset-y>
									<template v-slot:activator="{ on }">
										<v-text-field
										class="scl-form__date-select font-weight-black"
											v-model="dateFromFormatted"
											label="From"
											persistent-hint
											hide-details
											@blur="dateFrom = parseDate(dateFromFormatted)"
											v-on="on" append-icon="fas fas fa-calendar-alt"
										></v-text-field>
									</template>
									<v-date-picker v-model="dateFrom" no-title @input="dateFromMenu = false"></v-date-picker>
								</v-menu>

								<v-menu
									ref="dateToMenu"
									v-model="dateToMenu"
									:close-on-content-click="false"
									:nudge-right="40"
									hide-details
									lazy
									transition="scale-transition"
									offset-y
									full-width
									max-width="290px"
									min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field
										class="scl-form__date-select font-weight-black mt-3"
											v-model="dateToFormatted"
											label="To"
											persistent-hint
											hide-details
											@blur="dateTo = parseDate(dateToFormatted)"
											v-on="on" append-icon="fas fas fa-calendar-alt"
										></v-text-field>
										</template>
										<v-date-picker v-model="dateTo" :min="dateFrom" no-title @input="dateToMenu = false"></v-date-picker>
								</v-menu>
							</v-flex>
							<v-divider></v-divider>
							<v-flex xs12 class="pb-0">
								<p class="mb-0 font-weight-bold">Title</p>
							</v-flex>
							<v-flex xs12 class="pb-0">
								<v-text-field v-model="modalData.title" class="scl-form__input" hide-details outline solo label="Enter title"></v-text-field>
							</v-flex>
							<v-flex xs12 class="pb-0">
								<p class="mb-0 font-weight-bold">Message</p>
							</v-flex>
							<v-flex xs12 mb-3>
								<v-textarea v-model="modalData.message" height="200" class="scl-form__input" hide-details outline solo label="Enter title"></v-textarea>
							</v-flex>
							<v-flex xs12 class="pb-0">
								<p class="mb-0 font-weight-bold">Message Preview</p>
							</v-flex>
							<v-flex xs12 mb-3>
								<v-card class="mx-auto">
									<p v-html="modalData.message" class="scl-form__input pa-2" hide-details outline solo label="Enter title"></p>
								</v-card>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">save</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
