/**
 * SclExpectedOffhireModal
 */

// template
const template = `
	<div class="scl-send-invitation-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-send-invitation-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container class="scl-send-invitation-modal__container px-3 pt-0 pb-4">
						<v-layout row wrap fill-height>
							<v-flex xs12>
								<v-data-table
									:headers="listData.listHeaders"
									:items="listData.listItems"
									:rows-per-page-items="[5,10,15]"
									class="scl-send-invitation-modal__data-table"
								>
									<template v-slot:items="props">
										<td>{{ props.item.firstname }}</td>
										<td class="text-xs-left">{{ props.item.surname }}</td>
										<td class="text-xs-left">{{ props.item.email }}</td>
										<td class="text-xs-left">{{ props.item.capabilities }}</td>
										<td class="text-xs-left font-weight-black">{{ props.item.status }}</td>
										<td class="text-xs-left font-weight-black">{{ props.item.lastEmail }}</td>
										<td class="text-xs-left font-weight-black">
											<a @click.prevent="removeItem(props.index)">
												<v-icon size="16" color="#ff6105">fas fa-times</v-icon>
											</a>
										</td>
									</template>
								</v-data-table>
							</v-flex>
						</v-layout>
					</v-container>
					<v-card-actions class="scl-send-invitation-modal__actions justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">Send</v-btn>
				  	</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
