// component
const component = {
	name: 'SclBranchAutocomplete',
	data() {
		return {
			entries: [],
			isLoading: false,
			model: null,
			search: null
		};
	},
	computed: {
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.SuburbName} ${entry.Postcode} ${entry.StateCode}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	watch: {
		search() {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// Lazily load input items
			window
				.fetch('./assets/data/suburbs.json')
				.then(res => res.json())
				.then(res => {
					this.entries = res;
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => (this.isLoading = false));
		}
	},
	methods: {
		clear() {
			this.model = null;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
