// component
const component = {
	name: 'SclAccountAccessModal',
	data() {
		return {
			dialog: false,
			accountAccess: []
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.accountAccess ? Array.isArray(obj.accountAccess) : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '770',
					persistent: false,
					modalLabel: 'view business access',
					accountAccess: []
				},
				this.api
			);
		}
	},
	methods: {},
	watch: {
		'propApi.accountAccess': function (newVal) {
			this.accountAccess = newVal;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
