/**
 * SclThresholdModal
 */

// template
const template = `
	<div class="scl-threshold-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-threshold-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{ title }}  ss
								</legend>
							</v-card-title>
							<v-btn icon dark @click="closeModal">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl fluid class="pt-0">
						<v-layout row wrap>
							<v-flex class="pb-2 pt-0" xs12>
								<h3 v-if="currentThreshold.customer">{{ currentThreshold.customer }}</h3>
								<h3 v-else>Global threshold</h3>
							</v-flex>
							<v-flex class="pb-4 pt-0" xs12>
								<p class="mb-0">Change Threshold (1-30 Days)</p>
							</v-flex>
							<v-flex class="d-flex justify-space-between align-center pt-0" xs12>
								<v-text-field
									v-model="accountItem.threshold"
									class="scl-form__step-number font-weight-bold"
									hide-details
									solo
									flat
									type="number"
									label="Number"
									prepend-icon="fas fa-minus"
									append-outer-icon="fas fa-plus"
									@click:append-outer="increment('threshold')"
									@click:prepend="decrement('threshold')"
								></v-text-field>
							</v-flex>
						</v-layout>


						<div v-if="currentThreshold.thresholdType==='past-due-threshold'">
							<v-divider></v-divider>

							<v-layout row wrap>
								<v-flex class="pb-2" xs12>
									<h3>Self registration</h3>
								</v-flex>
								<v-flex class="pb-4 pt-0" xs12>
									<p class="mb-0">Assign capabilities (Please select at least one)</p>
								</v-flex>

								<v-flex pb-0 xs4>
									<div class="d-inline-flex">
										<v-checkbox
													v-model="accountData.myHires"
													label="My hires"
													hide-details
													class="mt-0 pt-0"></v-checkbox>
									</div>
								</v-flex>
								<v-flex pl-0 xs8>
										<v-radio-group :disabled="!accountData.myHires" v-model="accountData.myHiresWithRates" class="mt-0 ml-0 pt-0 d-flex">
											<v-layout row wrap>
												<v-flex px-0 xs5>
													<v-radio label="With rates" value="With Rates"></v-radio>
												</v-flex>
												<v-flex px-0 xs7>
													<v-radio label="Without rates" value="Without Rates"></v-radio>
												</v-flex>
											</v-layout>
										</v-radio-group>
								</v-flex>

								<v-flex xs12>
									<v-checkbox
										v-model="accountData.myInvoices"
										label="My Invoices"
										hide-details
										class="mt-0 pt-0 mb-3"
									></v-checkbox>
								</v-flex>

								<v-flex class="pb-4 pt-0" xs12>
									<p class="mb-0">Maximum number of sites</p>
								</v-flex>

								<v-flex class="d-flex justify-space-between align-center pt-0" xs12>
									<v-text-field
										v-model="accountItem.numberOfSites"
										class="scl-form__step-number font-weight-bold"
										hide-details
										solo
										flat
										type="number"
										label="Number"
										prepend-icon="fas fa-minus"
										append-outer-icon="fas fa-plus"
										@click:append-outer="increment('site')"
										@click:prepend="decrement('site')"
									></v-text-field>
								</v-flex>
							</v-layout>
						</div>

					</v-container>
					<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
						<v-btn color="accent" class="scl-component scl-button" :class="{'ml-2': $vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.smAndDown}" @click.prevent="submit">Save</v-btn>
				  </v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
