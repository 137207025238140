// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';

// component
const component = {
	name: 'SclUpdateUserAccessModal',
	mixins: [mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.modalData ? Array.isArray(obj.modalData) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'user-access-event',
					width: '670px',
					modalLabel: '',
					postbackURL: '',
					postbackPipe: '',
					accountData: {}
				},
				this.api
			);
		}
	},
	data() {
		return {
			accountData: {
				coatesHireOwner: false,
				coatesHireManager: false,
				myHires: false,
				myHiresWithRates: '',
				myHiresWithEOHD: '',
				myInvoices: '',
				myPricebook: false,
				myPricebookWithExport: '',
				myQuoteRequests: false,
				myTelemetry: false,
				addOwnerAccess: false,
				addManagerAccess: false
			},
			tooltips: {},
			dialog: false,
			isManager: null,
			managerType: null
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, () => {
			this.dialog = true;
		});
	},
	watch: {
		managerType: {
			handler(newVal) {
				if (!this.accountData) {
					return;
				}
				if (newVal == 'Admin') {
					this.accountData.coatesHireOwner = true;
					this.accountData.coatesHireManager = false;
				}
				if (newVal == 'Manager') {
					this.accountData.coatesHireOwner = false;
					this.accountData.coatesHireManager = true;
				}
			},
			deep: true,
			immediate: true
		},
		isManager: {
			handler(newVal) {
				if (!this.accountData) {
					return;
				}
				if (!newVal) {
					this.managerType = null;
					this.accountData.coatesHireOwner = false;
					this.accountData.coatesHireManager = false;
				} else {
					//default to Admin if enabled
					if (this.managerType === null || this.managerType === '') this.managerType = 'Admin';

					this.accountData.managerType = this.managerType;
					this.accountData.coatesHireOwner = this.accountData.managerType === 'Admin';
					this.accountData.coatesHireManager = this.accountData.managerType === 'Manager';
				}
			},
			deep: true,
			immediate: true
		},
		'propApi.accountData': {
			handler(newVal) {
				this.accountData = newVal;
				if (newVal?.coatesHireOwner) {
					this.isManager = true;
					this.managerType = 'Admin';
				}
				if (newVal?.coatesHireManager) {
					this.isManager = true;
					this.managerType = 'Manager';
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = {
					userId: this.accountData.userId,
					accountData: this.accountData
				};

				const response = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (response.status === 200 && response.data.success) {
					const snackBarMessage = {
						copy: response.data.message || 'User access successfully updated',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'User access update failed, please try again later.';
					const snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
