/**
 * SclAccountFilter api
 *
 */

const template = `
	<div class="scl-account-filter">
		<v-form class="scl-form fill-height">
			<v-toolbar flat class="scl-account-filter__content">
				<v-toolbar-title class="d-flex align-center scl-account-filter__toolbar-title">
					<p class="mb-0 font-weight-bold pr-3">{{accountName}}</p>
					<a class="" @click="clickEvent('change-account-event')">Select customer</a>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items class="d-flex align-center scl-account-filter__filter-items">
					<v-chip v-if="filterData.length > 0" class="ma-2 scl-account-filter__filter-type-chip font-weight-bold" dark color="#ff6105">Filtered</v-chip>
					<div v-if="filterData.length > 0" class="scl-account-filter__filter-divider ml-2 mr-3"></div>
					<a class="font-weight-bold scl-account-filter__filter-link" @click="clickEvent('edit-access-event', ['emitData', 'account-filter-event'])">
						<v-icon color="#ff6105" size="14" class="pr-1">fas fa-sliders-h</v-icon> Account filters
					</a>
				</v-toolbar-items>
			</v-toolbar>
		</v-form>
	</div>
`;

export default template;
