/**
 * SclInviteModal
 */

// template
const template = `
	<div class="scl-invite-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-invite-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-4" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">
									{{propApi.modalLabel}}
								</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form ref="form">
						<v-container grid-list-xl class="scl-invite-modal__container pt-0">
							<v-layout row wrap px-2 class="scl-invite-modal__container-layout">
								<v-flex xs12 pa-0>
									<p class="mb-4">Enter the email address of your work colleague to invite them to set up access to My Coates Hire</p>
									<v-text-field
										v-model="accountItem.emailAddress"
										:rules="[rules.required, rules.email]"
										placeholder="Email Address"
										class="scl-form__input"
										outline solo
										required
									></v-text-field>
								</v-flex>
								<v-flex xs12 class="px-0">
									<p class="body-1">Regards,<br>The My Coates Hire Team</p>
								</v-flex>
							</v-layout>
						</v-container>
						<v-card-actions class="justify-end pa-3" :class="{'d-flex': $vuetify.breakpoint.smAndDown}">
							<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">cancel</v-btn>
							<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit" >submit</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
