// Mixins
import mxApi from '@portals/mixins/common/mxApi.js';
import mxAccount from '@portals/mixins/global/mxAccount.js';

// component
const component = {
	name: 'SclFeedbackForm',
	mixins: [mxApi, mxAccount],
	data() {
		return {
			dialog: false,
			topic: '',
			response: null,
			message: null,
			feedback: {
				topic: '',
				subject: '',
				message: '',
				files: []
			},
			accountData: [],
			valid: false,
			rules: {
				required: value => !!value || 'This field is Required.'
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.name ? typeof obj.name === 'string' : true));
				set.add(!!(obj.role ? typeof obj.role === 'string' : true));
				set.add(!!(obj.tooltipMessage ? typeof obj.tooltipMessage === 'string' : true));
				set.add(!!(obj.email ? typeof obj.email === 'string' : true));
				set.add(!!(obj.phone ? typeof obj.phone === 'string' : true));
				set.add(!!(obj.selectItems ? Array.isArray(obj.selectItems) : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			if (this.propApi.response != null && this.propApi.response == 'True') {
				this.dialog = true;
				this.snackBarMessage = {
					copy: this.propApi.message,
					type: 'success'
				};
				this.clickEvent('snackbar-event', this.snackBarMessage);
			}
		});
		this.$root.$on(this.propApi.event, () => {
			this.submit();
		});
	},
	watch: {
		'propApi.data': {
			handler(newVal) {
				this.accountData = this._mxAccount_generateFormDetailsData(newVal);
			},
			deep: true,
			immediate: true
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					personal: {
						name: 'Mr. Johnson',
						role: 'Admin / Assistant',
						email: 'mjohnson@email.com',
						phone: '0414 444 444'
					},
					tooltipMessage: 'If you want to change your information, go to Profile Details',
					selectItems: [],
					event: 'feedback-form-event'
				},
				this.api
			);
		}
	},
	methods: {
		selectedFiles(data) {
			this.feedback.files = data;
		},
		clickEvent(event, data) {
			this.$root.$emit(event, data);
		},
		async submit() {
			if (!this.$refs.form.validate()) {
				return false;
			}
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				const payload = [
					{
						feedback: this.feedback,
						account: this.accountData
					}
				];
				const result = await this._mxApi_postData(payload, this.propApi.postbackURL);

				if (result?.status === 200 && result?.data.success) {
					const snackBarMessage = {
						copy: result.data.message || 'Feedback sent successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
				} else {
					this.errorMessage = 'Update failed, please try again later.';
					const snackBarMessage = {
						copy: result.data.message || this.errorMessage,
						type: 'error'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
				}
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
