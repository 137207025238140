/**
 * SclChangeAccountModal
 */

// template
const template = `
	<div class="scl-invoice-amount-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-invoice-amount-modal__component"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button d-none"
						depressed
						v-on="on"
					>
					account access
					</v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{propApi.modalLabel}}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-container grid-list-xl class="scl-invoice-amount-modal__container pt-0">
						<v-layout row wrap fill-height px-1 mt-0 class="scl-form">
							<v-flex xs12>
								<v-layout row wrap>
									<v-flex xs2 pr-0 pb-1 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
										<span class="subheading">Invoice no:</span>
									</v-flex>
									<v-flex xs12 md6 pb-1 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
										<span class="font-weight-bold title-2">{{invoice.invoiceNo}}</span>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12>
								<v-layout row wrap>
									<v-flex xs2 pr-0 py-1 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
										<span class="subheading">Invoice Date:</span>
									</v-flex>
									<v-flex xs12 md6 py-1 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
										<span class="font-weight-bold title-2">{{invoice.invoiceDate}}</span>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12>
								<v-layout row wrap>
									<v-flex xs2 pr-0 py-1 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
										<span class="subheading">Site:</span>
									</v-flex>
									<v-flex xs12 md9 py-1 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
										<span class="font-weight-bold title-2">{{invoice.site}}</span>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12>
								<v-layout row wrap>
									<v-flex xs2 pr-0 py-1 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
										<span class="subheading">PO No:</span>
									</v-flex>
									<v-flex xs12 md9 py-1 :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
										<span class="font-weight-bold title-2">{{invoice.purchaseNo}}</span>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 pb-0 class="underline mb-4" :class="{'px-0': $vuetify.breakpoint.mdAndUp}">
								<v-data-table
								:headers="results.headers"
								:items="results.tableItems"
								hide-actions
								class="scl-history-modal__data-table"
								>
									<template v-slot:items="props">
										<td class="text-xs-left body-1 pl-0">{{ props.item.paymentId }}</td>
										<td class="text-xs-left body-1">{{ props.item.paymentMethod }}</td>
										<td class="text-xs-left body-1">{{ props.item.paymentDate }}</td>
										<td class="text-xs-left font-weight-bold body-1">{{ props.item.amount }}</td>
									</template>
								</v-data-table>
							</v-flex>
							<v-flex xs12 class="underline" :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
								<p class="scl-invoice-amount-modal__credit mb-0">Credit Notes</p>
							</v-flex>
							<v-flex xs12 v-for="(row, i) in credits" :key="i" class="underline" :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
								<a :href="row.typeLink" target="_blank">{{row.creditInvoiceNo}}</a>
							</v-flex>
						</v-layout>
					</v-container>

					<v-container class="scl-invoice-amount-modal__footer">
						<v-layout row wrap fill-height>
							<v-flex xs12 :class="{'pl-0': $vuetify.breakpoint.mdAndUp}">
								<p class="mb-0 caption text-capitalize"><i>If you require additional information please contact your nearest branch.</i></p>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
