// component
const component = {
	name: 'SclCardImage',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.overlayTextColour ? typeof obj.variant === 'string' : true));
				set.add(!!(obj.variant ? typeof obj.variant === 'string' : true));
				set.add(!!(obj.textBottom ? typeof obj.textBottom === 'boolean' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					overlayTextColour: null,
					variant: null,
					textBottom: false,
					outline: false
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
