/**
 * SclContactList
 */

// template
const template = `
	<div class="scl-contact-list">
		<v-container grid-list-xl fluid>
			<v-layout row wrap class="mb-3 pb-2">
				<v-flex xs12 class="pa-0 mb-4 underline" v-for="(item, i) in listData" :key="i">
					<p class="font-weight-bold scl-account-detail__caption caption mb-1 text-uppercase">{{item.role}}</p>
					<v-layout column>
						<v-flex class="scl-contact-list__contact ml-2" xs12 v-for="(itemCard, k) in item.contact" :key="k">
							<scl-card-contact-simple :api="{icon: 'fas fa-id-card', name: itemCard.name, phone: itemCard.phone, email: itemCard.email, tooltipMessage: 'Contact me to add new users or to change your access'}"></scl-card-contact-simple>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
