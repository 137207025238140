<template>
	<div class="d-flex">
		<v-autocomplete
			v-model="selectedHour"
			:items="hours"
			class="scl-form__input scl-form__select"
			:append-icon="readonly ? '' : 'fas fa-chevron-down'"
			@change="selectTime"
			:disabled="readonly"
			:readonly="readonly"
			outline
			data-cy="selected Hour"
		></v-autocomplete>
		<span class="scl-form__colon"> : </span>
		<v-autocomplete
			v-model="selectedMinute"
			:items="minutes"
			class="scl-form__input scl-form__select"
			:append-icon="readonly ? '' : 'fas fa-chevron-down'"
			@change="selectTime"
			:disabled="readonly"
			:readonly="readonly"
			outline
			data-cy="selected Minute"
		></v-autocomplete>
	</div>
</template>

<script>
export default {
	name: 'SclTimeSelect',
	props: {
		assignTime: {
			type: String,
			required: true
		},
		readonly: {
			type: Boolean
		}
	},
	data() {
		const arrHours = Array(24)
			.fill(0)
			.map((_, i) => {
				return ('0' + i).replace(/\d(\d\d)/g, '$1');
			});
		const arrMin = Array(60)
			.fill(0)
			.map((_, i) => {
				return ('0' + i).replace(/\d(\d\d)/g, '$1');
			});
		return {
			selectedTime: '0000',
			selectedHour: '00',
			selectedMinute: '00',
			hours: arrHours,
			minutes: arrMin
		};
	},
	mounted() {
		this.selectedHour = this.assignTime ? this.assignTime.slice(0, 2) : 0;
		this.selectedMinute = this.assignTime ? this.assignTime.slice(-2) : 0;
		this.selectedTime = this.selectedHour + this.selectedMinute;
	},
	methods: {
		selectTime() {
			this.selectedTime = this.selectedHour + this.selectedMinute;
			this.$emit('time-selected', this.selectedTime);
		}
	}
};
</script>
