// component
const component = {
	name: 'SclCardSolid',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.href ? typeof obj.href === 'string' : true));
				set.add(!!(obj.img ? typeof obj.img === 'string' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					href: null,
					img: null,
					title: null,
					icon: null
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
