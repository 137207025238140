import axios from 'axios';
<template>
	<div class="scl-edit-access-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog v-model="dialog" :width="propApi.width" :persistent="propApi.persistent" content-class="scl-edit-access-modal-component">
				<template v-slot:activator="{ on }">
					<v-btn color="accent" class="scl-component scl-button d-none" depressed v-on="on"> account access </v-btn>
				</template>
				<v-card class="fill-height">
					<v-toolbar class="scl-dialog__toolbar mb-3" flat>
						<v-layout row>
							<v-card-title class="pa-0" primary-title>
								<legend class="scl-dialog__toolbar-title">{{ propApi.modalLabel }}</legend>
							</v-card-title>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-layout>
					</v-toolbar>
					<v-form v-model="valid" class="scl-form scl-edit-access-modal__form">
						<v-container grid-list-xl class="scl-edit-access-modal__container pt-0">
							<v-layout row wrap fill-height class="">
								<v-flex xs3 :class="{ 'd-none': $vuetify.breakpoint.smAndDown }" class="text-uppercase">master account</v-flex>
								<v-flex xs5 :class="{ 'd-none': $vuetify.breakpoint.smAndDown }" class="text-uppercase">site account</v-flex>
								<v-flex xs12 md4 class="text-xs-right">
									<a href="javascript:void(9);" @click.prevent="resetSelection">Reset selection</a>
									<a href="javascript:void(9);" @click.prevent="allSelection" class="pl-3">Select all</a>
									<a href="javascript:void(9);" @click.prevent="clearSelection" class="pl-3">Clear all</a>
								</v-flex>
							</v-layout>
							<v-layout row wrap v-for="(master, masterIndex) in accounts" :key="masterIndex" class="topline">
								<v-flex xs12 :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }" class="text-uppercase">master account</v-flex>
								<v-flex xs12 md3 class="text-uppercase">
									<div class="font-weight-bold title">
										<v-checkbox
											class="scl-edit-access-modal__checkbox"
											v-model="master.value"
											:indeterminate="master.indeterminate"
											:label="master.masterAccountName"
											@change="toggleAllSiteAccounts(masterIndex, $event)"
										></v-checkbox>
									</div>
									<div class="font-weight-bold subtitle">Customer Code</div>
									<div class="caption">{{ master.masterAccountCode }}</div>
								</v-flex>
								<v-flex xs12 md9 row>
									<v-layout row wrap>
										<v-flex xs12 :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }" class="text-uppercase">site account</v-flex>
										<v-flex xs12 md4 pb-0 v-for="(site, index) in master.siteAccounts" :key="index">
											<v-checkbox
												v-model="site.value"
												:label="site.siteAccountName"
												@change="setSiteAccountValue(masterIndex, index, $event)"
												class="scl-edit-access-modal__checkbox scl-edit-access-modal__checkbox--subcategory"
											></v-checkbox>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
					<v-card-actions class="scl-edit-access-modal__actions pa-4">
						<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">Cancel</v-btn>
						<v-btn v-if="propApi.method === 'emitData'" color="accent" class="scl-component scl-button ml-2" @click="onEmitData">Save</v-btn>
						<v-btn v-if="propApi.method === 'apiData' || !propApi.method" color="accent" class="scl-component scl-button ml-2" @click="submit"
							>Save</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import set from 'lodash/set';

// component
export default {
	name: 'SclSelectAccessModal',
	data() {
		return {
			dialog: false,
			filtered: false,
			method: 'apiData',
			methodTarget: '',
			accountsState: undefined,
			valid: true
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, event => {
			this.dialog = true;
			if (!event) {
				return;
			}
			this.method = event[0];
			this.methodTarget = event[1];
		});
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.method ? typeof obj.method === 'string' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.postbackPipe ? typeof obj.postbackPipe === 'string' : true));
				set.add(!!(obj.event ? typeof obj.event === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		accounts: {
			get() {
				return this.propApi.accountData.map((account, i) => {
					const item = this.accountsState?.[i] || account;
					const { siteAccounts, ...rest } = item;
					const totalSelectedAccounts = siteAccounts.filter(({ value }) => value).length;
					return {
						...rest,
						siteAccounts: siteAccounts.map(v => ({ ...v })),
						indeterminate: totalSelectedAccounts > 0 && siteAccounts.length !== totalSelectedAccounts,
						value: totalSelectedAccounts !== 0
					};
				});
			},
			set(accounts) {
				this.accountsState = accounts;
			}
		},
		// props defaults
		propApi() {
			return Object.assign(
				{
					event: 'select-access-event',
					postbackEvent: 'select-account-access-event',
					width: '870',
					persistent: false,
					userId: 0,
					modalLabel: '',
					postbackURL: '',
					postbackPipe: '',
					method: 'apiData',
					accountData: []
				},
				this.api
			);
		},
		noFilter() {
			let filter = true;
			this.accounts.forEach(item => {
				if (!item.value) {
					filter = false;
				}
				item.siteAccounts.forEach(site => {
					if (!site.value) {
						filter = false;
					}
				});
			});
			return filter;
		}
	},
	methods: {
		toggleAllSiteAccounts(idx, $event) {
			debugger;
			const { accounts } = this;
			this.accounts = set(
				accounts,
				[idx, 'siteAccounts'],
				accounts[idx].siteAccounts.map(account => ({
					...account,
					value: $event
				}))
			);
		},
		setSiteAccountValue(masterIdx, childIdx, $event) {
			this.accounts = set(this.accounts, [masterIdx, 'siteAccounts', childIdx, 'value'], $event);
		},
		async submit() {
			this.errorMessage = null;
			this.$root.$emit('loadingSpinner', true);
			try {
				this.$root.$emit(this.propApi.postbackEvent, this.accounts);
				this.dialog = false;
			} catch (e) {
				console.error(e);
				const snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
				this.$root.$emit('snackbar-event', snackBarMessage);
			} finally {
				this.$root.$emit('loadingSpinner', false);
			}
			this.dialog = false;
		},

		async getResults(data, url) {
			return await axios.post(url, data);
		},
		allSelection() {
			this.accounts.forEach((_, i) => this.toggleAllSiteAccounts(i, true));
		},
		clearSelection() {
			this.accounts.forEach((_, i) => this.toggleAllSiteAccounts(i, false));
		},
		resetSelection() {
			this.accounts = this.propApi.accountData;
		},
		onEmitData() {
			this.$root.$emit(this.methodTarget, this.accounts);
			this.dialog = false;
		},
		closeModal() {
			this.dialog = false;
		}
	},
	template: null
};
</script>

<style lang="scss">
@import './_SclSelectAccessModal.scss';
</style>
