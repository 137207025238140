<template>
	<div class="scl-alert-modal scl-dialog">
		<div class="text-xs-center">
			<v-dialog v-model="dialog" :width="propApi.width" :persistent="propApi.persistent" content-class="scl-modal__component">
				<template v-slot:activator="{ on }">
					<v-btn color="accent" class="scl-component scl-button d-none" depressed v-on="on"> modal </v-btn>
				</template>

				<v-form ref="form" v-model="valid">
					<v-card class="scl-report-modal-form scl-form">
						<v-card-title class="scl-dialog__toolbar px-4 pt-4 pb-2" primary-title>
							<legend class="scl-dialog__toolbar-title">
								{{ preference.name || propApi.modalLabel }}
							</legend>
							<v-btn icon dark @click="dialog = false">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-card-title>

						<v-card-text grid-list-xl fluid class="pa-0">
							<header class="px-4 pt-1">
								<h3 class="mt-2" v-if="propApi.subheadingLabel">{{ propApi.subheadingLabel }}</h3>
								<p v-if="propApi.schedule">{{ propApi.schedule }}</p>
								<p v-if="preference.description">{{ preference.description }}</p>
							</header>

							<v-alert :value="!!propApi.alertLabel" color="red lighten-3" type="error" class="mx-4">
								{{ propApi.alertLabel }}
							</v-alert>

							<section
								v-for="(section, i) in filteredModalData"
								:key="i"
								:class="`px-4 ${section.overline ? 'scl-alert-modal__section--overline pt-2' : ''}`"
							>
								<h3>{{ section.title }}</h3>
								<p v-if="section.description" class="mb-0">{{ section.description }}</p>
								<fieldset v-if="section.type === 'checkbox'" class="scl-alert-modal__checkboxes py-2">
									<v-checkbox
										hide-details
										v-for="checkbox in section.options"
										v-model="preference[section.key]"
										multiple
										:key="checkbox.label"
										:label="checkbox.label"
										:rules="[mustHaveMethod]"
										:value="checkbox.value"
									></v-checkbox>
								</fieldset>
								<v-radio-group
									class="my-2 scl-alert-modal__radio-buttons"
									hide-details
									v-if="section.type === 'radio'"
									v-model="preference[section.key]"
									@change="onOptInOut"
								>
									<v-radio v-for="radio in section.options" :key="radio.label" :label="radio.label" :value="radio.value"></v-radio>
								</v-radio-group>
								<v-select
									class="pt-0"
									v-if="section.type === 'select'"
									v-model="preference[section.key]"
									item-text="label"
									item-value="value"
									required
									:items="section.options"
									:name="section.title"
								></v-select>
								<v-alert :value="errorMessages[section.key]" color="red lighten-3" class="mt-2" type="error">
									{{ errorMessages[section.key] }}
								</v-alert>
							</section>
						</v-card-text>

						<v-card-actions class="justify-end px-4 pb-4 scl-alert-modal__section--underline" :class="{ 'd-flex': $vuetify.breakpoint.smAndDown }">
							<v-btn color="accent" class="scl-component scl-button" outline @click.prevent="closeModal">Cancel</v-btn>
							<v-btn color="accent" class="scl-component scl-button ml-2" @click.prevent="submit" :disabled="!isValid">save</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import merge from 'lodash/merge';
import mxApi from '@portals/mixins/common/mxApi';
import { AlertTypes, CapitalCities, DayOfWeek, Frequencies, Hours, Methods } from '@portals/constants';
import { capitalise, formatText } from '@portals/utils';

const toOptions = obj =>
	Object.values(obj).map(value => ({
		value,
		label: capitalise(value)
	}));

const sclAlertModal = {
	name: 'SclAlertModal',
	mixins: [mxApi],
	props: {
		api: {
			type: Object,
			required: false,
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.alertLabel ? typeof obj.alertLabel === 'string' : true));
				set.add(!!(obj.description ? typeof obj.alertLabel === 'string' : true));
				set.add(!!(obj.event ? typeof obj.alertLabel === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.postbackURL ? typeof obj.postbackURL === 'string' : true));
				set.add(!!(obj.modalData ? typeof obj.modalData === 'object' : true));
				set.add(!!(obj.modalLabel ? typeof obj.modalLabel === 'string' : true));
				set.add(!!(obj.subheadingLabel ? typeof obj.subheadingLabel === 'string' : true));
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.userId ? typeof obj.width === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		errorMessages() {
			// Vee-Validated might have a nicer way to do this but we map input error messages
			// back to their group so we can display validation under a collection of inputs
			const { modalData } = this.propApi;
			const { inputs = [] } = this.$refs.form || {};
			const errors = inputs.reduce((acc, { label, validations }) => ({ ...acc, [label]: validations }), {});
			return modalData.reduce((acc, { key, options = [] }) => {
				const items = options.flatMap(({ label }) => errors[label] || []);
				if (!items.length) {
					return acc;
				}
				return {
					...acc,
					[key]: items[0]
				};
			}, {});
		},
		propApi() {
			const { type = AlertTypes.Alert } = this.api;
			const method = {
				key: 'methods',
				options: [
					{
						label: Methods.Email,
						value: Methods.Email
					},
					{
						label: Methods.SMS,
						value: Methods.SMS
					}
				],
				overline: true,
				title: `How would you like to receive this ${type}?`,
				type: 'checkbox',
				visibleIf: { agree: true }
			};

			if (this.api.emailOnly) {
				method.description = `This ${type} will be sent via email`;
				method.options = [];
			}

			return merge(
				{
					alertLabel: '',
					description: '',
					disabled: false,
					event: 'event-alert-modal',
					persistent: true,
					postbackURL: '',
					modalData: [
						{
							key: 'agree',
							options: [
								{
									label: `Yes, send me ${type}s`,
									value: true
								},
								{
									label: "No, don't send",
									value: false
								}
							],
							title: `Would you like us to send you ${type}s?`,
							type: 'radio'
						},
						method,
						{
							key: 'frequency',
							options: this.formatFrequencies(Frequencies),
							overline: true,
							title: 'Frequency',
							type: 'select',
							visibleIf: { agree: true }
						},
						{
							key: 'day',
							options: toOptions(DayOfWeek),
							title: 'Which day',
							type: 'select',
							visibleIf: {
								agree: true,
								frequency: Object.values(Frequencies).filter(v => [Frequencies.ThreeTimesAWeek, Frequencies.Daily].includes(v))
							}
						},
						{
							key: 'city',
							options: toOptions(CapitalCities),
							title: 'Timezone',
							type: 'select',
							visibleIf: { agree: true }
						},
						{
							key: 'time',
							options: Hours,
							title: 'What time',
							type: 'select',
							visibleIf: { agree: true }
						}
					],
					modalLabel: '',
					schedule: '',
					type: AlertTypes.Alert,
					width: '528px',
					userId: null
				},
				{
					...this.api,
					disabled: Boolean(this.api.disabled)
				}
			);
		},
		filteredModalData() {
			return this.propApi.modalData?.filter(({ visibleIf = {} }) => {
				const invalid = Object.entries(visibleIf).filter(([key, value]) => {
					if (Array.isArray(value)) {
						return value.includes(this.preference[key]);
					}
					return this.preference[key] !== value;
				});
				return !invalid.length;
			});
		},
		isValid() {
			const { disabled, postbackURL } = this.propApi;
			if (disabled) {
				return false;
			}
			return !!(postbackURL && this.valid);
		}
	},
	data() {
		return {
			dialog: false,
			modalLabel: null,
			preference: {
				agree: false,
				city: CapitalCities.Sydney,
				day: DayOfWeek.Monday,
				frequency: Frequencies.Weekly,
				isEmail: false,
				isSMS: false,
				methods: [],
				time: Hours[14]
			},
			valid: false
		};
	},
	mounted() {
		this.$root.$on(this.propApi.event, ({ isEmail, isSMS, ...preference } = {}) => {
			this.dialog = true;
			const methods = [];

			if (isSMS) {
				methods.push(Methods.SMS);
			}

			if (isEmail) {
				methods.push(Methods.Email);
			}

			this.preference = {
				...this.preference,
				agree: !!(isEmail || isSMS),
				...omitBy(preference, v => isNil(v) || v === ''),
				methods
			};
		});
	},
	methods: {
		closeModal() {
			this.dialog = false;
		},
		getPreferences() {
			const { userId } = this.propApi;
			let { agree, methods, ...preference } = this.preference;

			// If user has chosen not to get the alert turn off all settings
			if (!agree) {
				preference = {
					...preference,
					city: undefined,
					day: undefined,
					frequency: undefined,
					isEmail: false,
					isSMS: false,
					time: undefined
				};
			} else {
				preference = {
					...preference,
					isEmail: methods.includes(Methods.Email),
					isSMS: methods.includes(Methods.SMS)
				};
			}

			return {
				userId,
				preference
			};
		},
		formatFrequencies(obj) {
			return Object.values(obj).map(value => {
				let label = capitalise(formatText(value, 'human'));

				if (value === Frequencies.ThreeTimesAWeek) {
					label = this.$t(`profile.${Frequencies.ThreeTimesAWeek}`);
				}

				return {
					value,
					label
				};
			});
		},
		mustHaveMethod() {
			const { methods } = this.preference;
			return methods.length ? true : 'Select an option for where you would like your alerts to be sent';
		},
		onOptInOut(value) {
			if (this.api.emailOnly) {
				if (value) {
					this.preference.methods = [Methods.Email];
				} else {
					this.preference.methods = [];
				}
			}
		},
		async submit() {
			this.errorMessage = null;
			let snackBarMessage = {};

			try {
				this.$root.$emit('loadingSpinner', true);
				const response = await this._mxApi_postData(this.getPreferences(), this.propApi.postbackURL);
				if (response.status === 200 && response.data.success) {
					snackBarMessage = {
						copy: response.data.message || 'Details updated successfully',
						type: 'success'
					};
					this.$root.$emit('snackbar-event', snackBarMessage);
					location.reload();
					this.dialog = false;
				} else {
					this.errorMessage = 'Details update failed, please try again later.';
					snackBarMessage = {
						copy: response.data.message || this.errorMessage,
						type: 'error'
					};
				}
			} catch (e) {
				snackBarMessage = {
					copy: 'Update failed, please try again later.',
					type: 'error'
				};
			}

			this.$root.$emit('snackbar-event', snackBarMessage);
			this.$root.$emit('loadingSpinner', false);
		}
	}
};

export default sclAlertModal;
</script>

<style lang="scss">
@import './_SclAlertModal.scss';
</style>
